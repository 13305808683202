import styled, { css } from "styled-components";

const Sizes = {
  large: css`
    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        font-size: 16px;
        margin-bottom: 20px;
      }

      @media ${theme.mediaQueries.smUp} {
        font-size: 18px;
        margin-bottom: 24px;
      }

      @media ${theme.mediaQueries.lgUp} {
        font-size: 20px;
        margin-bottom: 30px;
      }
    `}
  `,
  small: css`
    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        font-size: 14px;
        margin-bottom: 15px;
      }

      @media ${theme.mediaQueries.smUp} {
        font-size: 16px;
        margin-bottom: 18px;
      }
    `}
  `,
};

export const Heading = styled.h1`
  white-space: pre-line;
  text-transform: uppercase;
  color: ${({ theme, color }) => theme.colors[color]};
  line-height: 1.3;
  letter-spacing: 0.5px;
  font-weight: 600;

  ${({ size }) => Sizes[size]}

  ${({ disableMargin }) =>
    disableMargin &&
    `
    margin: 0 !important;
  `};
`;