import React from "react";

import * as S from "./Switch.styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";

const SunIcon = (
  <FontAwesomeIcon icon={faSun} style={{ right: "50%" }} color="#FFF" />
);

const MoonIcon = (
  <FontAwesomeIcon icon={faMoon} color="#FFF" />
);

const Switch = (props) => (
  <S.AppSwitch {...props} uncheckedIcon={SunIcon} checkedIcon={MoonIcon} />
);

export default Switch;
