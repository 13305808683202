import React from "react";
import PropTypes from "prop-types";

import * as S from "./Text.styles";

const TextComponent = ({
  component,
  color,
  weight,
  children,
  size,
  textTransform,
  ...restProps
}) => (
  <S.Text
    {...{
      color,
      weight,
      size,
      textTransform,
    }}
    {...restProps}
    as={component}
  >
    {children}
  </S.Text>
);

TextComponent.defaultProps = {
  component: "p",
  color: "primary",
  weight: 400,
  size: "normal",
  textTransform: "none",
  children: "",
  disableMargin: false,
};

TextComponent.propTypes = {
  component: PropTypes.oneOf(["p", "span", "li", "label"]),
  color: PropTypes.oneOf([
    "secondary",
    "primary",
    "dark",
    "danger",
    "light",
    "formLabelColor",
  ]),
  weight: PropTypes.oneOf([300, 400, 600]),
  size: PropTypes.oneOf(["normal", "extraSmall", "small", "medium", "large"]),
  textTransform: PropTypes.oneOf([
    "none",
    "uppercase",
    "lowercase",
    "capitalize",
  ]),
  disableMargin: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.elementType,
    PropTypes.node,
  ]),
};

export default TextComponent;
