import React from 'react'

export default props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    {...props}
  >
    <path d='M497 271h-15v-46c0-19.555-12.54-36.227-30-42.422V135c0-24.813-20.188-45-45-45h-45V15c0-8.285-6.715-15-15-15H106c-8.285 0-15 6.715-15 15v75H60c-33.086 0-60 26.914-60 60v286c0 20.031 7.758 39 21.848 53.41C36.086 503.977 54.96 512 75 512h362c24.813 0 45-20.188 45-45v-46h15c8.285 0 15-6.715 15-15V286c0-8.285-6.715-15-15-15zm-90-151c8.27 0 15 6.73 15 15v45h-60v-60zm-75 60h-91V30h91zm-151 0V30h30v150zM121 30h30v150h-30zm-61 90h31v60H60c-16.543 0-30-13.457-30-30s13.457-30 30-30zm392 347c0 8.27-6.73 15-15 15H75c-24.395 0-45-21.066-45-46V201.93A59.621 59.621 0 0060 210h377c8.27 0 15 6.73 15 15v46h-45c-41.355 0-75 33.645-75 75s33.645 75 75 75h45zm30-76h-75c-24.813 0-45-20.188-45-45s20.188-45 45-45h75zm0 0'></path>
    <path d='M422 346c0 8.285-6.715 15-15 15s-15-6.715-15-15 6.715-15 15-15 15 6.715 15 15zm0 0'></path>
  </svg>
)
