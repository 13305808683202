import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { ArrowIcon } from "@/assets/svg";

import * as S from "./Tooltip.styles";

const Tooltip = ({ color, ...restProps }) => (
  <ReactTooltip
    getContent={(dataTip) => (
      <S.CustomTooltip {...{ color }}>
        <ArrowIcon />
        <S.ToolTipText disableMargin {...{ color }}>
          {dataTip}
        </S.ToolTipText>
      </S.CustomTooltip>
    )}
    effect="solid"
    type="light"
    {...restProps}
  />
);

Tooltip.defaultProps = { color: "primary" };

Tooltip.propTypes = {
  color: PropTypes.oneOf(["secondary", "primary", "dark", "danger", "light"]),
};

export default Tooltip;
