import styled from "styled-components";

export const Items = styled.div`
  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      padding: 20px 30px;
    }

    @media ${theme.mediaQueries.smUp} {
      padding: 30px 0 40px 0;
    }
  `}
`;

export const Item = styled.div`
  margin-bottom: 20px;

  a {
    color: ${({ theme }) => theme.colors.formLabelColor};
  }

  strong {
    text-transform: uppercase;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;