import React, {useEffect, useState} from "react"
import {Col, Row} from "react-grid-system"
import {useDispatch, useSelector} from "react-redux"
import {useParams} from "react-router-dom";

import {isArray} from "lodash";
import moment from "moment/moment";

import {
    creditRequestSelector,
    fetchActiveCredits
} from "@/redux/slices/creditRequestSlice";
import {
    createEarlyRepayment,
    fetchEarlyRepaymentAmount
} from "@/redux/slices/creditRenegotiationSlice"

import {useRedirect} from "@/hooks";

import {DefaultPage} from "@/layouts"
import {MoneyIcon} from "@/assets/svg"
import {Section} from "@/components";
import Button from "@/components/common/Button";
import DatePicker from "@/components/common/DatePicker";

const formatAmount = (amount) => (amount ? `${amount}` : "0.00");
const formatDate = (dateStr) => {
    if (dateStr) {
        return moment(dateStr).format("DD.MM.YYYY");
    }
    return "НЯМА";
};

const EarlyRepayment = (creditTerms) => {
    const dispatch = useDispatch()
    const redirect = useRedirect()
    const params = useParams()
    const { activeCredits } = useSelector(creditRequestSelector)

    const [paymentDate, setPaymentDate] = useState(null)
    const [earlyRepaymentAmount, setEarlyRepaymentAmount] = useState(null)
    const [earlyRepaymentRenegotiation, setEarlyRepaymentRenegotiation] = useState(null)

    const onChoosePaymentDate = async (date) => {
        if (params.requestID) {
            setEarlyRepaymentAmount(await dispatch(fetchEarlyRepaymentAmount(params.requestID, date)))
            setPaymentDate(date)
        }
    }

    const onCreateEarlyRepayment = async () => {
        if (params.requestID && paymentDate && earlyRepaymentAmount.totalAmount) {
            setEarlyRepaymentRenegotiation(await dispatch(createEarlyRepayment(params.requestID, paymentDate)))
        }
    }

    useEffect(() => {
        dispatch(fetchActiveCredits());
    }, []);

    useEffect(() => {
        if (earlyRepaymentRenegotiation) redirect("/my-credits")
    }, [earlyRepaymentRenegotiation])

    return (
        <DefaultPage
            head={{
                icon: MoneyIcon,
                title: "Предсрочно погасяване",
                fullWidth: true,
            }}
        >
            <Row>
                <Col>
                    {params.requestID && isArray(activeCredits) && activeCredits.length
                        ? activeCredits.map(
                            (
                                {
                                    creditRequestId,
                                    contractStartsAt,
                                    contractEndsAt,
                                    creditNumber,
                                    creditAmount,
                                    creditPrincipal,
                                    installmentAmount,
                                    gracePeriodInstallmentAmount,
                                    nextInstallment,
                                    overdueInstallments,
                                    overdue,
                                    unpaidCreditAmount,
                                    unpaidPrincipal,
                                    unpaidGuarantorFee,
                                    unpaidPenaltiesAndFees,
                                    unpaidCreditAmountWithoutPenaltiesAndFees,
                                    documents,
                                },
                                index
                            ) => {
                                if (creditRequestId === params.requestID) {
                                    return (
                                        <React.Fragment key={`active-credit-${index}`}>
                                            <Section.Item>
                                                <DatePicker
                                                    label='Ще платя до'
                                                    minDate={new Date()}
                                                    maxDate={new Date(new Date().setDate(new Date().getDate()+5))}
                                                    fullWidth
                                                    selected={paymentDate}
                                                    onChange={onChoosePaymentDate}
                                                >
                                                </DatePicker>
                                            </Section.Item>
                                            <Section>
                                                <Section.Header>
                                                    <Section.Item>
                                                        <Section.Item className="sub">
                                                            ЗАЯВКА ЗА ПЪЛНО ПОГАСЯВАНЕ
                                                        </Section.Item>
                                                        <Section.Item className="num">
                                                            № {creditNumber}
                                                        </Section.Item>
                                                    </Section.Item>
                                                </Section.Header>
                                                <Section.Content layout="list" background="semiLight">
                                                    {earlyRepaymentAmount
                                                        ?
                                                            <Section.Item label="Сума за погасяване">
                                                                {formatAmount(earlyRepaymentAmount.totalAmount)} <small>лв.</small>
                                                            </Section.Item>
                                                        :
                                                            <Section.Item label="Сума за погасяване">
                                                                - <small>лв.</small>
                                                            </Section.Item>
                                                    }
                                                </Section.Content>
                                            </Section>
                                            {earlyRepaymentAmount &&
                                                <Section.Item style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button color='primary' style={{ zIndex: 0 }} onClick={onCreateEarlyRepayment}>ЗАЯВИ ПЪЛНО ПОГАСЯВАНЕ</Button>
                                                </Section.Item>
                                            }
                                        </React.Fragment>
                                    )
                                }
                            }
                        )
                        : null
                    }
                </Col>
                <Col></Col>
            </Row>
        </DefaultPage>
    )
}

export default EarlyRepayment