import React from "react";
import { Container, Row, Col } from "react-grid-system";
import { Social } from "@/components";

import * as S from './Footer.styles';

const FooterComponent = () => (
  <S.Footer>
    <S.Content>
      <Container>
        <Row align="center">
          <Col xs={12} sm={6}>
            <S.Copyright>© {new Date().getFullYear()} Cashbox - Всички права запазени</S.Copyright>
          </Col>
          <Col xs={12} sm={6}>
            <Social />
          </Col>
        </Row>
      </Container>
    </S.Content>
  </S.Footer>
);

export default FooterComponent;
