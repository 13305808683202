import * as Yup from 'yup'

export default {
  resetPassword: () => Yup.object().shape({
    email: Yup.string().email(window.translate.t('validations.invalidEmail'))
      .required(window.translate.t('validations.enterEmail')),
  }),

  setNewPassword: ()=> Yup.object().shape({

    resetPasswordCode: Yup.string()
      .required(window.translate.t('validations.enterCode')),

    newPasswordFirst: Yup.string()
      .min(4, window.translate.t('validations.min4'))
      .max(50, window.translate.t('validations.max50'))
      .required(window.translate.t('validations.enterPassword')),

    newPassword: Yup.string()
      .min(4, window.translate.t('validations.min4'))
      .max(50, window.translate.t('validations.max50'))
      .oneOf([Yup.ref('newPasswordFirst'), null], window.translate.t('validations.passwordsNotMatch'))
      .required(window.translate.t('validations.enterPassword')),
  }),

}
