import styled from "styled-components";

import { Text } from "@/components/common";

export const Banner = styled.div`
  width: 100%;
  margin-top: 15px;

  position: relative;
  overflow: hidden;

  background: url(${({ background }) => background});
  background-size: cover;
  border-radius: 8px;

  & img {
    display: block;
    width: 200px;
    margin: 0 auto;

    position: absolute;
    right: 0;
    left: 0;
  }

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      height: 65vw;
    }

    @media ${theme.mediaQueries.smUp} {
      height: 320px;
    }

    @media ${theme.mediaQueries.mdUp} {
      height: 320px;
      margin-top: 0;
    }

    @media ${theme.mediaQueries.lgUp} {
      height: 320px;
    }

    @media ${theme.mediaQueries.xlUp} {
      height: 330px;
    }
  `}
`;

export const BannerInfo = styled.div`
  min-width: 260px;
  padding: 20px 15px 45px 15px;

  position: absolute;
  right: 0;
  bottom: 0;

  background: rgba(255, 255, 255, 0.9);
`;

export const InfoItem = styled(Text)`
  margin: 0 0 5px 0;

  & svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;

    position: relative;
    top: 2px;

    fill: ${({ theme }) => theme.colors.secondary};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const BannerButton = styled.div`
  display: block;
  width: 100%;
  padding: 8px 15px;

  position: absolute;
  left: 0;
  bottom: 0;

  background: ${({ theme }) => theme.colors.secondary};

  color: ${({ theme }) => theme.colors.light};

  font-weight: 600;
  font-size: 17px;
`;
