import { types, flow } from 'mobx-state-tree'
import { request, HTTPmethods } from '@/utils'

export default types.model('CreditRequestStore', {
  incomeSourcesList: types.maybe(types.frozen()),
  citiesList: types.maybe(types.frozen()),
  creditDiscretionList: types.maybe(types.frozen()),
  creditPurposesList: types.maybe(types.frozen()),
})
  .actions(self => {
    return {
      getIncomeSource: flow(function* getIncomeSource () {

        const { data } = yield request({
          method: HTTPmethods.GET,
          url: '/ml/web/reference-data/income-sources',
        })

        self.incomeSourcesList = data
      }),

      getCreditDiscretion: flow(function* getCreditDiscretion () {

        const { data } = yield request({
          method: HTTPmethods.GET,
          url: '/ml/web/reference-data/credit-discretion',
        })

        self.creditDiscretionList = data
      }),


      getCreditPurposes: flow(function* getCreditPurposes () {

        const { data } = yield request({
          method: HTTPmethods.GET,
          url: '/ml/web/reference-data/credit-purposes',
        })

        self.creditPurposesList = data
      }),

      getCities: flow(function* getCities () {

        const { data } = yield request({
          method: HTTPmethods.GET,
          url: '/ml/web/cities/all',
        })

        self.citiesList = data
      }),
    }
  })
