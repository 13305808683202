import styled from "styled-components";

export const Message = styled.div`
  text-align: center;
  background: ${({ theme }) => theme.colors.concrete};
  border-radius: 15px;

  & svg {
    width: ${({ iconSize }) => (iconSize === "large" ? "170px" : "100px")};
    height: ${({ iconSize }) => (iconSize === "large" ? "170px" : "100px")};
    display: block;
    margin: 0 auto 30px auto;
    fill: ${({ theme }) => theme.colors.secondary};
  }

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      max-width: 280px;
      margin: 30px auto;
      padding: 15px;
    }

    @media ${theme.mediaQueries.smUp} {
      width: 360px;
      margin: 40px auto;
      padding: 30px;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  margin: 30px 0;
`;
