import React from "react";
import { useSelector } from 'react-redux';

import { withSteps } from "@/hoc";
import { Status } from "@/components";
import { creditRequestSelector } from "@/redux/slices/creditRequestSlice";

const AcceptedStep = () => {
  const { selectedOffer } = useSelector(creditRequestSelector);

  return <Status.Success {...selectedOffer} />;
};

export default withSteps(AcceptedStep);