import React, {useEffect, useMemo, useState, useCallback} from 'react';
import { SearchSelect } from "@/components/common";
import PropTypes from "prop-types";
import { fetchCities } from '../../services/integrationApi';
import {filterArray} from "@/utils";
import { createFilter } from "react-select";

const getSelectValue = ({ id, datamapId, name, prefix, district, municipality, hasDivisionUnits, postcode }) => ({
  value: datamapId,
  label: `${filterArray(filterArray(prefix, name).join(' '), municipality,  district).join(', ')}`,
  selection: { cityDatamapId: datamapId, cityId: id, cityName: `${filterArray(filterArray(prefix, name).join(' '), municipality,  district).join(', ')}`, hasDivisionUnits, zip: postcode },
  searchText: filterArray(prefix, name).join(' ')
})

const SelectSettlement = ({ errors, fullWidth, onChange, value, onChangeSettlement, ...restProps }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [cities, setCities] = useState([])
  const loadCities = async () => {
      try {
        setIsLoading(true);
        const { data: cities = [] } = await fetchCities();
        setCities(cities);
      } catch (e) {
        //
      } 
      finally {
        setIsLoading(false);
      }
    }

  useEffect(()=>{
      loadCities()
  }, []);   
 
  const cityOptions = useMemo(() => cities.map(getSelectValue), [cities]);

  const loadCitiesOptions = async (inputValue) => {
    try {
      //let cityOptions = [];
      if(inputValue){
        return cityOptions.filter(option => option.searchText.toLowerCase().includes(inputValue.toLowerCase())).slice(0,10);
      }
      return [];
    } catch (e) {
      //
    }
  }

  const onSelectChange = (value, selected) => {
    onChange(selected?.selection);
    onChangeSettlement(selected?.selection);
  }
  
  const defaultValue = useMemo(()=>{
    if(value && value.cityDatamapId > 0){
      return getSelectValue({ datamapId: value?.cityDatamapId, name: value?.cityName})
    }
    return null
  }
  , [value])

  return (
    <SearchSelect 
      isAsync={true}
      placeholder="Населено място"
      {...{
        errors,
        fullWidth,
      }}
      value={defaultValue}
      defaultValue={defaultValue}
      defaultOptions={[]}
      loadOptions={loadCitiesOptions}      
      isLoading={isLoading}
      isSearchable={true}
      onChange={onSelectChange}
      {...restProps}
    />
  )
}

SelectSettlement.propTypes = {
  errors: PropTypes.object,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeSettlement: PropTypes.func,
  value: PropTypes.shape(   {
    cityDatamapId: PropTypes.number,
    cityId:	PropTypes.string,
    cityName: PropTypes.string
    })
};

export default SelectSettlement;