import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 49.94 49.94'
    version='1.1'
    viewBox='0 0 49.94 49.94'
    xmlSpace='preserve'
  >
    <path d='M48.856 22.731a3.56 3.56 0 00.906-3.671 3.56 3.56 0 00-2.892-2.438l-12.092-1.757a1.58 1.58 0 01-1.19-.865L28.182 3.043a3.56 3.56 0 00-3.212-1.996 3.56 3.56 0 00-3.211 1.996L16.352 14c-.23.467-.676.79-1.191.865L3.069 16.623A3.557 3.557 0 00.178 19.06a3.56 3.56 0 00.906 3.671l8.749 8.528c.373.364.544.888.456 1.4L8.224 44.702a3.559 3.559 0 001.424 3.502 3.552 3.552 0 003.772.273l10.814-5.686a1.583 1.583 0 011.472 0l10.815 5.686a3.568 3.568 0 003.772-.273 3.559 3.559 0 001.424-3.502L39.651 32.66a1.582 1.582 0 01.456-1.4l8.749-8.529zM37.681 32.998l2.065 12.042a1.553 1.553 0 01-.629 1.547 1.558 1.558 0 01-1.665.121l-10.815-5.687a3.588 3.588 0 00-3.334.001L12.49 46.708a1.56 1.56 0 01-1.666-.121 1.554 1.554 0 01-.629-1.547l2.065-12.042a3.581 3.581 0 00-1.03-3.17l-8.75-8.529a1.55 1.55 0 01-.4-1.621c.19-.586.667-.988 1.276-1.077l12.091-1.757a3.576 3.576 0 002.697-1.959l5.407-10.957a1.551 1.551 0 011.418-.881c.616 0 1.146.329 1.419.881l5.407 10.957a3.575 3.575 0 002.696 1.959l12.092 1.757a1.552 1.552 0 011.276 1.077c.19.585.041 1.191-.4 1.621l-8.749 8.528a3.58 3.58 0 00-1.029 3.171z'></path>
  </svg>
)
