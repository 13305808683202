import styled from "styled-components";
import { Text } from "@/components/common";

export const Group = styled.div`
  position: relative;
  padding: 16px 0;

  ${({ theme, orientation }) =>
    orientation === "horizontal" &&
    `
    display: flex;
    flex-wrap: wrap;

    > * {
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    @media ${theme.mediaQueries.xsOnly} {
      > * {
        margin-right: 15px;
      }
    }

    @media ${theme.mediaQueries.smUp} {
      > * {
        margin-right: 25px;
      }
    }

    @media ${theme.mediaQueries.mdUp} {
      > * {
        margin-right: 20px;
      }
    }

    @media ${theme.mediaQueries.lgUp} {
      > * {
        margin-right: 40px;
      }
    }
  `};

  ${({ orientation }) =>
    orientation === "vertical" &&
    `
    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  `};
`;

export const Label = styled(Text)`
  margin-bottom: 22px;
  color: ${({ theme }) => theme.colors.formLabelColor};

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      text-align: center;
    }
  `}
`;