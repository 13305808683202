import { types, flow } from 'mobx-state-tree'
import { request, HTTPmethods } from '@/utils'
import { toast } from 'react-toastify'

export default types.model('ProfileStore', {
  profile: types.maybe(types.frozen()),
  isPhoneVerified: types.maybe(types.boolean),
})
  .actions(self => {
    return {
      getProfile: flow(function* getProfile () {
        self.profile = {}

        const response = yield request({
          method: HTTPmethods.GET,
          url: '/ml/web/users/me',
        })

        self.profile = response.data
      }),
      updateProfile: flow(function* updateGeneralInfo (fields, endpoint = '') {
        yield request({
          method: HTTPmethods.PUT,
          url: `/ml/web/users/me/${endpoint}`,
          data: { ...fields }
        })
        if(endpoint === 'phone') {
          self.isPhoneVerified = false
        } else {
          toast.success(window.translate.t(`messages.successAccountUpdated${endpoint ? `-${endpoint}` : ''}`))
        }
      }),
      validatePhone: flow(function* validatePhone (fields) {
        yield request({
          method: HTTPmethods.POST,
          url: '/ml/web/users/me/phone/validation',
          data: { ...fields }
        })

        self.isPhoneVerified = true
        toast.success(window.translate.t('messages.phoneVerified'))
      }),
      resendPhoneValidation: flow(function* resendPhoneValidation (fields) {
        yield request({
          method: HTTPmethods.POST,
          url: '/ml/web/users/me/phone/resend',
          data: { ...fields }
        })

        toast.success(window.translate.t('messages.phoneVerificationResent'))
      }),
    }
  })
