import styled from 'styled-components'

export const Card = styled.div`
  margin: 30px 0;
`

export const CardInner = styled.div`
  border-radius: 15px;
  background: ${({ theme, background })=> theme.colors[background]};

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      padding: 20px;
    }

    @media ${theme.mediaQueries.smOnly} {
      padding: 30px;
    }

    @media ${theme.mediaQueries.mdUp} {
      padding: 30px;
    }
  `}
`