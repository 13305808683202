import React from "react";
import PropTypes from "prop-types";

import { Box } from "@/components";

import * as S from "./CompanyInfo.styles";

const CompanyInfoComponent = ({ name, number, address, email, phone }) => (
  <S.CompanyInfo>
    <Box>
      <Box.Content>
        <Box.Columns left={<Box.Text size="medium">{name}</Box.Text>} />

        <Box.Columns
          style={{ alignItems: "center" }}
          left={<Box.Text size="small">ЕИК:</Box.Text>}
          right={<Box.Text weight={600} size="small">{number}</Box.Text>}
        />

        <Box.Columns
          style={{ alignItems: "center" }}
          left={<Box.Text size="small">Адрес:</Box.Text>}
          right={<Box.Text weight={600} size="small">{address}</Box.Text>}
        />

        <Box.Columns
          style={{ alignItems: "center" }}
          left={<Box.Text size="small">Email:</Box.Text>}
          right={<Box.Text weight={600} size="small">{email}</Box.Text>}
        />

        {phone && (
          <Box.Columns
            style={{ alignItems: "center" }}
            left={<Box.Text size="small">Телефон:</Box.Text>}
            right={<Box.Text weight={600} size="small">{phone}</Box.Text>}
          />
        )}
      </Box.Content>
    </Box>
  </S.CompanyInfo>
);

CompanyInfoComponent.defaultProps = { phone: "" };

CompanyInfoComponent.propTypes = {
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
};

export default CompanyInfoComponent;
