import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#F7B500">
        <g transform="translate(-46 -233) translate(46 233)">
          <path d="M21 0C9.402 0 0 9.402 0 21s9.402 21 21 21 21-9.402 21-21S32.598 0 21 0zm0 39c-9.941 0-18-8.059-18-18S11.059 3 21 3s18 8.059 18 18-8.059 18-18 18z" />
          <path d="M21.5 8.5c-.828 0-1.5.672-1.5 1.5v14c0 .828.672 1.5 1.5 1.5S23 24.828 23 24V10c0-.828-.672-1.5-1.5-1.5z" />
          <circle cx="21.5" cy="29.5" r="1.5" />
        </g>
      </g>
    </g>
  </svg>
);
