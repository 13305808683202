import {
  HTTPmethods,
  request
} from "@/utils";
import { urls } from "@/constants";

export const fetchCities = () => request({
  method: HTTPmethods.GET,
  url: `${urls.cities}`
});
export const fetchDistricts = (settlementId, name) => request({
  method: HTTPmethods.GET,
  url: `${urls.districts}?settlementId=${settlementId}&name=${encodeURIComponent(name)}`
});

export const fetchStreets = (settlementId, name) => request ({
  method: HTTPmethods.GET,
  url: `${urls.streets}?settlementId=${settlementId}&name=${encodeURIComponent(name)}`
});

export const saveUserAddress = (data) => request ({
  method: HTTPmethods.PUT,
  url: `${urls.updateUserAddress}`,
  data: data
});

export const fetchAddressCityextSmartUrl = (cityId, districtNameId, stretNameId, number, entrance, block) => {
  let baseUrl = `${urls.addressCityextSmart}?cityid=${cityId}&district_id=0&district_name_id=${districtNameId}&include_entrances=${entrance ? true : false}&number=${number ? number : 0}&street_id=0&street_name_id=${stretNameId}`;
  if(block) {
    baseUrl = baseUrl + `&block=${block}`;
  }
  if(entrance) {
    baseUrl = baseUrl + `&entrance=${entrance}`;
  }
  return baseUrl;
}
//cityid=68134&district_id=0&district_name_id=10&include_entrances=true&number=1&street_id=0&street_name_id=1&entrance=1&block=1
export const fetchAddressCityextSmart = (cityId, districtNameId, stretNameId, number, entrance, block) => request ({
  method: HTTPmethods.GET,
  url: fetchAddressCityextSmartUrl(cityId, districtNameId, stretNameId ? stretNameId : 0, number ? number : 0, entrance, block)
});

export const fetchAddressCityById = (cityId) => request ({
  method: HTTPmethods.GET,
  url: `${urls.addressCityById}?cityid=${cityId}`
})