import styled from "styled-components";

export const TextCenter = styled.div`
  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      text-align: center;
    }
  `}
`;
export const Link = styled.a`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;
