import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 478.856 478.856'
    version='1.1'
    viewBox='0 0 478.856 478.856'
    xmlSpace='preserve'
  >
    <path d='M406.872 160.017H6.856A6.848 6.848 0 000 166.857v192.016a6.848 6.848 0 006.84 6.856h272.016a6.857 6.857 0 000-13.712H13.712V173.729H400v17.144a6.848 6.848 0 006.84 6.856h.016a6.848 6.848 0 006.856-6.84v-24.016a6.849 6.849 0 00-6.84-6.856zM360.36 128.185l-320-72a6.885 6.885 0 00-5.176.896 6.897 6.897 0 00-3.024 4.304l-16 72a6.86 6.86 0 0013.392 2.976l14.504-65.288 313.296 70.488a6.857 6.857 0 003.008-13.376zM321.504 88.513l-192-80a6.88 6.88 0 00-8.8 3.272l-16 32a6.88 6.88 0 1012.304 6.143v.016l13.128-26.272L316.208 101.2a6.874 6.874 0 005.296-12.687z'></path>
    <path d='M326.352 192.017h-63.496a6.857 6.857 0 000 13.712h63.496a6.857 6.857 0 000-13.712zM198.856 192.473c-38.881 0-70.4 31.519-70.4 70.4.04 38.864 31.536 70.36 70.4 70.4 38.881 0 70.4-31.519 70.4-70.4s-31.519-70.4-70.4-70.4zm0 128c-31.812 0-57.6-25.788-57.6-57.6.035-31.797 25.803-57.565 57.6-57.6 31.812 0 57.6 25.788 57.6 57.6s-25.788 57.6-57.6 57.6zM390.856 214.873c-42.4 0-88 10.016-88 32v192c0 21.984 45.6 32 88 32s88-10.016 88-32v-192c0-21.984-45.6-32-88-32zm72 223.88c-1.208 4.44-25.2 16.12-72 16.12s-70.792-11.68-72-16v-12.576c17.024 8.576 45.144 12.576 72 12.576s54.984-4.04 72-12.584v12.464zm0-32c-1.208 4.44-25.2 16.12-72 16.12s-70.792-11.68-72-16v-12.576c17.024 8.576 45.144 12.576 72 12.576s54.984-4.04 72-12.584v12.464zm0-32c-1.208 4.44-25.2 16.12-72 16.12s-70.792-11.68-72-16v-12.576c17.024 8.576 45.144 12.576 72 12.576s54.984-4.04 72-12.584v12.464zm0-32c-1.208 4.44-25.2 16.12-72 16.12s-70.792-11.68-72-16v-12.576c17.024 8.576 45.144 12.576 72 12.576s54.984-4.04 72-12.584v12.464zm0-32c-1.208 4.44-25.2 16.12-72 16.12s-70.792-11.68-72-16v-12.576c17.024 8.536 45.144 12.576 72 12.576s54.984-4.04 72-12.584v12.464zm0-32c-1.208 4.44-25.2 16.12-72 16.12s-70.792-11.68-72-16v-12.576c17.024 8.536 45.144 12.576 72 12.576s54.984-4.04 72-12.584v12.464zm-72-15.88c-46.728 0-70.712-11.648-72-15.856v-.048c1.288-4.456 25.272-16.096 72-16.096 46.4 0 70.4 11.472 72 16-1.6 4.528-25.6 16-72 16z'></path>
    <path d='M134.856 320.009H74.384l-28.672-31.36v-52l31.664-30.92h57.48a6.857 6.857 0 000-13.712H74.592a6.863 6.863 0 00-4.8 1.944l-35.736 34.856a8.594 8.594 0 00-2.064 5.6v56.896a6.86 6.86 0 001.792 4.624l32.504 35.552a6.855 6.855 0 005.064 2.232h63.504a6.857 6.857 0 000-13.712zM202.856 254.873h-8c-2.488 0-4-1.392-4-2 0-.608 1.512-2 4-2h20a8 8 0 000-16h-8a8 8 0 00-16 0v.36a18.624 18.624 0 00-16 17.6c.573 10.489 9.507 18.548 20 18.04h8c2.488 0 4 1.392 4 2 0 .608-1.512 2-4 2h-20a8 8 0 000 16h8a8 8 0 0016 0v-.36a18.624 18.624 0 0016-17.6c-.573-10.489-9.507-18.548-20-18.04z'></path>
  </svg>
)
