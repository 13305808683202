
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-grid-system";

import { withSteps } from "@/hoc";
import { PersonalDetailsValidator } from "@/formValidators";
import { FieldGroup } from "@/components";
import { Form, Check } from "@/components/common";
import { enums } from "@/constants";
import { useFocusOnError } from "@/hooks";
import { useParams } from "react-router-dom";
import SelectControl from "./SelectControl";
import { yupResolver } from '@hookform/resolvers/yup';

// REDUX
import {
  creditRequestSelector,
  fetchPersonalDetails,
  postPersonalDetails,
  goToPreviousStep,
  fetchPaymentDetails,
  fetchCreditDataById,
  fetchAddressData,
} from "@/redux/slices/creditRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import {useTranslation} from "react-i18next";

const PersonalDetailsStep = ({
  referenceDataState,
  isLoading,
  buttonText,
  backText,
  creditDiscretionList,
  creditPurposesList,
}) => {
  const { t } = useTranslation();
  const { register, control, handleSubmit, errors, setValue, fullWidth } = useForm({
    resolver: yupResolver(PersonalDetailsValidator()),
  });
  const [hasCars, setHasCars] = useState(false);

  const { getCreditDiscretion, getCreditPurposes } = referenceDataState;
  const [defaultDiscretion, setDefaultDiscretion] = useState({
    value: "0077915c-3000-42c3-bc04-b47e163c1896",
    label: "Няма нужда",
  });
  const [defaultCreditPurpose, setDefaultCreditPurpose] = useState(null);
  const [defaultPaymentDay, setDefaultPaymentDay] = useState(null);
  const [defaultNumberOfCars, setDefaultNumberOfCars] = useState(null);
  const [defaultCarFuel, setDefaultCarFuel] = useState(null);
  const [defaultCarYearsOld, setDefaultCarYearsOld] = useState(null);

  const [enteredCalled, setEnteredCalled] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const { requestID } = useParams();
  const {
    personalDetails,
    paymentDetails,
    creditTerms,
    currentAddress
  } = useSelector(creditRequestSelector);

  useFocusOnError(errors);

  useEffect(() => {
    if (!enteredCalled) {
      dispatch(fetchPersonalDetails(requestID, false));
      setEnteredCalled(true);
    }

    if (!paymentDetails) {
      dispatch(fetchPaymentDetails(requestID, false));
    }

    if (!creditTerms) {
      dispatch(fetchCreditDataById(requestID, false));
    }

    if (!currentAddress) {
      dispatch(fetchAddressData(requestID, false));
    }
  }, []);

  useEffect(() => {
    if (!creditDiscretionList.length) {
      getCreditDiscretion();
    }
  }, [creditDiscretionList]);

  useEffect(() => {
    if (!creditPurposesList.length) {
      getCreditPurposes();
    }
  }, [creditPurposesList]);

  useEffect(() => {
    if (
      creditPurposesList.length &&
      personalDetails &&
      personalDetails.creditPurpose.value.id
    ) {
      const creditPurposeId = personalDetails.creditPurpose.value.id ? personalDetails.creditPurpose.value.id : "";
      const creditPurpose = creditPurposesList.find(
        (purpose) => purpose.id === creditPurposeId
      );
      if (creditPurpose) {
        setValue("creditPurpose.value.id", {
          value: creditPurpose.id,
          label: creditPurpose.name,
        });
        setDefaultCreditPurpose({
          value: creditPurpose.id,
          label: creditPurpose.name,
        });
      }
    }
  }, [personalDetails, creditPurposesList]);

  useEffect(() => {    
    if (
      creditDiscretionList &&
      personalDetails &&
      personalDetails.discretion.value.id
    ) {
      const discretionId = personalDetails.discretion.value.id
        ? personalDetails.discretion.value.id
        : "";
      const discretion = creditDiscretionList.find(
        (disc) => disc.id === discretionId
      );
      if (discretion) {
        setValue("discretion.value.id", {
          value: discretion.id,
          label: discretion.name,
        });
        setDefaultDiscretion({
          value: "0077915c-3000-42c3-bc04-b47e163c1896",
          label: "Няма нужда",
        });
      }
    }
  }, [personalDetails, creditDiscretionList]);

  const setNumberOfCars = () => {
    const nCars = personalDetails.numberOfCars ? personalDetails.numberOfCars : 0;

    const nCarsEnum = enums.numberOfCars.find((item) => item.value === nCars);
    if(nCarsEnum){
      setValue("numberOfCars", {value: nCarsEnum.value, label: nCarsEnum.label});
      setDefaultNumberOfCars({value: nCarsEnum.value, label: nCarsEnum.label});
    }    
  };

  const setCarFuel = () => {
    const fuelEnum = enums.carFuel.find(
      (fuel) => fuel.value === personalDetails.newestCarFuelUsed ?? ""
    );
    if(fuelEnum){
      setValue("newestCarFuelUsed", {value: fuelEnum.value, label: fuelEnum.label});
      setDefaultCarFuel({value: fuelEnum.value, label: fuelEnum.label});
    }
  };

  const setPaymentDay = () => {
    const paymentDay = personalDetails.paymentDayOfTheMonth ?? "";

    const paymentDayEnum = enums.paymentPeriod.find(
      (period) => period.value === paymentDay
    );
    if(paymentDayEnum){
      setValue("paymentDayOfTheMonth", {value: paymentDayEnum.value, label: paymentDayEnum.label});
      setDefaultPaymentDay({value: paymentDayEnum.value, label: paymentDayEnum.label});
    }    
  };

  useEffect(() => {
    if (personalDetails) {
      setPaymentDay();
      setNumberOfCars();
      if (personalDetails.numberOfCars) {
        setHasCars(true);
        //setNumberOfCars();
        setCarFuel();

        const newestCarYearsValue =
          personalDetails.newestCarYearsOld ||
          personalDetails.newestCarYearsOld === 0
            ? personalDetails.newestCarYearsOld
            : "";

        const newestCarYearsOld = enums.carsYearsOld.find(
          (year) => year.value === newestCarYearsValue
        );

        if (newestCarYearsOld) {
          setValue("newestCarYearsOld", {
            value: personalDetails?.newestCarYearsOld,
            label: personalDetails?.newestCarYearsOld,
          });
          setDefaultCarYearsOld({
            value: personalDetails?.newestCarYearsOld,
            label: personalDetails?.newestCarYearsOld,
          });
        }
      }
    }
  }, [personalDetails?.newestCarYearsOld, personalDetails?.numberOfCars]);

  useEffect(() => {
    if (personalDetails?.creditForPersonalUse != null) {
      setIsCreditForPersonalUse(personalDetails.creditForPersonalUse);

      if(personalDetails.creditForPersonalUse) {
        setIsCreditForPersonalUseValue(1);
        setValue("creditForPersonalUse", 1);
      }else{
        setIsCreditForPersonalUseValue(2);
        setValue("creditForPersonalUse", 2);
      }
    }
  }, [personalDetails?.creditForPersonalUse]);

  useFocusOnError(errors);

  const onSubmit = (data) => {
    const formatFields = [];
    const formattedData = {};
    Object.keys(data).map((key) =>
      Object.assign(formattedData, {
        [key]: formatFields.includes(key) ? data[key].value : data[key],
      })
    );

    const postData = {
      creditForPersonalUse: isCreditForPersonalUse,
      discretion: {
        value: {
          id: formattedData.discretion.value.id.value
        }
      },
      creditPurpose: {
        value: {
          id: formattedData.creditPurpose.value.id?.value
        }
      },
      paymentDayOfTheMonth: formattedData.paymentDayOfTheMonth.value,
      numberOfCars: formattedData.numberOfCars ? formattedData.numberOfCars.value : 0,
      newestCarFuelUsed: formattedData.newestCarFuelUsed ? formattedData.newestCarFuelUsed.value: null,
      newestCarYearsOld: formattedData.newestCarYearsOld ? formattedData.newestCarYearsOld.value : null,
    }

    dispatch(postPersonalDetails(requestID, postData))
  };

  const [isCreditForPersonalUse, setIsCreditForPersonalUse] = useState(undefined);
  const [isCreditForPersonalUseValue, setIsCreditForPersonalUseValue] = useState(undefined);

  const isCreditForPersonalUseFalsey = !isCreditForPersonalUse && isCreditForPersonalUse !== undefined;

  useEffect(() => {
    if(isCreditForPersonalUseValue === 1){
      setIsCreditForPersonalUse(true);
    }else if(isCreditForPersonalUseValue === 2){
      setIsCreditForPersonalUse(false);
      setValue("creditPurpose.value.id", { value: null, label: null });
    }
  }, [isCreditForPersonalUseValue])

  return (
    <Form
      {...{
        isLoading,
        buttonText,
        backText,
      }}
      onSubmit={handleSubmit(onSubmit)}
      onBack={() => {
        dispatch(goToPreviousStep());
      }}
    >
      <Row>
        <Col sm={4}>
            <Controller 
              as={SelectControl}
              isDisabled={true}
              defaultValue={defaultDiscretion}
              label={"Дискретност"}
              options={creditDiscretionList.map(({ id, name }) => {
                return {
                  value: id,
                  label: name,
                };
              })}
              name={`discretion.value.id`}                          
              errors={errors} fullWidth
              control={control}
            />          
        </Col>

        <Col sm={4}>
            <Controller 
              as={SelectControl}
              isDisabled={isCreditForPersonalUseFalsey}
              defaultValue={defaultCreditPurpose}
              label={"Цел на кредита"}
              options={creditPurposesList.map(({ id, name }) => {
                return {
                  value: id,
                  label: name,
                };
              })}
              name={`creditPurpose.value.id`}                          
              errors={errors} fullWidth
              control={control}
            />          
        </Col>

        <Col sm={4}>
            <Controller 
              as={SelectControl}
              defaultValue={defaultPaymentDay}
              label={"Желая да плащам на следното число"}
              options={enums.paymentPeriod}
              name={`paymentDayOfTheMonth`}                          
              errors={errors} fullWidth
              control={control}
            />                    
        </Col>

        <Col sm={4}>
            <Controller 
              as={SelectControl}
              defaultValue={defaultNumberOfCars}
              label={"Колко лични автомобили имаш?"}
              options={enums.numberOfCars}
              name={`numberOfCars`}                          
              errors={errors} fullWidth
              control={control}
              onEventChange={(selected) => {
                setHasCars(selected.value);
                if (selected.value === 0) {
                  setValue("newestCarFuelUsed", null);
                  setValue("newestCarYearsOld", null);
                }
                return selected;
              }}
            />          
        </Col>

        <Col sm={4}>
          <Form.ConditionalFields isShown={Boolean(hasCars)}>
            <Controller 
              as={SelectControl}
              defaultValue={defaultCarFuel}
              label={"На какво гориво е най-новата ти кола?"}
              options={enums.carFuel}
              name={`newestCarFuelUsed`}                          
              errors={errors} fullWidth
              control={control}              
            />             
          </Form.ConditionalFields>
        </Col>

        <Col sm={4}>
          <Form.ConditionalFields isShown={Boolean(hasCars)}>
            <Controller 
              as={SelectControl}
              defaultValue={defaultCarYearsOld}
              label={"На колко години е най-новата ти кола?"}
              options={enums.carsYearsOld}
              name={`newestCarYearsOld`}                          
              errors={errors} fullWidth
              control={control}              
            />            
          </Form.ConditionalFields>
        </Col>

        <Col xs={12}>
          <FieldGroup>
            <Check
                {...{ errors, ref: register }}
                name="creditForPersonalUse"
                type="radio"
                checked={isCreditForPersonalUse}
                value={1}
                onChange={({ currentTarget }) => {
                  setIsCreditForPersonalUseValue(1);
                  return true;
                }}
            >
              {t("fields.creditForPersonalUse")}
            </Check>
            <Check
                {...{ errors, ref: register }}
                name="creditForPersonalUse"
                type="radio"
                checked={isCreditForPersonalUseFalsey}
                value={2}
                onChange={({ currentTarget }) => {
                  setIsCreditForPersonalUseValue(2);
                  return true;
                }}
            >
              {t("fields.creditForProfessionalUse")}
            </Check>
          </FieldGroup>
        </Col>
      </Row>
    </Form>
  );
};

PersonalDetailsStep.propTypes = {
  creditRequestState: PropTypes.object,
  personalDetailsInitialValues: PropTypes.object,
  referenceDataState: PropTypes.object,
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
  backText: PropTypes.string,
  creditDiscretionList: PropTypes.array,
  creditPurposesList: PropTypes.array,
};

export default compose(
  inject(
    ({ store: { creditRequestState, referenceDataState, isLoading } }) => ({
      creditRequestState,
      referenceDataState,
      isLoading,
      personalDetailsInitialValues:
        creditRequestState.personalDetailsInitialValues,
      creditDiscretionList: referenceDataState.creditDiscretionList,
      creditPurposesList: referenceDataState.creditPurposesList,
    })
  ),
  observer
)(withSteps(PersonalDetailsStep));
