import { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { getStorage } from "@/utils";
import { storageKeys } from "@/constants";

const AuthProvider = ({ isLoading, children, redirect, historyState }) => {
  const history = useHistory();
  const isAuthenticated = getStorage(storageKeys.token);

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        history.push(redirect, historyState);
      }
    }
  }, [isLoading]);

  return children;
};

AuthProvider.defaultProps = { redirect: "/home" };

AuthProvider.propTypes = {
  isLoading: PropTypes.bool,
  historyState: PropTypes.object,
  redirect: PropTypes.string,
  children: PropTypes.any,
};

export default compose(
  inject(({ store: { isLoading } }) => ({
    isLoading,
  })),
  observer
)(AuthProvider);
