import styled from "styled-components";
import { Text } from "@/components/common/Text/Text.styles";
import RcReactSlider from "rc-slider";

export const RangeSliderWrapper = styled.div`
  position: relative;
  padding: 0 12px;
  margin-bottom: 30px;
`;

export const RangeSliderHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.formLabelColor};
`;

export const Label = styled(Text)`
  margin: 0;
  text-transform: uppercase;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.formLabelColor};
`;

export const Value = styled(Text)`
  margin: 0;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.formLabelColor};
`;

export const RangeSlider = styled(RcReactSlider)`
  & .rc-slider-rail,
  & .rc-slider-track,
  & .rc-slider-step {
    height: 6px;
  }

  & .rc-slider-rail {
    background: ${({ theme }) => theme.colors.rangeSliderTrackInactive};
    cursor: pointer;
    z-index: 2;
  }

  & .rc-slider-dot-active {
    display: none;
  }

  & .rc-slider-dot {
    display: none;
  }

  & .rc-slider-track {
    background: ${({ theme }) => theme.colors.rangeSliderTrackActive};
    z-index: 4;

    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      top: -7px;
      right: -10px;
      background: ${({ theme }) => theme.colors.rangeSliderDot};
      border-radius: 100%;
      cursor: pointer;
    }
  }

  & .rc-slider-mark-text {
    color: ${({ theme }) => theme.colors.formLabelColor};
    margin-top: 8px;
    font-size: 11px;
    display: none;

    &:first-child {
      margin-left: 2px !important;
      display: block;
    }

    &:last-child {
      margin-left: -6px;
      display: block;
    }
  }

  & .rc-slider-handle {
    display: none;
  }
`;