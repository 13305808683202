import { types, flow } from "mobx-state-tree";
import { request, HTTPmethods } from "@/utils";
import { urls } from "@/constants";

export default types
  .model("MessagesStore", {
    messages: types.maybe(types.frozen()),
    currentPage: types.number,
    pageSize: types.number,
    totalItems: types.number,
    totalPages: types.number,
    unreadMessages: types.number,
  })
  .actions((self) => ({
    getMessages: flow(function* getMessages(page) {
      const { data } = yield request({
        method: HTTPmethods.GET,
        url: `${urls.clientNotifications}?pageSize=${self.pageSize}&page=${
          page ?? self.currentPage
        }`,
      });

      self.messages = [
        ...self.messages,
        ...data.data // messages array
      ];

      self.totalItems = data.totalItems;
      self.totalPages = data.totalPages;
      self.currentPage = data.currentPage;
    }),

    setIsRead: flow(function* setIsRead(messageId) {
      yield request({
        method: HTTPmethods.POST,
        url: `${urls.clientNotifications}/${messageId}/seen`
      })
    }),

    getUnreadMessages: flow(function* getUnreadMessages() {
      const { data } = yield request({
        method: HTTPmethods.GET,
        url: `${urls.clientNotifications}?seen=false`,
        suppressErrors: true
      })

      self.unreadMessages = data.totalItems;
    })
  }));
