import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { withSteps } from "@/hoc";
import { Row, Col } from "react-grid-system";
import { enums } from "@/constants";
import { ProfileValidator, CurrentAddressValidator } from "@/formValidators";
import { ProfileAddressFields } from "@/components";
import { Form, Input, Select, Check } from "@/components/common";
import { yupResolver } from '@hookform/resolvers/yup';
import SelectControl from "./SelectControl";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  creditRequestSelector,
  fetchAddressData,
  goToPreviousStep,
  fetchCreditDataById,
  setCreditRequestID,
  setCurrentStep,
  postCurrentAddress,
} from "@/redux/slices/creditRequestSlice";
import { useParams } from "react-router-dom";

//TODO REMOVE THIS!!!
import randomSecond from "random-second";
import { useFocusOnError } from "@/hooks";

const CurrentAddressStep = ({
  referenceDataState,
  isLoading,
  buttonText,
  backText,
  citiesList,
  incomeSourcesList,
  formData,
  profile
}) => {
  const { t } = useTranslation();
  const [hasDivisionUnitsValue, setHasDivisionUnitsValue] = useState(false);
  const [hasStreetValue, setHasStreetValue] = useState(false);
  const [hasBuildingValue, setHasBuildingValue] = useState(false);
  const { creditRequestID, currentAddress } = useSelector(
    creditRequestSelector
  );
  const {
    register,
    handleSubmit,
    control,
    errors,
    setValue,
    getValues,
    watch,
    reset
  } = useForm({
    resolver: yupResolver(ProfileValidator.addressStep(hasDivisionUnitsValue, hasStreetValue, hasBuildingValue)),    
  });

  const [formDataLocal, setFormDataLocal] = useState(formData);
  const { getIncomeSource, getCities } = referenceDataState;
  const [defaultMoneySource, setDefaultMoneySource] = useState(null);
  const [defaultAddressOwnership, setDefaultAddressOwnership] = useState(null);
  const [enteredCalled, setEnteredCalled] = useState(false);
  const [defaultValuesWereSet, setDefaultValuesWereSet] = useState(false);
  const [currentAddressMatchesIdCardAddress, setCurrentAddressMatchesIdCardAddress] = useState(true);
  // Redux
  const dispatch = useDispatch();

  useEffect(() => {
    if(currentAddress && currentAddress.currentAddress.value){
      const formDataTmp = {
        settlement: {
          cityDatamapId: currentAddress ? currentAddress.currentAddress.value.cityDatamapId : null,
          cityId: currentAddress ? currentAddress.currentAddress.value.cityId : null,
          cityName: currentAddress ? currentAddress.currentAddress.value.cityName : null,
          zip: currentAddress ? currentAddress.currentAddress.value.zip : null, 
          hasDivisionUnits: true
        } 
        , district: {
          districtDatamapId: currentAddress ? currentAddress.currentAddress.value.districtDatamapId : null,
          districtName: currentAddress ? currentAddress.currentAddress.value.districtName : null
        }
        , street: {
          streetDatamapId: currentAddress ? currentAddress.currentAddress.value.streetDatamapId : null,
          streetName: currentAddress ? currentAddress.currentAddress.value.streetName : null
        },
        map: {
          latitude: currentAddress ? currentAddress.currentAddress.value.latitude : null,
          longitude: currentAddress ? currentAddress.currentAddress.value.longitude : null
        },
        building: currentAddress ? currentAddress.currentAddress.value.building : null,
        streetNo: currentAddress ? currentAddress.currentAddress.value.streetNo : null,
        entry: currentAddress ? currentAddress.currentAddress.value.entry : null, 
        apartment: currentAddress ? currentAddress.currentAddress.value.apartment : null,
        floor: currentAddress ? currentAddress.currentAddress.value.floor : null,  
        initalLoad: true,
        reload: true
      }
      setFormDataLocal(formDataTmp);
    }
  }, [currentAddress])
  
  const { requestID } = useParams();

  useFocusOnError(errors);

  useEffect(() => {
    if (!enteredCalled) {
      dispatch(fetchAddressData(requestID, false));
      setEnteredCalled(true);
    }
  }, []);

  useEffect(() => {
    if (!creditRequestID) {
      dispatch(setCurrentStep(enums.steps.currentAddress.key));
      dispatch(setCreditRequestID(requestID));
      dispatch(fetchCreditDataById(requestID));
    }
  }, [setCreditRequestID]);

  useEffect(() => {
    if (!incomeSourcesList.length) {
      getIncomeSource();
    }
  }, [incomeSourcesList]);

  useEffect(() => {
    if (!citiesList.length) {
      getCities();
    }
  }, [citiesList]);  

  useEffect(() => {
    
    if (
      incomeSourcesList.length &&
      currentAddress &&
      currentAddress.currentAddressOwnership
    ) {      
      const moneySource = incomeSourcesList.find(
        (source) => source.id === currentAddress.moneySource.id
      );    
      if(moneySource){
        setValue("moneySource.id", {value: moneySource.id,
          label: moneySource.name});
      }  
      
    }
  }, [currentAddress, incomeSourcesList]);

  useEffect(() => {
    if (currentAddress && !defaultValuesWereSet) {
      const currentAddressOwnershipValue = enums.addressOwnership.find(
        (el) =>
          el.value === currentAddress.currentAddressOwnership
      );      

      setValue("currentAddressOwnership", currentAddressOwnershipValue);
      setValue("currentAddressMatchesIdCardAddress", currentAddress.currentAddressMatchesIdCardAddress);
      setCurrentAddressMatchesIdCardAddress(currentAddress.currentAddressMatchesIdCardAddress);
      setDefaultValuesWereSet(true);
    }
  }, [currentAddress]);

  const inputProps = {
    ref: register,
    errors,
  };

  const onSubmit = (data) => {

    const formatFields = [];
    const formattedData = {};
    Object.keys(data).map((key) =>
      Object.assign(formattedData, {
        [key]: formatFields.includes(key) ? data[key].value : data[key],
      })
    );

    const postData = {
      moneySource: { id: data.moneySource.id.value},
      currentAddressLivingSinceYear: data.currentAddressLivingSinceYear,
      currentAddress: {
        value : {
          apartment: formattedData.apartment,
          building: formattedData.building,
          cityDatamapId: formattedData.settlement.cityDatamapId,
          cityId: formattedData.settlement.cityId,
          cityName: formattedData.settlement.cityName,
          datamapMatchCode: formattedData.datamapMatchCode,
          datamapPrecisionMethod: formattedData.datamapPrecisionMethod,
          datamapResponse: formattedData.datamapResponse,
          districtDatamapId: formattedData.district ? formattedData.district.districtDatamapId : formData.districtDatamapId,
          districtName: formattedData.district ? formattedData.district.districtName : formData.districtName,
          entry: formattedData.entry,
          floor: formattedData.floor,
          latitude: formattedData.map ?  formattedData.map.latitude : formData.latitude,
          longitude: formattedData.map ?  formattedData.map.longitude : formData.longitude,
          streetDatamapId: formattedData.street ? formattedData.street.streetDatamapId : formData.streetDatamapId,
          streetName: formattedData.street ? formattedData.street.streetName : formData.streetName,
          streetNo: formattedData.streetNo,
          zip: formattedData.zip
        },
        timeToFillInSecs: randomSecond({
          min: 8,
          max: 16,
        }),
      },
      currentAddressMatchesIdCardAddress: data.currentAddressMatchesIdCardAddress ? data.currentAddressMatchesIdCardAddress : false,
      currentAddressOwnership: data.currentAddressOwnership.value,
    }

    dispatch(postCurrentAddress(creditRequestID, postData));
  };  

  return (
    <>
      <Form
        {...{
          isLoading,
          loaderFixedCircle: false,
          buttonText,
          backText,
        }}
        onBack={() => {
          dispatch(goToPreviousStep());
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col md={4}>
            <Controller 
              as={SelectControl}
              label={t("fields.currentAddressOwnership")}
              options={enums.addressOwnership}
              name={`currentAddressOwnership`}                          
              errors={errors} fullWidth
              control={control}
            />
          </Col>

          <Col md={4}>
          <Controller 
              as={SelectControl}
              defaultValue={defaultMoneySource}
              label={t("fields.moneySource")}
              options={incomeSourcesList.map(({ id, name }) => {
                return {
                  value: id,
                  label: name,
                };
              })}
              name={`moneySource.id`}                          
              errors={errors} fullWidth 
              control={control}
            />            
          </Col>

          <Col md={4}>
            <Input
              {...inputProps}
              fullWidth
              label={t("fields.currentAddressLivingSinceYear")}
              name="currentAddressLivingSinceYear"
              value={currentAddress?.currentAddressLivingSinceYear}
              type="number"
            />
          </Col>
        </Row>

        <Form.Separator
          title="Въведете настоящ адрес"
          titleProps={{
            color: "secondary",
            size: "small",
          }}
        >
          <Row>
            <Col md={12}>
            {formDataLocal ? <ProfileAddressFields
              {...{
                register,
                errors,
                control,
                formData: formDataLocal,
                getValues,
                setValue,
                watch,
                reset,
                setHasDivisionUnitsValue,
                hasDivisionUnitsValue,
                setHasStreetValue,
                setHasBuildingValue
              }}
              colSize={6}
              customInputsStyle="gray"
            /> : null}
            </Col>
          </Row>
        </Form.Separator>

        <Row>
          <Col xs={12}>
            <Check
              ref={register}
              {...{ errors }}
              name="currentAddressMatchesIdCardAddress"
              checked={currentAddressMatchesIdCardAddress}
              onChange={({ currentTarget }) =>
                setCurrentAddressMatchesIdCardAddress(currentTarget.checked)
              }
            >
              {t("fields.currentAddressMatchesIdCardAddress")}
            </Check>
          </Col>
        </Row>
      </Form>
    </>
  );
};

CurrentAddressStep.defaultProps = {
  formData: {
    settlement: {
      cityDatamapId: null,
      cityId: null,
      cityName: null,
      zip: null, 
      hasDivisionUnits: true
    } 
    , district: {
      districtDatamapId: null,
      districtName: null
    }
    , street: {
      streetDatamapId: null,
      streetName: null
    },
    map: {
      latitude: null,
      longitude: null
    },
    building: null,
    streetNo: null,
    entry: null, 
    apartment: null,
    floor: null,    
    initalLoad: true
  },
};

CurrentAddressStep.propTypes = {
  creditRequestState: PropTypes.object,
  referenceDataState: PropTypes.object,
  profile: PropTypes.object,
  location: PropTypes.object,
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
  backText: PropTypes.string,
  currentAddressInitialValues: PropTypes.object,
  citiesList: PropTypes.array,
  incomeSourcesList: PropTypes.array,
  formData: PropTypes.object
};

export default compose(
  inject(({ store: { referenceDataState, isLoading, profile } }) => {
    return {
      referenceDataState,
      isLoading,
      citiesList: referenceDataState.citiesList,
      profile: profile,
      incomeSourcesList: referenceDataState.incomeSourcesList,
    };
  }),
  observer
)(withSteps(CurrentAddressStep));
