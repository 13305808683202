import styled from "styled-components";

export const ButtonWrapper = styled.div`
  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      margin: 30px 0;
      text-align: center;
    }

    @media ${theme.mediaQueries.smUp} {
      margin: 40px 0;
    }
  `}
`;
