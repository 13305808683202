import { configureStore } from "@reduxjs/toolkit";
import {
  authReducer,
  creditReducer,
  creditRequestReducer,
  creditRenegotiationReducer,
  globalReducer,
  messagesReducer,
  registerReducer,
  addressReducer
} from "./slices";

export default configureStore({
  reducer: {
    auth: authReducer,
    credit: creditReducer,
    creditRequest: creditRequestReducer,
    creditRenegotiation: creditRenegotiationReducer,
    global: globalReducer,
    messages: messagesReducer,
    address: addressReducer,
    register: registerReducer,
  },
});
