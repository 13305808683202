export default {
    events: {
        appOpen: 'app_open',
        login: 'login',
        logout: 'logout',
        signUp: 'sign_up',
        creditRequestInit: 'credit_request_init',
        creditRequestSend: 'credit_request_send',
        creditRequestAcceptOffer: 'credit_request_accept_offer',
        creditRequestRejectOffer: 'credit_request_reject_offer'
    },
    screens: {
        login: 'login_screen',
        resetPassword: 'reset_password_screen',
        changePassword: 'change_password_screen',
        forgottenPassword: 'forgotten_password_screen',
        myCredits: 'my_credits_screen',
        newCreditRequest: 'new_credit_screen',
        registration: 'registration_screen',
        uploadIdCard: 'upload_card_id_screen',
        confirmPhone: 'phone_confirmation_screen',
        payment: 'payment_screen',
        paymentDetails: 'payment_details_screen',
        messages: 'messages_screen',
        info: 'info_screen',
        infoDetails: 'info_details_screen',
        profile: 'profile_screen',
        pdfViewer: 'pdf_viewer_screen'
    },
    actions: {
        view: 'view',
        start: 'start',
        fail: 'fail',
        success: 'success',
        uploadStart: 'upload_start',

        payNow: 'pay_now',
        findOffice: 'find_office',

        click: 'click',
        enterClick: 'enter_click',
        confirmClick: 'confirm_click',
        backClick: 'back_click',
        profileClick: 'profile_click',
        passwordEditClick: 'pass_edit_click',
        mobileEditClick: 'mobile_edit_click',
        idCardEditClick: 'id_card_edit_click',
        emailEditClick: 'email_edit_click',
        addressEditClick: 'address_edit_click',
        newCreditClick: 'new_credit_click',
        myCreditsClick: 'my_credits_click',
        viewMessageClick: 'message_select_click',
        registrationClick: 'registration_click',
        tryAgainClick: 'try_again_click',
        forgottenPasswordClick: 'forgotten_password_click',
        aboutClick: 'about_click',
        contactsClick: 'contacts_click',
        promoClick: 'promo_click',
        howToPayClick: 'how_to_pay_click',
        howToGetCredit: 'how_to_get_credit_click',
        helpClick: 'help_click',
        usageTermsClick: 'usage_terms_click',
        personalDataPolicyClick: 'personal_data_policy_click',
        becomeAvalistClick: 'become_guarantor_click',

        nameChange: 'name_change',
        firstNameChange: 'first_name_change',
        lastNameChange: 'last_name_change',
        egnChange: 'egn_change',
        idCardChange: 'id_card_change',
        emailChange: 'e_mail_change',
        mobileChange: 'mobile_change'
    },
    errors: {
        invalidValidationCode: 'invalid_code',
        newValidationCode: 'send_new_code',
        invalidUserName: 'invalid_username',
        invalidPassword: 'invalid_password',
        invalidEmail: 'invalid_email',
    }
}