import React from 'react'
import PropTypes from 'prop-types'
import { useBeforeUnload } from 'react-use'
import { useForm } from 'react-hook-form'
import {  Link } from 'react-router-dom'
import styled from 'styled-components'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Input, Button, Text } from '@/components/common'
import { BackgroundPage } from '@/layouts'
import { AuthProvider } from '@/components'
import { ForgotPasswordValidator } from '@/formValidators'
import { AnonymousIcon, SuccessCircleIcon } from '@/assets/svg'
import { useFocusOnError, useRedirect } from '@/hooks'

const IconWrapper = styled.div`
  text-align: center;

  svg {
    fill: #fff;
  }

  ${({ theme, size }) => `
    @media ${theme.mediaQueries.xsOnly} {
      margin: ${size === 'large' ? '30px 0' : '20px 0 0 0'};

      svg {
        width: ${size === 'large' ? '90px' : '60px'};
      }
    }

    @media ${theme.mediaQueries.smUp} {
      margin: ${size === 'large' ? '50px 0' : '20px 0 0 0'};

      svg {
        width: ${size === 'large' ? '120px' : '80px'};
      }
    }
  `}
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const SuccessText = styled(Text)`
  text-align: center;

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      font-size: 16px;
    }

    @media ${theme.mediaQueries.smUp} {
      font-size: 18px;
    }
  `}
`

const BackButton = styled.div`
  text-align: center;
  margin-top: 40px;

  a {
    color: ${({ theme })=> theme.colors.light};
    font-weight: 600;
    text-transform: uppercase;
  }
`

const ResetPassword = ({ forgotPasswordState, isLoading }) => {
  //let history = useHistory()
  const redirect = useRedirect()
  const { t } = useTranslation()
  const { resetPassword, setNewPassword, screen, resetScreen, email } = forgotPasswordState
  const isResetPasswordScreen = screen === 'reset-password'
  const isSetNewPasswordScreen = screen === 'set-new-password'
  const isDoneScreen = screen === 'done'

  const { register, handleSubmit, errors } = useForm({ validationSchema: isResetPasswordScreen ? ForgotPasswordValidator.resetPassword() : ForgotPasswordValidator.setNewPassword() })

  useBeforeUnload(isSetNewPasswordScreen, t('messages.reloadMessage'))

  useFocusOnError(errors)

  return (
    <AuthProvider>
      <BackgroundPage
        title={isResetPasswordScreen ? 'Забравена парола' : ''}
        {... { isLoading }}
      >
        {isResetPasswordScreen && (
          <>
            <IconWrapper>
              <AnonymousIcon />
            </IconWrapper>
            <form onSubmit={handleSubmit(data => resetPassword(data))}>
              <Input
                ref={register}
                {... { errors }}
                label='Въведете e-mail'
                placeholder='Въведи e-mail адрес'
                name='email'
                fullWidth
                size='large'
                color='light'
              />

              <ButtonWrapper>
                <Button as='button' type='submit' color='light' size='large'>Изпрати</Button>
              </ButtonWrapper>

              <BackButton>
                <Link to='/login'>Назад</Link>
              </BackButton>
            </form>
          </>
        )}

        {isSetNewPasswordScreen && (
          <form onSubmit={handleSubmit(data => setNewPassword(data))}>
            <SuccessText color='light'>На посочения имейл <strong>{email}</strong> изпатихме код за промяна на паролата</SuccessText>
            <Input
              ref={register}
              {... { errors }}
              fullWidth
              color='light'
              name='resetPasswordCode'
              label='Въведете код'
            />

            <Input
              ref={register}
              {... { errors }}
              fullWidth
              color='light'
              name='newPasswordFirst'
              type='password'
              label='Въведете нова парола'
            />

            <Input
              ref={register}
              {... { errors }}
              fullWidth
              color='light'
              name='newPassword'
              type='password'
              label='Повторете новата парола'
            />

            <ButtonWrapper>
              <Button as='button' type='submit' color='light' size='large'>Промени паролата</Button>
            </ButtonWrapper>
          </form>
        )}

        {isDoneScreen && (
          <>
            <SuccessText textTransform='uppercase' color='light'>Паролата е успешно променена!</SuccessText>
            <IconWrapper size='large'>
              <SuccessCircleIcon />
            </IconWrapper>
            <ButtonWrapper>
              <Button
                onClick={()=> {
                  redirect('/login')
                  resetScreen()
                }}
                color='light'
                size='large'
              >
                Влезте в системата
              </Button>
            </ButtonWrapper>
          </>
        )}

      </BackgroundPage>
    </AuthProvider>
  )
}


ResetPassword.propTypes = {
  forgotPasswordState: PropTypes.object,
  isLoading: PropTypes.bool
}

export default compose(
  inject(({ store: { forgotPasswordState, isLoading } }) => ({
    forgotPasswordState,
    isLoading
  })),
  observer
)(ResetPassword)
