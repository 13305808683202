import React, { useMemo } from 'react';
import { SearchSelect } from "@/components/common";
import PropTypes from "prop-types";
import { fetchDistricts } from '../../services/integrationApi';
import {filterArray} from "@/utils";

const getSelectValue = ({ datamapId, name, prefix}) =>{ 
  const label = filterArray(prefix, name).join(' ')
  return {
    value: datamapId,
    label: label,
    selection: { districtDatamapId: datamapId, districtName: label}
  }
}

const DistrictSelect = ({ settlementId, errors, fullWidth, onChange, value, onDistrictChange, isDisabled, ...restProps }) => {

  const loadDistricts = async (inputValue) => {
    try {
      const { data: districts = [] } = await fetchDistricts(settlementId, inputValue);
      return (districts || []).map(getSelectValue);
    } catch (e) {
      //
    }
  }

  const onSelectChange = (value, selected) => {
    onChange(selected?.selection);
    onDistrictChange(selected?.selection);
  }

  const defaultValue = useMemo(()=>{    
    if(value && value.districtDatamapId > 0){
      return getSelectValue({ datamapId: value?.districtDatamapId, name: value?.districtName})
    }    
    return null
  }
  , [value])

  return (
    <SearchSelect 
      isAsync={true}
      placeholder="Квартал"
      {...{
        errors,
        fullWidth,
      }}
      isDisabled={isDisabled}
      value={defaultValue}
      defaultValue={defaultValue}
      defaultOptions={[]}
      loadOptions={loadDistricts}
      isSearchable={true}
      onChange={onSelectChange}
      {...restProps}
    />
  )
}

DistrictSelect.propTypes = {
  settlementId: PropTypes.number,
  errors: PropTypes.object,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onDistrictChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  value: PropTypes.shape(   {
    districtDatamapId: PropTypes.number,
    districtName: PropTypes.string
    })
};

export default DistrictSelect;