import styled from "styled-components";

export const MapWrapper = styled.div`
  position: relative;

  .leaflet-container {
    height: 400px;
    width: 100%;
  }
`;
