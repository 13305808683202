import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { isArray } from "lodash";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { MoneyIcon } from "@/assets/svg";
import { ResponsiveRender, PrivateAvalsForm, Section } from "@/components";
import { Headline } from "@/components/common";
import { DefaultPage } from "@/layouts";
import moment from "moment";
import enums from "src/constants/enums"
import {useRedirect} from "@/hooks";

const formatAmount = (amount) => (amount ? `${amount}` : "0.00");
const formatDate = (dateStr) => {
  if (dateStr) {
    return moment(dateStr).format("DD.MM.YYYY");
  }
  return "НЯМА";
};

const MyCredits = ({ creditState, authenticationState }) => {
  const { getMyCredits, myCredits, getMyPendingCredits, myPendingCredits } = creditState;
  const { getSession, session } = authenticationState;
  const { t } = useTranslation();
  const redirect = useRedirect()

  useEffect(() => {
    getMyPendingCredits();
    getMyCredits();
    getSession();
  }, []);

  const { activeCredits, avalCredits, renegotiations } = myCredits;

  return (
    <DefaultPage
      head={{
        icon: MoneyIcon,
        title: t("global.yourCredits"),
        fullWidth: true,
        rightContent: (
          <ResponsiveRender>
            {(breakpoint) =>
              breakpoint.smUp && (
                <Headline
                  disableMargin
                  style={{ textAlign: "right" }}
                  color="secondary"
                >
                  {t("global.avalCredits")}
                </Headline>
              )
            }
          </ResponsiveRender>
        ),
      }}
      disableContentGutter
    >
      <Row>
        <Col sm={6}>
        {isArray(myPendingCredits) && myPendingCredits.length
            ? myPendingCredits.map(
                (
                  {
                    creditRequestId,
                    step,
                    status,
                    creditNumber,
                    creditAmount,
                    requestedPrincipal,
                    approvedPrincipal,
                    numberOfPayments,
                    numberOfGracePeriodPayments,
                    installmentAmount,
                    gracePeriodInstallmentAmount,
                    contractStartsAt,
                    contractEndsAt
                  },
                  index
                ) => {
                  return (
                    <React.Fragment key={`pending-credit-${index}`}>
                      <ResponsiveRender>
                        {(breakpoint) =>
                          breakpoint.smUp && (
                            <Headline
                              disableMargin
                              style={{ textAlign: "left" }}
                              color="secondary"
                            >
                              {t("global.yourPendingCredits")}
                            </Headline>
                          )
                        }
                      </ResponsiveRender>
                      <Section key={`pending-credit-${index}`}>
                        <Section.Header>
                          <Section.Item>
                            {formatAmount(approvedPrincipal ?? requestedPrincipal)}
                            <small>лв.</small>
                            <Section.Item className="num">
                              № {creditNumber}
                            </Section.Item>
                          </Section.Item>
                        </Section.Header>

                        <Section.Content layout="list" background="semiLight">
                          <Section.Item label="Вноска">
                            {formatAmount(installmentAmount)} <small>лв.</small>
                          </Section.Item>
                          <Section.Item label="Вноска през гратисен период">
                            {numberOfGracePeriodPayments > 0 ? formatAmount(gracePeriodInstallmentAmount) : '-'} <small>лв.</small>
                          </Section.Item>
                          <Section.Item label="Брой вноски">
                            {numberOfPayments}
                          </Section.Item>
                          <Section.Item label="Брой гратисни периоди">
                            {numberOfGracePeriodPayments}
                          </Section.Item>
                          <Section.Item label="Статус">
                            {enums.creditRequestStatus[status]}
                          </Section.Item>
                        </Section.Content>
                        <Section.PendingCredit creditRequestId={creditRequestId} currentStep={step} />
                      </Section>
                    </React.Fragment>
                  );
                }
              )
            : null}

          {isArray(activeCredits) && activeCredits.length
            ? activeCredits.map(
                (
                  {
                    creditRequestId,
                    contractStartsAt,
                    contractEndsAt,
                    creditNumber,
                    creditAmount,
                    creditPrincipal,
                    installmentAmount,
                    gracePeriodInstallmentAmount,
                    nextInstallment,
                    overdueInstallments,
                    overdue,
                    unpaidCreditAmount,
                    unpaidPrincipal,
                    unpaidGuarantorFee,
                    unpaidPenaltiesAndFees,
                    unpaidCreditAmountWithoutPenaltiesAndFees,
                    unmaturedPrincipal,
                    documents,
                  },
                  index
                ) => {
                  const creditRenegotiations = renegotiations.filter(renegotiation => renegotiation.creditRequestId === creditRequestId)
                  return (
                    <React.Fragment key={`active-credit-${index}`}>
                      <Section key={`active-credit-${index}`}>
                        <Section.Header>
                          <Section.Item>
                            {formatAmount(creditPrincipal)}
                            <small>лв.</small>
                            <Section.Item className="num">
                              № {creditNumber}
                            </Section.Item>
                          </Section.Item>
                           <Section.Actions>
                            <Section.Item onClick={() => redirect(`/credit-request/${creditRequestId}/early-repayment`)}>Предсрочно погасяване</Section.Item>
                            {/*<Section.Item>Предоговаряне на кредит</Section.Item>*/}
                            {/*<Section.Item>Забавяне на вноска</Section.Item>*/}
                          </Section.Actions>
                        </Section.Header>

                        {creditRenegotiations.length === 1 ? (
                            <Section.Content layout="list" background="semiLight">
                              <Section.Item label="Активирано">
                                Предсрочно погасяване
                              </Section.Item>
                              <Section.Item label="Плати до">
                                {formatDate(creditRenegotiations[0].termsDate)}
                              </Section.Item>
                              <Section.Item label="Сума">
                                {formatAmount(creditRenegotiations[0].termsAmount)} <small>лв.</small>
                              </Section.Item>
                            </Section.Content>
                        ): null}

                        <Section.Content className="hasLogo" layout="columns">
                          <Section.Item label="Вноска">
                            {formatAmount(installmentAmount)} <small>лв.</small>
                          </Section.Item>
                          <Section.Item label="Оставащи">
                            {nextInstallment.overdueInDays ?? 0} дни
                          </Section.Item>
                          <Section.Item label="Вноска гратисен период">
                            {gracePeriodInstallmentAmount > 0 ? formatAmount(gracePeriodInstallmentAmount) : '-'} <small>лв.</small>
                          </Section.Item>
                          <Section.Item label="Следваща вноска">
                            {formatDate(nextInstallment.dueDate)}
                          </Section.Item>
                        </Section.Content>

                        {overdueInstallments.length > 0 ? (
                          <Section.Content layout="list" background="danger">
                            <Section.Item label="Дни просрочие">
                              {overdue} <small>дни</small>
                            </Section.Item>
                            <Section.Item label="Задължение">
                              {unpaidCreditAmountWithoutPenaltiesAndFees} <small>лв.</small>
                            </Section.Item>
                            <Section.Item label="Неустойки и такси">
                              {unpaidPenaltiesAndFees} <small>лв.</small>
                            </Section.Item>
                            <Section.Item label="Общо">
                              {unpaidCreditAmount} <small>лв.</small>
                            </Section.Item>
                          </Section.Content>
                        ) : null}

                        <Section.Content layout="list" background="semiLight">
                          <Section.Item label="Непадежирала главница">
                            {formatAmount(unmaturedPrincipal)} <small>лв.</small>
                          </Section.Item>
                          <Section.Item label="Първа вноска">
                            {formatDate(contractStartsAt)}
                          </Section.Item>
                          <Section.Item label="Последна вноска">
                            {formatDate(contractEndsAt)}
                          </Section.Item>
                          <Section.Item label="Кредит №">
                            {creditNumber}
                          </Section.Item>
                        </Section.Content>

                        <Section.Footer documents={documents} />
                      </Section>
                    </React.Fragment>
                  );
                }
              )
            : null}
        </Col>

        <ResponsiveRender>
          {(breakpoint) =>
            breakpoint.xsOnly && (
              <Col xs={12}>
                <Headline style={{ marginTop: 20 }} color="secondary">
                  {t("global.avalCredits")}
                </Headline>
              </Col>
            )
          }
        </ResponsiveRender>

        <Col sm={6}>
          {isArray(avalCredits) && avalCredits.length
            ? avalCredits.map(
                (
                  {
                    creditRequestId,
                    contractStartsAt,
                    contractEndsAt,
                    creditNumber,
                    creditAmount,
                    creditPrincipal,
                    installmentAmount,
                    gracePeriodInstallmentAmount,
                    numberOfGracePeriodPayments,
                    nextInstallment,
                    overdueInstallments,
                    overdue,
                    unpaidCreditAmount,
                    unpaidPrincipal,
                    unpaidGuarantorFee,
                    unpaidPenaltiesAndFees,
                    unpaidCreditAmountWithoutPenaltiesAndFees,
                    documents,
                  },
                  index
                ) => (
                    <React.Fragment key={`aval-credit-${index}`}>
                      <Section key={`aval-credit-${index}`}>
                      <Section.Header>
                        <Section.Item>
                          {formatAmount(creditPrincipal)}
                          <small>лв.</small>
                          <Section.Item className="num">
                            № {creditNumber}
                          </Section.Item>
                        </Section.Item>
                        {/* <Section.Actions>
                          <Section.Item>Предсрочно погасяване</Section.Item>
                          <Section.Item>Предоговаряне на кредит</Section.Item>
                          <Section.Item>Забавяне на вноска</Section.Item>
                        </Section.Actions> */}
                      </Section.Header>
                      <Section.Content className="hasLogo" layout="columns">
                        <Section.Item label="Вноска">
                          {formatAmount(installmentAmount)} <small>лв.</small>
                        </Section.Item>
                        <Section.Item label="Вноска през гратисен период">
                          {numberOfGracePeriodPayments > 0 ? formatAmount(gracePeriodInstallmentAmount) : '-'} <small>лв.</small>
                        </Section.Item>
                        <Section.Item label="Следваща вноска">
                          {formatDate(nextInstallment.dueDate)}
                        </Section.Item>
                        <Section.Item label="Оставащи">
                          {nextInstallment.overdueInDays ?? 0} дни
                        </Section.Item>
                      </Section.Content>

                      {overdueInstallments.length > 0 ? (
                        <Section.Content layout="list" background="danger">
                          <Section.Item label="Дни просрочие">
                            {overdue} <small>дни</small>
                          </Section.Item>
                          <Section.Item label="Задължение">
                            {unpaidCreditAmountWithoutPenaltiesAndFees} <small>лв.</small>
                          </Section.Item>
                          <Section.Item label="Неустойки и такси">
                            {unpaidPenaltiesAndFees} <small>лв.</small>
                          </Section.Item>
                          <Section.Item label="Общо">
                            {unpaidCreditAmount} <small>лв.</small>
                          </Section.Item>
                        </Section.Content>
                      ) : null}

                      <Section.Content layout="list" background="semiLight">
                        <Section.Item label="Непадежирала главница">
                          {formatAmount(unpaidPrincipal)} <small>лв.</small>
                        </Section.Item>
                        <Section.Item label="Първа вноска">
                          {formatDate(contractStartsAt)}
                        </Section.Item>
                        <Section.Item label="Последна вноска">
                          {formatDate(contractEndsAt)}
                        </Section.Item>
                        <Section.Item label="Кредит №">
                          {creditNumber}
                        </Section.Item>
                      </Section.Content>
                      <Section.Footer documents={documents} />
                    </Section>
                  </React.Fragment>
                )
              )
            : null}

          <PrivateAvalsForm />
        </Col>
      </Row>
    </DefaultPage>
  );
};

MyCredits.propTypes = {
  creditState: PropTypes.object,
  authenticationState: PropTypes.object,
};

export default compose(
  inject(({ store: { creditState } }) => ({ creditState })),
  inject(({ store: { authenticationState } }) => ({ authenticationState })),
  observer
)(MyCredits);
