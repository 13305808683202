import axios from 'axios'
import { store } from '@'
import { toast } from 'react-toastify'
import { backEndTranslation } from '@/utils'
import { getStorage } from './localStorage'
import { storageKeys } from '@/constants'

export const HTTPmethods = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PUT: 'put'
}

export default ({
  successMessage,
  suppressErrors = false,
  url,
  token,
  ...restArgs
}) => {
  const {
    authenticationState,
    setIsLoading,
  } = store

  token = token ?? getStorage(storageKeys.token);

  return new Promise(async (resolve, reject) => {
    setIsLoading(true)

    try {
      if (!url.startsWith('http')  && !url.startsWith('/')) {
        throw new Error('Please pass a valid URL!')
      }

      const response = await axios({
        ...restArgs,
        ...(!url.startsWith('http') ? { url: `${process.env.REACT_APP_API}${url}` } : { url: url }),
        ...(token && { headers: { 'x-auth-token': token } })
      })

      if (successMessage) {
        toast.success(successMessage)
      }

      resolve(response)

      setTimeout(()=> {
        setIsLoading(false)
      }, 2)

    } catch (err) {
      const { response } = err
      const sessionHasExpired = token && response && JSON.stringify(response.data).match(/Access Denied/gmi) && typeof response.data === 'object'


      if (sessionHasExpired) {
        authenticationState.logout(window.translate.t('login.expired'), 'error')

        reject(err)
      }

      if (!suppressErrors) {
        const errorDetailsFromResponseBody = typeof response === 'object' && response.data && (response.data.message || response.data.error) ? response.data.message || response.data.error : false
        toast.error(errorDetailsFromResponseBody ? backEndTranslation(errorDetailsFromResponseBody) : '')
      }

      reject(err)

      setTimeout(()=> {
        setIsLoading(false)
      }, 2)
    }
  })
}
