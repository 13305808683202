export default {
  global: {
    register: 'Регистрация',
    profile: 'Профил',
    pageNotFound: 'Страницата не е намерена',
    yourCredits: 'Кредити на твое име',
    yourPendingCredits: 'Заявки за кредит',
    avalCredits: 'Kредити, по които си поръчител',
    nextInstallment: 'СЛЕДВАЩА ВНОСКА',
    unpaidCreditAmount: 'ОСТАТЪК',
    nextInstallmentDueDate: 'ПАДЕЖ СЛЕДВАЩА ВНОСКА',
    overdue: 'Просрочие',
    overdueInDays: 'ДНИ В ПРОСРОЧИЕ',
    overdueAmount: 'Просрочени вноски',
    overduePenalties: 'Наказателна лихва',
    totalOverdue:'Общо',
    total: 'ОБЩО',
    unpaidPrincipal: 'Остатък главница',
    installmentAmount: 'Размер на вноска',
    contractStartsAt: 'Начало на договора',
    contractEndsAt: 'Край на договора',
    creditNumber: 'Кредит №',
  },
  messages: {
    'successLogin': 'Успешно влязохте в системата',
    'errorLogin': 'Грешно потребителско име или парола',
    'successLogout': 'Успешно излязохте от системата',
    'successRegister': 'Успешно се регистрирахте. Моля, потвърдете Вашият телефонен номер',
    'confirmPhone': 'Потвърдете Вашия телефон, за да активирате акаунта',
    'phoneVerified': 'Вашият телефон е успешно потвърден',
    'phoneVerificationResent': 'Кодът за потвърждение е изпратен отново',
    'successAccountUpdated': 'Акаунта е успешно актуализиран',
    'successAccountUpdated-phone': 'Телефона е успешно актуализиран',
    'successAccountUpdated-password': 'Паролата е успешно променена',
    'successAccountUpdated-address': 'Адреса е успешно актуализиран',
    'successAccountUpdated-id-card': 'Личната карта е успешно актуализирана',
    'reloadMessage': 'Ако презаредите страницата ще загубите Вашата заявка за кредит',
  },
  fields: {
    email: 'Имейл адрес',
    enterEmail: 'Въведете имейл адрес',
    password: 'Парола',
    enterPassword: 'Въведете парола',
    repeatPassword: 'Повторете паролата',
    enterRepeatPassword: 'Въведете паролата повторно',
    firstName: 'Име',
    enterFirstName: 'Въведете име',
    lastName: 'Фамилия',
    enterLastName: 'Въведете фамилия',
    enterMiddleName: 'Въведете презиме',
    middleName: 'Презиме',
    egn: 'ЕГН',
    enterEgn: 'Въведете ЕГН',
    idCardNumber: 'Номер на лична карта',
    enterIdCardNumber: 'Въведете номер на лична карта',
    mobilePhone: 'Мобилен телефон',
    enterMobilePhone: 'Въведете мобилен телефон',
    enterIdCardFront: 'Прикачете лицевата страна',
    enterIdCardBack: 'Прикачете задната страна',
    partnerCode: 'Код партньор',
    enterPartnerCode: 'Въведете реферален код на партньор',
    marketingConsent: 'Съгласявам се да получавам съобщения за директен маркетинг',
    personalDataProcessingConsent: 'Съгласявам се личните ми данни да бъдат обработвани',
    consent: 'Съгласен съм с',
    termsAndConditions: 'Общите условия',
    fall_into_category_36_2_ZMIP: 'Попадам в следната категория по чл. 36, ал. 2 от ЗМИП',
    fall_into_category_36_5_ZMIP: 'Попадам в следната категория по чл. 36, ал. 5 от ЗМИП',
    fall_into_category_36_2_ZMIP_in_the_last_12_months: 'През последните 12 месеца съм попадал в следната категория по чл. 36, ал. 2 от ЗМИП',
    fall_into_category_36_5_ZMIP_in_the_last_12_months: 'През последните 12 месеца съм попадал в следната категория по чл. 36, ал. 5 от ЗМИП',
    category_36_2_ZMIP: 'Категория по чл. 36, ал. 2',
    category_36_5_ZMIP: 'Категория по чл. 36, ал. 5',
    nameChanged: 'Има промяна в името ми през последните 12 месеца',
    previousName: 'Предходно име',
    lending: 'Кредитни продукти',
    peerToPeer: 'Инвестиции в кредитни продукти и алтернативното финансиране',
    crowdFunding: 'Колективно финансиране',
    actOnMyOwn: 'Oт свое име и за своя сметка по смисъла на чл.65 от ЗМИП',
    actOnThirdParty: 'Oт името и/или за сметка на трето лице, както следва:',
    creditForPersonalUse: 'Искам кредита за лична полза',
    creditForProfessionalUse: 'Искам кредита за професионална употреба',
    politicalPersonOtherInformation: 'Предоставям следната допълнителна информация във връзка с принадлежността ми към горепосоченатакатегория/и:',
    other: 'Друго',
    otherBusinessRelation: 'Други бизнес отношения',
    expectedAnnualAmount: 'Очакван годишен обем на деловите взаимоотношения/операциите, сделките',
    fullName: 'Три имена',
    birthDate: 'Дата на раждане',
    idCardIssuedBy: 'Лична карта издадена от',
    idCardIssueDate: 'Лична карта издадена на',
    idCardValidity: 'Лична карта валидна до',
    citizenship: 'Гражданство',
    otherCitizenship: 'Друго гражданство',
    socialSecurityNumber: 'SSN (При американско гражданство)',
    countryOfBirth: 'Държава на раждане',
    placeOfBirth: 'Населено място на раждане',
    thirdPartyName: 'Име на трето лице',
    thirdPartyEgn: 'ЕГН на трето лице',
    currentOccupation: 'Месторабота',
    currentAddressOwnership: 'Собственост на настоящия адрес',
    moneySource: 'Източник на доходи',
    currentAddressMatchesIdCardAddress: 'Настоящият ми адрес отговаря на този по лична карта',
    currentAddressLivingSinceYear: 'От колко години живеете на този адрес?'
  },
  buttons: {
    login: 'Вход',
    register: 'Регистрация',
    forgotPassword: 'Забравена парола',
    confirmAndContinue: 'Потвърди и продължи',
    continue: 'Продължи',
    goToHomePage: 'Към началната страница',
    readMore: 'Прочетете още',
    showLess: 'Покажи по-малко',
    download: 'Свали'
  },
  validations: {
    invalidEmail: 'Въведете валиден имейл адрес',
    invalidCardNumber: 'Въведете валиден номер на карта', 
    invalidIban: 'Въведете валиден IBAN',
    invalidIbanNotBG: 'Въведете валиден български IBAN',
    enterEmail: 'Въведете имейл',
    enterPassword: 'Въведете парола',
    enterFirstName: 'Въведете име',
    enterMiddleName: 'Въведете презиме',
    enterLastName: 'Въведете фамилия',
    enterCurrentAddressLivingSinceYear: 'Въведете колко години живеете на този адрес',
    enterEgn: 'Въведете ЕГН',
    enterCity: 'Въведете град',
    enterBlock: 'Въведете квартал',
    enterBlockNumber: 'Въведете номер на квартал',
    enterStreet: 'Въведете улица',
    enterStreetNumber: 'Въведете номер на улица',
    enterMobilePhone: 'Въведете мобилен телефон',
    enterIdCardNumber: 'Въведете номер на лична карта',
    enterIdCardFront: 'Прикачете лицевата страна на вашата лична карта',
    enterIdCardBack: 'Прикачете задната страна на вашата лична карта',
    enterZip: 'Въведете пощенски код',
    acceptForContinue: 'За да продължите, трябва да се съгласите',
    passwordsNotMatch: 'Паролите не съвпадат',
    min1Number: 'Номера трябва да бъде поне 1',
    enterAvalCode: 'Въведете SMS код',
    egnLength: 'Трябва да съдържа 10 символа',
    engOnlyNumbers: 'Трябва да съдържа само числа',
    idCardNumberLength:'Трябва да съдържа 9 символа',
    idCardNumberOnlyNumbers: 'Трябва да съдържа само числа',
    min8: 'Минимум 8 символа',
    max50: 'Максимум 50 символа',
    atLeastOneLetter: 'Трябва да съдържа поне една латинска буква',
    atLeastOneNumber: 'Трябва да съдържа поне една цифра',
    enterCategory_36_2_ZMIP: 'Въведете категория по чл. 36, ал. 2',
    enterCategory_36_5_ZMIP: 'Въведете категория по чл. 36, ал. 5',
    enterThirdPartyName: 'Въведете име на третото лице',
    enterThirdPartyEgn: 'Въведете ЕГН на третото лице',
    enterField: 'Попълнете полето',
    enterPreviousName: 'Въведете предходно име',
    enterCode: 'Въведете код',
    enterFullName: 'Въведете три имена',
    enterCitizenship: 'Въведете гражданство',
    enterCountryOfBirth: 'Въведете държава на раждане',
    enterPlaceOfBirth: 'Въведете населено място на раждане',
    enterЕxpectedAnnualAmount: 'Въведете очакван годишен обем',
    enterBirthDate: 'Въведете дата на раждане',
    enterIdCardIssueDate: 'Въведете дата на издаване',
    enterIdCardValidity: 'Въведете дата на валидност',
    enterCurrentAddressOwnership: 'Въведете собственост на настоящия адрес',
    enterМoneySource: 'Въведете източник на доходи',
    enterDiscretion: 'Въведете дали желаете дискретност',
    enterCreditPurpose: 'Въведете цел на кредита',
    enterPaymentDayOfTheMonth: 'Въведете на кое число желате да плащате',
    enterNumberOfCars: 'Въведете колко лични автомобили имаш',
    enterIban: 'Въведете IBAN',
    enterCardNumber: 'Въведете номер на карта',
    enterApartamentNumber: 'Въведете номер на апартамента',
    chooseAvalType: 'Изберете опция за обезпечение',
    choosePaymentType: 'Изберете начин на получаване на кредита'
  },
  backEnd: {
    'Wrong Username Or Password': 'Грешно потребителско име или парола',
    'WEEKLY': 'Седмично',
    'BIWEEKLY': 'Двуседмично',
    'MONTHLY': 'Месечно',
    'Wrong Aval Code': 'Грешен SMS КОД',
    'Wrong Validation Code': 'Грешен код',
    'User With The Same Email Already Exists': 'Съществува потребител с този имейл',
    'User Is Not Verified': 'Не сте потвърдили Вашият акаунт',
    'Wrong Reset Password Code': 'Грешен код',
    'FATCA Obligated People Are Not Allowed To Request Credits': 'Не предоставяме кредити на данъчно задължени лица към САЩ',
    'Entity Bg Cashbox Ml Func Credit Request Steps Credit Request Step 4 Payment And Aval Type Is Not Valid': 'Невалиден метод на плащане'
  },
  header: {
    internalLinks: {
      newCredit: 'Нов кредит',
      myCredits: 'Моите кредити',
      messages: 'Съобщения',
    },
    logIn: 'Вход за клиенти',
    logOut: 'Изход',
    profile: 'Профил'
  }
}
