import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { Row, Col } from "react-grid-system";
import { ProfileValidator } from "@/formValidators";
import { useForm } from "react-hook-form";
import { Input, Upload, Form } from "@/components/common";
import { useFocusOnError } from "@/hooks";
import {Label} from "@/components/common/Field/Field.styles";

const ChangeIdCard = ({ profileState, idCardNumber, idCardUploaded, isLoading }) => {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: ProfileValidator.idCard(),
  });

  const inputProps = {
    ref: register,
    errors,
  };

  const onSubmit = (data) => profileState.updateProfile(data, "id-card");

  useFocusOnError(errors);

  return (
    <Form
      {...{
        isLoading,
        loaderFixedCircle: false,
        loaderSize: "small",
        loaderBackground: "concrete",
      }}
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      buttonText="Актуализирай"
    >
      <Row>
        <Col sm={12} style={{ marginBottom: 30 }}>
          <Input
            {...inputProps}
            name="idCardNumber"
            defaultValue={idCardNumber}
            label="Номер на лична карта:"
            customStyle="gray"
          />
        </Col>
        <Col sm={12}>
          {!idCardUploaded && <Label style={{ color: 'red'}}>Липсват качени снимки на лична карта</Label>}
        </Col>
        <Col sm={6}>
          <Upload
            {...inputProps}
            name="idCardPictureFront"
            label="Качи тук лицевата страна"
            fullWidth
          />
        </Col>
        <Col sm={6}>
          <Upload
            {...inputProps}
            name="idCardPictureBack"
            label="Качи тук гърба"
            fullWidth
          />
        </Col>
      </Row>
    </Form>
  );
};

ChangeIdCard.propTypes = {
  profileState: PropTypes.object,
  idCardNumber: PropTypes.string,
  idCardUploaded: PropTypes.bool,
  formData: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default compose(
  inject(({ store: { profileState, isLoading } }) => ({
    profileState,
    isLoading,
  })),
  observer
)(ChangeIdCard);
