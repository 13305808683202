import styled, { css } from "styled-components";
import { Group } from "@/components/FieldGroup/FieldGroup.styles";
import { Headline, Text, Button } from "@/components/common";

export const Calculator = styled.div`
  position: relative;
`;

export const Switch = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;

  list-style: none;
`;

Switch.Item = styled.li`
  display: flex;
  width: 50%;
  padding: 10px;
  align-items: center;
  justify-content: center;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: ${({ theme, active }) =>
    active
      ? theme.colors.calculatorActiveTabBg
      : theme.colors.calculatorInactiveTabBg};

  color: ${({ theme, active }) =>
    active
      ? theme.colors.calculatorActiveTabLink
      : theme.colors.calculatorInactiveTabLink};
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  cursor: ${({ active }) => (active ? "default" : "pointer")};
`;

export const Content = styled.div`
  min-height: 400px;

  position: relative;

  background: ${({ theme }) => theme.colors.calculatorBg};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const CreditPackage = styled.div`
  width: 100%;
  max-width: 310px;

  position: relative;
  z-index: 6;

  ${({ theme }) => `
    @media ${theme.mediaQueries.smUp} {
      width: 50%;
    }

    @media ${theme.mediaQueries.mdUp} {
      width: 65%;
    }
  `}
`;

export const CalculatorForm = styled.div`
  padding: 0 15px 8px 15px;
`;

export const CalcCredit = styled.div`
  padding: 10px 15px;

  background: ${({ theme }) => theme.colors.primary};
`;

export const AmountItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ withAval, isInstallment }) => `
    ${withAval && "margin-bottom: 4px;"}
    ${isInstallment && "margin-bottom: 0;"}
  `}
`;

export const Amount = styled(Text)`
  margin: 0;

  ${({ isInstallment }) =>
    isInstallment
      ? css`
          min-height: 14px;
          font-size: 14px;
        `
      : css`
          min-height: 12px;
          font-size: 12px;
        `}

  ${({ isSmall }) =>
    isSmall &&
    css`
      opacity: 0.3;
    `}
`;

export const AmountDesc = styled(Text)`
  margin: 0;
  text-transform: uppercase;
  font-size: 13px;
  min-height: 13px;

  ${({ isSmall }) => `
    ${
      isSmall &&
      `
      min-height: 10px;
      opacity: 0.3;
      font-weight: 400;
      font-size: 10px;
    `
    }
  `}
`;

export const BottomContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 12px 15px;
`;

export const ValueLoader = styled.span`
  width: 45px;
  margin-right: auto;
  margin-left: auto;

  position: relative;

  text-align: center;

  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }

    30% {
      transform: translateY(-15px);
    }
  }

  span {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 3px;

    border-radius: 50%;
    background: #fff;

    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
`;

export const CalculatorTitle = styled(Headline)`
  margin-bottom: 6px;

  font-size: 14px;
`;

export const CreditPeriods = styled(Group)`
  padding: 0;
  justify-content: center;
`;

export const GetCreditButton = styled(Button)`
  font-size: 15px;
`;
