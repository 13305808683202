import styled from "styled-components";

export const CreditTotal = styled.div`
  display: block;

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      width: 100%;
    }

    @media ${theme.mediaQueries.smUp} {
      width: 70%;
    }

    @media ${theme.mediaQueries.mdUp} {
      width: 50%;
    }
  `}
`;
