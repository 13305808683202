import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BackgroundPage } from '@/layouts'
import { Headline, Button } from '@/components/common'

const NotFound = styled.div`
  text-align: center;
`

NotFound.Title = styled(Headline)`
  font-size: 120px;
`

const ButtonWrapper = styled.div`
  margin-top: 50px;
`

export default () => {
  const { t } = useTranslation()

  return (
    <BackgroundPage>
      <NotFound>
        <NotFound.Title color='light'>404</NotFound.Title>
        <Headline color='light'>{t('global.pageNotFound')}</Headline>
        <ButtonWrapper>
          <Button as={Link} color='light' to='/'>{t('buttons.goToHomePage')}</Button>
        </ButtonWrapper>
      </NotFound>
    </BackgroundPage>
  )
}
