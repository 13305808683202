import React from "react";
import Media from "react-media";
import PropTypes from "prop-types";

import { mediaQueries } from "@/constants";

const ResponsiveRender = ({ children }) => (
  <Media queries={mediaQueries}>{children}</Media>
);

ResponsiveRender.propTypes = { children: PropTypes.func };

export default ResponsiveRender;
