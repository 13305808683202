import * as Yup from 'yup'

export default ()=> Yup.object().shape({
  discretion: Yup.object().shape({
    value: Yup.object().shape({
      id: Yup.object().typeError(window.translate.t('validations.enterDiscretion'))
        .required(window.translate.t('validations.enterDiscretion')),
    }),
  }),

  creditPurpose: Yup.object().when('creditForPersonalUse', {
    is: 1, // Assuming '1' signifies true for creditForPersonalUse
    then: Yup.object().shape({
      value: Yup.object().shape({
        id: Yup.object().nullable().typeError(window.translate.t('validations.enterCreditPurpose'))
            .required(window.translate.t('validations.enterCreditPurpose')),
      }),
    }),
    otherwise: Yup.object().shape({
      value: Yup.object().shape({
        id: Yup.object().nullable().notRequired(), // Make it nullable and not required when creditForPersonalUse is not 1
      }),
    }),
  }),

  paymentDayOfTheMonth: Yup.object().typeError(window.translate.t('validations.enterPaymentDayOfTheMonth'))
    .required(window.translate.t('validations.enterPaymentDayOfTheMonth')),

    numberOfCars: Yup.object()
    .typeError(window.translate.t('validations.enterNumberOfCars'))
    .required(window.translate.t('validations.enterNumberOfCars')),

  newestCarFuelUsed: Yup.object().nullable().when(['numberOfCars.value'], {
    is: 0,
    otherwise: Yup.object().typeError(window.translate.t('validations.enterNumberOfCars'))
  }),

  newestCarYearsOld: Yup.object().nullable().when(['numberOfCars.value'], {
    is: 0,
    otherwise: Yup.object().typeError(window.translate.t('validations.enterNumberOfCars'))
  }),

  creditForPersonalUse: Yup.number().typeError(window.translate.t('validations.enterField')).required(window.translate.t('validations.enterField')),
})
