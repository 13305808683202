import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { Row, Col } from "react-grid-system";
import { useForm } from "react-hook-form";

import { Box, Loader } from "@/components";
import { Headline, Input, Button } from "@/components/common";
import { AvalsValidator } from "@/formValidators";
import { BulbIcon } from "@/assets/svg";
import { useFocusOnError, useRedirect } from "@/hooks";

import * as S from './PrivateAvalsForm.styles';
import { useDispatch, useSelector } from "react-redux";
import { creditRequestSelector, postAvalCode } from "@/redux/slices/creditRequestSlice";

const AvalForm = ({ isLoading }) => {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: AvalsValidator().avalCode(),
  });
  const dispatch = useDispatch();
  const { avals } = useSelector(creditRequestSelector)

  const redirect = useRedirect();

  useEffect(() => {
    if (avals?.codeVerified) {
      redirect(`/credit-request/${avals.id}/private-avals-sign`);
    }
  }, [avals?.codeVerified]);

  useFocusOnError(errors);

  return (
    <Box>
      <Box.Content background="danger">
        <S.BoxHeadline>
          <BulbIcon />
          <Headline disableMargin color="light">
            Стани личен поръчител
          </Headline>
        </S.BoxHeadline>
        <S.Form onSubmit={handleSubmit((data) => dispatch(postAvalCode(data)))}>
          <Row>
            <Col md={8}>
              <Input
                ref={register}
                {...{ errors }}
                color="light"
                name="avalCode"
                fullWidth
                label="Въведи SMS код тук:"
                customStyle="fullWhite"
              />
            </Col>
          </Row>
          <S.ButtonsWrapper>
            <Button as="button" type="submit" color="lightDanger" weight={600}>
              Потвърди
            </Button>
          </S.ButtonsWrapper>
        </S.Form>
      </Box.Content>
      <Loader background="danger" {...{ isLoading }} />
    </Box>
  );
};

AvalForm.propTypes = {
  creditRequestState: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default compose(
  inject(({ store: { isLoading } }) => ({
    isLoading,
  })),
  observer
)(AvalForm);
