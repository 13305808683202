import React from "react";

export default () => (
  <svg
    width="444"
    height="444"
    viewBox="0 0 444 444"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M221.657 143.492L414.391 336.226C421.055 342.889 431.859 342.889 438.523 336.226C445.185 329.561 445.185 318.758 438.523 312.094L233.723 107.294C227.059 100.632 216.255 100.632 209.591 107.294L4.79065 312.094C-1.75735 318.874 -1.56934 329.678 5.21066 336.226C11.8247 342.613 22.3097 342.613 28.9227 336.226L221.657 143.492Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="443.52"
          height="443.52"
          fill="currentColor"
          transform="translate(443.52 443.52) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
);
