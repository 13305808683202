import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 512.006 512.006'
    version='1.1'
    viewBox='0 0 512.006 512.006'
    xmlSpace='preserve'
  >
    <path d='M412.068 139.217C404.1 67.072 345.955 8.927 273.811.96c-44.661-4.932-89.325 9.297-122.537 39.043-33.238 29.768-52.302 72.419-52.302 117.012v20.001h40.002v-20.001c0-33.234 14.211-65.023 38.988-87.214 25.114-22.493 57.591-32.82 91.458-29.08 53.688 5.929 96.959 49.2 102.888 102.888 4.769 43.177-14.342 85.244-49.872 109.786-54.116 37.379-86.423 98.165-86.423 162.602v20.001h40.001v-20.001c0-51.297 25.852-99.778 69.155-129.688 47.65-32.914 73.284-89.275 66.899-147.092z'></path>
    <path d='M236.015 472.004H276.017V512.006H236.015z'></path>
  </svg>
)
