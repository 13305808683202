import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-grid-system'
import { Headline } from '@/components/common'
import { Loader } from '@/components'
import BackgroundImage from '@/assets/images/BackgroundScreen.jpg'
import BackgroundImageMobile from '@/assets/images/BackgroundScreenMobile.jpg'

const Background = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.dark};
  background-image: ${`url(${BackgroundImageMobile})`};

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      min-height: ${`calc(100vh - ${theme.header.height.xs})`};
      padding: 15px 0;
    }

    @media ${theme.mediaQueries.smUp} {
      min-height: ${`calc(100vh - ${theme.header.height.sm})`};
      padding: 25px 0;
      background-image: ${`url(${BackgroundImage})`};
    }

    @media ${theme.mediaQueries.mdUp} {
      min-height: ${`calc(100vh - ${theme.header.height.md})`};
    }

    @media ${theme.mediaQueries.lgUp} {
      min-height: ${`calc(100vh - ${theme.header.height.lg})`};
    }

    @media ${theme.mediaQueries.xlUp} {
      min-height: ${`calc(100vh - ${theme.header.height.xl})`};
    }
  `}
`

const TitleWrapper = styled.div`
  text-align: center;
`

const Shadow = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  box-shadow: inset 0 -8vh 18vw 6vw ${({ theme }) => theme.colors.dark};
  z-index: 2;
`

const Content = styled.div`
  position: relative;
  width: 100%;
  z-index: 3;
`

const Inner = styled.div`
  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      max-width: 400px;
      margin: 0 auto;
    }
  `}
`

const BackgroundComponent = ({ title, children, isLoading }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Background>
      <Content>
        <Container>
          <Row justify='center'>
            <Col sm={6} md={6} lg={5} xl={5}>
              <Inner>
                {title && (
                  <TitleWrapper>
                    <Headline size='large' color='light'>{title}</Headline>
                  </TitleWrapper>
                )}
                {children}
              </Inner>
            </Col>
          </Row>
        </Container>
      </Content>
      <Shadow />
      <Loader {... {
        isLoading,
        size: 'large',
        background: 'primary',
      }} />
    </Background>
  )
}

BackgroundComponent.defaultProps = {
  title: '',
  children: <></>,
  isLoading: false,
}

BackgroundComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
}

export default BackgroundComponent
