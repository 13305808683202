import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

import { useDispatch, useSelector } from "react-redux";

import { SignScreen } from "@/components";
import { DefaultPage } from "@/layouts";
import { RegisterIcon } from "@/assets/svg";
import { useRedirect } from "@/hooks";
import {
  creditRequestSelector,
  postPrivateAval,
} from "@/redux/slices/creditRequestSlice";

const PrivateAvalsSign = ({ isLoading }) => {
  const { avalRequestID, avals } = useSelector(creditRequestSelector);
  const { signed, signatureType, documents } = avals;

  const dispatch = useDispatch()
  const redirect = useRedirect();

  useEffect(() => {
    if (!avalRequestID) {
      redirect("/my-credits");
    }
    if (avalRequestID && signed) {
      redirect(`/credit-request/${avalRequestID}/private-avals-signed`);
    }
  }, [avalRequestID, signed]);

  return (
    <DefaultPage
      head={{
        icon: RegisterIcon,
        title: "Личен поръчител",
        subTitle: "Подписване на документите",
      }}
    >
      <SignScreen
        signatureType={signatureType}
        documents={documents}
        submitAction={dispatch(postPrivateAval)}
        {...{ isLoading }}
      />
    </DefaultPage>
  );
};

PrivateAvalsSign.propTypes = {
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
};

export default compose(
  inject(({ store: { isLoading } }) => ({ isLoading })),
  observer
)(PrivateAvalsSign);