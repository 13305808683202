import styled, { css } from "styled-components";

const Sizes = {
  large: css`
    ${({ theme, as }) => `
        height: ${as === "textarea" ? "140px" : "48px"};
        padding: ${as === "textarea" ? "20px" : "0 20px"};
      }

      @media ${theme.mediaQueries.smUp} {
        height: ${as === "textarea" ? "180px" : "52px"};
        padding: ${as === "textarea" ? "25px" : "0 25px"};
      }
    `}
  `,
  small: css`
    padding: ${({ as }) => (as === "textarea" ? "15px" : "0 15px")};

    ${({ theme, as }) => `
      @media ${theme.mediaQueries.xsOnly} {
        height: ${as === "textarea" ? "100px" : "44px"};
      }

      @media ${theme.mediaQueries.smUp} {
        height: ${as === "textarea" ? "140px" : "47px"};
      }
    `}
  `,
  extraSmall: css`
    padding: ${({ as }) => (as === "textarea" ? "15px" : "0 15px")};

    ${({ theme, as }) => `
      @media ${theme.mediaQueries.xsOnly} {
        height: ${as === "textarea" ? "100px" : "44px"};
      }

      @media ${theme.mediaQueries.smUp} {
        height: ${as === "textarea" ? "140px" : "47px"};
      }
    `}
  `,
};

const inputStyle = css`
  overflow: hidden;
  border-style: solid;
  -webkit-appearance: none;
  border-radius: ${({ theme }) => theme.borderRadius};
  border-color: ${({ theme, hasError }) =>
    hasError ? theme.colors.danger : theme.colors.border};
  border-width: ${({ color }) => (color === "light" ? 0 : "3px")};
  font-family: ${({ theme }) => theme.fonts.default};

  background: ${({ theme, customStyle }) => {
    if (customStyle) {
      if (customStyle === "fullWhite") {
        return theme.colors.light;
      }
      if (customStyle === "gray") {
        return theme.colors.whiteGray;
      }
    } else {
      return theme.colors.mainBackground;
    }
  }};

  color: ${({ theme, customStyle }) =>
    customStyle
      ? customStyle === "fullWhite"
        ? theme.colors.primary
        : theme.colors.formLabelColor
      : theme.colors.formLabelColor};

  display: block;
  width: 100%;
  font-size: 16px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  ${({ theme, fullWidth, as }) => `
    ${
      as === "textarea"
        ? `
      resize: none;
      border-radius: 20px;
    `
        : ""
    }

    @media ${theme.mediaQueries.smUp} {
      width: ${fullWidth ? "100%" : "auto"};
      min-width: ${fullWidth ? "0" : "390px"};
    }
  `}

  ${({ size }) => Sizes[size]}

  &::placeholder {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.silver};
  }

  &:focus,
  &:active {
    outline: none;
  }
`;

export const Input = styled.input`
  ${inputStyle}
`;

export const PhoneInput = styled.div`
  input {
    ${inputStyle}
  }
`;
