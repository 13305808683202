import React from "react";
import PropTypes from "prop-types";
import { AnimatedRender } from "@/components";

import * as S from "./Alert.styles";

const AlertComponent = ({ text, color, hide }) => (
  <AnimatedRender in={Boolean(text) && !hide}>
    <S.Alert className="Alert" {...{ color }}>
      {text}
    </S.Alert>
  </AnimatedRender>
);

AlertComponent.defaultProps = {
  text: "",
  color: "primary",
  hide: false,
};

AlertComponent.propTypes = {
  text: PropTypes.string,
  color: PropTypes.oneOf(["primary", "danger", "secondary"]),
  hide: PropTypes.bool,
};

export default AlertComponent;
