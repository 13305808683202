/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { ApmRoute } from '@elastic/apm-rum-react'
import { getStorage } from "@/utils";
import { storageKeys } from "@/constants";
import { getSession } from "@/redux/slices/authSlice";
import { useDispatch } from "react-redux";

const PrivateRoute = ({
  path,
  component: Component,
  redirect,
  activeStep,
  ...rest
}) => {
  const [checkingSession, setCheckingSession] = useState(true);

  const dispatch = useDispatch();
  const isAuthenticated = getStorage(storageKeys.token);

  useEffect(() => {
    if (checkingSession) {
      dispatch(getSession())
        .then()
        .catch()
        .finally(() => {
          setCheckingSession(false);
        });
    }
  }, [checkingSession, getSession, setCheckingSession]);

  return (
    !checkingSession && (
      <ApmRoute
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} {...{ activeStep }} />
          ) : (
            <Redirect
              to={{
                pathname: redirect,
                state: { redirectedFrom: path },
              }}
            />
          )
        }
      />
    )
  );
};

PrivateRoute.defaultProps = {
  component: () => <div></div>,
  redirect: "/",
};

export default PrivateRoute;
