import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { format } from "@tammo/react-iban";
import ReactPhoneInput from "react-phone-input-2";
import { getYupError } from "@/utils";
import { Tooltip } from "@/components";
import { Field } from "@/components/common";

import * as S from "./Input.styles";

const defaultProps = {
  label: "",
  size: "small",
  color: "primary",
  fullWidth: false,
  instructions: [],
  icon: <></>,
  customStyle: "",
  handleFocus: () => {},
  handleBlur: () => {},
};

const propTypes = {
  label: PropTypes.string,
  register: PropTypes.func,
  size: PropTypes.oneOf(["extraSmall", "small", "large"]),
  fullWidth: PropTypes.bool,
  color: PropTypes.oneOf(["light", "primary"]),
  instructions: PropTypes.array,
  icon: PropTypes.object,
  customStyle: PropTypes.string,
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
};

const InputComponent = forwardRef(
  (
    {
      label,
      color,
      size,
      fullWidth,
      instructions,
      icon,
      customStyle,
      handleBlur,
      handleFocus,
      ...restProps
    },
    ref
  ) => (
    <Field
      {...{
        label,
        size,
        color,
        errors: restProps?.errors,
        name: restProps?.name,
        type: restProps?.type,
        instructions,
        icon,
        customStyle,
      }}
    >
      <S.Input
        {...{
          color,
          size,
          fullWidth,
          customStyle,
          hasError: Boolean(getYupError(restProps?.errors, restProps?.name)),
        }}
        {...{
          ref,
          ...restProps,
        }}
        onFocusCapture={handleFocus}
        onBlurCapture={handleBlur}
      />
    </Field>
  )
);

InputComponent.defaultProps = defaultProps;

InputComponent.propTypes = propTypes;

const IBANInput = (props) => {
  const { value, onChange, ...restProps } = props;

  const formattedValue = format(value);

  const change = (event) => {
    const value = event.target.value || "";
    const formatted = format(value);
    const unformatted = formatted.replaceAll(" ", "");
    onChange(unformatted);
  };

  return <S.Input {...restProps} onChange={change} value={formattedValue} />;
};

IBANInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

InputComponent.IBAN = ({ label, color, size, instructions, ...restProps }) => {
  return (
    <Field
      {...{
        label,
        size,
        color,
        errors: restProps?.errors,
        name: restProps?.name,
        instructions,
      }}
    >
      <IBANInput
        {...{
          color,
          size,
          hasError: Boolean(getYupError(restProps?.errors, restProps?.name)),
        }}
        {...restProps}
        data-tip="Проверете внимателно вашият IBAN номер. В случай че сте посочили грешен IBAN, отговорността е изцяло ваша."
      />
      <Tooltip color="danger" place="right" />
    </Field>
  );
};

InputComponent.IBAN.defaultProps = defaultProps;
InputComponent.IBAN.propTypes = propTypes;

InputComponent.Phone = ({
  label,
  color,
  size,
  fullWidth,
  instructions,
  handleBlur,
  handleFocus,
  ...restProps
}) => (
  <Field
    {...{
      label,
      size,
      color,
      instructions,
      errors: restProps?.errors,
      name: restProps?.name,
    }}
  >
    <S.PhoneInput
      {...{
        size,
        color,
        fullWidth,
        hasError: Boolean(getYupError(restProps?.errors, restProps?.name)),
      }}
    >
      <ReactPhoneInput
        country="bg"
        regions="europe"
        autoFormat={false}
        {...restProps}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </S.PhoneInput>
  </Field>
);

InputComponent.Phone.defaultProps = defaultProps;
InputComponent.Phone.propTypes = propTypes;

export default InputComponent;
