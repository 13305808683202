import { createSlice } from "@reduxjs/toolkit";
import {
  getStorage,
  HTTPmethods,
  removeStorage,
  request,
  setStorage,
} from "@/utils";
import { storageKeys, urls } from "@/constants";
import { toast } from "react-toastify";
import { cleanup as creditCleanup } from './creditRequestSlice'
import { analyticsEvents, logAnalyticsEvent, setAnalyticsUserId } from "@/utils/analytics";

const SLICE_NAME = "auth";

const initialState = {
  sessionId: "",
  userProfile: {},
  hasError: false,
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSessionId: (state, { payload }) => {
      setStorage(storageKeys.token, payload);
      state.sessionId = payload;
    },

    setUserProfile: (state, { payload }) => {
        state.userProfile = payload;
    },

    setHasError: (state, { payload }) => {
      state.hasError = payload;
    },

    cleanup: () => {
      removeStorage(storageKeys.token);

      return initialState;
    },
  },
});

export const { setSessionId, setUserProfile, setHasError, cleanup } = authSlice.actions;

// - Selectors
export const authSelector = (state) => state[SLICE_NAME];

// - Reducer
export default authSlice.reducer;

// - Async Actions
export function login(fields) {
  return async (dispatch) =>
    await request({
      method: HTTPmethods.POST,
      url: urls.sessions,
      suppressErrors: true,
      data: {
        device: { deviceFingerprint: navigator.userAgent },
        ...fields,
      },
    })
      .then(({ data }) => {
        dispatch(setSessionId(data.sessionId));
        dispatch(setUserProfile(data.userProfile));
        setAnalyticsUserId(data.userProfile.userId);
        logAnalyticsEvent(analyticsEvents.events.login);
        return true
      })
      .catch(() => {
        toast.error(window.translate.t("messages.errorLogin"));

        dispatch(setHasError(true))
        return false
      });
}

export function logout() {
  return async (dispatch) =>
    await request({
      method: HTTPmethods.DELETE,
      url: urls.sessions,
      suppressErrors: true,
    })
      .then(() => {
        toast.success(window.translate.t("messages.successLogout"));

        dispatch(cleanup());
        dispatch(creditCleanup());

        logAnalyticsEvent(analyticsEvents.events.logout);
        setAnalyticsUserId(null);
      })
      .catch(() => dispatch(cleanup()));
}

export function getSession() {
  return async (dispatch) => {
    const token = getStorage(storageKeys.token);

    if (token) {
      await request({
        method: HTTPmethods.GET,
        url: urls.sessions,
        suppressErrors: true,
      })
        .then(({ data }) => {
          dispatch(setSessionId(data.sessionId));
          dispatch(setUserProfile(data.userProfile));
        })
        .catch(() => dispatch(logout()));
    } else {
      dispatch(logout());
    }
  };
}
