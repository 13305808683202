import styled from "styled-components";
import { Text } from "@/components/common";

export const StatusContainer = styled.div`
  margin: 3em auto;
  max-width: 500px;

  position: relative;

  text-align: center;
  color: ${({ theme }) => theme.colors.light};
`;

export const Headline = styled.div`
  font-weight: 600;
  font-size: 1.5em;
  text-transform: uppercase;
  line-height: 1.2;
`;

export const Iconbox = styled.div`
  display: flex;

  position: relative;
  z-index: 2;

  transform: translateY(-50%);

  text-align: center;
  justify-content: center;

  & > div {
    display: flex;
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;

    position: relative;

    border: 6px solid ${({ theme }) => theme.colors.mainBackground};
    border-radius: 50%;
  }

  svg {
    display: block;
    width: auto;
    height: 50%;

    position: relative;

    fill: white;
  }
`;

export const SubHeadline = styled.div`
  margin-top: 1em;

  font-size: 1.1em;
  line-height: 1.2;
  text-transform: uppercase;
`;

export const StatusInner = styled.div`
  padding: 0 2em 2em;
  margin-bottom: 1em;

  position: relative;

  border-radius: 15px;

  text-align: center;
`;

export const StatusButton = styled.div`
  width: 80%;
  padding: 1em;
  margin: 0 auto;

  position: relative;

  background: ${({ theme }) => theme.colors.primary};
  border-radius: 30px;

  cursor: pointer;

  font-size: 1em;
  font-weight: bold;

  transition: 0.3s;

  &:hover {
    background: #1668b6;
  }

  &:active {
    top: 1px;
    left: 1px;
  }
`;

export const WaitingText = styled(Text)`
  color: ${({ theme }) => theme.colors.light};
`;
