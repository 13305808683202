import React, { useState } from "react";
import PropTypes from "prop-types";

import * as S from './TabContent.styles';

const TabComponent = ({
  onClick,
  onActive,
  label,
  icon: Icon,
  mobileLabel,
  activeTab,
  alert,
}) => (
  <S.Tab
    isActive={activeTab === label}
    onClick={(event) => {
      onClick(label);
      if (typeof onActive === "function") {
        onActive(event, label);
      }
    }}
  >
    <S.Tab.Label hasMobileLabel={mobileLabel}>
      <Icon /> {label}
    </S.Tab.Label>
    {alert && <S.Tab.Alert>!</S.Tab.Alert>}
    {mobileLabel && (
      <S.Tab.MobileLabel>
        <Icon /> {mobileLabel}
      </S.Tab.MobileLabel>
    )}
  </S.Tab>
);

const TabContentComponent = ({ children, onChangeTab }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  return (
    <S.TabContentWrapper>
      <S.TabNav>
        {children.map((child, index) => {
          const { label } = child.props;
          return (
            <TabComponent
              key={`${label}${index}`}
              {...{ activeTab }}
              {...child.props}
              onClick={(tab) => {
                if (typeof onChangeTab === "function") {
                  onChangeTab(tab);
                }

                setActiveTab(tab);
              }}
            />
          );
        })}
      </S.TabNav>

      <S.TabContent>
        {children.map((child) => {
          const isActive = child.props.label === activeTab;
          const content = child.props.children;

          return isActive ? content : "";
        })}
      </S.TabContent>
    </S.TabContentWrapper>
  );
};

TabComponent.propTypes = {
  onClick: PropTypes.func,
  onActive: PropTypes.func,
  label: PropTypes.string,
  mobileLabel: PropTypes.string,
  activeTab: PropTypes.string,
  alert: PropTypes.bool,
  icon: PropTypes.elementType,
};

TabContentComponent.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  onChangeTab: PropTypes.func,
};

export default TabContentComponent;
