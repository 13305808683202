import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
//import { useHistory } from 'react-router-dom'
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ProfileValidator } from "@/formValidators";
import { useForm } from "react-hook-form";
import { Input, Form } from "@/components/common";
import { useFocusOnError, useRedirect } from "@/hooks";

const ChangePhone = ({ profileState, formData, isLoading }) => {
  const { t } = useTranslation();
  //let history = useHistory()
  const { register, handleSubmit, errors } = useForm({
    validationSchema: ProfileValidator.phone(),
  });
  const { mobilePhone } = formData;
  const redirect = useRedirect();

  const inputProps = {
    ref: register,
    errors,
  };

  useFocusOnError(errors);

  const onSubmit = (data) => {
    profileState.updateProfile(data, "phone");
    redirect("/confirm-phone", {
      redirectAfterConfirm: "/profile",
      mobilePhone: data.phone,
    });
  };

  return (
    <Form
      {...{
        isLoading,
        loaderFixedCircle: false,
        loaderSize: "small",
        loaderBackground: "concrete",
      }}
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      buttonText="Актуализирай телефона"
    >
      <Input
        {...inputProps}
        label={t("fields.mobilePhone")}
        defaultValue={mobilePhone}
        name="phone"
        customStyle="gray"
      />
    </Form>
  );
};

ChangePhone.propTypes = {
  profileState: PropTypes.object,
  formData: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default compose(
  inject(({ store: { profileState, isLoading } }) => ({
    profileState,
    isLoading,
  })),
  observer
)(ChangePhone);
