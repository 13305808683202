import { createSlice, current } from "@reduxjs/toolkit";
import { HTTPmethods, removeStorage, request } from "@/utils";
import { storageKeys, urls } from "@/constants";
import { orderBy, uniqBy } from "lodash";

const SLICE_NAME = "messages";

const initialState = {
  messages: [],
  currentPage: 0,
  pageSize: 10,
  totalItems: 0,
  totalPages: 0,
  numberOfUnreadMessages: 0,
  hasError: false,
};

export const messagesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setMessages: (state, { payload }) => {
      let newMsg = [...current(state.messages)];
      if (payload.length) {
        const uniq = uniqBy(
          [...current(state.messages), ...payload],
          (e) => e.id
        );
        newMsg = uniq;
      }

      const sorted = orderBy(newMsg, [(e) => e.createdAt], ["desc"]);
      state.messages = sorted;
    },

    setPageDetails: (state, { payload }) => {
      state.currentPage = payload.currentPage;
      state.pageSize = payload.pageSize;
      state.totalItems = payload.totalItems;
      state.totalPages = payload.totalPages;
    },

    setNumberOfUnreadMessages: (state, { payload }) => {
      state.numberOfUnreadMessages = payload;
    },

    setHasError: (state, { payload }) => {
      state.error = payload;
    },

    updateMessageSeenAt: (state, { payload }) => {
      const newMsgArray = current(state.messages).map((msg) =>
        msg.id === payload.id ? { ...msg, seenAt: payload.seenAt } : msg
      );

      state.messages = newMsgArray;
    },

    cleanup: () => {
      removeStorage(storageKeys.token);

      return initialState;
    },
  },
});

export const {
  setMessages,
  setPageDetails,
  setNumberOfUnreadMessages,
  setHasError,
  updateMessageSeenAt,
  cleanup,
} = messagesSlice.actions;

// - Selectors
export const messagesSelector = (state) => state[SLICE_NAME];

// - Reducer
export default messagesSlice.reducer;

// - Async Actions
export function getMessages(pageSize = 10, page = 0) {
  return async (dispatch) => {
    await request({
      method: HTTPmethods.GET,
      url: `${urls.clientNotifications}?pageSize=${pageSize}&page=${page}`,
    })
      .then(({ data }) => {
        dispatch(setMessages(data.data));
        dispatch(
          setPageDetails({
            currentPage: data.currentPage,
            pageSize: data.pageSize,
            totalItems: data.totalItems,
            totalPages: data.totalPages,
          })
        );
      })
      .catch(() => dispatch(setHasError()));
  };
}

export function setIsRead(messageId) {
  return async (dispatch) => {
    await request({
      method: HTTPmethods.POST,
      url: `${urls.clientNotifications}/${messageId}/seen`,
    })
      .then(({ data }) => {
        dispatch(
          updateMessageSeenAt({
            seenAt: data.seenAt,
            id: messageId,
          })
        );
        dispatch(getUnreadMessages());
      })
      .catch(() => dispatch(setHasError()));
  };
}

export function getUnreadMessages() {
  return async (dispatch) => {
    await request({
      method: HTTPmethods.GET,
      url: `${urls.clientNotifications}?seen=false`,
      suppressErrors: true
    })
      .then(({ data }) => {
        dispatch(setNumberOfUnreadMessages(data.totalItems));
        dispatch(setMessages(data.data));
      })
      .catch(() => dispatch(setHasError()));
  };
}
