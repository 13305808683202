import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

export const Nav = styled.ul`
  text-align: ${({ align }) => align};
`;

Nav.Item = styled.li`
  display: inline-block;

  ${({ theme, size }) => `
    @media ${theme.mediaQueries.xsOnly} {
      margin-right: ${size === "small" ? "10px" : "20px"};
    }

    @media ${theme.mediaQueries.smUp} {
      margin-right: ${size === "small" ? "8px" : "10px"};
    }

    @media ${theme.mediaQueries.mdUp} {
      margin-right: ${size === "small" ? "15px" : "30px"};
    }
  `}

  &:last-child {
    margin-right: 0;
  }
`;

Nav.Link = styled(NavLink)`
  font-weight: ${({ weight }) => weight};
  color: ${({ theme, color }) => theme.colors[color]};
  text-transform: uppercase;
  background: transparent;
  border: 0;
  line-height: 1;
  padding: 0;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }

  ${({ theme, size }) => `
    @media ${theme.mediaQueries.xsOnly} {
      font-size: ${size === "small" ? "11px" : "14px"};
    }

    @media ${theme.mediaQueries.smUp} {
      font-size: ${size === "small" ? "12px" : "14px"};
    }

    @media ${theme.mediaQueries.mdUp} {
      font-size: ${size === "small" ? "12px" : "16px"};
    }
  `}
`;

export const MainNav = styled.div`
  background: ${({ theme }) => theme.colors.mainTabBg};
`;

MainNav.Wrapper = styled.ul`
  display: flex;
  list-style: none;
`;

MainNav.Item = styled.li`
  position: relative;
`;

MainNav.Bubble = styled.span`
  padding: 0.25em 0.5em;

  position: absolute;
  top: -0.5em;
  right: -0.5em;
  z-index: 1;

  background-color: ${({ theme }) => theme.colors.danger};
  border-radius: 10em;

  color: ${({ theme }) => theme.colors.light};
`;

MainNav.Link = styled(NavLink)`
  display: block;

  position: relative;

  border: 0;
  background: transparent;

  color: ${({ theme }) => theme.colors.mainTabLink};
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;

  cursor: pointer;

  ${({ theme }) => `
    @media ${theme.mediaQueries.smOnly} {
      padding: 14px 18px;
      font-size: 14px;
    }

    @media ${theme.mediaQueries.mdUp} {
      padding: 12px 36px;
      font-size: 15px;
    }

    @media ${theme.mediaQueries.lgUp} {
      padding: 12px 40px;
    }
  `}

  &:focus,
  &:active {
    outline: none;
  }

  &.active {
    background: ${({ theme }) => theme.colors.mainTabActiveBg};
    box-shadow: 0px 0px 10px 1px rgba(0, 75, 147, 0.55);
    clip-path: inset(0px -15px 0px -15px);

    font-weight: 600;
  }
`;

export const ToggleNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    @media ${theme.mediaQueries.xsOnly} {
      flex-direction: column;

      ul {
        margin-bottom: 2rem;
      }
    }
  `}

  ul {
    li {
      display: block;
      text-align: center;
      margin: 0 0 30px 0;

      &:last-child {
        margin-bottom: 0;
      }

      > * {
        font-size: 22px;
        letter-spacing: 1.5px;
      }
    }
  }
`;

export const Open = styled.div`
  width: 30px;
  height: 30px;

  svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
    fill: #fff;
  }
`;
