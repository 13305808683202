import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='511pt'
    height='511pt'
    viewBox='-53 1 511 511.999'
  >
    <path d='M276.41 3.957C274.062 1.484 270.844 0 267.508 0H67.778C30.921 0 .5 30.3.5 67.152v377.692C.5 481.699 30.922 512 67.777 512h271.086c36.856 0 67.278-30.3 67.278-67.156V144.94c0-3.214-1.485-6.304-3.586-8.656zm3.586 39.7l84.469 88.671h-54.91c-16.325 0-29.559-13.11-29.559-29.433zm58.867 443.609H67.777c-23.125 0-42.543-19.168-42.543-42.422V67.152c0-23.125 19.293-42.418 42.543-42.418h187.485v78.16c0 30.051 24.242 54.168 54.293 54.168h71.851v287.782c0 23.254-19.293 42.422-42.543 42.422zm0 0'></path>
    <path d='M305.102 401.934H101.539c-6.8 0-12.367 5.562-12.367 12.367 0 6.8 5.566 12.367 12.367 12.367h203.688c6.8 0 12.367-5.566 12.367-12.367 0-6.805-5.567-12.367-12.492-12.367zm0 0M167.578 321.297c2.477 2.598 5.817 3.96 9.152 3.96 2.97 0 5.938-1.112 8.41-3.214l102.524-93.992c5.07-4.574 5.441-12.492.742-17.438-4.574-5.07-12.492-5.32-17.437-.742l-93.621 85.582-45.758-49.719c-4.574-5.07-12.488-5.316-17.438-.738-5.07 4.574-5.316 12.488-.742 17.438zm0 0'></path>
  </svg>
)
