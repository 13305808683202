import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME = "global";

const initialState = {
  globalTheme: 'light',
};

export const globalSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setGlobalTheme: (state, { payload }) => {
      state.globalTheme = payload;
    },

    cleanup: () => initialState
  },
});

export const { setGlobalTheme, cleanup } = globalSlice.actions;

// - Selectors
export const globalSelector = (state) => state[SLICE_NAME];

// - Reducer
export default globalSlice.reducer;

export function setGlobalThemeAsync(theme) {
  return async (dispatch) => {
    await dispatch(setGlobalTheme(theme))
  }
}