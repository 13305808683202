import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import ChangePhone from "./ChangePhone";
import ChangeUserAddress from "./ChangeUserAddress";
import ChangeIdCard from "./ChangeIdCard";
import { TabContent } from "@/components";
import { DefaultPage } from "@/layouts";
import {
  RegisterIcon,
  UserIcon,
  SettingsIcon,
  PasswordIcon,
  LocationIcon,
  IDCardIcon,
} from "@/assets/svg";

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.colors.secondary};
    width: 26px;
    height: 26px;
  }

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      justify-content: center;
      margin-bottom: 30px;
    }

    @media ${theme.mediaQueries.smUp} {
      margin-bottom: 10px;
    }
  `}
`;

ProfileInfo.Name = styled.span`
  font-weight: 600;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.formLabelColor};

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      font-size: 16px;
    }

    @media ${theme.mediaQueries.smUp} {
      font-size: 18px;
    }
  `}
`;

const Profile = ({ profileState, referenceDataState }) => {
  const { getProfile, profile } = profileState;
  const { getCities, citiesList } = referenceDataState;

  const { firstName, familyName, idCardNumber, idCardUploaded } = profile;

  useEffect(() => {
    getProfile();
    getCities();
  }, []);

  return (
    <DefaultPage
      head={{
        title: "Моят профил",
        icon: RegisterIcon,
        rightContent: (
          <ProfileInfo>
            <UserIcon />
            <ProfileInfo.Name>
              {firstName} {familyName}
            </ProfileInfo.Name>
          </ProfileInfo>
        ),
      }}
      disableContentGutter
    >
      <TabContent>
        <div label="Основна информация" mobileLabel="Инфо" icon={SettingsIcon}>
          <div style={{ marginBottom: 30 }}>
            <ChangeEmail formData={profile} />
          </div>

          <ChangePhone formData={profile} />
        </div>

        <div label="Промяна на парола" mobileLabel="Парола" icon={PasswordIcon}>
          <ChangePassword formData={profile} />
        </div>

        <div label="Настоящ адрес" icon={LocationIcon}>
          <ChangeUserAddress {...(profile.currentAddress && {
              formData: profile.currentAddress,
              citiesList,
            })} />
        </div>

        <div label="Лична карта" icon={IDCardIcon} alert={!idCardUploaded}>
          <ChangeIdCard {...{ idCardNumber, idCardUploaded }} />
        </div>
      </TabContent>
    </DefaultPage>
  );
};

Profile.propTypes = {
  profileState: PropTypes.object,
  referenceDataState: PropTypes.object,
};

export default compose(
  inject(({ store: { profileState, referenceDataState } }) => ({
    profileState,
    referenceDataState,
  })),
  observer
)(Profile);
