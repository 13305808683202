import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  HTTPmethods,
  request
} from "@/utils";
import { urls } from "@/constants";

const SLICE_NAME = 'address';

const initialState = {
  cities: [],
  districts: [],
  streets: [],
  settlement: [],
  streetSettlement: [],
  allSettlements: []
}

export const addressSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCities: (state, action) => {
      state.cities = action.payload;
    },
    setDistricts: (state, action) => {
      state.districts = action.payload;
    },
    setStreets: (state, action) => {
      state.streets = action.payload;
    },
    setSettlement: (state, action) => {
      state.settlement = action.payload;
    },
    setStreetSettlement: (state, action) => {
      state.streetSettlement = action.payload;
    },
    setAllSettlements: (state, action) => {
      state.allSettlements = action.payload;
    }
  }
});

// Actions from the slice

export const { 
  setCities,
  setDistricts,
  setStreets,
  setSettlement,
  setStreetSettlement,
  setAllSettlements 
} = addressSlice.actions;

// Selectors

export const addressSelector = (state) => state[SLICE_NAME];
export const districtsSelector = createSelector(addressSelector, address => address.districts)

// Reducers 

export default addressSlice.reducer;

// Async Actions

export function fetchCities () {
  return async dispatch => {
      await request({
        method: HTTPmethods.GET,
        url: `${urls.cities}`
      }).then(({ data }) => dispatch(setCities(data))).catch(() => {});
  }
}

export function fetchSettlement (settlementId, name) {
  return async dispatch => {
    await request({
      method: HTTPmethods.GET,
      url: `${urls.districts}?settlementId=${settlementId}&actual=1&name=${name}`
    }).then(({data}) => dispatch(setSettlement(data))).catch(() => {});
  }
}

export function fetchStreetsSettlement (settlementId, name) {
  return async dispatch => {
    await request({
      method: HTTPmethods.GET,
      url: `${urls.streets}?settlementId=${settlementId}&actual1=&name${name}`
    }).then(({data}) => dispatch(setStreetSettlement(data))).catch(() => {});
  }
}

export function fetchAllSettlements () {
  return async dispatch => {
    await request({
      method: HTTPmethods.GET,
      url: `${urls.settlements}`
    }).then(({data}) => dispatch(setAllSettlements(data))).catch(() => {});
  }
}