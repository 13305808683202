import React from "react";
import { constants } from "./constants";
import GlobalStyle from "./GlobalStyle";
import { useQueryParam } from "./hooks";

const RenderGlobal = () => {
  const isMobile = useQueryParam(constants.isMobile);
  return <GlobalStyle isMobile={isMobile} />;
};

export default RenderGlobal;
