import { createSlice } from "@reduxjs/toolkit";
import { request, HTTPmethods } from "@/utils";
import { urls } from "@/constants";

const SLICE_NAME = "creditRenegotiation";

const initialState = {
    error: null,
};

export const creditRenegotiationSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const {
    setError
} = creditRenegotiationSlice.actions;

export const creditRenegotiationSelector = (state) => state[SLICE_NAME];

export default creditRenegotiationSlice.reducer;

// Async actions
export function fetchEarlyRepaymentAmount(creditRequestId, paymentDate) {
    return async (dispatch) => {
        try {
            const response = await request({
                method: HTTPmethods.GET,
                url: `${urls.creditRenegotiations}/early-repayment-amount`,
                params: { creditRequestId, paymentDate },
            });

            return response.data;
        } catch (error) {
            dispatch(setError(error));
        }
    };
}

export function createEarlyRepayment(creditRequestId, paymentDate) {
    return async (dispatch) => {
        try {
            const response = await request({
                method: HTTPmethods.POST,
                url: `${urls.creditRenegotiations}/early-repayment`,
                params: { creditRequestId, paymentDate },
            });

            return response.data;
        } catch (error) {
            dispatch(setError(error));
        }
    };
}
