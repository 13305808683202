import React, {useEffect} from 'react'
import { Row, Col } from 'react-grid-system'
import { useDispatch, useSelector } from 'react-redux'

import { MoneyIcon } from '@/assets/svg'
import { Calculator, Banner } from '@/components'
import { DefaultPage } from '@/layouts'
import { useQueryParam, useRedirect } from '@/hooks'
import { constants, enums, storageKeys } from '@/constants'
import { setStorage, removeStorage } from '@/utils'
import {
  setCreditTerms,
  cleanup,
  requestCredit,
} from '@/redux/slices/creditRequestSlice'
import { authSelector } from '@/redux/slices/authSlice'

const Home = () => {
  const isMobile = useQueryParam(constants.isMobile)
  const embed = useQueryParam(constants.embed)
  const redirect = useRedirect()
  const dispatch = useDispatch()
  const { sessionId, userProfile } = useSelector(authSelector)

  const handleOnClick = (creditDetails) => {
    dispatch(cleanup())
    dispatch(setCreditTerms(creditDetails))
    setStorage(storageKeys.creditTerms, creditDetails, true)

    if (sessionId) {
      dispatch(requestCredit()).then((res) => {
        if (!res) {
            (embed) ? window.top.location.href = process.env.REACT_APP_BASE : redirect('/home')
        } else if (res?.creditRequestId) {
          (embed) ? window.top.location.href = process.env.REACT_APP_BASE : redirect(`/credit-request/${res.creditRequestId}/${enums.steps[res.currentStep].slug}`)
        } else {
          (embed) ? window.top.location.href = process.env.REACT_APP_BASE : redirect('/credit-request')
        }
      })
    } else {
      (embed) ? window.top.location.href = process.env.REACT_APP_BASE : redirect('/login')
    }
  }

  useEffect(() => {
    if (sessionId && userProfile?.partnerId) {
      removeStorage(storageKeys.creditTerms)
      if (embed) {
        window.top.location.href = process.env.REACT_APP_BASE
      } else {
        if (!userProfile?.phoneVerified) {
          redirect('/confirm-phone')
        } else {
          redirect('/home')
        }
      }
    }
    if (sessionId && !userProfile?.phoneVerified) {
      redirect('/confirm-phone')
    } else {
      redirect('/home')
    }
  }, [])

  return (
    <DefaultPage
      head={{
        icon: MoneyIcon,
        title: 'Избери нов кредит',
      }}
      disableContentGutter
    >
      <Row>
        <Col xs={12} md={5}>
          <Calculator
            onGetCreditClick={(creditDetails) => handleOnClick(creditDetails)}
            hideByInstallment={!!userProfile?.partnerId}
          />
        </Col>
        <Col xs={12} md={7}>
          {!isMobile && <Banner />}
        </Col>
      </Row>
    </DefaultPage>
  )
}

export default Home
