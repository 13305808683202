import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Logo = styled(Link)`
  display: inline-block;

  & svg {
    width: 100%;
    display: block;
  }

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      width: 160px;
      margin: 0 auto;
      display: block;
    }

    @media ${theme.mediaQueries.smOnly} {
      width: 180px;
    }

    @media ${theme.mediaQueries.mdUp} {
      width: 200px;
    }
  `}
`