import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Row, Col } from "react-grid-system";
import { Input } from "@/components/common";
import SelectSettlement from "./SelectSettlement";
import DistrictSelect from "./DistrictSelect";
import StreetSelect from "./StreetSelect";
import MapWraper from "./MapWraper";
import { useTranslation } from "react-i18next";
import { fetchAddressCityById, fetchAddressCityextSmart } from "./../../services/integrationApi";

const ProfileAddressForm = ({
  register,
  control,
  errors,
  formData,
  watch,
  colSize,
  fullWidth,
  address,
  customInputsStyle,
  customReset,
  setValue,
  setHasDivisionUnitsValue,
  setHasStreetValue,
  setHasBuildingValue,
  hasDivisionUnitsValue
}) => {
  const { t } = useTranslation();
  const {
    apartment,    
    floor,
    latitude,
    longitude,
    initalLoad,
  } = formData; 

  const [validationErrors, setValidationErrors] = useState(errors);

  const inputProps = {
    ref: register,
    errors: validationErrors,
    fullWidth,
  };  

  const {
    settlement: {cityDatamapId, hasDivisionUnits, cityName, cityId, zip} = {}, 
    district, 
    street, 
    map, 
    entry,
    streetNo, 
    building
  } = watch();
 
  const [initalLoadLocalState, setInitalLoadLocalState] = useState(initalLoad);

  useEffect(() => {
    if(formData && formData.reload) {
      setValue("settlement", {cityDatamapId: formData.settlement.cityDatamapId, cityId: formData.settlement.cityId, cityName: formData.settlement.cityName});
      setValue("district", {districtDatamapId: formData.district.districtDatamapId, districtName: formData.district.districtName});
      setValue("street", {streetDatamapId: formData.street.streetDatamapId, streetName: formData.street.streetName});
      setValue("building", formData.building);
      setValue("streetNo", formData.streetNo);
      setValue("entry", formData.entry);
      setValue("floor", formData.floor);
      setValue("zip", formData.settlement.zip);
      setValue("map", {latitude: formData.map.latitude, longitude: formData.map.longitude});
      setHasDivisionUnitsValue(formData.district.districtDatamapId ? true : false);
      setInitalLoadLocalState(false);      
    }
  }, [formData])

  const settlementChange = async (value) => { 
    setValidationErrors([]);
    setHasDivisionUnitsValue(value.hasDivisionUnits); 
    setValue("district", undefined);
    setValue("building", undefined);
    setValue("street", undefined);
    setValue("streetNo", undefined);
    setValue("entry", undefined);
    setValue("floor", undefined);
    setValue("apartment", undefined);
    setValue("zip", value.zip);    
  }

  const districtChange = (value) => {  
    setValue("building", undefined);  
    setValue("street", undefined);
    setValue("streetNo", undefined);
    setValue("entry", undefined);
    setValue("floor", undefined);
    setValue("apartment", undefined);
  }

  const onCustomLatLng = (latitude, longitude) => {
    setValue("map", {latitude: latitude, longitude: longitude});
  }

  
  useEffect(() => {
    if(building && building.length){
      setValidationErrors([]);
      setHasBuildingValue(true);
    }else{
      setHasBuildingValue(false);
    }
  }, [building])

  useEffect(() => {
    if(street && street.streetDatamapId > 0){
      setValidationErrors([]);
      setHasStreetValue(true);
    }else{
      setValue("street", undefined);
      setHasStreetValue(false);
    }
  }, [street])

  useEffect(async ()=>{
    
    if(!initalLoadLocalState){
      if(district && district.districtDatamapId && ((street && street.streetDatamapId) || building)){
        //cityId, districtNameId, stretNameId, number, entrance, block
        const cityData = await fetchAddressCityextSmart(cityDatamapId, district.districtDatamapId, street ? street.streetDatamapId : 0, streetNo, entry, building).catch(err => {
          return null;
        });
        if(cityData?.data?.result?.length){
          setValue("zip", cityData.data.result[0].PostCode);
          setValue("map", {latitude: cityData.data.result[0].Y, longitude: cityData.data.result[0].X});
        }else{
          const cityDataOption = await fetchAddressCityById(cityDatamapId).catch(err => {
            return null;
          });
          if(cityDataOption?.data?.result){
            setValue("zip", cityDataOption.data.result.PostCode);
            setValue("map", {latitude: cityDataOption.data.result.Y, longitude: cityDataOption.data.result.X});
          } 
        }
      }else if(cityDatamapId) {
        const cityDataOption = await fetchAddressCityById(cityDatamapId).catch(err => {
          return null;
        });
        if(cityDataOption?.data?.result){
          setValue("zip", cityDataOption.data.result.PostCode);
          setValue("map", {latitude: cityDataOption.data.result.Y, longitude: cityDataOption.data.result.X});
        } 
      }       
    } else {
      setValue("settlement", {cityDatamapId: formData.settlement.cityDatamapId, cityId: formData.settlement.cityId, cityName: formData.settlement.cityName});
      setValue("district", {districtDatamapId: formData.district.districtDatamapId, districtName: formData.district.districtName});
      setValue("street", {streetDatamapId: formData.street.streetDatamapId, streetName: formData.street.streetName});
      setValue("building", formData.building);
      setValue("streetNo", formData.streetNo);
      setValue("entry", formData.entry);
      setValue("floor", formData.floor);
      setValue("zip", formData.settlement.zip);
      setValue("map", {latitude: formData.map.latitude, longitude: formData.map.longitude});
      setHasDivisionUnitsValue(formData.district.districtDatamapId ? true : false);
      setInitalLoadLocalState(false);
    } 
    },[district, street, entry, streetNo, building])

  const onAddressChangeLatLng = (value) => {
    // TODO: ?!?
  }

  useEffect(() =>{
    setValidationErrors(errors);
    if(!initalLoadLocalState && !cityDatamapId){
      setValidationErrors({...errors, settlement: {message: t('validations.enterCity')}});
    }
  },[errors]);


  return (
    <>    
      <Row>        
        <Col>
          <Row>
            <Col sm={colSize}>
              <Controller
                label={"Населено място"}
                as={SelectSettlement}
                name={`settlement`}
                errors={validationErrors} fullWidth={fullWidth}
                control={control}
                onChangeSettlement={settlementChange}
              />
            </Col>
          </Row>
        <Row>
        <Col xs={colSize}>
          <Controller 
            as={DistrictSelect}
            label={"Квартал"}
            name={`district`}
            settlementId={cityDatamapId} 
            isDisabled={!cityDatamapId || !hasDivisionUnitsValue}
            errors={validationErrors} fullWidth={fullWidth} 
            control={control}
            onDistrictChange={districtChange}
            //valueName="selectedValue"
          />
        </Col>
        <Col xs={3}>
          <Input
            {...inputProps}
            name={`building`}
            readOnly={!cityDatamapId || !hasDivisionUnitsValue}
            defaultValue={building}
            label="Блок/Сграда"
            customStyle={customInputsStyle}
          />
        </Col>
        </Row>
        <Row>
        <Col xs={colSize}>
          <Controller 
            as={StreetSelect} 
            label={"Улица/Булевард"}
            name={`street`}
            isDisabled={!cityDatamapId || !hasDivisionUnitsValue}
            settlementId={cityDatamapId} 
            errors={validationErrors} fullWidth={fullWidth} 
            control={control}                       
            // valueName="selectedValue"
          />
        </Col>
        <Col xs={3}>
          <Input
            {...inputProps}
            name={`streetNo`}
            defaultValue={streetNo}
            type="number"
            label="Номер"
            customStyle={customInputsStyle}
            readOnly={!cityDatamapId || !hasDivisionUnitsValue}
          />
        </Col>
        </Row>
        <Row>
        <Col md={colSize}>
          <Input
            {...inputProps}
            name={`entry`}
            defaultValue={entry}
            label="Вход"
            customStyle={customInputsStyle}
            readOnly={!cityDatamapId || !hasDivisionUnitsValue}
          />
        </Col>
        <Col md={3}>
          <Input
            {...inputProps}
            name={`floor`}
            defaultValue={floor}
            type="number"
            label="Етаж"
            customStyle={customInputsStyle}
            readOnly={!cityDatamapId || !hasDivisionUnitsValue}
          />
        </Col>
        </Row>
        <Row>
        <Col xs={colSize}>
          <Input
            {...inputProps}
            name={`apartment`}
            defaultValue={apartment}
            type="number"
            label="Апартамент"
            customStyle={customInputsStyle}
            readOnly={!cityDatamapId || !hasDivisionUnitsValue}
          />
        </Col>
        <Col xs={3}>
          <Input
            {...inputProps}
            name={`zip`}
            defaultValue={zip}
            type="number"
            label="Пощенски код"
            customStyle={customInputsStyle}
            readOnly={true}
          />
        </Col>
        </Row>
      </Col>
      </Row>
      <Row>
        <Col style={{height: "450px"}}>
          <Controller 
            as={MapWraper} 
            name={`map`}
            defaultValue={map}
            control={control}
            onCustomLatLng={onCustomLatLng} 
          />        
        </Col>     
      </Row>
    </>
  );
};

ProfileAddressForm.defaultProps = {
  colSize: 4,
  errors: {},
  formData: {},
  isValid: false,
  fullWidth: true,
  customInputsStyle: "",
};

ProfileAddressForm.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  colSize: PropTypes.number,
  formData: PropTypes.object,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  customReset: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  fullWidth: PropTypes.bool,
  customInputsStyle: PropTypes.string,
  text: PropTypes.string,
  address: PropTypes.object,
  initalLoad: PropTypes.bool,
  setHasDivisionUnitsValue: PropTypes.func,
  setHasStreetValue: PropTypes.func,
  setHasBuildingValue: PropTypes.func,
  hasDivisionUnitsValue: PropTypes.bool
};

export default ProfileAddressForm;