import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 275.836 275.836'
    version='1.1'
    viewBox='0 0 275.836 275.836'
    xmlSpace='preserve'
  >
    <path d='M191.344 20.922l-95.155 95.155a5.99 5.99 0 00-1.565 2.734l-8.167 31.454a6.003 6.003 0 007.315 7.316l31.454-8.168a6.003 6.003 0 002.734-1.565l95.153-95.153.005-.004.004-.005 19.156-19.156a5.998 5.998 0 00.001-8.484L218.994 1.758a5.998 5.998 0 00-8.485 0l-19.155 19.155-.005.004-.005.005zm-70.713 117.286l-19.993 5.192 5.191-19.993 89.762-89.762 14.801 14.802-89.761 89.761zm94.12-123.723l14.801 14.802-10.675 10.675-14.801-14.802 10.675-10.675z'></path>
    <path d='M238.037 65.022a6 6 0 00-6 6v192.813H43.799V34.417h111.063a6 6 0 000-12H37.799a6 6 0 00-6 6v241.419a6 6 0 006 6h200.238a6 6 0 006-6V71.022a6 6 0 00-6-6z'></path>
  </svg>
)
