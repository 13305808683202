import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { enums } from "@/constants";
import { withSteps } from "@/hoc";
import { Status } from "@/components";
import {
    creditRequestSelector,
    fetchVerificationStatus
} from "@/redux/slices/creditRequestSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const VerificationStatusStep = ({ isLoading }) => {
    const dispatch = useDispatch();
    const {
        verificationStatus
    } = useSelector(creditRequestSelector);

    const { requestID } = useParams();

    useEffect(() => {
        dispatch(fetchVerificationStatus(requestID));
    }, []);

    useEffect(() => {
        let getStatus = false;

        if (verificationStatus === enums.verificationStatus.PENDING) {
            getStatus = setInterval(() => {
                dispatch(fetchVerificationStatus(requestID));
            }, 10000);
        }

        return function cleanup() {
            clearInterval(getStatus);
        };
    }, [verificationStatus]);

    return (
        <>
            {verificationStatus === enums.verificationStatus.PENDING && (
                <Status.Verification />
            )}

            {verificationStatus === enums.verificationStatus.APPROVED && (
                <Status.Success />
            )}

            {verificationStatus === enums.verificationStatus.REJECTED && (
                <Status.Rejected />
            )}

            {verificationStatus === enums.verificationStatus.EXPIRED && (
                <Status.Rejected />
            )}
        </>
    );
};

VerificationStatusStep.propTypes = {
    isLoading: PropTypes.bool,
    //verificationStatus: PropTypes.string,
};

export default compose(
    inject(({ store: { isLoading } }) => ({
        isLoading,
    })),
    observer
)(withSteps(VerificationStatusStep));
