import React from "react";
import PropTypes from "prop-types";

import { backEndTranslation } from "@/utils";
import { Box } from "@/components";

import * as S from "./CreditTotal.styles";

const CardTotalComponent = ({
  creditProductName,
  installmentAmount,
  gracePeriodInstallmentAmount,
  numberOfPayments,
  numberOfGracePeriodPayments,
  requestedAmount,
  creditAmount,
  paymentPeriod,
  creditAmountWithAvalCompany,
  installmentWithAvalCompany,
  gracePeriodInstallmentWithAvalCompany,
  interestRate,
}) => (
  <S.CreditTotal>
    <Box>
      <Box.Content>
        <Box.Columns
          style={{ alignItems: "center" }}
          left={<Box.Text background="none">Продукт</Box.Text>}
          right={
            <Box.Text weight={600} background="none">
              {creditProductName}
            </Box.Text>
          }
        />

        <Box.Columns
          style={{ alignItems: "center" }}
          left={<Box.Text>Сума на кредита</Box.Text>}
          right={
            <Box.Text weight={600} size="medium">
              {requestedAmount}{" "}
              <span style={{ textTransform: "lowercase" }}>лв.</span>
            </Box.Text>
          }
        />

        <Box.Columns
          style={{
            alignItems: "center",
            marginBottom: 5,
          }}
          left={<Box.Text>Брой вноски</Box.Text>}
          right={
            <Box.Text weight={600} size="medium">
              {numberOfPayments}
            </Box.Text>
          }
        />

          <Box.Columns
              style={{
                  alignItems: "center",
                  marginBottom: 5,
              }}
              left={<Box.Text>Гратисни периоди</Box.Text>}
              right={
                  <Box.Text weight={600} size="medium">
                      {numberOfGracePeriodPayments ?? "Няма"}
                  </Box.Text>
              }
          />

        <Box.Columns
          style={{ alignItems: "center" }}
          left={<Box.Text>Период на плащане</Box.Text>}
          right={
            <Box.Text weight={600}>
              {backEndTranslation(paymentPeriod)}
            </Box.Text>
          }
        />

        <Box.Columns
          style={{ alignItems: "center" }}
          left={<Box.Text disableMargin>Сума за връщане</Box.Text>}
          right={
            <Box.Text disableMargin weight={600} size="medium">
              {creditAmount}{" "}
              <span style={{ textTransform: "lowercase" }}>лв.</span>
            </Box.Text>
          }
        />

        <Box.Columns
          style={{ alignItems: "center" }}
          left={
            <Box.Text style={{ color: "#a9a9a9" }} size="extraSmall">
              С гарант
            </Box.Text>
          }
          right={
            <Box.Text weight={600} size="small" style={{ color: "#a9a9a9" }}>
              {creditAmountWithAvalCompany}{" "}
              <span style={{ textTransform: "lowercase" }}>лв.</span>
            </Box.Text>
          }
        />

          {numberOfGracePeriodPayments > 0 && (
              <>
                  <Box.Columns
                      style={{
                          alignItems: "center",
                          marginBottom: 5,
                      }}
                      left={<Box.Text disableMargin>Вноска гратисен период</Box.Text>}
                      right={
                          <Box.Text disableMargin weight={600} size="medium">
                              {gracePeriodInstallmentAmount}{" "}
                              <span style={{ textTransform: "lowercase" }}>лв.</span>
                          </Box.Text>
                      }
                  />

                  <Box.Columns
                      style={{ alignItems: "center" }}
                      left={
                          <Box.Text style={{ color: "#a9a9a9" }} size="extraSmall">
                              С гарант
                          </Box.Text>
                      }
                      right={
                          <Box.Text weight={600} size="small" style={{ color: "#a9a9a9" }}>
                              {gracePeriodInstallmentWithAvalCompany}{" "}
                              <span style={{ textTransform: "lowercase" }}>лв.</span>
                          </Box.Text>
                      }
                  />
              </>
          )}

        <Box.Columns
          style={{
            alignItems: "center",
            marginBottom: 5,
          }}
          left={<Box.Text disableMargin>Вноска</Box.Text>}
          right={
            <Box.Text disableMargin weight={600} size="medium">
              {installmentAmount}{" "}
              <span style={{ textTransform: "lowercase" }}>лв.</span>
            </Box.Text>
          }
        />

        <Box.Columns
          style={{ alignItems: "center" }}
          left={
            <Box.Text style={{ color: "#a9a9a9" }} size="extraSmall">
              С гарант
            </Box.Text>
          }
          right={
            <Box.Text weight={600} size="small" style={{ color: "#a9a9a9" }}>
              {installmentWithAvalCompany}{" "}
              <span style={{ textTransform: "lowercase" }}>лв.</span>
            </Box.Text>
          }
        />

        <Box.Columns
          style={{
            alignItems: "center",
            marginBottom: 5,
          }}
          left={
            <Box.Text
              disableMargin
              size="extraSmall"
              style={{ color: "#a9a9a9" }}
            >
              Лихвен процент
            </Box.Text>
          }
          right={
            <Box.Text
              disableMargin
              weight={600}
              size="small"
              style={{ color: "#a9a9a9" }}
            >
              {interestRate * 100}%
            </Box.Text>
          }
        />
      </Box.Content>
    </Box>
  </S.CreditTotal>
);

CardTotalComponent.propTypes = {
  creditProductName: PropTypes.string.isRequired,
  installmentAmount: PropTypes.number.isRequired,
  gracePeriodInstallmentAmount: PropTypes.number.isRequired,
  numberOfPayments: PropTypes.number.isRequired,
  numberOfGracePeriodPayments: PropTypes.number.isRequired,
  requestedAmount: PropTypes.number.isRequired,
  creditAmount: PropTypes.number.isRequired,
  paymentPeriod: PropTypes.string.isRequired,
  creditAmountWithAvalCompany: PropTypes.number.isRequired,
  installmentWithAvalCompany: PropTypes.number.isRequired,
  gracePeriodInstallmentWithAvalCompany: PropTypes.number.isRequired,
  interestRate: PropTypes.number.isRequired,
};

export default CardTotalComponent;
