import { types, flow } from 'mobx-state-tree'
import { request, HTTPmethods, buildQueryParams, roundToDecimal } from '@/utils'

export default types.model('CreditStore', {
  creditProducts: types.maybe(types.array(types.frozen())),
  creditTerms: types.maybe(types.frozen()),
  myCredits: types.maybe(types.frozen()),
  myPendingCredits: types.maybe(types.frozen())
})
  .actions(self => {
    return {
      getCreditProducts: flow(function* getCreditProducts() {
        self.creditProducts = []

        const response = yield request({
          method: HTTPmethods.GET,
          url: '/ml/web/credit-products',
        })

        self.creditProducts = response.data
      }),

      getRepaymentTotalSum: flow(function* getRepaymentTotalSum(creditRequestId) {

        const response = yield request({
          method: HTTPmethods.GET,
          url: `/ml/web/credit-requests/${creditRequestId}/total-repayment-amount`,
          suppressErrors: true
        })
          return response.data
      }),

      getCreditTerms: flow(function* getCreditTerms(queryParamObject, by) {
        self.creditTerms = {}

        const response = yield request({
          method: HTTPmethods.GET,
          url: `/ml/web/credit-terms/${by}${buildQueryParams(queryParamObject)}`,
          suppressErrors: true
        })

        self.creditTerms = response.data
      }),

      getMyCredits: flow(function* getMyCredits() {
        self.myCredits = {}

        const response = yield request({
          method: HTTPmethods.GET,
          url: '/ml/web/credit-requests/active',
        })

        self.myCredits = response.data
      }),

      getMyPendingCredits: flow(function* getMyPendingCredits() {
        self.myPendingCredits = {}

        const response = yield request({
          method: HTTPmethods.GET,
          url: '/ml/web/credit-requests/pending',
        })

        self.myPendingCredits = response.data
      }),
    }
  })
