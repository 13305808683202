import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { Row, Col } from "react-grid-system";
import { formatSubmitData, getStorage } from "@/utils";
import { storageKeys } from "@/constants";
import { Input, Check, Text, Upload, Form } from "@/components/common";
import { FieldGroup, Card, AuthProvider } from "@/components";
import { DefaultPage } from "@/layouts";
import { RegisterValidator } from "@/formValidators";
import { RegisterIcon } from "@/assets/svg";
import { useFocusOnError } from "@/hooks";

import * as S from "./Register.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  setFieldData,
  registerSelector,
} from "@/redux/slices/registerSlice";

const Register = ({ isLoading }) => {
  const { register, handleSubmit, control, errors } = useForm({
    validationSchema: RegisterValidator(),
  });
  const { t } = useTranslation();
  const creditTerms = getStorage(storageKeys.creditTerms, {}, true);
  const { creditProductId } = creditTerms;

  const dispatch = useDispatch();
  const { fields, tmpSMSVerificationCode } = useSelector(registerSelector);

  const [processStarted, setProcessStarted] = useState(false);

  const [fieldStartTimes, setFieldStartTimes] = useState({
    email: 0,
    firstName: 0,
    familyName: 0,
    egn: 0,
    idCardNumber: 0,
    mobilePhone: 0,
    passwordFirst: 0,
    password: 0,
    referral: 0
  });

  const processTimeStart = useRef()

  const handleFocus = (name) => {
    if (!processStarted) {
      setProcessStarted(true);
      processTimeStart.current = new Date().getTime();
    }

    setFieldStartTimes((prevState) => ({
      ...prevState,
      [name]: new Date().getTime(),
    }));
  }

  const handleBlur = (name, event) => {
    const charLength = event?.target.value.length;

    const startTime = fieldStartTimes[name];
    const endTime = new Date().getTime();

    const currentTime = endTime - startTime;
    const fieldTotalTime = fields[name].timeToFillInSecs;

    const newTotalTime = currentTime + fieldTotalTime;
    dispatch(
      setFieldData({
        name,
        timeToFillInSecs: newTotalTime,
        numberOfSymbols: charLength,
      })
    );
  };

  const inputProps = {
    ref: register,
    errors,
  };

  useFocusOnError(errors);

  const onSubmit = (data) => {
    if (data.mobilePhone) {
      data.mobilePhone = "+" + data.mobilePhone;
    }

    const processTimeEnd = new Date().getTime();
    const totalTime = processTimeEnd - processTimeStart.current;

    const formattedData = formatSubmitData(
      data,
      [
        "egn",
        "email",
        "familyName",
        "firstName",
        "idCardNumber",
        "mobilePhone"
      ],
      fields
    );

    dispatch(createUser(formattedData, totalTime));
  };

  return (
    <AuthProvider
      redirect="/confirm-phone"
      historyState={{
        tmpSMSVerificationCode,
        redirectAfterConfirm: creditProductId ? "/credit-request" : "/home",
      }}
    >
      <DefaultPage
        head={{
          title: t("global.register"),
          subTitle: "Попълни данните по-долу",
          icon: RegisterIcon,
        }}
      >
        <Form
          onSubmit={handleSubmit(onSubmit)}
          {...{ isLoading }}
          buttonText={t("buttons.confirmAndContinue")}
        >
          <Row>
            <Col xs={12} sm={6}>
              <Input
                {...inputProps}
                name="email"
                label={t("fields.email")}
                placeholder={t("fields.enterEmail")}
                handleFocus={() => handleFocus("email")}
                handleBlur={(e) => handleBlur("email", e)}
              />
            </Col>

            <Col xs={12} sm={6}>
              <Input
                {...inputProps}
                name="firstName"
                label={t("fields.firstName")}
                placeholder={t("fields.enterFirstName")}
                handleFocus={() => handleFocus("firstName")}
                handleBlur={(e) => handleBlur("firstName", e)}
              />
            </Col>

            <Col xs={12} sm={6}>
              <Input
                {...inputProps}
                name="familyName"
                label={t("fields.lastName")}
                placeholder={t("fields.enterLastName")}
                handleFocus={() => handleFocus("familyName")}
                handleBlur={(e) => handleBlur("familyName", e)}
              />
            </Col>

            <Col xs={12} sm={6}>
              <Input
                {...inputProps}
                name="egn"
                type="number"
                label={t("fields.egn")}
                placeholder={t("fields.enterEgn")}
              />
            </Col>

            <Col xs={12} sm={6}>
              <Input
                {...inputProps}
                name="idCardNumber"
                label={t("fields.idCardNumber")}
                placeholder={t("fields.enterIdCardNumber")}
                handleFocus={() => handleFocus("idCardNumber")}
                handleBlur={(e) => handleBlur("idCardNumber", e)}
              />
            </Col>

            <Col xs={12} sm={6}>
              <Controller
                as={
                  <Input.Phone
                    {...{ errors }}
                    label={t("fields.mobilePhone")}
                    specialLabel=""
                    placeholder={t("fields.enterMobilePhone")}
                    handleFocus={() => handleFocus("mobilePhone")}
                    handleBlur={(e) => handleBlur("mobilePhone", e)}
                  />
                }
                {...{ control }}
                defaultValue=""
                name="mobilePhone"
              />
            </Col>

            <Col xs={12} sm={6}>
              <Input
                {...inputProps}
                name="passwordFirst"
                type="password"
                label={t("fields.password")}
                placeholder={t("fields.enterPassword")}
              />
            </Col>

            <Col xs={12} sm={6}>
              <Input
                {...inputProps}
                name="password"
                type="password"
                label={t("fields.repeatPassword")}
                placeholder={t("fields.enterRepeatPassword")}
              />
            </Col>

            <Col xs={12}>
              <Card headline="Документ за самоличност">
                <S.TextCenter>
                  <Text>
                    Снимай личната си карта или друг валиден документ за
                    самоличност от двете страни и го качи тук.
                  </Text>
                </S.TextCenter>

                <Row>
                  <Col sm={6}>
                    <Upload
                      {...inputProps}
                      name="base64IdCardPictureFront"
                      label={t("fields.enterIdCardFront")}
                    />
                  </Col>
                  <Col sm={6}>
                    <Upload
                      {...inputProps}
                      name="base64IdCardPictureBack"
                      label={t("fields.enterIdCardBack")}
                    />
                  </Col>
                </Row>

                <S.TextCenter>
                  <Text disableMargin>
                    * подържани типове файлове: PDF, PNG, TIFF, JPEG...
                    Максимален размер на файла ...
                  </Text>
                </S.TextCenter>
              </Card>
            </Col>

            <Col sm={6}>
              <FieldGroup>
                <Check {...inputProps} name="marketingConsent">
                  {t("fields.marketingConsent")}
                </Check>
                <Check {...inputProps} name="personalDataProcessingConsent">
                  {t("fields.personalDataProcessingConsent")}
                </Check>
                <Check {...inputProps} name="termsAndConditionsConsent">
                  {`${t("fields.consent")} `}
                  <S.Link
                    href="https://cashbox.bg/%d1%83%d1%81%d0%bb%d0%be%d0%b2%d0%b8%d1%8f-%d0%b7%d0%b0-%d0%bf%d0%be%d0%bb%d0%b7%d0%b2%d0%b0%d0%bd%d0%b5/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("fields.termsAndConditions")}
                  </S.Link>
                </Check>
              </FieldGroup>
            </Col>

            <Col sm={6}>
              <Input
                  {...inputProps}
                  name="referral"
                  label={t("fields.partnerCode")}
                  placeholder={t("fields.enterPartnerCode")}
                  handleFocus={() => handleFocus("referral")}
                  handleBlur={(e) => handleBlur("referral", e)}
              />
            </Col>
          </Row>
        </Form>
      </DefaultPage>
    </AuthProvider>
  );
};

Register.propTypes = {
  isLoading: PropTypes.bool,
};

export default compose(
  inject(({ store: { isLoading } }) => ({
    isLoading,
  })),
  observer
)(Register);
