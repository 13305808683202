import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'react-grid-system'

import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'

import { withSteps } from '@/hoc'
import { AvalsValidator } from '@/formValidators'
import { Form, Check, Input, Headline } from '@/components/common'
import {CompanyInfo, FieldGroup} from '@/components'

import {
  creditRequestSelector,
  fetchAvalsDetails,
  postAvalsDetails,
  goToPreviousStep,
  fetchCreditDataById,
  fetchAddressData,
  fetchPersonalDetails,
  fetchPaymentDetails,
} from '@/redux/slices/creditRequestSlice'
import { useDispatch, useSelector } from 'react-redux'

const AvalsStep = ({ isLoading, buttonText, backText }) => {
  const { t } = useTranslation()

  const { requestID } = useParams()
  const dispatch = useDispatch()
  const {
    paymentDetails,
    avals,
    creditTerms,
    currentAddress,
    personalDetails,
  } = useSelector(creditRequestSelector)
  const { details } = avals

  const avalType = paymentDetails?.avalType
  const isPrivateAvals = avalType === 'PRIVATE_AVALS'
  const isCompanyAvals = avalType === 'AVAL_COMPANY'

  const validations = {
    AVAL_COMPANY: AvalsValidator().companyAval(),
    PRIVATE_AVALS: AvalsValidator().privateAvals(),
  }

  const { register, errors, control, handleSubmit, setValue } = useForm({
    validationSchema: validations[avalType],
  })

  useEffect(() => {
    dispatch(fetchPaymentDetails(requestID, false))

    if (!personalDetails) {
      dispatch(fetchPersonalDetails(requestID, false))
    }

    if (!creditTerms) {
      dispatch(fetchCreditDataById(requestID, false))
    }

    if (!currentAddress) {
      dispatch(fetchAddressData(requestID, false))
    }
  }, [])

  useEffect(() => {
    if (
      paymentDetails &&
      personalDetails &&
      creditTerms &&
      currentAddress?.currentAddress
    ) {
      dispatch(fetchAvalsDetails(requestID))
    }
  }, [paymentDetails, personalDetails, creditTerms, currentAddress])

  useEffect(() => {
    if (isCompanyAvals) {
      setValue('acceptedAvalCompany', details?.acceptedAvalCompany)
    }
  }, [details])

  const guarantorInfo = {
    name: paymentDetails?.guarantorCompanyName,
    number: paymentDetails?.guarantorCompanyNumber,
    address: paymentDetails?.guarantorAddress,
    email: paymentDetails?.guarantorEmail
  }

  return (
    <Form
      onSubmit={handleSubmit((data) => dispatch(postAvalsDetails(requestID, data)))}
      {...{
        isLoading,
        buttonText,
        backText,
      }}
      onBack={() => dispatch(goToPreviousStep())}
    >
      <div>
        {isCompanyAvals && (
          <>
            <div style={{ marginBottom: 30 }}>
              <CompanyInfo
                  name={guarantorInfo.name}
                  number={guarantorInfo.number}
                  address={guarantorInfo.address}
                  email={guarantorInfo.email}
              />
            </div>
            <Check
              ref={register}
              {...{ errors }}
              weight={600}
              name='acceptedAvalCompany'
            >
              Съгласен съм и възлагам <strong>{guarantorInfo.name}</strong> да ми е гарант по договора
              за кредит, за който кандидатствам
            </Check>
            {paymentDetails?.isPartnerRequest && (
              <FieldGroup label="Посочи начин, по който ще се подпише договора:">
                <Check ref={register} type="radio" name="signatureType" checked={true} value="EURO_TRUST" {...{ errors }}>
                  <strong>ЕВРОТРЪСТ</strong>
                </Check>

                <Check
                    ref={register}
                    type="radio"
                    name="signatureType"
                    value="SIGNATURE_PAD"
                    {...{ errors }}
                >
                  <strong>ТАБЛЕТ ЗА ПОДПИС</strong>
                </Check>
              </FieldGroup>
            )}
          </>
        )}

        {isPrivateAvals && (
          <Row>
            {[...Array(2).keys()].map((item, index) => (
              <Col sm={6} key={index}>
                <Headline size='small' color='secondary'>
                  личен поръчител №{index + 1}
                </Headline>
                <Input
                  ref={register}
                  {...{ errors }}
                  name={`privateAvals[${index}]firstName`}
                  label={t('fields.firstName')}
                  placeholder={t('fields.enterFirstName')}
                />

                <Input
                  ref={register}
                  {...{ errors }}
                  name={`privateAvals[${index}]middleName`}
                  label={t('fields.middleName')}
                  placeholder={t('fields.enterMiddleName')}
                />

                <Input
                  ref={register}
                  {...{ errors }}
                  name={`privateAvals[${index}]familyName`}
                  label={t('fields.lastName')}
                  placeholder={t('fields.enterLastName')}
                />

                <Controller
                  as={
                    <Input.Phone
                      {...{ errors }}
                      label={t('fields.mobilePhone')}
                      placeholder={t('fields.enterMobilePhone')}
                    />
                  }
                  {...{ control }}
                  defaultValue=''
                  name={`privateAvals[${index}]phone`}
                />
              </Col>
            ))}

            <Col xs={12}>
              <Form.Separator disableMarginBottom>
                <Check
                  ref={register}
                  {...{ errors }}
                  weight={600}
                  name='informedPrivateAvals'
                >
                  Информирал съм личните си поръчители
                </Check>
              </Form.Separator>
            </Col>
          </Row>
        )}
      </div>
    </Form>
  )
}

AvalsStep.propTypes = {
  creditRequestState: PropTypes.object,
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
  backText: PropTypes.string,
  avalsInitialData: PropTypes.object,
}

export default compose(
  inject(({ store: { creditRequestState, isLoading } }) => ({
    creditRequestState,
    isLoading,
    avalsInitialData: creditRequestState.avalsInitialData,
  })),
  observer
)(withSteps(AvalsStep))
