/* eslint-disable no-constant-condition */
import { createGlobalStyle } from "styled-components";
import resetCSS from "styled-reset";
import { breakpoints, constants, mediaQueries } from "@/constants";
import { setConfiguration } from "react-grid-system";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "leaflet/dist/leaflet.css";

const palette = {
  seaBlue: "#24c1ec",
  normalBlue: "#004b93",
  darkBlue: "#003b73",
  deepBlue: "#0d2745",
  lightBlue: "#e2e8eb",
  white: "#fff",
  pink: "#d80454",
  yellow: "#f7b500",
  silverGray: "#8da4b1",
  gray: "#323232",
};

const themeLight = {
  colors: {
    secondary: `${palette.seaBlue}`,
    primary: `${palette.normalBlue}`,
    dark: `${palette.darkBlue}`,
    light: `${palette.white}`,
    concrete: "#f3f3f3",
    silver: `${palette.silverGray}`,
    danger: `${palette.pink}`,
    border: "#c6d1d7",
    semiLight: "#e2e8eb",
    blueGray: "rgba(141,164,177,0.5)",
    warning: `${palette.yellow}`,
    mainMenuLinks: `${palette.normalBlue}`,
    mainHeadline: `${palette.normalBlue}`,
    mainBackground: `${palette.white}`,
    mainTabBg: `${palette.lightBlue}`,
    mainTabActiveBg: `${palette.white}`,
    mainTabLink: `${palette.normalBlue}`,
    footerBg: `${palette.silverGray}`,
    calculatorBg: `${palette.lightBlue}`,
    calculatorInactiveTabBg: `${palette.normalBlue}`,
    calculatorActiveTabBg: `${palette.lightBlue}`,
    calculatorInactiveTabLink: `${palette.white}`,
    calculatorActiveTabLink: `${palette.normalBlue}`,
    formLabelColor: `${palette.normalBlue}`,
    formRadioDot: `${palette.normalBlue}`,
    formSelectBg: `${palette.white}`,
    formSelectArrow: `${palette.normalBlue}`,
    formSelectOptionColor: `${palette.silverGray}`,
    formSelectBorder: `${palette.silverGray}`,
    formSelectBorderLight: "#c6d1d7",
    rangeSliderDot: `${palette.normalBlue}`,
    rangeSliderTrackInactive: `${palette.seaBlue}`,
    rangeSliderTrackActive: `${palette.normalBlue}`,
    stepActiveBg: `${palette.normalBlue}`,
    stepActiveColor: `${palette.white}`,
    stepInactiveColor: `${palette.normalBlue}`,
    stepBorderActive: `${palette.normalBlue}`,
    stepBorderInactive: `${palette.normalBlue}`,
    uploadBg: `${palette.white}`,
    whiteGray: `${palette.white}`,
  },
  transition: { default: "0.3s ease-in-out" },
  header: {
    height: {
      xs: "60px",
      sm: "120px",
      md: "116px",
      lg: "116px",
      xl: "116px",
    },
  },
  fonts: { default: "'Lato', Arial, sans-serif" },
  borderRadius: "30px",
  mediaQueries,
};

const themeDark = {
  colors: {
    secondary: `${palette.seaBlue}`,
    primary: `${palette.normalBlue}`,
    dark: `${palette.darkBlue}`,
    light: `${palette.white}`,
    concrete: "#f3f3f3",
    silver: `${palette.silverGray}`,
    danger: `${palette.pink}`,
    border: "#c6d1d7",
    semiLight: `${palette.lightBlue}`,
    blueGray: "rgba(141,164,177,0.5)",
    warning: `${palette.yellow}`,
    mainMenuLinks: `${palette.white}`,
    mainHeadline: `${palette.white}`,
    mainBackground: `${palette.deepBlue}`,
    mainTabBg: `${palette.normalBlue}`,
    mainTabActiveBg: `${palette.deepBlue}`,
    mainTabLink: `${palette.white}`,
    footerBg: `${palette.gray}`,
    calculatorBg: `${palette.gray}`,
    calculatorInactiveTabBg: `${palette.normalBlue}`,
    calculatorActiveTabBg: `${palette.gray}`,
    calculatorInactiveTabLink: `${palette.white}`,
    calculatorActiveTabLink: `${palette.white}`,
    formLabelColor: `${palette.white}`,
    formRadioDot: `${palette.seaBlue}`,
    formSelectArrow: `${palette.white}`,
    formSelectOptionColor: `${palette.white}`,
    formSelectBorder: `${palette.silverGray}`,
    formSelectBorderLight: `${palette.seaBlue}`,
    rangeSliderDot: `${palette.seaBlue}`,
    rangeSliderTrackInactive: `${palette.silverGray}`,
    rangeSliderTrackActive: `${palette.seaBlue}`,
    stepActiveBg: `${palette.white}`,
    stepActiveColor: `${palette.normalBlue}`,
    stepInactiveColor: `${palette.silverGray}`,
    stepBorderActive: `${palette.white}`,
    stepBorderInactive: `${palette.silverGray}`,
    uploadBg: "#4d4d4d",
    whiteGray: `${palette.gray}`,
  },
  transition: { default: "0.3s ease-in-out" },
  header: {
    height: {
      xs: "60px",
      sm: "120px",
      md: "116px",
      lg: "116px",
      xl: "116px",
    },
  },
  fonts: { default: "'Lato', Arial, sans-serif" },
  borderRadius: "30px",
  mediaQueries,
};

export const theme = (theme = constants.themeLight) => {
  if (theme === constants.themeLight) {
    return { ...themeLight };
  } else {
    return { ...themeDark };
  }
};

//Set grid custom breakpoints
setConfiguration({
  breakpoints: Object.keys(breakpoints).map((key) => breakpoints[key]),
  containerWidths: ["100%", 994, 1050, 1100],
  gutterWidth: 20,
});

export default createGlobalStyle`
  ${resetCSS}

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }

  body {

    background: ${({ theme }) => theme.colors.mainBackground};
    font-family: ${({ theme }) => theme.fonts.default};

    ${({ theme, isMobile }) => `
      @media ${theme.mediaQueries.xsOnly} {
        padding-top: ${isMobile ? 0 : theme.header.height.xs};
      }

      @media ${theme.mediaQueries.smUp} {
        padding-top: ${isMobile ? 0 : theme.header.height.sm};
      }

      @media ${theme.mediaQueries.mdUp} {
        padding-top: ${isMobile ? 0 : theme.header.height.md};
      }

      @media ${theme.mediaQueries.lgUp} {
        padding-top: ${isMobile ? 0 : theme.header.height.lg};
      }

      @media ${theme.mediaQueries.xlUp} {
        padding-top: ${isMobile ? 0 : theme.header.height.xl};
      }
    `}
  }

  body.mobile{
    padding-top: 0;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }

  strong {
    font-weight: 600;
  }

  .modal-animated-render {
    position: relative;
    z-index: 12;
  }

  .Toastify__toast-container--top-right {
    top: 50px;
    right: 15px;

    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        top: auto;
        bottom: 20px;
        left: 20px;
        width: calc(100% - 40px);
      }
    `}
  }

  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.primary};
  }

  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.danger};
  }

  .Toastify__progress-bar {
    background: ${({ theme }) => theme.colors.secondary};
  }

  .Toastify__toast-body {
    padding: 0 10px;
    font-size: 15px;
  }

  .Toastify__toast {
    border-radius: 6px;
  }

  .Toastify__close-button {
    opacity: 1;
  }
`;
