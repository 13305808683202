import styled, { css } from "styled-components";

export const Logo = styled.img`
  display: block;

  ${({ theme }) => css`
    @media ${theme.mediaQueries.xsOnly} {
      width: 120px;
      margin: 0 auto 20px auto;
    }

    @media ${theme.mediaQueries.smUp} {
      width: 160px;
      margin: 0 auto 30px auto;
    }
  `}
`;

export const Links = styled.ul`
  text-align: center;

  & li {
    display: inline-block;

    & a {
      color: ${({ theme }) => theme.colors.light};
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  ${({ theme }) => css`
    @media ${theme.mediaQueries.xsOnly} {
      & li {
        margin: 0 10px 10px 10px;

        & a {
          font-size: 15px;
        }
      }
    }

    @media ${theme.mediaQueries.smUp} {
      & li {
        margin: 0 25px 10px 25px;

        & a {
          font-size: 16px;
        }
      }
    }
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ theme }) => css`
    @media ${theme.mediaQueries.xsOnly} {
      margin: 40px 0 30px 0;
    }

    @media ${theme.mediaQueries.smUp} {
      margin: 20px 0 40px 0;
    }
  `}
`;
