import React from "react";
import PropTypes from "prop-types";

import * as S from "./Button.styles";

const ButtonComponent = ({
  color,
  size,
  children,
  circled,
  weight,
  fullWidth,
  disable,
  ...resetProps
}) => {
  const onMouseEnter = (event) => {
    const $target = event.target;
    const $animatedElement = $target.querySelector(".bubble");
    const rect = $target.getBoundingClientRect();
    const offset = {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    };
    const relX = event.pageX - offset.left;
    const relY = event.pageY - offset.top;

    if ($animatedElement) {
      $animatedElement.setAttribute(
        "style",
        `top: ${relY}px; left: ${relX}px;`
      );
    }
  };

  return (
    <S.Button
      {...{
        color,
        size,
        circled,
        weight,
        fullWidth,
      }}
      className={disable && "disabled"}
      {...resetProps}
      onMouseEnter={(event) => onMouseEnter(event)}
    >
      <span className="children">{children}</span>
      <span className="bubble"></span>
    </S.Button>
  );
};

ButtonComponent.defaultProps = {
  color: "primary",
  size: "medium",
  circled: false,
  textTransform: "uppercase",
  weight: 400,
  fullWidth: false,
  children: "",
  disable: false,
};

ButtonComponent.propTypes = {
  color: PropTypes.oneOf([
    "light",
    "primary",
    "danger",
    "lightDanger",
    "lightPrimary",
    "transparent",
  ]),
  size: PropTypes.oneOf(["verySmall", "small", "medium", "large"]),
  circled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  textTransform: PropTypes.oneOf([
    "none",
    "uppercase",
    "lowercase",
    "capitalize",
  ]),
  weight: PropTypes.oneOf([300, 400, 600]),
  children: PropTypes.string,
  disable: PropTypes.bool,
};

ButtonComponent.Text = ({ children, color, ...resetProps }) => (
  <S.ButtonText {...{ color }} {...resetProps}>
    {children}
  </S.ButtonText>
);

ButtonComponent.Text.defaultProps = { color: "primary" };

ButtonComponent.Text.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["light", "primary", "danger", "lightDanger"]),
};

export default ButtonComponent;
