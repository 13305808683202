import styled from 'styled-components'

export const Cardbox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  position: relative;

  & > * {
    flex: 1;
    padding: 5px;
  }
`
