import React from "react";
import PropTypes from "prop-types";
import { getYupError } from "@/utils";
import { Text, Alert } from "@/components/common";

import * as S from "./Field.styles";

const FieldComponent = ({
  label,
  color,
  size,
  onLabelClick,
  instructions,
  children,
  icon,
  customStyle,
  ...restProps
}) => (
  <S.Field {...{ size }} {...restProps}>
    <S.Label
      labelSize={size}
      onClick={onLabelClick}
      customStyle={customStyle}
      {...{ color }}
    >
      {label}
    </S.Label>
    <S.ChildrenWrapper>
      {children}
      {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
    </S.ChildrenWrapper>
    {instructions.length > 0 && (
      <S.Information {...{ size }}>
        {instructions.map((item, index) => (
          <Text key={`${item}-${index}`} {...{ color }}>
            {item}
          </Text>
        ))}
      </S.Information>
    )}
    <S.FieldError>
      <Alert
        color="danger"
        text={getYupError(restProps?.errors, restProps?.name)}
      />
    </S.FieldError>
  </S.Field>
);

FieldComponent.defaultProps = {
  label: "",
  color: "formLabelColor",
  size: "small",
  onLabelClick: () => {},
  instructions: [],
  children: <></>,
  icon: <></>,
  customStyle: "",
};

FieldComponent.propTypes = {
  label: PropTypes.string,
  color: PropTypes.oneOf(["light", "primary", "formLabelColor"]),
  size: PropTypes.oneOf(["extraSmall", "small", "large"]),
  onLabelClick: PropTypes.func,
  instructions: PropTypes.array,
  children: PropTypes.any,
  icon: PropTypes.object,
  customStyle: PropTypes.string,
};

export default FieldComponent;
