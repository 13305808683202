import React from "react";
import PropTypes from "prop-types";
import { CrossIcon } from "@/assets/svg";
import { AnimatedRender } from "@/components";
import { Headline } from "@/components/common";

import * as S from "./Modal.styles";

const ModalComponent = ({ isOpen, headline, onClose, children }) => (
  <AnimatedRender className="modal-animated-render" duration={200} in={isOpen}>
    <S.Modal>
      <S.ModalInner>
        <S.Close onClick={onClose}>
          <CrossIcon />
        </S.Close>
        <S.ModalContent>
          {headline && (
            <S.ModalHeadline>
              <Headline size="large">{headline}</Headline>
            </S.ModalHeadline>
          )}
          {children}
        </S.ModalContent>
      </S.ModalInner>
    </S.Modal>
  </AnimatedRender>
);

ModalComponent.defaultProps = {
  headline: "",
  isOpen: false,
  onClose: () => {},
  children: <></>,
};

ModalComponent.propTypes = {
  headline: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
};

export default ModalComponent;
