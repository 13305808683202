import React from 'react'
import PropTypes from 'prop-types'
import { Headline } from '@/components/common'

import * as S from './Card.styles';

const CardComponent = ({ headline, children, background }) => (
  <S.Card>
    {headline && (
      <Headline as='h6' size='small' color='secondary'>{headline}</Headline>
    )}
    <S.CardInner {... { background }}>
      {children}
    </S.CardInner>
  </S.Card>
)

CardComponent.defaultProps = {
  headline: '',
  children: <></>,
  background: 'semiLight',
}

CardComponent.propTypes = {
  headline: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
    PropTypes.node,
  ]),
  background: PropTypes.oneOf(['light', 'semiLight', 'concrete']),
}

export default CardComponent
