import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container } from "react-grid-system";
import { AnimatedRender } from "@/components";
import { MenuIcon, CrossIcon } from "@/assets/svg";

import * as S from "./Nav.styles";

const NavComponent = ({
  items,
  color,
  size,
  weight,
  align,
  onItemClick,
  ...restProps
}) => (
  <S.Nav {...{ align }} {...restProps}>
    {items.map(({ label, ...restProps }, index) => (
      <S.Nav.Item key={`${label}-${index}`} {...{ size }}>
        <S.Nav.Link
          onClick={onItemClick}
          {...{
            color,
            size,
            weight,
          }}
          {...restProps}
        >
          {label}
        </S.Nav.Link>
      </S.Nav.Item>
    ))}
  </S.Nav>
);

NavComponent.defaultProps = {
  size: "small",
  color: "formLabelColor",
  weight: 400,
  align: "left",
  onItemClick: () => {},
};

NavComponent.propTypes = {
  items: PropTypes.array.isRequired,
  size: PropTypes.oneOf(["small", "large"]),
  color: PropTypes.oneOf(["light", "primary", "formLabelColor"]),
  weight: PropTypes.oneOf([400, 500, 600, 700, 800, 900]),
  align: PropTypes.oneOf(["left", "center", "right"]),
  onItemClick: PropTypes.func,
};

NavComponent.Main = ({ items, ...restProps }) => (
  <S.MainNav {...restProps}>
    <Container>
      <S.MainNav.Wrapper>
        {items.map(({ label, ...restProps }, index) => (
          <S.MainNav.Item key={`${label}-${index}`}>
            {restProps.bubble ? (
              <S.MainNav.Bubble>{restProps.bubble}</S.MainNav.Bubble>
            ) : (
              <></>
            )}
            <S.MainNav.Link {...restProps}>{label}</S.MainNav.Link>
          </S.MainNav.Item>
        ))}
      </S.MainNav.Wrapper>
    </Container>
  </S.MainNav>
);

NavComponent.Main.propTypes = { items: PropTypes.array.isRequired };

NavComponent.Toggle = ({ items, openIcon: OpenIcon, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <S.Open onClick={() => setIsOpen(true)}>
        <OpenIcon />
      </S.Open>
      <AnimatedRender in={isOpen}>
        <S.ToggleNav>
          <S.Close onClick={() => setIsOpen(false)}>
            <CrossIcon />
          </S.Close>
          <NavComponent
            color="light"
            {...{ items }}
            onItemClick={() => {
              window.scrollTo(0, 0);
              setIsOpen(false);
            }}
          />
          {children}
        </S.ToggleNav>
      </AnimatedRender>
    </>
  );
};

NavComponent.Toggle.defaultProps = { openIcon: MenuIcon, children: <></> };

NavComponent.Toggle.propTypes = {
  items: PropTypes.array.isRequired,
  openIcon: PropTypes.elementType,
  children: PropTypes.any,
};

export default NavComponent;
