export default (data, formatFields, fieldsData) => {
  const formattedData = {}
  Object.keys(data)
    .map(key => Object.assign(formattedData, {
      [key]: formatFields.includes(key) ? {
        value: data[key],
        numberOfCorrections: fieldsData[key].numberOfCorrections,
        numberOfSymbols: fieldsData[key].numberOfSymbols,
        timeToFillInSecs: fieldsData[key].timeToFillInSecs,
      } : data[key]
    }
    ))
  return formattedData
}