import React from 'react'
import {
  InitialRequest,
  CurrentAddress,
  PersonalDetails,
  Payment,
  Avals,
  RequestStatus,
  Sign,
  VerificationStatus,
  Signed,
} from '@/containers/CreditRequest'
import { WalletIcon } from '@/assets/svg'
import styled from 'styled-components'
import enums from './enums'

const StyledTitle = styled.div`
  position: relative

  & svg {
    left: 0
    top: 0
    display: inline
    fill: ${({ theme }) => theme.colors.secondary}
  }

  & span {
    margin-top: 10px
    margin-left: 35px
  }

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      & svg {
        width: 22px
        height: 22px
      }
    }

    @media ${theme.mediaQueries.smUp} {
      & svg {
        width: 24px
        height: 24px
      }
    }
  `}
`

export const stepsOrder = [
  enums.steps.initialRequestStart.key,
  enums.steps.initialRequest.key,
  enums.steps.currentAddress.key,
  enums.steps.personalDetails.key,
  enums.steps.payment.key,
  enums.steps.avals.key,
  enums.steps.requestStatus.key,
  enums.steps.sign.key,
  enums.steps.verificationStatus.key,
  enums.steps.signed.key,
]

export const stepKeyByServerKey = Object.values(enums.steps).reduce(
  (current, { key, serverKey }) => {
    if (serverKey) {
      current[serverKey] = key
    }
    return current
  },
  {}
)

export const stepSlugByServerKey = Object.values(enums.steps).reduce(
  (current, { slug, serverKey }) => {
    if (serverKey) {
      current[serverKey] = slug
    }
    return current
  },
  {}
)

export default {
  initialRequestStart: {
    title: (
      <StyledTitle>
        <WalletIcon /> <span>Това е твоят кредит</span>
      </StyledTitle>
    ),
    component: InitialRequest,
    slug: enums.steps.initialRequestStart.slug,
    stepWithRequestID: false,
    disableReload: false,
  },

  initialRequest: {
    title: (
      <StyledTitle>
        <WalletIcon /> <span>Това е твоят кредит</span>
      </StyledTitle>
    ),
    component: InitialRequest,
    slug: enums.steps.initialRequest.slug,
    stepWithRequestID: true,
    disableReload: true,
  },

  currentAddress: {
    title: 'Въпросник за кредитна оценка – настоящ адрес',
    component: CurrentAddress,
    slug: enums.steps.currentAddress.slug,
    stepWithRequestID: true,
    disableReload: true,
  },

  personalDetails: {
    title: 'Въпросник за кредитна оценка – информационни данни',
    component: PersonalDetails,
    slug: enums.steps.personalDetails.slug,
    stepWithRequestID: true,
    disableReload: true,
  },

  payment: {
    title: 'Въпросник за кредитна оценка – обезпечение и начин на усвояване',
    component: Payment,
    slug: enums.steps.payment.slug,
    stepWithRequestID: true,
    disableReload: true,
  },

  avals: {
    title: 'Поръчителство (вид и съгласие)',
    component: Avals,
    slug: enums.steps.avals.slug,
    stepWithRequestID: true,
    disableReload: true,
  },

  requestStatus: {
    title: '',
    component: RequestStatus,
    slug: enums.steps.requestStatus.slug,
    stepWithRequestID: true,
    disableReload: false,
  },

  sign: {
    title: 'Подписване на договор',
    component: Sign,
    slug: enums.steps.sign.slug,
    stepWithRequestID: true,
    disableReload: false,
    buttonText: 'Подпиши договора',
  },

  verificationStatus: {
    title: '',
    component: VerificationStatus,
    slug: enums.steps.verificationStatus.slug,
    stepWithRequestID: true,
    disableReload: false,
  },

  signed: {
    component: Signed,
    slug: enums.steps.signed.slug,
    stepWithRequestID: true,
    disableReload: false,
  },
}
