import styled from "styled-components";

export const BoxHeadline = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    display: block;
    width: 36px;
    height: 36px;
    fill: #fff;
    margin-right: 20px;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => `
    @media ${theme.mediaQueries.smDown} {
      justify-content: center;
      margin-top: 15px;

      > * {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    @media ${theme.mediaQueries.mdUp} {
      margin-top: 20px;

      > * {
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  `}
`;

export const Form = styled.form`
  position: relative;
`;