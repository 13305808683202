import styled, { css } from "styled-components";

const Sizes = {
  large: css`
    min-width: 250px;

    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        padding: 10px 12px;
        font-size: 18px;
      }

      @media ${theme.mediaQueries.smUp} {
        padding: 12px 20px;
        font-size: 20px;
      }
    `}
  `,
  medium: css`
    /* min-width: 150px; */

    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        padding: 12px 15px;
        font-size: 15px;
      }

      @media ${theme.mediaQueries.smUp} {
        padding: 10px 18px;
        font-size: 16px;
      }

      @media ${theme.mediaQueries.mdUp} {
        padding: 12px 20px;
        font-size: 17px;
      }
    `}
  `,
  small: css`
    /* min-width: 180px; */

    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        padding: 12px 15px;
        font-size: 14px;
      }

      @media ${theme.mediaQueries.smUp} {
        padding: 12px 20px;
        font-size: 15px;
      }
    `}
  `,

  verySmall: css`
    /* min-width: 50px; */

    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        padding: 10px 12px;
        font-size: 12px;
      }

      @media ${theme.mediaQueries.smUp} {
        padding: 10px 12px;
        font-size: 12px;
      }
    `}
  `,
};

const Colors = {
  light: css`
    border: 2px solid ${({ theme }) => theme.colors.light};
    background: transparent;
  `,
  primary: css`
    border: 0;
    background: ${({ theme }) => theme.colors.primary};
  `,
  danger: css`
    border: 0;
    background: ${({ theme }) => theme.colors.danger};
  `,
  lightDanger: css`
    border: 0;
    background: ${({ theme }) => theme.colors.light};
    color: ${({ theme }) => theme.colors.danger};
  `,
  lightPrimary: css`
    border: 0;
    background: ${({ theme }) => theme.colors.light};
    color: ${({ theme }) => theme.colors.primary};
  `,
  transparent: css`
    border: 0;
    background: transparent;
    color: ${({ theme, background }) => {
      background === "semiLight"
        ? theme.colors.formLabelColor
        : theme.colors.light;
    }};
  `,
};

export const Button = styled.a`
  display: inline-block;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};

  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  overflow: hidden;

  border-radius: ${({ theme }) => theme.borderRadius};

  color: ${({ theme }) => theme.colors.light};
  font-style: normal;
  text-transform: ${({ textTransform }) => textTransform};
  line-height: 1;
  cursor: pointer;
  letter-spacing: 1.2px;
  text-align: center;
  display: inline-block;
  font-weight: ${({ weight }) => weight};
  color: ${({ theme }) => theme.colors.light};
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: color ${({ theme }) => theme.transition.default};
  &::after {
    display: none;
  }

  &:focus,
  &:active {
    outline: none;
  }

  ${({ size }) => Sizes[size]}
  ${({ color }) => Colors[color]}

    .bubble {
    position: absolute;
    width: 0;
    height: 0;
    display: block;
    border-radius: 50%;
    background: ${({ theme, color }) =>
      color === "light" ? theme.colors.light : theme.colors.secondary};
    transition: width ${({ theme }) => theme.transition.default},
      height ${({ theme }) => theme.transition.default};
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .children {
    position: relative;
    z-index: 2;

    svg {
      position: relative;
      height: 1em;
      width: auto;
    }

    > div {
      position: relative;
      z-index: 2;
    }
  }

  &:hover {
    color: ${({ theme, color }) =>
      color === "light" ? theme.colors.primary : theme.colors.light};

    .bubble {
      width: 225%;
      height: 560px;
    }
  }

  &.disabled {
    &:hover {
    }

    opacity: 0.5;
    display: inline-block; /* For IE11/ MS Edge bug */
    pointer-events: none;
  }

  ${({ circled, theme }) =>
    circled &&
    css`
      display: flex;
      min-width: 0;
      padding: 0;
      align-content: center;
      align-items: center;
      justify-content: center;

      border-radius: 100%;

      font-size: 20px;

      @media ${theme.mediaQueries.xsOnly} {
        width: 80px;
        height: 80px;
      }

      @media ${theme.mediaQueries.smUp} {
        width: 90px;
        height: 90px;
      }

      &:hover {
        & .bubble {
          width: 220%;
          height: 220%;
        }
      }
    `}
`;

export const ButtonText = styled.a`
  cursor: pointer;
  padding: 10px 20px;
  color: ${({ theme, color }) => theme.colors[color]};

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      font-size: 15px;
    }

    @media ${theme.mediaQueries.smUp} {
      font-size: 17px;
    }
  `}
`;
