import { createSlice } from "@reduxjs/toolkit";
import { request, HTTPmethods, getStorage } from "@/utils";
import { urls, stepsOrder, storageKeys } from "@/constants";
import { toast } from "react-toastify";
import { stepKeyByServerKey } from "@/constants/steps";
import {analyticsEvents, logAnalyticsEvent} from "@/utils/analytics";

const SLICE_NAME = "creditRequest";
const APPROVAL_STATUS = {
  APPROVED: "APPROVED",
  PENDING: "PENDING",
  COUNTER_OFFERS: "COUNTER_OFFERS",
  BETTER_OFFERS: "BETTER_OFFERS",
  REJECTED: "REJECTED",
  EXPIRED: "EXPIRED",
};
const VERIFICATION_STATUS = {
  APPROVED: "APPROVED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  EXPIRED: "EXPIRED",
};

const initialState = {
  approvalStatus: APPROVAL_STATUS.PENDING,
  verificationStatus: VERIFICATION_STATUS.PENDING,
  avals: {
    codeVerified: false,
    details: null,
    documents: null,
    id: "",
    requestId: "",
    signatureType: "",
    signed: false,
  },
  creditRequestID: "",
  creditTerms: null,
  currentAddress: null,
  currentStep: "",
  hasError: false,
  initialRequest: null,
  isModalOpen: false,
  isPendingRequest: false,
  legal: { documents: null, signatureType: null },
  offers: [],
  paymentDetails: null,
  personalDetails: null,
  selectedOffer: null,
  signed: false,
  activeCredits: []
};

export const creditRequestSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    requestHasError: (state) => {
      state.hasError = true;
    },

    initialRequestSuccess: (state, { payload }) => {
      state.currentStep = "currentAddress";
      state.creditRequestID = payload;
    },

    setCreditRequestID: (state, { payload }) => {
      state.creditRequestID = payload;
    },

    setCurrentStep: (state, { payload }) => {
      state.currentStep = payload;
    },

    setOffers: (state, { payload }) => {
      state.offers = payload;
    },

    setCreditTerms: (state, { payload }) => {
      if (state.creditTerms !== null) {
        state.creditTerms = {
          ...state.creditTerms,
          ...payload,
        };
      } else {
        state.creditTerms = payload;
      }
    },

    setLegal: (state, { payload }) => {
      const { documents, signatureType } = payload;

      state.legal.documents = documents;
      state.legal.signatureType = signatureType;
    },

    setInitialRequest: (state, { payload }) => {
      state.initialRequest = payload;
    },

    setCurrentAddress: (state, { payload }) => {
      state.currentAddress = payload;
    },

    setPersonalDetails: (state, { payload }) => {
      state.personalDetails = payload;
    },

    setPaymentDetails: (state, { payload }) => {
      state.paymentDetails = payload;
    },

    setAvalsDetails: (state, { payload }) => {
      state.avals.details = payload;
    },

    setApprovalStatus: (state, { payload }) => {
      state.approvalStatus = payload;
    },

    setSelectedOffer: (state, { payload }) => {
      state.selectedOffer = payload;
    },

    setVerificationStatus: (state, { payload }) => {
      state.verificationStatus = payload;
    },

    setSigned: (state) => {
      state.signed = true;
    },

    setIsModalOpen: (state, { payload }) => {
      state.isModalOpen = payload;
    },

    setAvals: (state, { payload }) => {
      state.avals.codeVerified = true;
      state.avals.requestId = payload.creditRequestId;
      state.avals.documents = payload.documents;
      state.avals.signatureType = payload.avalSignatureType || "";
    },

    setAvalCodeVerified: (state, { payload }) => {
      state.avals.codeVerified = payload;
    },

    setAvalSigned: (state, { payload }) => {
      state.avals.signed = payload;
    },

    setIsPendingRequest: (state, { payload }) => {
      state.isPendingRequest = payload;
    },

    goToPreviousStep: (state) => {
      const currentIndex = stepsOrder.findIndex(
        (step) => step === state.currentStep
      );
      const prevStep = stepsOrder[currentIndex - 1];

      state.currentStep = prevStep;
    },

    cleanup: (_, { payload }) => {
      if (payload) {
        toast.success(payload);
      }

      return initialState;
    },

    setActiveCredits: (state, { payload }) => {
      state.activeCredits = payload;
    },
  },
});

// - Sync Actions
export const {
  requestHasError,
  initialRequestSuccess,
  setCreditRequestID,
  setCurrentStep,
  setOffers,
  setCreditTerms,
  setLegal,
  setInitialRequest,
  setCurrentAddress,
  setPersonalDetails,
  setPaymentDetails,
  setAvalsDetails,
  setApprovalStatus,
  setVerificationStatus,
  setSelectedOffer,
  setSigned,
  setIsModalOpen,
  setAvals,
  setAvalCodeVerified,
  setAvalSigned,
  setIsPendingRequest,
  goToPreviousStep,
  cleanup,
  setActiveCredits
} = creditRequestSlice.actions;

// - Selectors
export const creditRequestSelector = (state) => state[SLICE_NAME];

// - Reducer
export default creditRequestSlice.reducer;

// - Async Actions
export function postInitialRequest(requestID, data, setStep = true) {
  return async (dispatch) => {
    try {
      const response = await request({
        method: HTTPmethods.POST,
        url: `${urls.creditRequests}/${requestID}`,
        data,
      });

      dispatch(initialRequestSuccess(response.data.id));
      dispatch(setInitialRequest(data));
      if (setStep) {
        dispatch(setCurrentStep(stepsOrder[2]));
      }

      logAnalyticsEvent(analyticsEvents.events.creditRequestInit);
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

export function requestCredit(
  creditDetails = getStorage(storageKeys.creditTerms)
) {
  return async (dispatch) => {
    const response = await request({
      method: HTTPmethods.GET,
      url: `${urls.creditRequests}/status`,
    })
      .then(({ data }) => {
        // For pending requests, were taking only the first in the array
        //
        // A customer can have only one pending credit request,
        // so it should never be an issue to take only the first in an array
        //
        // Later implementations will have "company" entities that can have multiple pending requests
        // this is yet to be implemented even on the BE

        if (data.pending.length > 0) {
          const { step, status, creditRequestId } = data.pending[0];
          const currentStep = stepKeyByServerKey[step];

          if (!status || status === "COLLECTING_DATA") {
            if (creditDetails) {
              dispatch(setCurrentStep(stepsOrder[0]));
              dispatch(setCreditTerms(JSON.parse(creditDetails)));

              return 'new_credit'
            }
          // } else if (status === 'PENDING_TRANSFER' || step === 'STEP7_OFFER_SIGNED') {
          } else {
            dispatch(setIsPendingRequest(true))
            dispatch(setCreditRequestID(creditRequestId));
            dispatch(fetchCreditDataById(creditRequestId));
            dispatch(setCurrentStep(currentStep));

            return {
              creditRequestId,
              currentStep,
            };
          }
        } else {
          return 'new_credit'
        }
      })
      .catch(() => dispatch(requestHasError()));

    return response;
  };
}

export function fetchCreditDataById(creditID) {
  return async (dispatch) => {
    try {
      const { data } = await request({
        method: HTTPmethods.GET,
        url: `${urls.creditRequests}/${creditID}`,
      });

      const {
        creditProductId,
        installmentAmount,
        gracePeriodInstallmentAmount,
        numberOfPayments,
        numberOfGracePeriodPayments,
        requestedAmount,
      } = data;

      dispatch(
        setCreditTerms({
          creditProductId,
          installmentAmount,
          gracePeriodInstallmentAmount,
          numberOfPayments,
          numberOfGracePeriodPayments,
          requestedAmount,
        })
      );
      dispatch(setInitialRequest(data));
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

// Address
export function fetchAddressData(creditID, setStep = true) {
  return async (dispatch) => {
    try {
      const { data } = await request({
        method: HTTPmethods.GET,
        url: `${urls.creditRequests}/${creditID}/step2-current-address`,
        suppressErrors: true,
      });

      dispatch(setCurrentAddress(data));
      if (setStep) {
        dispatch(setCurrentStep(stepsOrder[2]));
      }
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

export function postCurrentAddress(requestID, data, setStep = true) {
  return async (dispatch) => {
    try {
      await request({
        method: HTTPmethods.POST,
        url: `${urls.creditRequests}/${requestID}/step2-current-address`,
        data,
      });

      dispatch(setCurrentAddress(data));
      if (setStep) {
        dispatch(setCurrentStep(stepsOrder[3]));
      }
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

// Personal Details
export function fetchPersonalDetails(requestID, setStep = true) {
  return async (dispatch) => {
    try {
      const { data } = await request({
        method: HTTPmethods.GET,
        url: `${urls.creditRequests}/${requestID}/step3-personal-details`,
      });

      dispatch(setPersonalDetails(data));
      if (setStep) {
        dispatch(setCurrentStep(stepsOrder[3]));
      }
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

export function postPersonalDetails(requestID, data, setStep = true) {
  return async (dispatch) => {
    try {
      await request({
        method: HTTPmethods.POST,
        url: `${urls.creditRequests}/${requestID}/step3-personal-details`,
        data,
        suppressErrors: true,
      });

      dispatch(setPersonalDetails(data));
      if (setStep) {
        dispatch(setCurrentStep(stepsOrder[4]));
      }
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

// Payment
export function fetchPaymentDetails(requestID, setStep = true) {
  return async (dispatch) => {
    try {
      const { data } = await request({
        method: HTTPmethods.GET,
        url: `${urls.creditRequests}/${requestID}/step4-payment`,
        suppressErrors: true,
      });

      dispatch(setPaymentDetails(data));
      if (setStep) {
        dispatch(setCurrentStep(stepsOrder[4]));
      }
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

export function postPaymentDetails(requestID, data, setStep = true) {
  return async (dispatch) => {
    try {
      await request({
        method: HTTPmethods.POST,
        url: `${urls.creditRequests}/${requestID}/step4-payment`,
        data,
        suppressErrors: true,
      });

      dispatch(setPaymentDetails(data));
      if (setStep) {
        dispatch(setCurrentStep(stepsOrder[5]));
      }
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

// Avals
export function fetchAvalsDetails(requestID, setStep = true) {
  return async (dispatch) => {
    try {
      const { data } = await request({
        method: HTTPmethods.GET,
        url: `${urls.creditRequests}/${requestID}/step5-avals`,
        suppressErrors: true,
      });

      dispatch(setAvalsDetails(data));
      if (setStep) {
        dispatch(setCurrentStep(stepsOrder[5]));
      }
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

export function postAvalsDetails(requestID, data, setStep = true) {
  return async (dispatch) => {
    try {
      await request({
        method: HTTPmethods.POST,
        url: `${urls.creditRequests}/${requestID}/step5-avals`,
        data,
        suppressErrors: true,
      });

      if (setStep) {
        dispatch(setCurrentStep(stepsOrder[6]));
      }

      logAnalyticsEvent(analyticsEvents.events.creditRequestSend);
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

// Avals
export function fetchApprovalStatus(requestID) {
  return async (dispatch) => {
    try {
      const { data } = await request({
        method: HTTPmethods.GET,
        url: `${urls.creditRequests}/${requestID}/approval`,
        suppressErrors: true,
      });

      if (data) {
        const { creditOffers = [], status } = data;

        dispatch(setOffers(creditOffers));
        dispatch(setApprovalStatus(status));
      }
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

export function postApproveOffer(requestID, offer, setStep = true) {
  return async (dispatch) => {
    await request({
      method: HTTPmethods.POST,
      url: `${urls.creditRequests}/${requestID}/approve/${offer.id}`,
    })
      .then(({ data }) => {
        dispatch(setSelectedOffer(offer));
        dispatch(
          setLegal({
            documents: data.documents,
            signatureType: data.signatureType,
          })
        );
        if (setStep) {
          dispatch(setCurrentStep(stepsOrder[7]));
        }

        logAnalyticsEvent(analyticsEvents.events.creditRequestAcceptOffer);
      })
      .catch(() => dispatch(requestHasError()));
  };
}

// documents to be signed
export function fetchSignDocuments(requestID) {
  return async (dispatch) => {
    try {
      const { data } = await request({
        method: HTTPmethods.GET,
        url: `${urls.creditRequests}/${requestID}/sign`,
        suppressErrors: true,
      });

      if (data) {
        dispatch(
          setLegal({
            documents: data.documents,
            signatureType: data.signatureType,
          })
        );
      }
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

// Step [7]
export function postSignDocument(requestID) {
  return async (dispatch) => {
    await request({
      method: HTTPmethods.POST,
      url: `${urls.creditRequests}/${requestID}/sign`,
    })
      .then(() => {
        dispatch(setSigned());
        dispatch(setCurrentStep(stepsOrder[8]));
      })
      .catch(() => dispatch(requestHasError()));
  };
}

export function postAvalCode(data) {
  return async (dispatch) => {
    await request({
      method: HTTPmethods.POST,
      url: `${urls.creditRequests}/private-avals`,
      data,
    })
      .then((response) => {
        dispatch(setCreditRequestID(response.creditRequestId));
        dispatch(setAvals(response.data));
      })
      .catch(() => dispatch(requestHasError()));
  };
}

export function postPrivateAval(avalRequestId, data) {
  return async (dispatch) => {
    await request({
      method: HTTPmethods.POST,
      url: `${urls.creditRequests}/private-avals/${avalRequestId}/sign`,
      data,
    })
      .then(() => {
        dispatch(setAvalSigned(true));
        dispatch(setAvalCodeVerified(false));
        dispatch(setCurrentStep(stepsOrder[8]));
      })
      .catch(() => dispatch(requestHasError()));
  };
}

export function fetchVerificationStatus(requestID) {
  return async (dispatch) => {
    try {
      const { data } = await request({
        method: HTTPmethods.GET,
        url: `${urls.creditRequests}/${requestID}/verification`,
        suppressErrors: true,
      });

      if (data) {
        const { status } = data;

        dispatch(setVerificationStatus(status));
      }
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

export function rejectCreditRequest(requestID) {
  return async (dispatch) => {
    try {
      await request({
        method: HTTPmethods.POST,
        url: `${urls.creditRequests}/${requestID}/reject`,
        suppressErrors: true,
      });

      logAnalyticsEvent(analyticsEvents.events.creditRequestRejectOffer);
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}

export function fetchActiveCredits() {
  return async (dispatch) => {
    try {
      const { data } = await request({
        method: HTTPmethods.GET,
        url: `${urls.creditRequests}/active`,
        suppressErrors: true,
      });

      if (data) {
        dispatch(setActiveCredits(data.activeCredits));
      }
    } catch (error) {
      dispatch(requestHasError());
    }
  };
}