import { types, flow } from 'mobx-state-tree'
import { request, HTTPmethods } from '@/utils'

export default types.model('AddressStore', {
  addressesList: types.maybe(types.frozen()),
  coordinates: types.maybe(types.frozen()),
})
  .actions(self => {
    return {
      getAddress: flow(function* getAddress (address) {
        const { city, street } = address || {}

        const encodeAddressString = encodeURI(`${city}, ${street}`)

        const { data } = yield request({
          method: HTTPmethods.GET,
          url: `/integrations/datamap/address_roof/?text_search=${encodeAddressString}`,
        })

        self.addressesList = data?.result
      }),

      getCoordinates: flow(function* getCoordinates (address) {
        const {
          BlockID,
          ComplexID,
          ComplexNameID,
          SettlID,
          StreetID,
          StreetNameID,
        } = address || {}

        const { data } = yield request({
          method: HTTPmethods.GET,
          url: `/integrations/datamap/address_cityext_smart/?cityid=${SettlID}&district_id=${ComplexID}&district_name_id=${ComplexNameID}&street_id=${StreetID ? StreetID : ''}&street_name_id=${StreetNameID ? StreetNameID : ''}&block=${BlockID ? BlockID : ''}&number=&entrance=&include_entrances=false`,
        })

        const results = data?.result

        if(results && results.length) {
          const { X, Y } = results[0]
          self.coordinates = {
            lat: Y,
            lng: X,
          }
        } else {
          self.coordinates = undefined
        }
      }),

      setCoordinates(coordinates) {
        self.coordinates = coordinates
      },
    }
  })
