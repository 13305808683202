import React from "react";
import PropTypes from "prop-types";
import { Button } from "@/components/common";

import * as S from './Box.styles';

const BoxComponent = ({ children }) => <S.Box>{children}</S.Box>;

BoxComponent.Content = ({ children, ...props }) => (
  <S.BoxContent {...props}>{children}</S.BoxContent>
);

BoxComponent.Columns = ({ left, right, ...restProps }) => (
  <S.Columns {...restProps}>
    {(left || right) && (
      <>
        <span>{left}</span>
        <span style={{ textAlign: "right" }}>{right}</span>
      </>
    )}
  </S.Columns>
);

BoxComponent.Sum = ({ items, ...props }) => (
  <S.SumList>
    {items.map(({ label, amount }) => (
      <li key={`${label}-${amount}`}>
        <S.SumText {...props} disableMargin>
          {label}
        </S.SumText>{" "}
        <S.SumText {...props} weight={600} disableMargin>
          {amount}
        </S.SumText>
      </li>
    ))}
  </S.SumList>
);

BoxComponent.Button = ({ children, ...restProps }) => (
  <S.ButtonContainer>
    <Button weight={600} size="large" {...restProps}>
      {children}
    </Button>
  </S.ButtonContainer>
);

BoxComponent.Text = ({ children, ...restProps }) => (
  <S.TextComponent weight={600} textTransform="uppercase" {...restProps}>
    {children}
  </S.TextComponent>
);

BoxComponent.Links = ({ children }) => <S.Links>{children}</S.Links>;

BoxComponent.defaultProps = { children: <></> };
BoxComponent.Content.defaultProps = {
  children: <></>,
  // background: "semiLight",
  background: "",
};

BoxComponent.Columns.defaultProps = {
  left: <></>,
  right: <></>,
};

BoxComponent.Text.defaultProps = { children: "" };

BoxComponent.Sum.defaultProps = { items: [] };

BoxComponent.Links.defaultProps = { children: <></> };

BoxComponent.propTypes = { children: PropTypes.any };

BoxComponent.Button.defaultProps = { children: "" };

BoxComponent.Content.propTypes = {
  children: PropTypes.any,
  background: PropTypes.oneOf(["primary", "danger", "semiLight"]),
};

BoxComponent.Columns.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
};

BoxComponent.Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.elementType,
    PropTypes.node,
  ]),
};

BoxComponent.Sum.propTypes = { items: PropTypes.array };

BoxComponent.Button.propTypes = { children: PropTypes.string };

BoxComponent.Links.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.elementType,
    PropTypes.node,
  ]),
};

export default BoxComponent;
