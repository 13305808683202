import styled, { css } from "styled-components";

const Sizes = {
  mobile: {
    normal: "14px",
    small: "12px",
    extraSmall: "11px",
    medium: "18px",
    large: "24px",
  },
  tablet: {
    normal: "15px",
    small: "14px",
    extraSmall: "12px",
    medium: "22px",
    large: "30px",
  },
  desktop: {
    normal: "15px",
    small: "14px",
    extraSmall: "12px",
    medium: "22px",
    large: "30px",
  },
};

const textStyle = css`
  white-space: pre-line;
  color: ${({ theme, color }) => theme.colors[color]};
  font-weight: ${({ weight }) => weight};
  text-transform: ${({ textTransform }) => textTransform};
  margin-bottom: ${({ disableMargin }) => (disableMargin ? 0 : "20px")};
  line-height: 1.3;

  ${({ theme, size }) => `
    @media ${theme.mediaQueries.xsOnly} {
      font-size: ${Sizes.mobile[size]};
    }

    @media ${theme.mediaQueries.smOnly} {
      font-size: ${Sizes.tablet[size]};
    }

    @media ${theme.mediaQueries.mdUp} {
      font-size: ${Sizes.desktop[size]};
    }
  `}
`;

export const Text = styled.span`
  ${textStyle};
`

export const p = styled.p`
  ${textStyle}
`;

export const span = styled.span`
  ${textStyle}
`;

export const li = styled.li`
  ${textStyle}
`;
