import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 512 512'
    version='1.1'
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <path d='M472 312.642v139c0 11.028-8.972 20-20 20H60c-11.028 0-20-8.972-20-20v-139H0v139c0 33.084 26.916 60 60 60h392c33.084 0 60-26.916 60-60v-139h-40z'></path>
    <path d='M256 0.358L131.716 124.642 160 152.926 236 76.926 236 388.642 276 388.642 276 76.926 352 152.926 380.284 124.642z'></path>
  </svg>
)
