import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import useWindowSize from '@/hooks/useWindowSize'
import { Status } from '@/components'

import '@/assets/css/slickslider.css'
import * as S from './OfferCarousel.styles'

const OfferCarousel = ({
  offers,
  onOfferSelected,
  ...restProps
}) => {
  const windowSize = useWindowSize()

  const [carouselNeeded, setCarouselNeeded] = useState(true)
  const [dotslNeeded, setDotsNeeded] = useState(false)
  const offersReady = offers.length //how much offers exist

  // Maping offers array in list
  const Offerlist = offers.map((offer, index) => {
    let offerProps = {
      ...restProps,
      ...offer,
      onButtonClick: () => {
        onOfferSelected(offer)
      },
    }

    return <Status.Counter {...offerProps} key={`offer-${index}`} />
  })

  // Depends on sreen width and offers count use different layout: slider/inline view
  useEffect(() => {
    if (offersReady > 1) {
      setCarouselNeeded(true)

      if (windowSize.width < 400) {
        setDotsNeeded(true)
      } else {
        setDotsNeeded(false)
      }
    } else {
      setCarouselNeeded(false)
    }
  }, [windowSize, offers])

  return (
    <>
      {carouselNeeded ? (
        <Slider
          dots={dotslNeeded}
          infinite={false}
          centerMode={true}
          focusOnSelect={true}
          speed={500}
          slidesToShow={1}
          arrows={false}
          variableWidth={true}
          centerPadding={0}
        >
          {Offerlist}
        </Slider>
      ) : (
        <S.Cardbox>{Offerlist}</S.Cardbox>
      )}
    </>
  )
}

OfferCarousel.propTypes = {
  offers: PropTypes.array,
  onOfferSelected: PropTypes.func,
}

export default OfferCarousel
