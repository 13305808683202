import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "rc-slider/assets/index.css";

import * as S from './RangeSlider.styles';

const getMarks = (max, min, incrementStep) => {
  const marks = {};
  let lastMark = 0;

  Array(Math.floor((max - min) / incrementStep) + 1)
    .fill(min)
    .map((x, i) => {
      const currentStep = x + i * incrementStep;
      Object.assign(marks, { [currentStep]: `${currentStep}` });
      lastMark = currentStep;
    });
  if (lastMark < max) Object.assign(marks, { [max]: `${max}` });
  return marks;
};

const RangeSliderComponents = ({
  onChange,
  label,
  valueLabel,
  min,
  max,
  defaultValue,
  incrementStep,
}) => {
  const [value, setValue] = useState(defaultValue ? defaultValue : min);

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <S.RangeSliderWrapper>
      <S.RangeSliderHead>
        <S.Label weight={600}>{label}</S.Label>
        <S.Value weight={600}>
          {value} {valueLabel}
        </S.Value>
      </S.RangeSliderHead>

      <S.RangeSlider
        onChange={(currentValue) => {
          onChange(currentValue);
          setValue(currentValue);
        }}
        {...{
          min,
          max,
          step: null,
          included: true,
          defaultValue,
          marks: getMarks(max, min, incrementStep),
          value: value
        }}
      />
    </S.RangeSliderWrapper>
  );
};

RangeSliderComponents.defaultProps = {
  onChange: () => {},
  label: "",
  valueLabel: "",
  defaultValue: 0,
};

RangeSliderComponents.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  valueLabel: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  defaultValue: PropTypes.number,
  incrementStep: PropTypes.number.isRequired,
};

export default RangeSliderComponents;
