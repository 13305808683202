import React from "react";
import PropTypes from "prop-types";

import * as S from './Headline.styles'

const HeadlineComponent = ({
  as,
  color,
  size,
  disableMargin,
  children,
  ...restProps
}) => {
  return (
    <S.Heading
      {...{
        color,
        size,
        disableMargin,
        as,
      }}
      {...restProps}
    >
      {children}
    </S.Heading>
  );
};

HeadlineComponent.defaultProps = {
  as: "h1",
  color: "mainHeadline",
  size: "large",
  disableMargin: false,
  children: "",
};

HeadlineComponent.propTypes = {
  as: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  size: PropTypes.oneOf(["small", "large"]),
  color: PropTypes.oneOf([
    "secondary",
    "primary",
    "dark",
    "light",
    "mainHeadline",
  ]),
  disableMargin: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default HeadlineComponent;
