import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 512 512'
    version='1.1'
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <path d='M418.88 344.043l-54.869-19.093c-3.989-1.408-8.491-.277-11.371 2.859-26.901 29.355-61.227 45.525-96.64 45.525s-69.739-16.171-96.64-45.525a10.715 10.715 0 00-11.371-2.859L93.12 344.043C37.419 363.413 0 416.043 0 475.008v15.659c0 5.888 4.779 10.667 10.667 10.667h490.667c5.888 0 10.667-4.779 10.667-10.667v-15.659c-.001-58.987-37.42-111.616-93.121-130.965zM490.667 480H21.333v-4.992c0-49.899 31.659-94.443 78.784-110.827l48.384-16.832c30.251 30.571 68.181 47.317 107.499 47.317s77.248-16.747 107.499-47.317l48.384 16.832c47.125 16.384 78.784 60.928 78.784 110.827V480z'></path>
    <path d='M383.936 141.44c.043-.917.064-1.856.064-2.773 0-70.592-57.408-128-128-128s-128 57.408-128 128c0 .917.021 1.856.064 2.773-12.715 6.955-21.397 26.389-21.397 50.56 0 29.675 13.099 52.224 30.677 53.291C156.736 309.547 203.435 352 256 352s99.264-42.453 118.656-106.709c17.579-1.067 30.677-23.616 30.677-53.291 0-24.171-8.682-43.605-21.397-50.56zm-11.264 82.091a10.698 10.698 0 00-9.643-.811 10.69 10.69 0 00-6.315 7.296C341.781 290.219 301.312 330.667 256 330.667s-85.781-40.448-100.715-100.651c-.832-3.307-3.499-5.76-6.656-7.061-1.173-.491-2.453-.704-3.755-.704-2.176 0-4.395.619-6.208 1.749C136 224 128 212.651 128 192c0-19.563 7.189-30.763 10.197-31.915.107.021.235.043.32.043 3.136.235 6.357-.811 8.619-3.072 2.261-2.283 3.392-5.461 3.051-8.64l-.299-2.667c-.256-2.347-.555-4.672-.555-7.083C149.333 79.851 197.184 32 256 32s106.667 47.851 106.667 106.667c0 2.411-.299 4.736-.555 7.061l-.299 2.667c-.32 3.2.789 6.379 3.051 8.64 2.283 2.283 5.461 3.349 8.619 3.093.085-.021.213-.021.32-.043C376.811 161.237 384 172.437 384 192c0 20.651-7.829 32-11.328 31.531z'></path>
    <path d='M256 96c-25.131 0-42.667 17.536-42.667 42.667 0 5.888 4.779 10.667 10.667 10.667s10.667-4.779 10.667-10.667c0-19.243 14.912-21.333 21.333-21.333s21.333 2.091 21.333 21.333c0 17.899-3.947 26.069-16.235 33.557-23.723 14.507-27.691 33.685-26.304 42.731.811 5.248 5.291 8.853 10.453 8.853.427 0 .875-.043 1.323-.085 5.696-.661 9.813-5.931 9.344-11.669-.021-.448-.704-11.221 16.32-21.611 18.773-11.477 26.432-26.475 26.432-51.776C298.667 113.536 281.131 96 256 96z'></path>
    <circle cx='245.333' cy='256' r='10.667'></circle>
  </svg>
)
