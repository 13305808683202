import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Input, Text } from "@/components/common";
import { SignalIcon, BatteryIcon, WiFiIcon } from "@/assets/svg";

import * as S from './PhoneCodeInput.styles';

const PhoneCodeInput = forwardRef(({ mobilePhone, ...restProps }, ref) => {
  const date = new Date();

  return (
    <div>
      <S.TextWrapper>
        <Text>Посоченият от теб телефонен номер е:</Text>
      </S.TextWrapper>

      <S.PhoneWrapper>
        <S.LeftSide>
          <span>CELL</span>
          <span>
            {date.getHours()}:{date.getMinutes()}
          </span>
        </S.LeftSide>

        <S.RightSide>
          <SignalIcon />
          <WiFiIcon />
          <BatteryIcon />
        </S.RightSide>

        <S.PhoneNumber>{mobilePhone}</S.PhoneNumber>

        <Input
          type="text"
          placeholder="Въведете кода"
          fullWidth
          color="light"
          label="Потвърди SMS код"
          {...{
            ref,
            ...restProps,
          }}
        />
      </S.PhoneWrapper>
    </div>
  );
});

PhoneCodeInput.defaultProps = { mobilePhone: "" };

PhoneCodeInput.propTypes = { mobilePhone: PropTypes.string };

export default PhoneCodeInput;
