/* eslint-disable no-console */
import { useEffect } from 'react'

const ELEMENT_OFFSET = 250

export default (errors)=>{
  
  
  useEffect(()=>{
    setTimeout(()=>{
      const firstError =  document.querySelector('.Alert')
      if(firstError){
        const errorRect = firstError.getBoundingClientRect()
        window.scroll( 0,window.pageYOffset + errorRect.top - ELEMENT_OFFSET)        
      }   
    },5)
  },[errors])
}