import styled from "styled-components";

export const TabContentWrapper = styled.div`
  ${({ theme }) => `
    @media ${theme.mediaQueries.mdUp} {
      display: flex;
    }

    @media ${theme.mediaQueries.smOnly} {
      border-radius: 8px;
      overflow: hidden;
    }
  `}
`;

export const TabNav = styled.ul`
  position: relative;
  list-style: none;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.calculatorBg};

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      margin-bottom: 10px;
      border-radius: 6px;
      overflow: hidden;
    }

    @media ${theme.mediaQueries.smDown} {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    @media ${theme.mediaQueries.mdUp} {
      border-radius: 8px;
      width: 25%;
      margin: 0 15px 0 0;
    }
  `}
`;

export const Tab = styled.li`
  display: block;
  line-height: 1;
  position: relative;

  ${({ isActive, theme }) => `
    cursor: ${isActive ? "default" : "pointer"};
    background: ${isActive ? theme.colors.primary : "transparent"};
    color: ${isActive ? theme.colors.light : theme.colors.formLabelColor};

    svg {
      fill: ${isActive ? theme.colors.light : theme.colors.formLabelColor};
    }

    @media ${theme.mediaQueries.xsOnly} {
      padding: 10px;
      font-size: 14px;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    @media ${theme.mediaQueries.smOnly} {
      padding: 14px 10px;
      font-size: 16px;

      svg {
        width: 26px;
        height: 26px;
      }
    }

    @media ${theme.mediaQueries.smDown} {
      text-align: center;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        display: block;
        margin: 0 auto 10px auto;
      }
    }

    @media ${theme.mediaQueries.mdUp} {
      width: 100%;
      padding: 15px 20px;
      font-size: 16px;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 15px;
      }
    }
  `}
`;

Tab.Label = styled.span`
  ${({ theme, hasMobileLabel }) => `

    @media ${theme.mediaQueries.mdUp} {
      display: flex;
      align-items: center;
    }

    ${
      hasMobileLabel
        ? `
      @media ${theme.mediaQueries.xsOnly} {
        display: none;
      }
    `
        : ""
    }
  `}
`;

Tab.MobileLabel = styled.span`
  ${({ theme }) => `
    @media ${theme.mediaQueries.smUp} {
      display: none;
    }
  `}
`;

Tab.Alert = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;

  padding: 0.25em 0.5em;
  
  background-color: ${({ theme }) => theme.colors.danger};
  border-radius: 10em;

  color: ${({ theme }) => theme.colors.light};
`;

export const TabContent = styled.div`
  background: ${({ theme }) => theme.colors.calculatorBg};
  padding: 20px;

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      border-radius: 6px;
    }

    @media ${theme.mediaQueries.mdUp} {
      width: 75%;
      border-radius: 8px;
    }
  `}
`;
