import styled, { css } from "styled-components";

export const CheckboxWrapper = styled.div`
  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      margin-bottom: 10px;
      text-align: left;
    }

    @media ${theme.mediaQueries.smUp} {
      margin-bottom: 18px;
    }
  `}
`;

const GeneralStyle = {
  checkbox: css`
    &::before {
      border-radius: 3px;
    }

    svg {
      position: absolute;
      top: 4px;
      left: 4px;
      opacity: 0;
      fill: ${({ theme }) => theme.colors.primary};

      ${({ theme, size }) => `
        @media ${theme.mediaQueries.xsOnly} {
          width: 12px;
          height: 12px;
        }

        @media ${theme.mediaQueries.smUp} {
          width: ${size === "large" ? "16px" : "12px"};
          height: ${size === "large" ? "16px" : "12px"};
        }
      `}
    }
  `,
  radio: css`
    &::before {
      border-radius: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      background: ${({ theme }) => theme.colors.formRadioDot};
      border-radius: 100%;
      transition: opacity 0.3s ease;
      opacity: 0;
      top: ${({ size }) => (size === "large" ? "4px" : "3px")};
      left: ${({ size }) => (size === "large" ? "4px" : "3px")};
    }

    ${({ theme, size }) => `
      @media ${theme.mediaQueries.xsOnly} {
        &::after {
          width: 14px;
          height: 14px;
        }
      }

      @media ${theme.mediaQueries.smUp} {
        &::after {
          width: ${size === "large" ? "16px" : "12px"};
          height: ${size === "large" ? "16px" : "12px"};
        }
      }
    `}
  `,
};

export const Label = styled.label`
  display: inline-block;

  & span {
    display: block;
    position: relative;
    color: ${({ theme }) => theme.colors.formLabelColor};
    font-weight: ${({ weight }) => weight};
    min-height: ${({ size }) => (size === "large" ? "26px" : "20px")};
    padding-top: ${({ size }) => (size === "large" ? "3px" : 0)};
    line-height: 1.3;
    cursor: pointer;
    user-select: none;
    transition: color 0.3s ease;
    padding-left: 32px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid ${({ theme }) => theme.colors.border};
      background: ${({ theme }) => theme.colors.light};
      transition: background 0.3s ease;
    }

    ${({ theme, size }) => `
      @media ${theme.mediaQueries.xsOnly} {
        font-size: 14px;
        padding-left: 28px;

        &::before {
          width: 18px;
          height: 18px;
        }
      }

      @media ${theme.mediaQueries.smUp} {
        font-size: ${size === "large" ? "15px" : "14px"};
        padding-left: ${size === "large" ? "32px" : "26px"};

        &::before {
          width: ${size === "large" ? "22px" : "16px"};
          height: ${size === "large" ? "22px" : "16px"};
        }
      }
    `}

    ${({ type }) => GeneralStyle[type]}
  }

  & input {
    display: none;

    &:checked ~ span {
      & svg,
      &::after {
        opacity: 1;
      }
    }
  }
`;
