import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#D80454">
        <g>
          <path
            d="M20.196.356C9.396.356.64 9.11.64 19.91c0 10.8 8.755 19.556 19.556 19.556 10.8 0 19.555-8.756 19.555-19.556S30.996.356 20.196.356zm0 36.444c-9.328 0-16.89-7.561-16.89-16.889 0-9.327 7.562-16.889 16.89-16.889 9.327 0 16.888 7.562 16.888 16.89 0 9.327-7.561 16.888-16.888 16.888z"
            transform="translate(-47 -341) translate(47 341)"
          />
          <path
            d="M29.084 19.022h-8V9.244c0-.736-.597-1.333-1.333-1.333s-1.333.597-1.333 1.333V21.69h10.666c.737 0 1.334-.597 1.334-1.333 0-.737-.597-1.334-1.334-1.334z"
            transform="translate(-47 -341) translate(47 341)"
          />
        </g>
      </g>
    </g>
  </svg>
);
