import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { getYupError } from "@/utils";
import { TickIcon } from "@/assets/svg";
import { Alert } from "@/components/common";

import * as S from "./Check.styles";

const CheckboxComponent = forwardRef(
  ({ children, type, weight, size, ...restProps }, ref) => (
    <S.CheckboxWrapper {...{ size }}>
      <S.Label
        {...{
          type,
          weight,
          size,
        }}
      >
        <input
          {...{
            ref,
            type,
          }}
          {...restProps}
        />
        <span>
          {type === "checkbox" && <TickIcon />}
          {children}
        </span>
        <Alert
          color="danger"
          text={getYupError(restProps?.errors, restProps?.name)}
        />
      </S.Label>
    </S.CheckboxWrapper>
  )
);

CheckboxComponent.defaultProps = {
  children: <></>,
  type: "checkbox",
  weight: 400,
  size: "normal",
};

CheckboxComponent.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(["checkbox", "radio"]),
  weight: PropTypes.oneOf([400, 600]),
  size: PropTypes.oneOf(["small", "normal"]),
};

export default CheckboxComponent;
