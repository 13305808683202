import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ProfileValidator } from "@/formValidators";
import { useForm } from "react-hook-form";
import { FieldGroup } from "@/components";
import { Input, Check, Form } from "@/components/common";
import { useFocusOnError } from "@/hooks";

const ChangeEmail = ({ profileState, formData, isLoading }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    validationSchema: ProfileValidator.generalInfo(),
  });
  const { email, marketingConsent } = formData;
  const { getProfile, profile } = profileState;
  const inputProps = {
    ref: register,
    errors,
  };

  useFocusOnError(errors);

  const onSubmit = (data) => {
    profileState.updateProfile(data).then(getProfile);
  };

  return (
    <Form
      {...{
        isLoading,
        loaderFixedCircle: false,
        loaderSize: "small",
        loaderBackground: "concrete",
      }}
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      buttonText="Запази"
    >
      <Input
        {...inputProps}
        label={t("fields.email")}
        defaultValue={email}
        name="email"
        customStyle="gray"
      />

      <FieldGroup>
        <Check
          {...inputProps}
          defaultChecked={marketingConsent}
          name="marketingConsent"
        >
          Съгласявам се да бъда информиран/а за промоции
        </Check>
      </FieldGroup>
    </Form>
  );
};

ChangeEmail.propTypes = {
  profileState: PropTypes.object,
  formData: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default compose(
  inject(({ store: { profileState, isLoading } }) => ({
    profileState,
    isLoading,
  })),
  observer
)(ChangeEmail);
