import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Row, Col } from "react-grid-system";
import moment from "moment";

import { MoneyIcon } from "@/assets/svg";
import { DefaultPage } from "@/layouts";
import { Message } from "@/components";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessages,
  messagesSelector,
  setIsRead,
} from "@/redux/slices/messagesSlice";

const Messages = () => {
  const dispatch = useDispatch();
  const { currentPage, messages, totalItems, pageSize, totalPages } = useSelector(
    messagesSelector
  );

  useEffect(() => {
    dispatch(getMessages(pageSize, currentPage));
  }, []);

  const hasMoreMessages = messages.length !== totalItems && currentPage !== totalPages;

  const fetchMoreData = () => dispatch(getMessages(pageSize, currentPage + 1));

  const handleOnRead = (msgId) => dispatch(setIsRead(msgId));

  return (
    <DefaultPage
      head={{
        icon: MoneyIcon,
        title: "Messages",
        fullWidth: true,
      }}
    >
      <Row>
        <Col>
          {messages.length > 0 && (
            <InfiniteScroll
              pageStart={currentPage}
              loadMore={fetchMoreData}
              hasMore={hasMoreMessages}
              loader={<h4 key={0}>Loading...</h4>}
            >
              {messages.map((msg) => {
                const date = moment(msg.createdAt).format("DD.MM.YYYY");

                return (
                  <Message
                    key={msg.id}
                    title={msg.title}
                    isRead={!!msg.seenAt}
                    createdAt={date}
                    messageId={msg.id}
                    type={msg.type}
                    handleOnRead={() => handleOnRead(msg.id)}
                  >
                    {msg.message}
                  </Message>
                );
              })}
            </InfiniteScroll>
          )}
        </Col>
      </Row>
    </DefaultPage>
  );
};


export default Messages
