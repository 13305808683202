import styled from "styled-components";

export const AdditionalOffers = styled.div`
  margin-top: 60px;

  position: relative;

  text-align: center;
`;

export const Item = styled.div`
  padding-top: 30px;

  position: relative;

  border-radius: 20px;
  border: 3px solid ${({ theme }) => theme.colors.primary};
`;

export const ButtonWrapper = styled.div`
  transform: translateY(50%);
`;

export const Icon = styled.div`
  svg {
    width: 80px;
    height: 80px;

    fill: ${({ theme }) => theme.colors.secondary};
  }
`;
