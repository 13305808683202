import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import {Button, Form} from '@/components/common'
import { PhoneCodeInput } from '@/components'
import { DefaultPage } from '@/layouts'
import { ConfirmPhoneValidator } from '@/formValidators'
import { RegisterIcon } from '@/assets/svg'
import { useFocusOnError, useRedirect } from '@/hooks'
import {setIsModalOpen} from "@/redux/slices/creditRequestSlice";

const ConfirmPhone = ({
  authenticationState,
  profileState,
  isLoading,
  location,
}) => {
  const { t } = useTranslation()
  //let history = useHistory()
  const { register, handleSubmit, errors } = useForm({ validationSchema: ConfirmPhoneValidator() })
  const sessionMobilePhone = authenticationState.session?.userProfile?.mobilePhone
  const stateMobilePhone = location?.state?.mobilePhone
  const mobilePhone = stateMobilePhone ? stateMobilePhone : sessionMobilePhone
  const redirect = useRedirect()

  useFocusOnError(errors)

  useEffect(() => {
    authenticationState.getSession()
  }, [])

  useEffect(() => {
    if(!isLoading) {
      if(profileState.isPhoneVerified) {
        redirect(location?.state?.redirectAfterConfirm)
      }
    }
  }, [isLoading])

  return (
    <DefaultPage head={{
      title: t('global.profile'),
      subTitle: 'Потвърждаване на телефонния номер',
      icon: RegisterIcon
    }}>
      <Form
        {... { isLoading }}
        onSubmit={handleSubmit(data => profileState.validatePhone(data))}
        buttonText='Потвърди и продължи'
        hasBackground
      >
        <PhoneCodeInput
          {... {
            mobilePhone,
            ref: register,
            errors
          }}
          name='code'
        />
        <Button.Text onClick={() => {profileState.resendPhoneValidation()}}>
          Изпрати нов код
        </Button.Text>
      </Form>
    </DefaultPage>
  )
}

ConfirmPhone.propTypes = {
  authenticationState: PropTypes.object,
  profileState: PropTypes.object,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
}

export default compose(
  inject(({ store: { authenticationState, profileState, isLoading } }) => ({
    authenticationState,
    profileState,
    isLoading
  })),
  observer
)(withRouter(ConfirmPhone))
