import React from 'react'
import { Modal } from '@/components'
import { Button } from '@/components/common'
import { useRedirect } from '@/hooks'
import { useDispatch, useSelector } from 'react-redux'
import {
  creditRequestSelector,
  rejectCreditRequest,
  setIsModalOpen,
  cleanup,
} from '@/redux/slices/creditRequestSlice'

const CreditRequestModal = () => {
  const redirect = useRedirect()
  const dispatch = useDispatch()

  const { isModalOpen, creditRequestID } = useSelector(creditRequestSelector)

  return (
    <Modal
      isOpen={isModalOpen}
      headline='Наистина ли искате да се откажете от кредита?'
      onClose={() => dispatch(setIsModalOpen(false))}
    >
      <Button
        color='danger'
        onClick={() => {
          dispatch(rejectCreditRequest(creditRequestID))
          dispatch(cleanup('Успешно се отказахте от кредита'))
          dispatch(setIsModalOpen(false))
          redirect('/home')
        }}
      >
        Отказвам се от кредита
      </Button>
    </Modal>
  )
}

export default CreditRequestModal