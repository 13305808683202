import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Form } from "@/components/common";
import { ProfileValidator } from "@/formValidators";
import { useTranslation } from "react-i18next";
import { ProfileAddressFields } from "@/components";
import { useFocusOnError } from "@/hooks";
import { saveUserAddress } from "./../../services/integrationApi";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

const ChangeUserAddress = ({
  profileState,
  referenceDataState,
  formData,
  isLoading,
}) => {
  const [hasDivisionUnitsValue, setHasDivisionUnitsValue] = useState(false);
  const [hasStreetValue, setHasStreetValue] = useState(false);
  const [hasBuildingValue, setHasBuildingValue] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,    
    errors,
    getValues,
    setValue,
    watch,
    reset
  } = useForm({
      resolver: yupResolver(ProfileValidator.address(hasDivisionUnitsValue, hasStreetValue, hasBuildingValue)), 
  });

  useFocusOnError(errors);

  const formDataLocal = {
    settlement: {
      cityDatamapId: formData.cityDatamapId,
      cityId: formData.cityId,
      cityName: formData.cityName,
      zip: formData.zip, 
      hasDivisionUnits: false
    } 
    , district: {
      districtDatamapId: formData.districtDatamapId,
      districtName: formData.districtName
    }
    , street: {
      streetDatamapId: formData.streetDatamapId,
      streetName: formData.streetName
    },
    map: {
      latitude: formData.latitude,
      longitude: formData.longitude
    },
    building: formData.building,
    streetNo: formData.streetNo,
    entry: formData.entry,  
    apartment: formData.apartment,
    floor: formData.floor,  
    initalLoad: true
  }
  

  const customReset = async (type, zip, cityDatamapId) => {
    //  
  }

  const onSubmit = (data) => {
    const formatFields = [];
    const formattedData = {};
    Object.keys(data).map((key) =>
      Object.assign(formattedData, {
        [key]: formatFields.includes(key) ? data[key].value : data[key],
      })
    );

    const postData = {
      currentAddress: {
        apartment: formattedData.apartment,
        building: formattedData.building,
        cityDatamapId: formattedData.settlement.cityDatamapId,
        cityId: formattedData.settlement.cityId,
        cityName: formattedData.settlement.cityName,
        datamapMatchCode: formattedData.datamapMatchCode,
        datamapPrecisionMethod: formattedData.datamapPrecisionMethod,
        datamapResponse: formattedData.datamapResponse,
        districtDatamapId: formattedData.district ? formattedData.district.districtDatamapId : 0,
        districtName: formattedData.district ? formattedData.district.districtName : null,
        entry: formattedData.entry,
        floor: formattedData.floor,
        latitude: formattedData.map ?  formattedData.map.latitude : formData.latitude,
        longitude: formattedData.map ?  formattedData.map.longitude : formData.longitude,
        streetDatamapId: formattedData.street ? formattedData.street.streetDatamapId : 0,
        streetName: formattedData.street ? formattedData.street.streetName : null,
        streetNo: formattedData.streetNo,
        zip: formattedData.zip
      },
      currentAddressMatchesIdCardAddress: true,
      currentAddressOwnership: "OWN"
    }

    saveUserAddress(postData);
    toast.success(t('messages.successAccountUpdated-address'))
  };  

  return (
    <Form
      {...{
        isLoading,
        loaderFixedCircle: false,
        loaderSize: "small",
        loaderBackground: "concrete",
      }}
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      buttonText="Актуализирай"
    >
     {formDataLocal ? <ProfileAddressFields
        {...{
          register,
          errors,
          control,
          formData: formDataLocal,
          getValues,
          setValue,
          watch,
          reset,
          customReset,
          setHasDivisionUnitsValue,
          setHasStreetValue,
          setHasBuildingValue,
          hasDivisionUnitsValue
        }}
        colSize={6}
        customInputsStyle="gray"
      /> : null }
    </Form>
  )
};

ChangeUserAddress.defaultProps = {
  formData: {
    settlement: {
      cityDatamapId: null,
      cityId: null,
      cityName: null,
      zip: null, 
      hasDivisionUnits: true
    } 
    , district: {
      districtDatamapId: null,
      districtName: null
    }
    , street: {
      streetDatamapId: null,
      streetName: null
    },
    map: {
      latitude: null,
      longitude: null
    },
    building: null,
    streetNo: null,
    entry: null, 
    apartment: null,
    floor: null,    
    initalLoad: true
  },
};

ChangeUserAddress.propTypes = {
  profileState: PropTypes.object,
  referenceDataState: PropTypes.object,
  formData: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default ChangeUserAddress;