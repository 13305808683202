import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { StepsMarker } from "@/components";
import { steps } from "@/constants";
import { DefaultPage } from "@/layouts";
import { RegisterIcon } from "@/assets/svg";
import { useRedirect } from "@/hooks";
import { useSelector } from "react-redux";
import { creditRequestSelector } from "@/redux/slices/creditRequestSlice";

function withSteps(WrappedComponent) {
  const Step = ({ activeStep, ...restProps }) => {
    const redirect = useRedirect();

    const { currentStep, creditRequestID } = useSelector(
      creditRequestSelector
    );
    const { slug, stepWithRequestID, buttonText, backText, title } =
      steps[currentStep] || {};

    useEffect(() => {
      if (!currentStep) return;

      if (creditRequestID) {
        redirect(
          `/credit-request/${stepWithRequestID ? `${creditRequestID}` : ""}/${
            slug ?? ""
          }`
        );
      } else {
        redirect("/credit-request");
      }
    }, [currentStep, creditRequestID]);

    return (
      <DefaultPage
        head={{
          icon: RegisterIcon,
          title: "Кандидатстване",
          subTitle: title || "",
          rightContent: (
            <StepsMarker
              activeStep={activeStep}
              totalSteps={Object.keys(steps).length}
            />
          ),
        }}
      >
        <WrappedComponent
          {...restProps}
          buttonText={buttonText ? buttonText : "Потвърди и продължи"}
          backText={backText ? backText : "Назад"}
          {...{ currentStep }}
        />
      </DefaultPage>
    );
  };

  Step.defaultProps = {
    activeStep: "",
  };

  Step.propTypes = {
    activeStep: PropTypes.any,
  };

  return Step;
}

export default withSteps;
