import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#00B4E1">
        <path
          d="M82.145 580.854c-3.78-3.779-8.807-5.854-14.147-5.854s-10.369 2.075-14.148 5.854c-7.306 7.313-7.812 18.996-1.274 26.892-.928 1.914-2.016 3.188-3.265 3.796-.692.337-1.071 1.07-.953 1.83s.7 1.35 1.46 1.468c.455.076.944.11 1.45.11 2.464 0 5.054-.853 7.197-2.362 2.91 1.577 6.192 2.412 9.524 2.412 5.34 0 10.368-2.084 14.148-5.863 3.78-3.779 5.863-8.797 5.863-14.145 0-5.332-2.075-10.359-5.855-14.138zm-14.147-3.83c4.8 0 9.313 1.865 12.713 5.264 3.4 3.4 5.264 7.912 5.264 12.712s-1.864 9.313-5.264 12.712c-3.4 3.4-7.913 5.264-12.713 5.264-3.206 0-6.361-.852-9.103-2.472-.16-.093-.338-.143-.515-.143-.278 0-.557.118-.75.329-1.865 1.417-4.177 2.226-6.362 2.226-.1 0-.202 0-.303-.008 1.484-.987 2.708-2.606 3.729-4.935.16-.362.092-.784-.17-1.08-6.284-7.093-5.955-17.907.76-24.622 3.4-3.382 7.913-5.247 12.714-5.247zm7.164 12.124l-8.613 8.625-3.477-3.476c-.62-.619-1.62-.619-2.24 0-.618.619-.618 1.62 0 2.239l4.597 4.582c.303.303.711.46 1.12.46.408 0 .803-.157 1.12-.46l9.732-9.731c.62-.619.62-1.62 0-2.239-.619-.619-1.62-.619-2.239 0z"
          transform="translate(-48 -575)"
        />
      </g>
    </g>
  </svg>
);
