import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";

import { Text } from "@/components/common";

import * as S from "./Documents.styles";

const DocumentsComponent = ({ authenticationState, isLoading, items }) => {
  const { getSession, session } = authenticationState;

  useEffect(() => {
    getSession();
  }, []);

  return (
    <S.Items {...{ isLoading }}>
      {items.map(({ name, id }, index) => {
        const link = `${process.env.REACT_APP_API}/ml/web/documents/${id}?sessionId=${session?.sessionId}`;

        return (
          <S.Item key={`${name}-${index}`}>
            <Text>
              <a href={link} rel="noopener noreferrer" target="_blank" download>
                виж. <strong>{name}</strong> (pdf)
              </a>
            </Text>
          </S.Item>
        );
      })}
    </S.Items>
  );
};

DocumentsComponent.propTypes = {
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  authenticationState: PropTypes.object,
};

export default compose(
  inject(({ store: { authenticationState } }) => ({ authenticationState })),
  observer
)(DocumentsComponent);
