import styled from "styled-components";
import Switch from "react-switch";

export const AppSwitch = styled(Switch).attrs(({ theme, uncheckedIcon, checkedIcon }) => ({
  onColor: theme.colors.mainBackground,
  uncheckedIcon,
  checkedIcon
}))`
  order: 3;

  .svg-inline--fa {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(50%);
  }
`;
