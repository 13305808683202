import styled, { css } from 'styled-components'

const Sizes = {
  large: css`
    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        width: 220px;
        height: 220px;
      }

      @media ${theme.mediaQueries.smUp} {
        width: 280px;
        height: 280px;
      }
    `}
  `,
  medium: css`
    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        width: 180px;
        height: 180px;
      }

      @media ${theme.mediaQueries.smUp} {
        width: 240px;
        height: 240px;
      }
    `}
  `,
  small: css`
    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        width: 100px;
        height: 100px;
      }

      @media ${theme.mediaQueries.smUp} {
        width: 160px;
        height: 160px;
      }
    `}
  `,
}

export const FormLoadingOverlay = styled.div`
  position: ${({ position }) => position};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  opacity: 0.7;
  z-index: 4;

  svg {
    position: ${({ fixedCircle, position }) =>
    fixedCircle ? 'fixed' : position};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    animation: rotate 1s linear infinite;
    transform-origin: center center;

    ${({ size }) => Sizes[size]}
  }

  svg .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite,
      strokeColorLoader 4s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes strokeColorLoader {
    100%,
    0% {
      stroke: ${({ theme }) => theme.colors.primary};
    }
    40% {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
    66% {
      stroke: ${({ theme }) => theme.colors.primary};
    }
    80%,
    90% {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
  }
`