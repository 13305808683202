// Google Firebase
import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent, setUserId } from "firebase/analytics"

// Analytics constants
import { events } from "../constants"

// nanoid unique id generator
import { nanoid } from "@reduxjs/toolkit";

// Firebase Config
const firebaseConfig = {
    apiKey: "AIzaSyA9I1fxcCH4BLQaObBSIXWiz27LyOFWl8U",
    authDomain: "cashbox-bg.firebaseapp.com",
    projectId: "cashbox-bg",
    storageBucket: "cashbox-bg.appspot.com",
    messagingSenderId: "250779914802",
    appId: "1:250779914802:web:4bf55302db76cd06b7222b",
    measurementId: "G-CMFTGS05PC"
}

let userId = null
const analyticsId = nanoid()

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

// FingerprintJS
let fp = null

export const getFingerprint = () => fp
export const setFingerprint = (fingerprint) => fp = fingerprint

export const getAnalyticsUserId = () => userId
export const setAnalyticsUserId = (uid) => {
    userId = uid
    setUserId(analytics, userId)
}

export const analyticsEvents = events

export const logAnalyticsEvent = (eventName, eventParams = {}) => {
    const params = {
        sid: analyticsId,
        did: fp.visitorId,
        uid: userId,
        ...eventParams
    }
    logEvent(analytics, eventName, params)
}

export const logAnalyticsScreenEvent = (screenId, actionId, eventParams = {}) => {
    const params = {
        sid: '',
        did: fp.visitorId,
        uid: '',
        screen_id: screenId,
        ...eventParams
    }
    const eventName = actionId ? screenId + '_' + actionId : screenId
    logEvent(analytics, eventName, params)
}

