export const setStorage = (key, value, stringify = false) => localStorage.setItem(key, stringify ? JSON.stringify(value) : value)

export const getStorage = (key, defaultValue = undefined, isJson) => {
  const item = localStorage.getItem(key)

  if(isJson) {
    return item ? JSON.parse(item) : defaultValue
  } else {
    return item || defaultValue
  }
}

export const removeStorage = (key) => localStorage.removeItem(key)