import React from "react";

export default () => (
  <svg
    width="444"
    height="444"
    viewBox="0 0 444 444"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M221.863 300.028L29.1294 107.294C22.4654 100.631 11.6614 100.631 4.99735 107.294C-1.66465 113.959 -1.66465 124.762 4.99735 131.426L209.797 336.226C216.461 342.888 227.265 342.888 233.929 336.226L438.729 131.426C445.277 124.646 445.089 113.842 438.309 107.294C431.695 100.907 421.21 100.907 414.597 107.294L221.863 300.028Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="443.52" height="443.52" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
