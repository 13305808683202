import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'

import { SpeechBubble, Time, Warning } from "@/assets/svg";
import * as S from "./Message.styles";

const Message = ({
  type,
  title,
  createdAt,
  isRead,
  handleOnRead,
  children,
}) => {
  const [isMessageRead, setIsMessageRead] = useState(isRead);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const renderIcon = () => {
    switch (type) {
      case "INFO":
        return <SpeechBubble />;
      case "ALERT":
        return <Time />;
      case "WARNING":
        return <Warning />;
      default:
        return <></>;
    }
  };

  const handleReadMore = () => {
    setIsOpen((prevState) => !prevState);

    if (!isMessageRead) {
      handleOnRead();
      setIsMessageRead((prevState) => !prevState);
    }
  };

  return (
    <S.Wrapper>
      <S.Date>{createdAt}</S.Date>

      <S.InnerWrapper type={type} isRead={isMessageRead}>
        <S.ReadIndicator isRead={isMessageRead} />
        <S.Icon>{renderIcon()}</S.Icon>

        <S.ContentContainer>
          <S.Title>{title}</S.Title>

          <S.Collapsible isOpen={isOpen}>
            {children}
          </S.Collapsible>

          <S.ReadMoreToggle onClick={() => handleReadMore()}>
            {!isOpen ? t('buttons.readMore') : t('buttons.showLess')}
          </S.ReadMoreToggle>
        </S.ContentContainer>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

Message.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["INFO", "ALERT", "WARNING"]),
  isRead: PropTypes.bool,
  createdAt: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.elementType,
    PropTypes.node,
  ]),
  handleOnRead: PropTypes.func.isRequired,
};

Message.defaultProps = {
  type: "INFO",
  isRead: false,
  children: <></>,
};

export default Message;
