export default {
  currentStep: 'currentStep',
  token: 'token',
  creditRequestID: 'creditRequestID',
  avalType: 'avalType',
  creditTerms: 'creditTerms',
  creditRequestOffers: 'creditRequestOffers',
  creditRequestSelectedOffer: 'creditRequestSelectedOffer',
  signatureType: 'signatureType',
  documents: 'documents',
  avalCreditRequestID: 'avalCreditRequestID',
  avalDocuments: 'avalDocuments',
  avalSignatureType: 'avalSignatureType',
  theme: 'theme',
}
