import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ArrowDownIcon,
  ArrowTopIcon,
  DotsIcon,
  WalletIcon,
} from "@/assets/svg";
import { useSelector } from "react-redux";
import { authSelector } from "@/redux/slices/authSlice";
import { Button } from "@/components/common";
import Anime from "react-anime";
import { useTranslation } from "react-i18next";
import { useRedirect } from "@/hooks";
import enums from "@/constants/enums";
import { stepKeyByServerKey } from "@/constants/steps"

// Styled components
import * as S from "./Section.styles";

const SectionComponent = ({ children }) => {
  return <S.Section>{children}</S.Section>;
};
SectionComponent.defaultProps = { children: <></> };
SectionComponent.propTypes = {
  children: PropTypes.any,
};

SectionComponent.Actions = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  let bubbleAnim = {
    opacity: [0, 1],
    duration: 100,
    scale: [0.5, 1],
    easing: "linear",
  };
  return (
    //here you can use that like documents={legal.documents} => like it was at Sign.jsx

    <S.Actions>
      <S.Dots onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <ArrowTopIcon /> : <DotsIcon />}
      </S.Dots>
      <Anime {...bubbleAnim}>
        {isOpen && <S.ActionList>{children}</S.ActionList>}
      </Anime>
    </S.Actions>
  );
};
SectionComponent.Actions.defaultProps = { children: <></> };
SectionComponent.Actions.propTypes = { children: PropTypes.any };

SectionComponent.Header = ({ children }) => (
  <S.SectionHeader>
    <WalletIcon />
    {children}
  </S.SectionHeader>
);
SectionComponent.Header.defaultProps = { children: <></> };
SectionComponent.Header.propTypes = { children: PropTypes.any };

SectionComponent.Content = ({ children, layout, background, className }) => (
  <S.SectionContent className={className} {...{ layout, background }}>
    <>{children}</>
  </S.SectionContent>
);
SectionComponent.Content.defaultProps = {
  children: <></>,
  layout: "",
  background: "",
  className: "",
};
SectionComponent.Content.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  layout: PropTypes.oneOf(["columns", "list"]),
  background: PropTypes.string,
};

SectionComponent.Item = ({ children, label, ...restProps }) => (
  <S.SectionItem {...restProps}>
    {label && <S.Label>{label}</S.Label>}
    <S.Wrapper>{children}</S.Wrapper>
  </S.SectionItem>
);
SectionComponent.Item.defaultProps = { children: <></>, label: "" };
SectionComponent.Item.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
};

SectionComponent.PendingCredit = ({creditRequestId, currentStep}) => {
  const redirect = useRedirect()

  const redirectToPendingCredit = () => {
    const step = stepKeyByServerKey[currentStep]
    redirect(`/credit-request/${creditRequestId}/${enums.steps[step].slug}`)
  }

  return (
    <S.SectionFooter>
    <S.Button as={Button} color="lightPrimary" fullWidth weight={600} onClick={redirectToPendingCredit}>
      Продължи 
    </S.Button>
  </S.SectionFooter>
  )
}
SectionComponent.PendingCredit.defaultProps = {};
SectionComponent.PendingCredit.propTypes = {
  creditRequestId: PropTypes.string,
  currentStep: PropTypes.string
};

SectionComponent.Footer = ({ documents }) => {
  const { sessionId } = useSelector(authSelector);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  let animeProps = {
    opacity: [0, 1],
    scaleY: [0, 1],
    duration: 800,
    // translateX:[-200, 0],
    delay: (el, i) => i * 50,
  };

  return (
    <S.SectionFooter>
      <S.Button as={Button} color="lightPrimary" fullWidth weight={600} onClick={() => window.open("https://cashbox.bg/как-да-върна-кредит", "_blank")}>
        Плащане
      </S.Button>
      <S.Toggle
        as={Button}
        color="transparent"
        onClick={() => setIsOpen(!isOpen)}
      >
        Документи {isOpen ? <ArrowTopIcon /> : <ArrowDownIcon />}
      </S.Toggle>

      {isOpen && (
        <SectionComponent.Content layout="list">
          <Anime {...animeProps}>
              {documents.map((document) => {
                const link = `${process.env.REACT_APP_API}/ml/web/documents/${document.id}?sessionId=${sessionId}`;
                return (
                  <SectionComponent.Item key={document.id} label={document.documentTitle}>
                  <a href={link} rel="noopener noreferrer" target="_blank" download>{t('buttons.download')}</a>
                </SectionComponent.Item>
                )
              })}
          </Anime>
        </SectionComponent.Content>
      )}
    </S.SectionFooter>
  );
};
SectionComponent.Footer.defaultProps = { children: <></> };
SectionComponent.Footer.propTypes = {
  children: PropTypes.any,
  documents: PropTypes.array,
  authenticationState: PropTypes.object,
};

export default SectionComponent