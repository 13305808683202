import * as Yup from 'yup'

export default {
  generalInfo: ()=> Yup.object().shape({
    email: Yup.string().email(window.translate.t('validations.invalidEmail'))
      .required(window.translate.t('validations.enterEmail')),
  }),

  phone: () => Yup.object().shape({
    phone: Yup.string()
      .required(window.translate.t('validations.enterMobilePhone')),
  }),

  password: () => Yup.object().shape({
    oldPassword: Yup.string()
      .min(4, window.translate.t('validations.min4'))
      .max(50, window.translate.t('validations.max50'))
      .required(window.translate.t('validations.enterPassword')),

    firstPassword: Yup.string()
      .min(4, window.translate.t('validations.min4'))
      .max(50, window.translate.t('validations.max50'))
      .required(window.translate.t('validations.enterPassword')),

    newPassword: Yup.string()
      .min(4, window.translate.t('validations.min4'))
      .max(50, window.translate.t('validations.max50'))
      .oneOf([Yup.ref('firstPassword'), null], window.translate.t('validations.passwordsNotMatch'))
      .required(window.translate.t('validations.enterPassword')),
  }),

  address: (hasDivisionUnits, hasStreetValue, hasBuildingValue)=> Yup.object().shape({
    settlement: Yup.object()
      .required(window.translate.t('validations.enterCity')),
    district: Yup.mixed().when((value, schema) => hasDivisionUnits && !hasStreetValue ? Yup.object()
      .required(window.translate.t('validations.enterBlock')) : schema),
    building: Yup.mixed().when((value, schema) => hasDivisionUnits && !hasStreetValue ? Yup.string()
      .min(1, window.translate.t('validations.min1Number'))
      .typeError(window.translate.t('validations.enterBlock')) : schema),    
    street: Yup.mixed().when((value, schema) => hasDivisionUnits && !hasBuildingValue ? Yup.object()
      .required(window.translate.t('validations.enterStreet')) : schema),
    streetNo: Yup.mixed().when((value, schema) => hasDivisionUnits && !hasBuildingValue ? Yup.string()
      .min(1, window.translate.t('validations.min1Number'))
      .typeError(window.translate.t('validations.enterStreetNumber')) : schema),
    zip: Yup.string()
      .typeError(window.translate.t('validations.enterZip'))
      .required(window.translate.t('validations.enterZip')),
  }),

  addressStep: (hasDivisionUnits, hasStreetValue, hasBuildingValue)=> Yup.object().shape({
    currentAddressOwnership: Yup.object()
    .required(window.translate.t('validations.enterCurrentAddressOwnership')),
    moneySource: Yup.object().shape({ 
      id: Yup.object().typeError(window.translate.t('validations.enterМoneySource')).required(window.translate.t('validations.enterМoneySource')) 
    }),
    currentAddressLivingSinceYear: Yup.string()
      .min(1, window.translate.t('validations.min1Number'))
      .typeError(window.translate.t('validations.enterCurrentAddressLivingSinceYear')),
      settlement: Yup.object()
      .required(window.translate.t('validations.enterCity')),
    district: Yup.mixed().when((value, schema) => hasDivisionUnits && !hasStreetValue ? Yup.object()
      .required(window.translate.t('validations.enterBlock')) : schema),
    building: Yup.mixed().when((value, schema) => hasDivisionUnits && !hasStreetValue ? Yup.string()
      .min(1, window.translate.t('validations.min1Number'))
      .typeError(window.translate.t('validations.enterBlock')) : schema),    
    street: Yup.mixed().when((value, schema) => hasDivisionUnits && !hasBuildingValue ? Yup.object()
    .required(window.translate.t('validations.enterStreet')) : schema),
    streetNo: Yup.mixed().when((value, schema) => hasDivisionUnits && !hasBuildingValue ?  Yup.string()
        .min(1, window.translate.t('validations.min1Number'))
        .typeError(window.translate.t('validations.enterStreetNumber')) : schema),
    zip: Yup.string()
        .typeError(window.translate.t('validations.enterZip'))
        .required(window.translate.t('validations.enterZip')),
  }),

  idCard: ()=> Yup.object().shape({
    idCardNumber: Yup.string()
      .length(9,window.translate.t('validations.idCardNumberLength'))
      .matches(/^[0-9]*$/,window.translate.t('validations.idCardNumberOnlyNumbers'))
      .required(window.translate.t('validations.enterIdCardNumber')),

    idCardPictureFront: Yup.string()
      .required(window.translate.t('validations.enterIdCardFront')),

    idCardPictureBack: Yup.string()
      .required(window.translate.t('validations.enterIdCardBack')),
  })
}
