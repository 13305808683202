// Internet Explorer Polyfills
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Provider as MobxProvider } from "mobx-react";

import * as serviceWorker from "./serviceWorker";

// FingerprintJS
import FingerprintJS from '@fingerprintjs/fingerprintjs'

// analytics
import { analyticsEvents, logAnalyticsEvent, setFingerprint } from './utils/analytics'

// Utilities | Constants
import { storageKeys } from "@/constants";
import { getStorage } from "@/utils";

// Translations
import { bg } from "./i18n";

// Redux | MobX
import { Provider } from "react-redux";
import reduxStore from "./redux/store";
import rootStore from "@/stores";

// Root component
import App from "./App";

// Initialize FingerprintJS
const fpPromise = FingerprintJS.load({
  monitoring: false
});

export const store = rootStore.create({
  authenticationState: {
    token: getStorage(storageKeys.token, undefined),
    session: {},
  },
  registrationState: {
    mobilePhone: "",
  },
  profileState: {
    profile: {},
    isPhoneVerified: false,
  },
  creditState: {
    creditProducts: [],
    creditTerms: {},
    myCredits: {
      activeCredits: [],
      avalCredits: [],
    },
  },
  referenceDataState: {
    incomeSourcesList: [],
    citiesList: [],
    creditDiscretionList: [],
    creditPurposesList: [],
  },
  creditRequestState: {
    approvalStatus: undefined,
    avalCodeVerified: false,
    avalSigned: false,
    removeRequestModalIsOpen: false,
  },
  forgotPasswordState: {
    email: undefined,
    screen: "reset-password",
  },
  addressState: {
    addressesList: [],
    coordinates: undefined,
  },
  messagesState: {
    messages: [],
    currentPage: 0,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
    unreadMessages: 0,
  },
});

i18n.use(LanguageDetector)
i18n.use(initReactI18next)
i18n.on("languageChanged",(lang) => {
  const closestLanguage = Object.keys(
    i18n.services.resourceStore.data
  ).find((item) => lang.match(new RegExp(item, "gi")));
  const fallBack = i18n.options.fallbackLng[0];
  store.setLanguage(closestLanguage || fallBack);
})
i18n.init({
  resources: {bg: {translation: {...bg}}},
  fallbackLng: "bg",
}).then()

//  TODO Ideally we can try to add the 'i18n' instance to a discrete Mobx store so we can access it from there.
window.translate = i18n;

toast.configure({ autoClose: 14000 });

ReactDOM.render(
  <Provider store={reduxStore}>
    <MobxProvider {...{ store }}>
      <App />
    </MobxProvider>
  </Provider>,
  document.getElementById("root"),
  async () => {
    await Promise.all([
      (async () => {
        const fp = await fpPromise
        const res = await fp.get()
        setFingerprint(res)
        logAnalyticsEvent(analyticsEvents.events.appOpen)
      })(),
      setTimeout(() => {
        document.querySelector(".loader").classList.add("loaded");
      }, 1000)
    ])
  }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
