import * as Yup from 'yup'

export default () => {
  return {
    companyAval: ()=> Yup.object().shape({
      acceptedAvalCompany: Yup.bool()
        .oneOf([true], window.translate.t('validations.acceptForContinue')),
    }),

    avalCode: ()=> Yup.object().shape({
      avalCode: Yup.string()
        .required(window.translate.t('validations.enterAvalCode')),
    }),

    privateAvals: () => Yup.object().shape({
      privateAvals: Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string()
            .required(window.translate.t('validations.enterFirstName')),

          middleName: Yup.string()
            .required(window.translate.t('validations.enterMiddleName')),

          familyName: Yup.string()
            .required(window.translate.t('validations.enterLastName')),

          phone: Yup.string()
            .required(window.translate.t('validations.enterMobilePhone')),
        })
      ),

      informedPrivateAvals: Yup.bool()
        .oneOf([true], window.translate.t('validations.acceptForContinue')),
    }),
  }
}
