import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { useForm, Controller } from "react-hook-form";
import { withSteps } from "@/hoc";
import { PaymentValidator } from "@/formValidators";
import { FieldGroup } from "@/components";
import { Form, Input, Check } from "@/components/common";
import { useFocusOnError } from "@/hooks";
import { useParams } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

// REDUX
import {
  creditRequestSelector,
  postPaymentDetails,
  fetchPaymentDetails,
  goToPreviousStep,
  fetchPersonalDetails,
  fetchCreditDataById,
  fetchAddressData, fetchAvalsDetails,
} from "@/redux/slices/creditRequestSlice";
import { useDispatch, useSelector } from "react-redux";

const PaymentsStep = ({ isLoading, buttonText, backText }) => {
  const { register, handleSubmit, setValue, control, errors } = useForm({
    resolver: yupResolver(PaymentValidator())
  });
  const [paymentType, setPaymentType] = useState(null);

  const [enteredCalled, setEnteredCalled] = useState(false);

  const { requestID } = useParams();
  const dispatch = useDispatch();
  const {
    avals,
    paymentDetails,
    personalDetails,
    creditTerms,
    currentAddress,
  } = useSelector(creditRequestSelector);

  useEffect(() => {
    if (paymentType !== "CREDIT_OR_DEBIT_CARD") {
      setValue("cardNumber", "");
    }
    if (paymentType !== "REVOLUTE" && paymentType !== "BANK_TRANSFER") {
      setValue("iban", "");
    }
  }, [paymentType, setValue]);

  useEffect(() => {
    if (!enteredCalled) {
      dispatch(fetchPaymentDetails(requestID, false));
      setEnteredCalled(true);
    }

    if (!paymentDetails) {
      dispatch(fetchPaymentDetails(requestID, false));
    }

    if (!personalDetails) {
      dispatch(fetchPersonalDetails(requestID, false));
    }

    if (!creditTerms) {
      dispatch(fetchCreditDataById(requestID, false));
    }

    if (!currentAddress) {
      dispatch(fetchAddressData(requestID, false));
    }

    if (!avals || !avals.id) {
      dispatch(fetchAvalsDetails(requestID, false));
    }
  }, []);

  useEffect(() => {
    if (paymentDetails) {
      setValue("avalType", paymentDetails.avalType ? paymentDetails.avalType : "");
      setValue("paymentType", paymentDetails.paymentType ? paymentDetails.paymentType : "");
      setValue("cardNumber", paymentDetails.cardNumber ? paymentDetails.cardNumber : "");
      setValue("iban", paymentDetails.iban ? paymentDetails.iban : "");      

      setPaymentType(paymentDetails.paymentType);
    }
  }, [paymentDetails]);

  const isPartnerRequest = paymentDetails?.isPartnerRequest
  const hadCredits = paymentDetails?.hadCredits

  useFocusOnError(errors);

  return (
    <Form
      {...{
        isLoading,
        buttonText,
        backText,
      }}
      onSubmit={handleSubmit((data) =>
        dispatch(
          postPaymentDetails(requestID, {
            ...data,
            cardNumber: data.cardNumber ? data.cardNumber : null,
            iban: data.iban ? data.iban : null,
          })
        )
      )}
      onBack={() => {
        dispatch(goToPreviousStep());
      }}
    >
      <FieldGroup label="ОБЕЗПЕЧЕНИЕ (опция):">
        <Check ref={register} type="radio" name="avalType" value="AVAL_COMPANY" {...{ errors }}>
          Гарант, осигурен от <strong>CASHBOX</strong>
        </Check>

        <Check
          ref={register}
          type="radio"
          name="avalType"
          value="PRIVATE_AVALS"
          {...{ errors }}
        >
          Предоставяне на 2-ма <strong>лични поръчители</strong>
        </Check>

        <Check
          ref={register}
          type="radio"
          name="avalType"
          value="NO_WARRANT"
          {...{ errors }}
        >
          <strong>Без обезпечение</strong>
        </Check>
      </FieldGroup>

      <FieldGroup label="Посочи начин, по който да получиш кредита:">
        <Check
          type="radio"
          ref={register}
          name="paymentType"
          defaultChecked
          value="EASY_PAY"
          onChange={({ currentTarget }) => {
            setValue("iban", "");
            setValue("cardNumber", "");
            setPaymentType(currentTarget.value);
          }}
          {...{ errors }}
        >
          На гише в <strong>EasyPay</strong>
        </Check>
        <Check
            type="radio"
            ref={register}
            name="paymentType"
            defaultChecked
            value="FAST_PAY"
            onChange={({ currentTarget }) => {
              setValue("iban", "");
              setValue("cardNumber", "");
              setPaymentType(currentTarget.value);
            }}
            {...{ errors }}
        >
          На гише във <strong>FastPay</strong>
        </Check>

        {/* <Check
          type="radio"
          ref={register}
          name="paymentType"
          value="CREDIT_OR_DEBIT_CARD"
          onChange={({ currentTarget }) => {
            setValue("iban", "");
            setPaymentType(currentTarget.value);
          }}
          {...{ errors }}
        >
          По моя <strong>кредитна / дебитна карта</strong>
        </Check>

        <Check
          type="radio"
          ref={register}
          name="paymentType"
          value="REVOLUTE"
          onChange={({ currentTarget }) => {
            setValue("cardNumber", "");
            setPaymentType(currentTarget.value);
          }}
          {...{ errors }}
        >
          Чрез <strong>Revolut</strong>
        </Check> */}

        {!isPartnerRequest && hadCredits &&  (
            <Check
                type="radio"
                ref={register}
                name="paymentType"
                value="BANK_TRANSFER"
                onChange={({ currentTarget }) => {
                  setValue("cardNumber", "");
                  setPaymentType(currentTarget.value);
                }}
                {...{ errors }}
            >
              По <strong>банкова сметка</strong>
            </Check>
        )}
      </FieldGroup>

      <Form.ConditionalFields
        isShown={paymentType === "REVOLUTE" || paymentType === "BANK_TRANSFER"}
      >
        <Controller
          as={
            <Input.IBAN
              {...{ errors }}
              label="IBAN"
              placeholder="Въведете IBAN"
            />
          }
          {...{ control }}
          name="iban"
        />
      </Form.ConditionalFields>

      <Form.ConditionalFields isShown={paymentType === "CREDIT_OR_DEBIT_CARD"}>
        <Controller
          as={
            <Input
              {...{ errors }}
              label="Номер на кредитна/дебитна карта"
              placeholder="Въведете номер"
            />
          }
          {...{ control }}
          name="cardNumber"
        />
      </Form.ConditionalFields>
    </Form>
  );
};

PaymentsStep.propTypes = {
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
  backText: PropTypes.string,
  paymentInitialValues: PropTypes.object,
};

export default compose(
  inject(({ store: { isLoading } }) => ({
    isLoading,
  })),
  observer
)(withSteps(PaymentsStep));
