import styled from 'styled-components'

export const List = styled.ul`
  list-style: none;
`

export const Item = styled.li`
  position: relative;
  margin-bottom: 20px;
  padding-left: 22px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 6px;
    height: 6px;
    background: ${({ theme })=> theme.colors.primary};
    border-radius: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }
`