import React, { useMemo } from 'react';
import { Select } from "@/components/common";
import PropTypes from "prop-types";
import { fetchStreets } from '../../services/integrationApi';


const getSelectValue = ({ datamapId, name }) => {
  return {
    value: datamapId,
    label: name,
    selection: { streetDatamapId: datamapId, streetName: name }
  }
}

const StreetSelect = ({ settlementId, errors, fullWidth, onChange, value, isDisabled, ...restProps}) => {
  const loadStreets = async (inputValue) => {
    try {
      const { data: streets = [] } = await fetchStreets(settlementId, inputValue);
      return (streets || []).map(getSelectValue);
    } catch (e) {
      //
    }
  }

  const onSelectChange = (value, selected) => {
    onChange(selected?.selection);
  }

  const defaultValue = useMemo(()=>{
    if(value && value.streetDatamapId > 0) {
      return getSelectValue({ datamapId: value?.streetDatamapId, name: value?.streetName})
    }
    return null
  }, [value])

  return (
    <Select 
      isAsync={true}
      placeholder="Улица/Булевард"
      {...{
        errors,
        fullWidth,
      }}
      isDisabled={isDisabled}
      value={defaultValue}
      defaultValue={defaultValue}
      isSearchable={true}
      defaultOptions={[]}
      loadOptions={loadStreets}
      onChange={onSelectChange}
      {...restProps}
    />
  )
}

StreetSelect.propTypes = {
  settlementId: PropTypes.number,
  errors: PropTypes.object,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  value: PropTypes.shape(   {
    streetDatamapId: PropTypes.number,
    streetName: PropTypes.string
    })
};

export default StreetSelect;