import styled from "styled-components";
import { Text } from "@/components/common";

export const StepsWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      display: flex;
      justify-content: center;
    }
  `}
`;

export const Label = styled(Text)`
  margin-right: 20px;
  margin-bottom: 0;

  ${({ theme }) => `
    color: ${theme.colors.formLabelColor};

    @media ${theme.mediaQueries.xsOnly} {
      display: none;
    }
  `}
`;

export const Steps = styled.ul`
  position: relative;
  display: flex;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin: auto 0;
    height: 1px;
    border-top: 2px dotted
      ${({ theme, active }) =>
        active
          ? theme.colors.stepBorderActive
          : theme.colors.stepBorderInactive};
  }
`;

export const Step = styled.li`
  position: relative;
  z-index: 3;
  display: inline-block;
  user-select: none;
  background: ${({ theme, active }) =>
    active ? theme.colors.stepActiveBg : theme.colors.mainBackground};
  color: ${({ theme, active }) =>
    active ? theme.colors.stepActiveColor : theme.colors.stepInactiveColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 2px solid
    ${({ theme, active }) =>
      active ? theme.colors.stepBorderActive : theme.colors.stepBorderInactive};
  transition: background 0.3s, color 0.3s ease;

  ${({ theme, active }) => `
    @media ${theme.mediaQueries.xsOnly} {
      width: 24px;
      height: 24px;
      font-size: 12px;
      margin-right: 12px;
    }

    @media ${theme.mediaQueries.smUp} {
      width: 28px;
      height: 28px;
      font-weight: ${active ? 600 : 400};
      margin-right: 19px;
    }

    @media ${theme.mediaQueries.mdUp} {
      margin-right: 26px;
    }
  `}

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background: ${({ theme }) => theme.colors.mainBackground};
  }

  &::before {
    left: -4px;
  }

  &::after {
    right: -4px;
  }

  &:last-child {
    margin-right: 0;
  }
`;
