export default (paramObject) => {

  if (typeof paramObject !== 'object') {
    // eslint-disable-next-line
      console.warn(`paramObject needs to be an Object. ${typeof paramObject} was passed`)
    return ''
  }

  let params = new URLSearchParams()

  for (let [key, value] of Object.entries(paramObject)) {
    if (value !== undefined && value !== null) {
      value = `${value}`

      if (value.length > 0) {
        params.set(key, value)
      }
    }
  }

  return `?${params.toString()}`
}
