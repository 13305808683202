import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { ProfileValidator } from "@/formValidators";
import { useForm } from "react-hook-form";
import { Input, Form } from "@/components/common";
import { useFocusOnError } from "@/hooks";

const ChangePassword = ({ profileState, isLoading }) => {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: ProfileValidator.password(),
  });

  const inputProps = {
    ref: register,
    errors,
  };

  const onSubmit = (data) => {
    profileState.updateProfile(data, "password");
  };

  useFocusOnError(errors);

  return (
    <Form
      {...{
        isLoading,
        loaderFixedCircle: false,
        loaderSize: "small",
        loaderBackground: "concrete",
      }}
      size="small"
      onSubmit={handleSubmit(onSubmit)}
      buttonText="Запази"
    >
      <Input
        {...inputProps}
        type="password"
        name="oldPassword"
        label="Стара парола:"
        customStyle="gray"
      />
      <Input
        {...inputProps}
        type="password"
        name="firstPassword"
        label="Нова парола:"
        customStyle="gray"
      />
      <Input
        {...inputProps}
        type="password"
        name="newPassword"
        label="Повтори новата парола:"
        customStyle="gray"
      />
    </Form>
  );
};

ChangePassword.propTypes = {
  profileState: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default compose(
  inject(({ store: { profileState, isLoading } }) => ({
    profileState,
    isLoading,
  })),
  observer
)(ChangePassword);
