import styled, { css } from "styled-components";

const Types = {
  INFO: css`
    border-color: ${({ theme }) => theme.colors.blueGray};
  `,
  ALERT: css`
    border-color: ${({ theme }) => theme.colors.danger};
  `,
  WARNING: css`
    border-color: ${({ theme }) => theme.colors.warning};
  `,
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  color: ${({ theme }) => theme.colors.formLabelColor};
`;

export const InnerWrapper = styled.div`
  display: flex;

  ${({ theme }) => css`
    @media ${theme.mediaQueries.xsOnly} {
      padding: 20px;
      margin-bottom: 20px;
    }

    @media ${theme.mediaQueries.smUp} {
      padding: 25px;
      margin-bottom: 25px;
    }
  `}

  position: relative;

  border-width: 3px;
  border-style: solid;
  ${({ type }) => Types[type]}; // border-color
  border-radius: 10px;

  .ReactCollapse--collapse {
    margin: 1em 0;

    transition: height 0.3s ease;
  }
`;

export const Date = styled.span`
  align-self: end;
  margin-bottom: 0.25em;
  color: ${({ theme }) => theme.colors.silver};
`;

export const ReadIndicator = styled.div`
  width: 0.8em;
  height: 0.8em;

  position: absolute;
  top: 0.5em;
  right: 0.5em;

  border-radius: 10em;

  background-color: ${({ isRead, theme }) =>
    isRead ? theme.colors.blueGray : theme.colors.danger};
`;

export const ReadMoreToggle = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.formLabelColor};
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  margin-left: 1em;
  overflow: hidden;
`;

export const Icon = styled.div``;

export const Title = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.formLabelColor};
`;

export const Collapsible = styled.div`
  margin: 1em 0;

  max-height: ${({ isOpen }) => (isOpen ? "10rem" : "0")};
  overflow: hidden;
  text-overflow: ellipsis;

  transition: max-height 0.5s linear;
`;
