import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Input, Button } from '@/components/common'
import { BackgroundPage } from '@/layouts'
import { LoginValidator } from '@/formValidators'
import LogoPNG from '@/assets/images/logo.png'
import { useFocusOnError, useRedirect } from '@/hooks'
import { enums } from '@/constants'

import { login } from '@/redux/slices/authSlice'
import { requestCredit } from '@/redux/slices/creditRequestSlice'

import * as S from './Login.styles'
import { globalSelector } from '@/redux/slices/globalSlice'

const Login = ({ isLoading }) => {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: LoginValidator(),
  })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const redirect = useRedirect()
  useFocusOnError(errors)

  const { globalTheme } = useSelector(globalSelector)

  const inputProps = {
    ref: register,
    errors,
    fullWidth: true,
    size: 'large',
    color: 'light',
  }

  return (
    <BackgroundPage {...{ isLoading }}>
      <S.Logo src={LogoPNG} />
      <form
        onSubmit={handleSubmit((data) => {
          dispatch(login(data)).then((resp) => {
            if (resp) {
              // TODO: Refactor this to be a custom hook Its used in Home.jsx as well
              dispatch(requestCredit()).then((res) => {
                if (!res) {
                    redirect('/home')
                } else if (res?.creditRequestId) {
                    redirect(`/credit-request/${res.creditRequestId}/${enums.steps[res.currentStep].slug}`)
                } else {
                    redirect('/home')
                }
              })
            }
          })
        })}
      >
        <Input
          {...inputProps}
          name='username'
          label={t('fields.email')}
          customStyle={globalTheme === 'dark' ? '' : 'fullWhite'}
          placeholder={t('fields.enterEmail')}
        />

        <Input
          {...inputProps}
          name='password'
          label={t('fields.password')}
          customStyle={globalTheme === 'dark' ? '' : 'fullWhite'}
          type='password'
          placeholder={t('fields.enterPassword')}
        />
        <S.ButtonContainer>
          <Button as='button' type='submit' color='light' circled>
            {t('buttons.login')}
          </Button>
        </S.ButtonContainer>
      </form>
      <S.Links>
        <li>
          <Link to='/register'>{t('buttons.register')}</Link>
        </li>
        <li>
          <Link to='/forgot-password'>{t('buttons.forgotPassword')}</Link>
        </li>
      </S.Links>
    </BackgroundPage>
  )
}

Login.propTypes = {
  isLoading: PropTypes.bool,
}

export default compose(
  inject(({ store: { isLoading } }) => ({
    isLoading,
  })),
  observer
)(Login)
