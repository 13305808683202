import React, { } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { Row, Col } from "react-grid-system";
import { Input } from "@/components/common";

const AddressForm = ({
  register,
  errors,
  formData,
  namePrefix,
  colSize,
  fullWidth,
  customInputsStyle,
}) => {
  let {
    apartment,
    building,
    cityName,
    entry,
    floor,
    street,
    streetNo,
    zip,
  } = formData;

  const inputProps = {
    ref: register,
    errors,
    fullWidth,
  };

  const formattedPrefix = namePrefix ? `${namePrefix}.` : "";


  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col sm={colSize}>
              <Input
                {...inputProps}
                name={`${formattedPrefix}cityName`}
                defaultValue={cityName}
                label="Град:"
                customStyle={customInputsStyle}
              />              
            </Col>

            <Col sm={colSize}>
              <Input
                {...inputProps}
                name={`${formattedPrefix}street`}
                defaultValue={street}
                label="Улица:"
                customStyle={customInputsStyle}
              />
            </Col>

            <Col sm={colSize}>
              <Input
                {...inputProps}
                name={`${formattedPrefix}streetNo`}
                defaultValue={streetNo}
                type="number"
                label="Улица номер:"
                customStyle={customInputsStyle}
              />
            </Col>

            <Col sm={colSize}>
              <Input
                {...inputProps}
                defaultValue={building}
                name={`${formattedPrefix}building`}
                label="Сграда: "
                customStyle={customInputsStyle}
              />
            </Col>

            <Col sm={colSize}>
              <Input
                {...inputProps}
                defaultValue={entry}
                name={`${formattedPrefix}entry`}
                label="Вход: "
                customStyle={customInputsStyle}
              />
            </Col>

            <Col sm={colSize}>
              <Input
                {...inputProps}
                type="number"
                defaultValue={floor}
                name={`${formattedPrefix}floor`}
                label="Етаж:"
                customStyle={customInputsStyle}
              />
            </Col>

            <Col sm={colSize}>
              <Input
                {...inputProps}
                type="number"
                defaultValue={apartment}
                name={`${formattedPrefix}apartment`}
                label="Апартамент:"
                customStyle={customInputsStyle}
              />
            </Col>

            <Col sm={colSize}>
              <Input
                {...inputProps}
                name={`${formattedPrefix}zip`}
                defaultValue={zip}
                type="number"
                label="Пощенски код:"
                customStyle={customInputsStyle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

AddressForm.defaultProps = {
  namePrefix: "",
  colSize: 4,
  errors: {},
  formData: {},
  isValid: false,
  fullWidth: true,
  customInputsStyle: "",
};

AddressForm.propTypes = {
  isLoading: PropTypes.bool,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  namePrefix: PropTypes.string,
  colSize: PropTypes.number,
  formData: PropTypes.object,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  fullWidth: PropTypes.bool,
  customInputsStyle: PropTypes.string,
};

export default compose(
  inject(({ store: { isLoading } }) => ({
    isLoading,
  })),
  observer
)(AddressForm);
