import React from "react";
import PropTypes from "prop-types";

import * as S from "./StepsMarker.styles";

const StepsComponent = ({ activeStep, totalSteps }) => (
  <S.StepsWrapper>
    <S.Label weight={600} textTransform="uppercase">
      Стъпка
    </S.Label>
    <S.Steps>
      {Array.from(Array(totalSteps), (_, step) => {
        const currentStep = step + 1;
        return (
          <S.Step
            key={`step-${currentStep}`}
            active={activeStep === currentStep}
          >
            {currentStep}
          </S.Step>
        );
      })}
    </S.Steps>
  </S.StepsWrapper>
);

StepsComponent.propTypes = {
  activeStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default StepsComponent;
