import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 503.592 503.592'
    version='1.1'
    viewBox='0 0 503.592 503.592'
    xmlSpace='preserve'
  >
    <path d='M472.272 173.194l.008-3.444c0-30.188-25.1-55.756-54.86-55.756H54.74c-30.26 0-54.74 24.76-54.74 55.02v165.7c0 30.264 24.552 54.884 54.812 54.884l362.608-.132c30.264 0 54.696-24.616 54.696-54.884v-4.168c15.736-.048 31.476-14.1 31.476-31.368v-94.484c0-17.264-14.06-31.32-31.32-31.368z'></path>
  </svg>
)
