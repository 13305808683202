import React from "react";

import { CheckIcon } from "@/assets/svg";
import BannerImage from "@/assets/images/banner.jpg";
import LogoBanner from "@/assets/images/logo-banner.png";

import * as S from "./Banner.styles";

const Banner = () => (
  <S.Banner background={BannerImage}>
    <img src={LogoBanner} />
    <S.BannerInfo>
      <S.InfoItem weight={600}>
        <CheckIcon />{" "}Онлайн потвърждение
      </S.InfoItem>

      <S.InfoItem weight={600}>
        <CheckIcon />{" "}Дълго право на връщане
      </S.InfoItem>

      <S.InfoItem weight={600}>
        <CheckIcon />{" "}Кредит удобен за теб
      </S.InfoItem>

      <S.BannerButton>Изчислете и вземете сега &gt;</S.BannerButton>
    </S.BannerInfo>
  </S.Banner>
);

export default Banner;
