import { createSlice } from "@reduxjs/toolkit";
import {
  buildQueryParams,
  getStorage,
  HTTPmethods,
  request,
} from "@/utils";
import { urls } from "@/constants";

const SLICE_NAME = "credit";

const initialState = {
  creditProducts: [],
  creditTerms: getStorage('creditTerms') || null,
  hasError: false,
};

export const creditSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCreditProducts: (state, { payload }) => {
      state.creditProducts = payload;
    },

    setCreditTerms: (state, { payload }) => {
      state.creditTerms = payload;
    },

    setHasError: (state, { payload }) => {
      state.error = payload;
    },

    cleanup: () => initialState
  },
});

export const { setCreditProducts, setCreditTerms, setHasError, cleanup } = creditSlice.actions;

// - Selectors
export const creditSelector = (state) => state[SLICE_NAME];

// - Reducer
export default creditSlice.reducer;

// - Async Actions
export function getCreditProducts() {
  return async (dispatch) => {
    await request({
      method: HTTPmethods.GET,
      url: urls.creditProducts
    })
    .then(({ data }) => {
      const paymentPeriodOrder = ["WEEKLY", "BIWEEKLY", "MONTHLY"];
      data.forEach(el => {
        el.creditProducts = el.creditProducts.sort((a, b) => paymentPeriodOrder.indexOf(a.paymentPeriod) - paymentPeriodOrder.indexOf(b.paymentPeriod))
      });
      dispatch(setCreditProducts(data))
    })
    .catch(() => dispatch(setHasError(true)))
  }
}

export function getCreditTerms(queryParamObject, byPeriod) {
  return async (dispatch) => {
    await request({
      method: HTTPmethods.GET,
      url: `${urls.creditTerms}/${byPeriod}${buildQueryParams(queryParamObject)}`,
      suppressErrors: true
    })
    .then(({ data }) => dispatch(setCreditTerms(data)))
    .catch(() => dispatch(setHasError(true)))
  }
}