import styled from "styled-components";

export const Alert = styled.div`
  position: relative;
  display: inline-block;
  color: ${({ theme }) => theme.colors.light};
  background: ${({ theme, color }) => theme.colors[color]};
  margin-top: 15px;
  padding: 6px 10px;
  font-size: 14px;
  border-radius: 2px;
  max-width: 300px;

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${({ theme, color }) => theme.colors[color]};
  }
`;
