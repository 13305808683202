import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Row, Col } from "react-grid-system";
import { Card, AddressFields, List, FieldGroup } from "@/components";
import { Form, Check, Input, DatePicker } from "@/components/common";

const PoliticalPersonDeclaration = ({
  inputProps,
  register,
  errors,
  control,
  getValues,
  setValue,
  setIsNameChanged,
  t,
  isFallIntoCategory_36_2,
  checkProps,
  isNameChanged,
  setIsShownCurrentAddress,
  isFallIntoCategory_36_5,
  isShownCurrentAddress,
  setIsShownMailingAddress,
  isShownMailingAddress,
  setIsFallIntoCategory_36_2,
  setIsFallIntoCategory_36_5,
}) => (
  <>
    <Form.Separator title="ДЕКЛАРАЦИЯ ПО ЗМИП">
      <Row>
        <Col md={4}>
          <Input
            {...inputProps}
            label={t("fields.fullName")}
            name="politicalPersonDeclaration.fullName"
          />
        </Col>

        <Col md={4}>
          <Input
            {...inputProps}
            label={t("fields.egn")}
            type="number"
            name="politicalPersonDeclaration.egn"
          />
        </Col>

        <Col md={4}>
          <Controller
            as={
              <DatePicker
                fullWidth
                label={t("fields.birthDate")}
                maxDate={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
              />
            }
            {...{
              control,
              errors,
            }}
            defaultValue=""
            name="politicalPersonDeclaration.birthDate"
          />
        </Col>

        <Col md={4}>
          <Input
            {...inputProps}
            label={t("fields.idCardNumber")}
            type="number"
            name="politicalPersonDeclaration.idCardNumber"
          />
        </Col>

        <Col md={4}>
          <Input
            {...inputProps}
            label={t("fields.idCardIssuedBy")}
            name="politicalPersonDeclaration.idCardIssuedBy"
          />
        </Col>

        <Col md={4}>
          <Controller
            as={<DatePicker fullWidth label={t("fields.idCardIssueDate")} />}
            {...{
              control,
              errors,
            }}
            defaultValue=""
            name="politicalPersonDeclaration.idCardIssueDate"
          />
        </Col>

        <Col md={4}>
          <Controller
            as={<DatePicker fullWidth label={t("fields.idCardValidity")} />}
            {...{
              control,
              errors,
            }}
            defaultValue=""
            name="politicalPersonDeclaration.idCardValidity"
          />
        </Col>

        <Col md={4}>
          <Input
            {...inputProps}
            label={t("fields.citizenship")}
            name="politicalPersonDeclaration.citizenship"
          />
        </Col>

        <Col md={4}>
          <Input
            {...inputProps}
            label={t("fields.otherCitizenship")}
            name="politicalPersonDeclaration.otherCitizenship"
          />
        </Col>

        <Col md={4}>
          <Input
            {...inputProps}
            label={t("fields.socialSecurityNumber")}
            name="politicalPersonDeclaration.socialSecurityNumber"
          />
        </Col>

        <Col md={4}>
          <Input
            {...inputProps}
            label={t("fields.countryOfBirth")}
            name="politicalPersonDeclaration.countryOfBirth"
          />
        </Col>

        <Col md={4}>
          <Input
            {...inputProps}
            label={t("fields.placeOfBirth")}
            name="politicalPersonDeclaration.placeOfBirth"
          />
        </Col>

        <Col md={4}>
          <Input
            {...inputProps}
            label={t("fields.currentOccupation")}
            name="politicalPersonDeclaration.currentOccupation"
          />
        </Col>

        <Col md={4}>
          <Controller
            as={
              <Input.Phone
                {...{ errors }}
                fullWidth
                label={t("fields.mobilePhone")}
                placeholder={t("fields.enterMobilePhone")}
                specialLabel=""
              />
            }
            {...{ control }}
            defaultValue=""
            name="politicalPersonDeclaration.mobilePhone"
          />
        </Col>

        <Col md={4}>
          <Input
            {...inputProps}
            label={t("fields.email")}
            name="politicalPersonDeclaration.email"
          />
        </Col>

        <Col md={6}>
          <FieldGroup>
            <Check
              {...checkProps}
              name="politicalPersonDeclaration.nameChanged"
              onChange={({ currentTarget }) =>
                setIsNameChanged(currentTarget.checked)
              }
            >
              {t("fields.nameChanged")}
            </Check>
          </FieldGroup>

          <Form.ConditionalFields isShown={isNameChanged}>
            <Input
              {...inputProps}
              label={t("fields.previousName")}
              name="politicalPersonDeclaration.previousName"
            />
          </Form.ConditionalFields>
        </Col>
      </Row>
    </Form.Separator>

    <Form.Separator disableMarginTop>
      <Card headline="Адрес по лична карта" background="concrete">
        <AddressFields
          namePrefix="politicalPersonDeclaration.idCardAddress"
          {...{
            register,
            errors: errors,
            control,
            getValues,
            setValue,
          }}
        />
      </Card>

      <Row>
        <Col md={6}>
          <Check
            {...checkProps}
            name="politicalPersonDeclaration.currentAddressMatchesIdCardAddress"
            onChange={({ currentTarget }) =>
              setIsShownCurrentAddress(!currentTarget.checked)
            }
          >
            Настоящият ми адрес отговаря на този по лична карта
          </Check>

          <Form.ConditionalFields isShown={isShownCurrentAddress}>
            <Card headline="Настоящ адрес" background="concrete">
              <AddressFields
                namePrefix="politicalPersonDeclaration.currentAddress"
                colSize={12}
                {...{
                  register,
                  errors: errors,
                  control,
                  getValues,
                  setValue,
                }}
              />
            </Card>
          </Form.ConditionalFields>
        </Col>

        <Col md={6}>
          <Check
            {...checkProps}
            name="politicalPersonDeclaration.mailingAddressMatchesIdCardAddress"
            onChange={({ currentTarget }) =>
              setIsShownMailingAddress(!currentTarget.checked)
            }
          >
            Адресът ми за кореспонденция отговаря на този по лична карта
          </Check>

          <Form.ConditionalFields isShown={isShownMailingAddress}>
            <Card headline="Адрес за кореспонденция" background="concrete">
              <AddressFields
                namePrefix="politicalPersonDeclaration.mailingAddress"
                colSize={12}
                {...{
                  register,
                  errors,
                  control,
                  getValues,
                  setValue,
                }}
              />
            </Card>
          </Form.ConditionalFields>
        </Col>
      </Row>
    </Form.Separator>

    <Form.Separator
      title="Декларирам, че:"
      disableMarginTop
      disableMarginBottom
    >
      <Row>
        <Col md={6}>
          <Check
            {...checkProps}
            name="politicalPersonDeclaration.fall_into_category_36_2_ZMIP"
            onChange={({ currentTarget }) =>
              setIsFallIntoCategory_36_2(currentTarget.checked)
            }
          >
            {t("fields.fall_into_category_36_2_ZMIP")}
          </Check>

          <Card background="concrete">
            <List
              items={[
                "Държавни глави, ръководители на правителства, министри и заместник-министри или помощник-министри/",
                "Членове на парламенти или на други законодателни органи;",
                "Членове на конституционни съдилища, на върховни съдилища или на други висши органи на съдебната власт, чиито решения не подлежат на последващо обжалване освен при изключителни обстоятелства;",
                "Членове на сметна палата;",
                "Членове на управителни органи на централни банки;",
                "Посланици и управляващи дипломатически мисии;",
                "Висши офицери от въоръжените сили;",
                "Членове на административни, управителни или надзорни  органи на държавни предприятия и търговски дружества с едноличен собственик – държавата;",
                "Кметове и заместник-кметове на общини, кметове и заместник-кметове на райони и председатели на общински съвети;",
                "Членове на управителните органи на политически партии;",
                "Ръководители и заместник-ръководители на международни организации, членове на управителни или надзорни органи в международни организации или лица, изпълняващи еквивалентна функция в такива организации.",
              ]}
            />
          </Card>
        </Col>

        <Col md={6}>
          <Check
            {...checkProps}
            name="politicalPersonDeclaration.fall_into_category_36_5_ZMIP"
            onChange={({ currentTarget }) =>
              setIsFallIntoCategory_36_5(currentTarget.checked)
            }
          >
            {t("fields.fall_into_category_36_5_ZMIP")}
          </Check>

          <Card background="concrete">
            <List
              items={[
                "Съпрузите или лицата, които живеят във фактическо съжителство на съпружески начала;",
                "Низходящите от първа степен и техните съпрузи или лицата, с които низходящите от първа степен живеят във фактическо съжителство на съпружески начала (деца);",
                "Възходящите от първа степен и техните съпрузи или лицата, с които възходящите от първа степен живеят във фактическо съжителство на съпружески начала (родители);",
                "Роднините по съребрена линия от втора степен и техните съпрузи или лицата, с които роднините по съребрена линия от втора степен живеят във фактическо съжителство на съпружески начала (брат/сестра);",
                "Физическо лице, което е действителен собственик съвместно с лице по чл. 36, ал. 2 от ЗМИП на юридическо лице или друго правно образувание или се намира в други близки търговски, професионални или други делови взаимоотношения с лице по чл. 36, ал. 2 от ЗМИП;",
                "Физическо лице, което е едноличен собственик или действителен собственик на юридическо лице или друго правно образувание, за което се знае, че е било създадено в полза на лице по чл. 36, ал. 2 от ЗМИП.",
              ]}
            />
          </Card>
        </Col>

        <Col sm={6}>
          <Form.ConditionalFields isShown={isFallIntoCategory_36_2}>
            <Check
              {...checkProps}
              name="politicalPersonDeclaration.fall_into_category_36_2_ZMIP_in_the_last_12_months"
            >
              {t("fields.fall_into_category_36_2_ZMIP_in_the_last_12_months")}
            </Check>

            <Input
              {...inputProps}
              label={t("fields.category_36_2_ZMIP")}
              name="politicalPersonDeclaration.category_36_2_ZMIP"
            />
          </Form.ConditionalFields>

          <Form.ConditionalFields
            isShown={isFallIntoCategory_36_2 || isFallIntoCategory_36_5}
          >
            <Input
              {...inputProps}
              as="textarea"
              label={t("fields.politicalPersonOtherInformation")}
              name="politicalPersonDeclaration.otherInformation"
            />
          </Form.ConditionalFields>
        </Col>

        <Col sm={6}>
          <Form.ConditionalFields isShown={isFallIntoCategory_36_5}>
            <Check
              {...checkProps}
              name="politicalPersonDeclaration.fall_into_category_36_5_ZMIP_in_the_last_12_months"
            >
              {t("fields.fall_into_category_36_5_ZMIP_in_the_last_12_months")}
            </Check>
            <Input
              {...inputProps}
              label={t("fields.category_36_5_ZMIP")}
              name="politicalPersonDeclaration.category_36_5_ZMIP"
            />
          </Form.ConditionalFields>
        </Col>
      </Row>
    </Form.Separator>
  </>
);

PoliticalPersonDeclaration.propTypes = {
  inputProps: PropTypes.object,
  register: PropTypes.func,
  errors: PropTypes.object,
  control: PropTypes.object,
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  setIsNameChanged: PropTypes.func,
  t: PropTypes.func,
  isFallIntoCategory_36_2: PropTypes.bool,
  checkProps: PropTypes.object,
  isNameChanged: PropTypes.bool,
  setIsShownCurrentAddress: PropTypes.func,
  isFallIntoCategory_36_5: PropTypes.bool,
  isShownCurrentAddress: PropTypes.bool,
  setIsShownMailingAddress: PropTypes.func,
  isShownMailingAddress: PropTypes.bool,
  setIsFallIntoCategory_36_2: PropTypes.func,
  setIsFallIntoCategory_36_5: PropTypes.func,
};

export default PoliticalPersonDeclaration;
