import React, { useMemo } from 'react';
import { Select } from "@/components/common";
import PropTypes from "prop-types";

const getSelectValue = ({ value, label}) =>{ 
  return {
    value,
    label,
    selection: { value, label}
  }
}

const SelectControl = ({ options, label, errors, fullWidth, onChange, onEventChange, value, ...restProps }) => {

  const onSelectChange = (value, selected) => {
    onChange(selected);
    onEventChange ? onEventChange(selected) : null;
  }

  const defaultValue = useMemo(()=>{    
    if(value){
      return getSelectValue({ value: value?.value, label: value?.label})
    }    
    return undefined
  }, [value]) 

  return (
    <Select
      label={label}
      isAsync={false}
      {...{
        errors,
        fullWidth,
      }}
      isDisabled={false}
      value={defaultValue}
      defaultValue={defaultValue}
      defaultOptions={[]}
      options={options}
      isSearchable={false}
      onChange={onSelectChange}
      {...restProps}
    />
  )
}

SelectControl.propTypes = {
    options: PropTypes.object,
    label: PropTypes.string,
    errors: PropTypes.object,
    fullWidth: PropTypes.bool,
    onEventChange: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.shape(   {
        value: PropTypes.string,
        label: PropTypes.string
        })
};

export default SelectControl;