import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 512.012 512.012'
    version='1.1'
    viewBox='0 0 512.012 512.012'
    xmlSpace='preserve'
  >
    <path d='M102.694 330.764c-5.664-2.624-12.32-1.696-17.088 2.368l-64 54.72a16.016 16.016 0 00-5.6 12.16v96c0 8.832 7.168 16 16 16h64c8.832 0 16-7.168 16-16v-150.72c0-6.24-3.648-11.904-9.312-14.528zM230.694 221.004c-5.632-2.624-12.32-1.696-17.088 2.368l-64 54.72a16.016 16.016 0 00-5.6 12.16v205.76c0 8.832 7.168 16 16 16h64c8.832 0 16-7.168 16-16v-260.48c0-6.24-3.648-11.936-9.312-14.528zM358.694 111.244c-5.664-2.624-12.32-1.696-17.088 2.368l-64 54.72a16.016 16.016 0 00-5.6 12.16v315.52c0 8.832 7.168 16 16 16h64c8.832 0 16-7.168 16-16v-370.24c0-6.24-3.616-11.904-9.312-14.528zM486.694 1.484c-5.632-2.624-12.352-1.728-17.088 2.368l-64 54.72a16.016 16.016 0 00-5.6 12.16v425.28c0 8.832 7.168 16 16 16h64c8.832 0 16-7.168 16-16v-480c0-6.24-3.616-11.936-9.312-14.528z'></path>
  </svg>
)
