import styled from "styled-components";

export const CreditTerms = styled.div`
  margin-top: 25px;

  position: relative;
`;

export const TermRow = styled.div`
  display: flex;
  margin-bottom: 17px;
  justify-content: space-between;
  align-items: flex-end;

  font-size: 15.7px;
`;

export const TermsLabel = styled.div`
  text-align: left;
`;

export const TermsValue = styled.div`
  text-align: right;

  span {
    font-weight: 600;
    font-size: 1.5em;

    @media (max-width: 700px) {
      font-size: 1.2em;
    }
  }
`;