import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { useDispatch, useSelector } from "react-redux";
import {
  creditRequestSelector,
  fetchAddressData,
  fetchAvalsDetails,
  fetchCreditDataById,
  fetchPaymentDetails,
  fetchPersonalDetails,
  fetchSignDocuments,
  postSignDocument,
  setIsModalOpen,
} from "@/redux/slices/creditRequestSlice";
import { useParams } from "react-router-dom";
import { withSteps } from "@/hoc";
import { SignScreen } from "@/components";
import { Button } from "@/components/common";
import { isEmpty } from "lodash";

const SignStep = ({ isLoading, buttonText }) => {
  const {
    avals,
    legal,
    paymentDetails,
    personalDetails,
    creditTerms,
    currentAddress,
  } = useSelector(creditRequestSelector);
  const dispatch = useDispatch();
  const { requestID } = useParams();

  useEffect(() => {
    if (!paymentDetails) {
      dispatch(fetchPaymentDetails(requestID, false));
    }

    if (!personalDetails) {
      dispatch(fetchPersonalDetails(requestID, false));
    }

    if (!creditTerms) {
      dispatch(fetchCreditDataById(requestID, false));
    }

    if (!currentAddress) {
      dispatch(fetchAddressData(requestID, false));
    }

    if (isEmpty(avals)) {
      dispatch(fetchAvalsDetails(requestID, false));
    }

    if (isEmpty(legal.documents)) {
      dispatch(fetchSignDocuments(requestID));
    }
  }, []);

  return (
    <SignScreen
      signatureType={legal.signatureType}
      documents={legal.documents}
      submitAction={() => dispatch(postSignDocument(requestID))}
      formAdditionalContent={
        <Button.Text
          weight={600}
          onClick={() => dispatch(setIsModalOpen(true))}
          color="danger"
        >
          отказвам се от кредита
        </Button.Text>
      }
      {...{
        isLoading,
        buttonText,
      }}
    />
  );
};

SignStep.propTypes = {
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
};

export default compose(
  inject(({ store: { isLoading } }) => ({
    isLoading,
  })),
  observer
)(withSteps(SignStep));
