import styled from 'styled-components'
import { Text } from "@/components/common/Text/Text.styles";

export const FieldWrapper = styled.div`
  display: inline-block;

  ${({ theme, fullWidth }) => `
    @media ${theme.mediaQueries.xsOnly} {
      width: 100%;
      margin-bottom: 40px;
    }

    @media ${theme.mediaQueries.smUp} {
      width: 100%;
      margin-bottom: 30px;
    }

    @media ${theme.mediaQueries.mdUp} {
      width: ${fullWidth ? "100%" : "auto"};
      min-width: ${fullWidth ? "0" : "400px"};
    }
  `}
`;

export const FileName = styled(Text)`
  width: 200px;
  margin: 20px auto 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.formLabelColor};
`;

export const UploadContainer = styled.div`
  position: relative;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.uploadBg};
  border-radius: 8px;
  text-align: center;

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      padding: 20px;
    }

    @media ${theme.mediaQueries.smUp} {
      padding: 30px;
    }
  `}

  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  width: 110px;
  height: 70px;
  margin: 30px auto;
  border-radius: 20px;
  border-color: ${({ theme, isAccepted, isRejected, isReadyForUpload }) => {
    if (isAccepted) {
      return theme.colors.secondary;
    }
    if (isRejected) {
      return theme.colors.danger;
    }
    if (isReadyForUpload) {
      return theme.colors.formLabelColor;
    }
  }};

  & svg {
    width: 40px;
    fill: ${({ theme, isAccepted, isRejected, isReadyForUpload }) => {
      if (isAccepted) {
        return theme.colors.secondary;
      }
      if (isRejected) {
        return theme.colors.danger;
      }
      if (isReadyForUpload) {
        return theme.colors.formLabelColor;
      }
    }};
  }
`;

export const UploadButton = styled.div`
  display: inline-block;
  border: 2px solid;
  padding: 15px 36px;
  min-width: 190px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ theme, isAccepted, isRejected, isReadyForUpload }) => {
    if (isAccepted) {
      return `
        color: ${theme.colors.secondary};
        border-color: ${theme.colors.secondary};
      `;
    }
    if (isRejected) {
      return `
        color: ${theme.colors.danger};
        border-color: ${theme.colors.danger};
      `;
    }
    if (isReadyForUpload) {
      return `
        color: ${theme.colors.formLabelColor};
        border-color: ${theme.colors.formLabelColor};
      `;
    }
  }};
`;

export const UploadHeading = styled(Text)`
  color: ${({ theme }) => theme.colors.formLabelColor};
`;

export const ImageRender = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;

  img {
    display: block;
    width: 100%;
  }
`;
