import styled from "styled-components";

export const FormWrapper = styled.div`
  margin: 0;
`;

export const Form = styled.form`
  position: relative;
  background: ${({ theme, hasBackground }) =>
    hasBackground ? theme.colors.semiLight : "transparent"};
  border-radius: 15px;

  ${({ theme, hasBackground }) => `
    @media ${theme.mediaQueries.xsOnly} {
      padding: ${hasBackground ? "20px" : 0};
    }

    @media ${theme.mediaQueries.smOnly} {
      padding: ${hasBackground ? "30px" : 0};
    }

    @media ${theme.mediaQueries.mdUp} {
      padding: ${hasBackground ? "30px" : 0};
    }
  `}
`;

Form.Separator = styled.div`
  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      padding: 20px 0;
    }

    @media ${theme.mediaQueries.smUp} {
      padding: 40px 0;
    }
  `}

  ${({ disableMarginTop }) => `
    ${disableMarginTop ? "padding-top: 0 !important;" : ""}
  `}

  ${({ disableMarginBottom }) => `
    ${disableMarginBottom ? "padding-bottom: 0 !important;" : ""}
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;

  ${({ theme, size }) => `
    @media ${theme.mediaQueries.xsOnly} {
      a {
        margin-bottom: 10px;
      }

      text-align: center;
      justify-content: center;
      padding-top: ${size === "small" ? "15px" : "30px"};
    }

    @media ${theme.mediaQueries.smUp} {
      padding-top: ${size === "small" ? "15px" : "40px"};
    }
  `}
`;

export const AdditionalContent = styled.div`
  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      margin: 20px 0;
      text-align: left;
    }

    @media ${theme.mediaQueries.smUp} {
      margin: 30px 0;
    }
  `}
`;

export const FieldsWrapper = styled.div`
  position: relative;
  z-index: 3;
`;
