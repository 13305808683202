import React, { forwardRef, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { getYupError } from "@/utils";
import { useDropzone } from "react-dropzone";
import { Alert } from "@/components/common";
import { UploadIcon, TickLightIcon, CrossIcon } from "@/assets/svg";

import * as S from "./Upload.styles";

const Upload = forwardRef(({ label, fullWidth, ...restProps }, ref) => {
  let error = getYupError(restProps?.errors, restProps?.name);

  const [selectedFile, setSelectedFile] = useState({
    base64: "",
    name: "",
  });

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    multiple: false,
    onDrop: useCallback((acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedFile({
          base64: event.target.result,
          name: file.name,
        });
      };
      reader.readAsDataURL(file);
    }, []),
    ...restProps,
  });

  const hasFiles = acceptedFiles.length > 0;
  const isAccepted = hasFiles;
  const isReadyForUpload = !hasFiles && !error;
  const isRejected = !hasFiles && Boolean(error);

  return (
    <S.FieldWrapper {...{ fullWidth }}>
      <S.UploadContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <S.UploadHeading disableMargin>{label}</S.UploadHeading>
        {selectedFile.name && (
          <S.FileName weight={600}>{selectedFile.name}</S.FileName>
        )}
        <S.IconContainer
          {...{
            isAccepted,
            isRejected,
            isReadyForUpload,
          }}
        >
          {isAccepted && <TickLightIcon />}
          {isRejected && <CrossIcon />}
          {isReadyForUpload && <UploadIcon />}
        </S.IconContainer>
        <input
          type="hidden"
          value={selectedFile.base64.split(",")[1]}
          {...{
            ref,
            name: restProps?.name,
          }}
        />
        <S.UploadButton
          {...{
            isAccepted,
            isRejected,
            isReadyForUpload,
          }}
        >
          {isAccepted ? "Успешно прикачен" : "Качи"}
        </S.UploadButton>
      </S.UploadContainer>
      <Alert color="danger" hide={hasFiles} text={error} />
      {selectedFile.base64 && (
        <S.ImageRender>
          <img src={selectedFile.base64} />
        </S.ImageRender>
      )}
    </S.FieldWrapper>
  );
});

Upload.defaultProps = {
  label: "",
  accept: "image/png,image/jpeg",
  fullWidth: false,
};

Upload.propTypes = {
  label: PropTypes.string,
  accept: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default Upload;
