import React from "react";
import PropTypes from "prop-types";

import { Loader, CreditTerms } from "@/components";
import {
  TickIcon,
  StarIcon,
  CrossIcon,
  QuestionIcon,
  CongratulationIcon,
} from "@/assets/svg";
import { useRedirect, useQueryParam } from "@/hooks";
import { Text } from "@/components/common";

import * as S from "./Status.styles";

const Status = ({
  additionalContent,
  button,
  cardBg,
  headline,
  icon: Icon,
  iconBg,
  creditInstallment,
  installment,
  creditGracePeriodInstallment,
  gracePeriodInstallment,
  interestRate,
  isLoading,
  numberOfPayments,
  numberOfGracePeriodPayments,
  paymentPeriod,
  principal,
  subHeadline,
}) => (
  <S.StatusContainer>
    <S.StatusInner style={{ background: cardBg }}>
      <S.Iconbox>
        <div style={{ background: iconBg }}>
          <Icon />
        </div>
      </S.Iconbox>
      <S.Headline disableMargin>{headline}</S.Headline>
      {subHeadline && <S.SubHeadline>{subHeadline}</S.SubHeadline>}
      {additionalContent}
      <CreditTerms
        {...{
          principal,
          installment,
          gracePeriodInstallment,
          creditGracePeriodInstallment,
          creditInstallment,
          interestRate,
          paymentPeriod,
          numberOfPayments,
          numberOfGracePeriodPayments,
          isLoading,
        }}
      />
    </S.StatusInner>

    {button && !isLoading && (
      <S.StatusButton disabled={isLoading} onClick={button.onClick} weight={600}>
        {button.label}
      </S.StatusButton>
    )}
  </S.StatusContainer>
);

Status.defaultProps = {
  additionalContent: <></>,
  button: false,
  cardBg: "linear-gradient(212deg, #b7c5cd 0%, #6e8fa2 100%)",
  hasLargeIcon: true,
  headline: "",
  iconBg: "gray",
  iconColor: "secondary",
  installment: 0,
  gracePeriodInstallment: 0,
  interestRate: 0,
  isLoading: false,
  largeIcon: null,
  paymentPeriod: "",
  principal: 0,
  subHeadline: "",
};

Status.propTypes = {
  additionalContent: PropTypes.any,
  button: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  cardBg: PropTypes.string,
  hasLargeIcon: PropTypes.bool,
  headline: PropTypes.string,
  icon: PropTypes.elementType.isRequired,
  iconBg: PropTypes.string,
  iconColor: PropTypes.oneOf(["danger", "secondary"]),
  installment: PropTypes.number,
  gracePeriodInstallment: PropTypes.number,
  creditGracePeriodInstallment: PropTypes.number,
  creditInstallment: PropTypes.number,
  interestRate: PropTypes.number,
  isLoading: PropTypes.bool,
  largeIcon: PropTypes.elementType,
  numberOfPayments: PropTypes.string,
  numberOfGracePeriodPayments: PropTypes.string,
  paymentPeriod: PropTypes.string,
  principal: PropTypes.number,
  subHeadline: PropTypes.string,
};

Status.Approved = ({ onButtonClick, ...restProps }) => (
  <Status
    headline="Одобрен си!"
    icon={TickIcon}
    iconBg="#00B4E1"
    cardBg="linear-gradient(205deg, #06D2F0 0%, #00B4E1 95%)"
    button={{
      label: "Вземи своя кредит сега",
      onClick: onButtonClick,
    }}
    {...restProps}
  />
);

Status.Approved.propTypes = { onButtonClick: PropTypes.func };

Status.Better = ({ onButtonClick, ...restProps }) => (
  <Status
    headline="Ти си супер клиент!"
    subHeadline={"Твоят кредит \n е одобрен"}
    icon={StarIcon}
    iconBg="#0370AE"
    cardBg="linear-gradient(206deg, #0370AE 0%, #004B93 95%)"
    button={{
      label: "Вземи своя кредит сега",
      onClick: onButtonClick,
    }}
    {...restProps}
  />
);

Status.Better.propTypes = { onButtonClick: PropTypes.func };

Status.Counter = ({ onButtonClick, ...restProps }) => (
  <Status
    headline="Оферта!"
    subHeadline="Можем да ти дадем кредит със следните условия"
    icon={QuestionIcon}
    iconBg="#D70554"
    button={{
      label: "Вземи своя кредит сега",
      onClick: onButtonClick,
    }}
    {...restProps}
  />
);

Status.Counter.propTypes = { onButtonClick: PropTypes.func };

Status.Rejected = (props) => {
  const isMobile = useQueryParam("isMobile");
  const redirect = useRedirect();

  return (
    <Status
      headline="Не си одобрен!"
      subHeadline="За съжаление не отговаряш на изискванията ни за получаване на кредит"
      icon={CrossIcon}
      iconBg="#D70554"
      cardBg="linear-gradient(206deg, #E1437F 0%, #D70554 95%)"
      button={{
        label: "Към началната страница",
        onClick: () => isMobile
        ? window.open("cashbox://calculator", "_blank")
        : redirect("/"),
      }}
      {...props}
    />
  );
};

Status.Loading = () => (
  <Status
    headline={"Процес на \n одобрение..."}
    icon={QuestionIcon}
    button={false}
    hasLargeIcon={false}
    additionalContent={
      <div style={{ marginTop: 25 }}>
        <Loader position="relative" size="medium" />
        <div style={{ marginTop: 25 }}>
          <S.WaitingText>Моля, изчакайте...</S.WaitingText>
          <S.WaitingText>Процесът на одобрение е автоматичен, но може да се нуждае от Преглед на заявлението Ви от Наш оператор</S.WaitingText>
        </div>
      </div>
    }
  />
);

Status.Verification = () => (
    <Status
        headline={"Процес на \n проверка..."}
        icon={QuestionIcon}
        button={false}
        hasLargeIcon={false}
        additionalContent={
            <div style={{ marginTop: 25 }}>
                <Loader position="relative" size="medium" />
                <div style={{ marginTop: 25 }}>
                    <S.WaitingText>Моля, изчакайте...</S.WaitingText>
                    <S.WaitingText>Процесът на проверка е автоматичен, но може да се нуждае от Преглед на документите Ви от Наш оператор</S.WaitingText>
                </div>
            </div>
        }
    />
);

Status.Success = (props) => {
  const isMobile = useQueryParam("isMobile");
  const redirect = useRedirect();

  return (
    <Status
      headline={"Твоят кредит \n е готов!"}
      icon={CongratulationIcon}
      iconBg="#00B4E1"
      cardBg="linear-gradient(205deg, #06D2F0 0%, #00B4E1 95%)"
      button={{
        label: "Към моите кредити",
        onClick: () =>
          isMobile
            ? window.open("cashbox://my-credits", "_blank")
            : redirect("/my-credits"),
      }}
      additionalContent={
        <div style={{ marginTop: 25 }}>
          <Text style={{ fontSize: 16 }}>
            {
              "Сумата по вашия кредит е изпратена \n по посочения от теб начин"
            }
          </Text>
        </div>
      }
      {...props}
    />
  );
};

export default Status;
