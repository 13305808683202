import React from "react";
import PropTypes from "prop-types";
import { Button } from "@/components/common";

import * as S from "./SuccessMessage.styles";

const SuccessMessage = ({
  icon: Icon,
  content,
  iconSize,
  buttonText,
  buttonProps,
}) => (
  <S.Message {...{ iconSize }}>
    {Icon && <Icon />}
    {content}
    {buttonText && (
      <S.ButtonWrapper>
        <Button {...buttonProps}>{buttonText}</Button>
      </S.ButtonWrapper>
    )}
  </S.Message>
);

SuccessMessage.defaultProps = {
  icon: false,
  content: <></>,
  iconSize: "small",
  buttonText: "",
  buttonProps: {},
};

SuccessMessage.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.elementType]),
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]),
  iconSize: PropTypes.oneOf(["small", "large"]),
  buttonText: PropTypes.string,
  buttonProps: PropTypes.object,
};

export default SuccessMessage;
