import { types, flow } from 'mobx-state-tree'
import { request, HTTPmethods } from '@/utils'

export default types.model('ForgotPasswordStore', {
  email: types.maybe(types.string),
  screen: types.maybe(types.string),
})
  .actions(self => {
    return {
      resetPassword: flow(function* resetPassword(fields) {
        self.email = undefined


        try{
                  
          yield request({
            method: HTTPmethods.DELETE,
            url: '/ml/web/users/password',
            data: { ...fields },
            suppressErrors: true
          })

          self.email = fields.email

        }
        finally{
          self.screen = 'set-new-password'
        }
      }),

      setNewPassword: flow(function* setNewPassword(fields) {
        yield request({
          method: HTTPmethods.POST,
          url: '/ml/web/users/password/validation',
          data: {
            email: self.email,
            ...fields
          }
        })

        self.screen = 'done'
      }),

      resetScreen: ()=> {
        self.screen = 'reset-password'
      },

    }
  })
