import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='-69 0 512 512'
  >
    <path d='M186.914 384.336c8.285 0 15-6.719 15-15v-62.137c0-8.285-6.715-15-15-15-8.281 0-15 6.715-15 15v62.137c0 8.281 6.719 15 15 15zm0 0'></path>
    <path d='M358.828 164.531h-70.992v-73.07C287.836 41.027 242.562 0 186.914 0 131.27 0 85.996 41.027 85.996 91.46v73.071H15c-8.285 0-15 6.719-15 15v191.832C0 448.91 63.09 512 140.637 512h92.558c77.547 0 140.637-63.09 140.637-140.637V179.531c-.004-8.281-6.719-15-15.004-15zm-242.832-73.07C115.996 57.57 147.81 30 186.914 30c39.106 0 70.922 27.57 70.922 61.46v73.071h-141.84zm227.832 279.902C343.828 432.367 294.2 482 233.191 482h-92.554C79.633 482 30 432.367 30 371.363V194.531h313.828zm0 0'></path>
  </svg>
)
