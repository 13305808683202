import { createSlice, current } from "@reduxjs/toolkit";
import { HTTPmethods, request } from "@/utils";
import { urls } from "@/constants";
import { setSessionId } from "./authSlice";
import { toast } from "react-toastify";
import { analyticsEvents, logAnalyticsEvent, setAnalyticsUserId } from "@/utils/analytics";

const SLICE_NAME = "register";

const initialState = {
  hasError: false,
  isAuthenticated: false,
  tmpSMSVerificationCode: "",
  mobilePhone: "",
  fields: {
    email: {
      numberOfCorrections: -1,
      numberOfSymbols: 0,
      timeToFillInSecs: 0,
    },
    firstName: {
      numberOfCorrections: -1,
      numberOfSymbols: 0,
      timeToFillInSecs: 0,
    },
    familyName: {
      numberOfCorrections: -1,
      numberOfSymbols: 0,
      timeToFillInSecs: 0,
    },
    egn: {
      numberOfCorrections: -1,
      numberOfSymbols: 0,
      timeToFillInSecs: 0,
    },
    idCardNumber: {
      numberOfCorrections: -1,
      numberOfSymbols: 0,
      timeToFillInSecs: 0,
    },
    mobilePhone: {
      numberOfCorrections: -1,
      numberOfSymbols: 0,
      timeToFillInSecs: 0,
    }
  },
};

export const registerSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setHasError: (state, { payload }) => {
      state.error = payload;
    },

    setFieldData: (state, { payload }) => {
      const currentFields = current(state.fields);
      const thisField = currentFields[payload.name];
      const currentFocusTime = thisField.numberOfCorrections;

      const newFields = Object.assign({}, currentFields, {
        [payload.name]: {
          numberOfCorrections: currentFocusTime + 1,
          numberOfSymbols: payload.numberOfSymbols || thisField.numberOfSymbols,
          timeToFillInSecs:
            payload.timeToFillInSecs || thisField.timeToFillInSecs,
        },
      });

      state.fields = newFields;
    },

    setMobilePhone: (state, { payload }) => {
      state.mobilePhone = payload;
    },

    setTmpSMSVerificationCode: (state, { payload }) => {
      state.tmpSMSVerificationCode = payload;
    },

    cleanup: () => initialState,
  },
});

export const {
  setHasError,
  setMobilePhone,
  setTmpSMSVerificationCode,
  setFieldData,
  cleanup,
} = registerSlice.actions;

// - Selectors
export const registerSelector = (state) => state[SLICE_NAME];

// - Reducer
export default registerSlice.reducer;

// - Async Actions
export function createUser(fields, timeSpent = 0) {
  return async (dispatch) => {
    await request({
      method: HTTPmethods.POST,
      url: urls.users,
      data: {
        device: { deviceFingerprint: navigator.userAgent },
        ...fields,
        timeSpentOnRegistration: timeSpent,
      },
    })
      .then(({ data }) => {
        const { mobilePhone, tmpSMSVerificationCode } = data.userProfile;

        dispatch(setSessionId(data.sessionId));
        dispatch(setMobilePhone(mobilePhone));
        dispatch(setTmpSMSVerificationCode(tmpSMSVerificationCode));

        setAnalyticsUserId(data.userProfile.userId);
        logAnalyticsEvent(analyticsEvents.events.signUp);
        logAnalyticsEvent(analyticsEvents.events.login);

        toast.success(window.translate.t("messages.successRegister"));
      })
      .catch(() => dispatch(setHasError()));
  };
}
