import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-grid-system";

import { CreditTerms } from "@/components";
import { Headline, Button } from "@/components/common";
import { StarIcon } from "@/assets/svg";

import * as S from "./AdditionalOffers.styles";

const AdditionalOffersComponent = ({ offers, isLoading }) => {
  if (offers.length) return null;

  return (
    <S.AdditionalOffers>
      <Headline size="large" color="secondary">
        Контра оферти
      </Headline>

      <Row>
        {offers.map((offer, index) => (
          <Col lg={4} md={6} sm={6} xs={12} key={`offer-${index}`}>
            <S.Item>
              <S.Icon>
                <StarIcon />
              </S.Icon>

              <CreditTerms {...offer} />

              <S.ButtonWrapper>
                <Button disabled={isLoading} weight={600}>
                  Вземи офертата
                </Button>
              </S.ButtonWrapper>
            </S.Item>
          </Col>
        ))}
      </Row>
    </S.AdditionalOffers>
  )
}

AdditionalOffersComponent.defaultProps = {
  offers: [],
  isLoading: false,
};

AdditionalOffersComponent.propTypes = {
  offers: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default AdditionalOffersComponent;
