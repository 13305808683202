export default {
  carFuel: [
    {
      value: 'DIESEL',
      label: 'Дизел',
    },
    {
      value: 'GASOLINE',
      label: 'Бензин',
    },
    {
      value: 'LPG',
      label: 'Газ',
    },
    {
      value: 'METHANE',
      label: 'Метан',
    },
    {
      value: 'OTHER',
      label: 'Друго',
    }
  ],

  expectedAnnualAmount: [
    {
      value: 'UP_TO_10000',
      label: 'до 10 000 лв.'
    },
    {
      value: 'FROM_10000_TO_50000',
      label: 'от 10 000 лв. до 50 000 лв.'
    },
    {
      value: 'OVER_50000',
      label: 'над 50 000 лв.'
    }
  ],

  carsYearsOld: [...Array(40).keys()].map(item => {
    return {
      value: item,
      label: item
    }
  }),

  numberOfCars: [...Array(4).keys()].map(item => {
    return {
      value: item,
      label: item
    }
  }),

  paymentPeriod: [...Array(30).keys()].map(item => {
    return {
      value: item + 1,
      label: item + 1
    }
  }),

  addressOwnership: [
    {
      value: 'OWN',
      label: 'Собствен'
    },
    {
      value: 'RENT',
      label: 'Под наем'
    },
    {
      value: 'FRIEND_RELATIVE',
      label: 'Живея при приятели'
    }
  ],

  steps: {
    initialRequestStart: {
      index: 0,
      key: 'initialRequestStart',
      slug: '',
      serverKey: '',
      serverStatus: ''
    },
    initialRequest: {
      index: 1,
      key: 'initialRequest',
      slug: 'initial-request',
      serverKey: 'STEP1_CREDIT_PRODUCT',
      serverStatus: 'COLLECTING_DATA',
    },
    currentAddress: {
      index: 2,
      key: 'currentAddress',
      slug: 'current-address',
      serverKey: 'STEP2_CURRENT_ADDRESS',
      serverStatus: 'COLLECTING_DATA',
    },
    personalDetails: {
      index: 3,
      key: 'personalDetails',
      slug: 'personal-details',
      serverKey: 'STEP3_DETAILS',
      serverStatus: 'COLLECTING_DATA',
    },
    payment: {
      index: 4,
      key: 'payment',
      slug: 'payment',
      serverKey: 'STEP4_PAYMENT_AND_AVAL',
      serverStatus: 'COLLECTING_DATA',
    },
    avals: {
      index: 5,
      key: 'avals',
      slug: 'avals',
      serverKey: 'STEP5_AVALS',
      serverStatus: 'COLLECTING_DATA'
    },
    requestStatus: {
      index: 6,
      key: 'requestStatus',
      slug: 'request-status',
      serverKey: 'STEP5_AVALS',
      serverStatus: 'PENDING_SCORING'
    },
    sign: {
      index: 7,
      key: 'sign',
      slug: 'sign',
      serverKey: 'STEP6_OFFER_ACCEPTED',
      serverStatus: 'PENDING_SIGNING',
    },
    verificationStatus: {
      index: 8,
      key: 'verificationStatus',
      slug: 'verification-status',
      serverKey: 'STEP7_OFFER_SIGNED',
      serverStatus: 'PENDING_MANUAL_VERIFICATION'
    },
    signed: {
      index: 9,
      key: 'signed',
      slug: 'signed',
      serverKey: '',
      serverStatus: 'PENDING_TRANSFER'
    },
  },

  approvalStatus: {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    COUNTER_OFFERS: 'COUNTER_OFFERS',
    BETTER_OFFERS: 'BETTER_OFFERS',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
  },

  verificationStatus: {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
  },

  creditRequestStatus: {
    PENDING_SCORING: 'ОЧАКВА ОЦЕНКА',
    PENDING_MANUAL_SCORING: 'ОЧАКВА ОЦЕНКА',
    PENDING_OFFERS: 'ПРИЕМИ ОФЕРТА',
    PENDING_SIGNING: 'ОЧАКВА ПОДПИС',
    PENDING_TRANSFER: 'ПАРИЧЕН ТРАНСФЕР'
  }
}