export default {
  useDarkMode: 'useDarkMode',
  isMobile: 'isMobile',
  embed: 'embed',
  themeDark: 'dark',
  themeLight: 'light',
  signatureType: {
    advanced: 'ADVANCED_E_SIGN',
    euroTrust: 'EURO_TRUST',
    signaturePad: 'SIGNATURE_PAD'
  }
}