import styled, { css } from "styled-components";

const SelectClass = "Select";

const Sizes = {
  large: css`
    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        height: 50px;
        padding: 0 20px;
      }

      @media ${theme.mediaQueries.smUp} {
        height: 56px;
        padding: 0 25px;
      }
    `}
  `,
  small: css`
    padding: 0 15px;

    ${({ theme }) => `
      @media ${theme.mediaQueries.xsOnly} {
        height: 44px;
      }

      @media ${theme.mediaQueries.smUp} {
        height: 47px;
      }
    `}
  `,
  extraSmall: css`
    padding: 0 15px;
    height: 40px;
  `,
};

const selectStyle = css`
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  width: 100%;
  font-size: 14px;

  ${({ theme, fullWidth }) => `
    @media ${theme.mediaQueries.mdUp} {
      width: ${fullWidth ? "100%" : "auto"};
      min-width: ${fullWidth ? "0" : "390px"};
      font-size: 16px;
    }
  `}

  ${({ size }) => Sizes[size]}
`;

export const SelectWrapper = styled.div`
  display: inline-block;
  width: 100%;

  ${({ theme, fullWidth }) => `
    @media ${theme.mediaQueries.mdUp} {
      min-width: ${fullWidth ? "0" : "390px"};
      width: ${fullWidth ? "100%" : "auto"};
    }
  `}
`;

export const Select = styled.div`
  & .${SelectClass}__control {
    cursor: pointer;

    background: ${({ theme, customStyle }) => {
      if (customStyle) {
        if (customStyle === "gray" || customStyle === "grayLight") {
          return theme.colors.whiteGray;
        }
      } else {
        return theme.colors.mainBackground;
      }
    }};


    transition: none;

    border-color: ${({ theme, hasError, customStyle }) => {
      if (hasError) {
        return theme.colors.danger;
      } else if (customStyle === "grayLight") {
        return theme.colors.formSelectBorderLight;
      } else {
        return theme.colors.border;
      }
    }};

    border-style: solid;
    border-width: ${({ color }) => (color === "light" ? 0 : "3px")};

    &:hover,
    &:focus,
    &:active {
      border-color: ${({ theme }) => theme.colors.formSelectBorder};
    }

    &--is-focused {
      border-color: ${({ theme }) => theme.colors.formSelectBorder};
      box-shadow: none;

      &:hover {
        border-color: ${({ theme }) => theme.colors.formSelectBorder};
      }
    }

    &--menu-is-open {
      border-color: ${({ theme }) => theme.colors.primary};
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        border-color: ${({ theme }) => theme.colors.primary};
      }
    }

    ${selectStyle}
  }

  & .${SelectClass}__indicator-separator {
    display: none;
  }

  & .${SelectClass}__single-value, & .${SelectClass}__placeholder {
    font-size: 16px;
    padding: 2px 0;
    color: ${({ theme }) => theme.colors.silver};
  }

  & .${SelectClass}__single-value {
    color: ${({ theme }) => theme.colors.formLabelColor};
  }

  & .${SelectClass}____placeholder {
    color: ${({ theme }) => theme.colors.formLabelColor};
  }

  & .${SelectClass}__value-container {
    padding: 2px 0;
  }

  & .${SelectClass}__menu {
    border: 3px solid ${({ theme }) => theme.colors.primary};
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: none;
    padding: 0;
    margin-top: -3px;
    overflow: hidden;
    background: ${({ theme, customGraySelect }) =>
      customGraySelect
        ? theme.colors.formSelectCusomGrayBg
        : theme.colors.mainBackground};
  }

  & .${SelectClass}__option {
    cursor: pointer;
    padding: 15px 20px;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.silver};

    transition: background 0.3s ease, color 0.3s ease;

    &--is-selected {
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.light};

      &:hover {
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.light};
      }
    }

    &--is-focused {
      background: #f7f7f7;
      background: ${({ theme }) => theme.colors.blueGray};
      color: ${({ theme }) => theme.colors.formLabelColor};

      &.${SelectClass}__option--is-selected {
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.light};

        &:hover {
          color: ${({ theme }) => theme.colors.light};
        }
      }

      &:focus,
      &:active {
        background: ${({ theme }) => theme.colors.semiLight};
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const DropdownIndicator = styled.div`
  transform: rotate(-90deg);

  & svg {
    width: 26px;
    height: 26px;
    fill: ${({ theme }) => theme.colors.formSelectArrow};
  }
`;