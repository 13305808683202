import styled from "styled-components";

export const DatePickerWrapper = styled.div`
  .react-datepicker-popper[data-placement^="top"] {
    .react-datepicker {
      transform: translateY(12px);
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
    display: block;
  }

  .react-datepicker__month {
    padding: 10px;
  }

  .react-datepicker__header {
    background: #fbfbfb;
    border-bottom: 1px solid #e0e0e0;
  }

  .react-datepicker {
    border: 0;
    background: transparent;
  }

  .react-datepicker__current-month {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 18px;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 40px;

    &:focus {
      outline: none;
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${({ theme }) => theme.colors.primary};
    left: 20px;
  }

  .react-datepicker__navigation--next {
    border-left-color: ${({ theme }) => theme.colors.primary};
    right: 20px;
  }

  .react-datepicker__current-month--hasYearDropdown {
    margin-bottom: 12px;
  }

  .react-datepicker__month-container {
    border: 3px solid ${({ theme }) => theme.colors.primary};
    box-shadow: none;
    border-radius: ${({ theme }) => theme.borderRadius};
    background: ${({ theme }) => theme.colors.light};
    overflow: hidden;
  }

  .react-datepicker__day--selected {
    background: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.light};
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 14px;

    &:focus {
      outline: none;
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    background: ${({ theme }) => theme.colors.secondary};
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-top-color: ${({ theme }) => theme.colors.primary};
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    border-bottom-color: ${({ theme }) => theme.colors.primary};
  }

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    margin-bottom: -6px;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    margin-top: -6px;
  }

  .react-datepicker__year-dropdown-container {
    background: #fff;
    border-radius: 3px;
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDU1IDU2Ij4KPHBhdGggZD0iTTQ0LjU1NyAxOS40OTVjLTIuMDY5IDIuMDY5LTE2LjU1NCAxNi4wMzYtMTYuNTU0IDE2LjAzNnMtMTUuMDAyLTEzLjk2Ny0xNy4wNzEtMTYuMDM2LTMuMTA0IDMuMTA0LTMuMTA0IDMuMTA0bDIwLjE3NSAxOC42MjMgMTkuNjU4LTE4LjYyM2MwIDAtMS4wMzUtNS4xNzMtMy4xMDQtMy4xMDR6IiBmaWxsPSIjNTg1ODU4Ii8+Cjwvc3ZnPg==");
    background-position: right 10px center;
    background-repeat: no-repeat;
    cursor: pointer;
    text-overflow: "";
    padding: 6px 30px 6px 15px;
    border: 0;
    border-radius: 3px;
    font-size: 14px;
    border: 1px solid #c5c5c5;

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
