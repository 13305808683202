import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { enums } from "@/constants";
import { withSteps } from "@/hoc";
import { Status, OfferCarousel } from "@/components";
import { Button } from "@/components/common";
import { isEmpty } from "lodash";
import {
  creditRequestSelector,
  fetchAddressData,
  fetchApprovalStatus,
  fetchAvalsDetails,
  fetchCreditDataById,
  fetchPaymentDetails,
  postApproveOffer,
  setIsModalOpen
} from "@/redux/slices/creditRequestSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const RequestStatusStep = ({ isLoading }) => {
  const dispatch = useDispatch();
  const {
    offers,
    approvalStatus,
    avals,
    paymentDetails,
    creditTerms,
    currentAddress,
  } = useSelector(creditRequestSelector);

  const { requestID } = useParams();

  useEffect(() => {
    if (!avals || !avals.id) {
      dispatch(fetchAvalsDetails(requestID, false));
    }

    if (!paymentDetails) {
      dispatch(fetchPaymentDetails(requestID, false));
    }

    if (!creditTerms) {
      dispatch(fetchCreditDataById(requestID, false));
    }

    if (!currentAddress) {
      dispatch(fetchAddressData(requestID, false));
    }

    dispatch(fetchApprovalStatus(requestID));
  }, []);

  useEffect(() => {
    let getStatus = false;

    if (approvalStatus === enums.approvalStatus.PENDING) {
      getStatus = setInterval(() => {
        dispatch(fetchApprovalStatus(requestID));
      }, 10000);
    }

    return function cleanup() {
      clearInterval(getStatus);
    };
  }, [approvalStatus]);

  const firstCreditRequestOffer = offers ? offers[0] : null;

  const statusProps = {
    ...firstCreditRequestOffer,
    onButtonClick: () =>
      dispatch(postApproveOffer(requestID, firstCreditRequestOffer || "")),
    isLoading,
  };

  return (
    <>
      {isEmpty(firstCreditRequestOffer) &&
        approvalStatus === enums.approvalStatus.PENDING && <Status.Loading />}

      {approvalStatus === enums.approvalStatus.APPROVED && (
        <Status.Approved {...statusProps} />
      )}

      {approvalStatus === enums.approvalStatus.COUNTER_OFFERS && (
        <>
          <OfferCarousel
            offers={offers}
            onOfferSelected={(offer) =>
              dispatch(postApproveOffer(requestID, offer))
            }
            {...{ isLoading }}
          />
          <div style={{textAlign: 'center'}}>
            <Button.Text
            weight={600}
            onClick={() => dispatch(setIsModalOpen(true))}
            color="danger"
            >
              отказвам се от кредита
            </Button.Text>
          </div>
        </>
      )}

      {approvalStatus === enums.approvalStatus.BETTER_OFFERS && (
        <>
          <Status.Better {...statusProps} />
          <div style={{textAlign: 'center'}}>
            <Button.Text
            weight={600}
            onClick={() => dispatch(setIsModalOpen(true))}
            color="danger"
            >
              отказвам се от кредита
            </Button.Text>
          </div>
        </>
      )}

      {approvalStatus === enums.approvalStatus.REJECTED && <Status.Rejected />}

      {approvalStatus === enums.approvalStatus.EXPIRED && (
        <Status.Rejected {...statusProps} />
      )}
    </>
  );
};

RequestStatusStep.propTypes = {
  isLoading: PropTypes.bool,
  approvalStatus: PropTypes.string,
};

export default compose(
  inject(({ store: { isLoading } }) => ({
    isLoading,
  })),
  observer
)(withSteps(RequestStatusStep));
