const webRootUrl = '/ml/web';

export default {
  sessions: `${webRootUrl}/sessions`,
  clientNotifications: `${webRootUrl}/client-notifications`,
  creditRequests: `${webRootUrl}/credit-requests`,
  creditRenegotiations: `${webRootUrl}/credit-renegotiations`,
  creditTerms: `${webRootUrl}/credit-terms`,
  creditProducts: `${webRootUrl}/credit-products`,
  users: `${webRootUrl}/users`,
  cities: '/integrations/datamap/settlements/all',
  districts: '/integrations/datamap/districts/search',
  streets: '/integrations/datamap/streets/search',
  settlements: '/integrations/datamap/settlements/all',
  updateUserAddress: `${webRootUrl}/users/me/address`,
  addressCityextSmart: '/integrations/datamap/address_cityext_smart',
  addressCityById: '/integrations/datamap/address_city_by_id'
}