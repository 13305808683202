import styled, { css } from "styled-components";
import { Text } from "@/components/common/Text/Text.styles";

const Sizes = {
  label: {
    large: css`
      ${({ theme }) => `
        @media ${theme.mediaQueries.xsOnly} {
          padding: 0 20px;
        }

        @media ${theme.mediaQueries.smUp} {
          padding: 0 25px;
        }
      `}
    `,
    small: css`
      padding: 0 15px;
      min-width: 140px;
    `,
    extraSmall: css`
      padding: 0 15px;
      margin-bottom: 8px !important;
      min-width: 140px;
      font-size: 14px;
    `,
  },
  field: {
    large: css`
      ${({ theme }) => `
        @media ${theme.mediaQueries.xsOnly} {
          padding: 15px 0;
        }

        @media ${theme.mediaQueries.smUp} {
          padding: 20px 0;
        }
      `}
    `,
    small: css`
      ${({ theme }) => `
        @media ${theme.mediaQueries.xsOnly} {
          padding: 14px 0;
        }

        @media ${theme.mediaQueries.smUp} {
          padding: 16px 0;
        }
      `}
    `,
    extraSmall: css`
      padding: 14px 0;
    `,
  },
};

export const Field = styled.div`
  position: relative;

  ${({ size }) => Sizes.field[size]}

  ${({ type }) => (type === "hidden" ? "display: none;" : "")}
`;

export const FieldError = styled.div`
  margin-left: 16px;
`;

export const Label = styled(Text)`
  cursor: pointer;
  display: inline-block;
  ${({ labelSize }) => Sizes.label[labelSize]}

  color: ${({ theme, customStyle }) =>
    customStyle
      ? customStyle === "fullWhite"
        ? theme.colors.light
        : theme.colors.formLabelColor
      : theme.colors.formLabelColor};

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      margin-bottom: 8px;
    }

    @media ${theme.mediaQueries.smUp} {
      margin-bottom: 12px;
    }
  `}
`;

export const Information = styled.div`
  text-align: left;
  ${({ size }) => Sizes.label[size]}
  margin-top: 20px;
`;

export const ChildrenWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  pointer-events: none;

  svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.colors.primary};
  }
`;
