/* eslint-disable */
import { types, flow } from "mobx-state-tree";
import { request, HTTPmethods, setStorage, getStorage } from "@/utils";
import { storageKeys } from "@/constants";
import { toast } from "react-toastify";

export default types
  .model("CreditRequestStore", {
    approvalStatus: types.maybe(types.string),
    initialRequestURL: types.maybe(types.string),
    removeRequestModalIsOpen: types.maybe(types.boolean),
    prevStep: types.maybe(types.string),
    initialRequestInitialValues: types.maybe(types.frozen()),
    currentAddressInitialValues: types.maybe(types.frozen()),
    personalDetailsInitialValues: types.maybe(types.frozen()),
    paymentInitialValues: types.maybe(types.frozen()),
    avalsInitialData: types.maybe(types.frozen()),
  })
  .actions((self) => {
    return {
      setInitialRequest: flow(function* setInitialRequest(fields) {
        const requestId = self.initialRequestURL ? self.initialRequestURL : "";
        const { data } = yield request({
          method: HTTPmethods.POST,
          url: "/ml/web/credit-requests/" + requestId,
          data: { ...fields },
        });

        if (data) {
          setStorage(storageKeys.creditRequestID, data?.id);
          setStorage(storageKeys.currentStep, "currentAddress");
        }
      }),

      initialRequestEntered: flow(function* initialRequestEntered() {
        if (self.prevStep && getStorage(storageKeys.creditRequestID)) {
          const { data } = yield request({
            method: HTTPmethods.GET,
            url: `/ml/web/credit-requests/${getStorage(
              storageKeys.creditRequestID
            )}`,
            suppressErrors: true,
          });
          if (data) {
            self.initialRequestInitialValues = { ...data };
            const {
              creditProductId,
              installmentAmount,
              gracePeriodInstallmentAmount,
              numberOfPayments,
              requestedAmount,
              numberOfGracePeriodPayments
            } = data;
            const prevTerm = getStorage(storageKeys.creditTerms, {}, true);
            setStorage(
              storageKeys.creditTerms,
              {
                ...prevTerm,
                creditProductId,
                installmentAmount,
                gracePeriodInstallmentAmount,
                numberOfPayments,
                requestedAmount,
                numberOfGracePeriodPayments
              },
              true
            );
          }
        } else {
          self.initialRequestInitialValues = undefined;
        }
        self.initialRequestURL = self.prevStep
          ? getStorage(storageKeys.creditRequestID)
            ? getStorage(storageKeys.creditRequestID)
            : ""
          : "";
        self.prevStep = "";
      }),

      setCurrentAddress: flow(function* setCurrentAddress(fields) {
        yield request({
          method: HTTPmethods.POST,
          url: `/ml/web/credit-requests/${getStorage(
            storageKeys.creditRequestID
          )}/step2-current-address`,
          data: { ...fields },
        });

        setStorage(storageKeys.currentStep, "personalDetails");
        self.currentAddressInitialValues = undefined;
      }),

      currentAddressEntered: flow(function* currentAddressEntered() {
        self.prevStep = "initialRequest";
        const { data } = yield request({
          method: HTTPmethods.GET,
          url: `/ml/web/credit-requests/${getStorage(
            storageKeys.creditRequestID
          )}/step2-current-address`,
          suppressErrors: true,
        });
        if (data) {
          self.currentAddressInitialValues = { ...data };
        }
      }),

      setPersonalDetails: flow(function* setPersonalDetails(fields) {
        yield request({
          method: HTTPmethods.POST,
          url: `/ml/web/credit-requests/${getStorage(
            storageKeys.creditRequestID
          )}/step3-personal-details`,
          data: { ...fields },
        });

        setStorage(storageKeys.currentStep, "payment");
        self.currentAddressInitialValues = undefined;
      }),

      personalDetailsEntered: flow(function* personalDetailsEntered() {
        self.prevStep = "currentAddress";
        const { data } = yield request({
          method: HTTPmethods.GET,
          url: `/ml/web/credit-requests/${getStorage(
            storageKeys.creditRequestID
          )}/step3-personal-details`,
          suppressErrors: true,
        });
        if (data) {
          self.personalDetailsInitialValues = { ...data };
        }
      }),

      setPayment: flow(function* setPayment(fields) {
        yield request({
          method: HTTPmethods.POST,
          url: `/ml/web/credit-requests/${getStorage(
            storageKeys.creditRequestID
          )}/step4-payment`,
          data: { ...fields },
        });

        setStorage(storageKeys.avalType, fields.avalType);
        if (fields.avalType === 'NO_WARRANT') {
          setStorage(storageKeys.currentStep, "requestStatus");
        } else {
          setStorage(storageKeys.currentStep, "avals");
        }
      }),

      paymentEntered: flow(function* paymentEntered() {
        self.prevStep = "personalDetails";
        const { data } = yield request({
          method: HTTPmethods.GET,
          url: `/ml/web/credit-requests/${getStorage(
            storageKeys.creditRequestID
          )}/step4-payment`,
          suppressErrors: true,
        });
        if (data) {
          self.paymentInitialValues = { ...data };
        }
      }),

      setAvals: flow(function* setAvals(fields) {
        yield request({
          method: HTTPmethods.POST,
          url: `/ml/web/credit-requests/${getStorage(
            storageKeys.creditRequestID
          )}/step5-avals`,
          data: { ...fields },
        });

        setStorage(storageKeys.currentStep, "requestStatus");
      }),

      avalsEntered: flow(function* avalsEntered() {
        self.prevStep = "payment";
        const { data } = yield request({
          method: HTTPmethods.GET,
          url: `/ml/web/credit-requests/${getStorage(
            storageKeys.creditRequestID
          )}/step5-avals`,
          suppressErrors: true,
        });

        if (data) {
          self.avalsInitialData = { ...data };
        }
      }),

      setApproveOffer: flow(function* setApproveOffer(creditRequestOffer) {
        const { data } = yield request({
          method: HTTPmethods.POST,
          url: `/ml/web/credit-requests/${getStorage(
            storageKeys.creditRequestID
          )}/approve/${creditRequestOffer?.id}`,
        });

        setStorage(
          storageKeys.creditRequestSelectedOffer,
          creditRequestOffer,
          true
        );
        setStorage(storageKeys.documents, data?.documents, true);
        setStorage(storageKeys.signatureType, data?.signatureType);
        setStorage(storageKeys.currentStep, "sign");
      }),

      setSign: flow(function* setSign() {
        yield request({
          method: HTTPmethods.POST,
          url: `/ml/web/credit-requests/${getStorage(
            storageKeys.creditRequestID
          )}/sign`,
        });

        setStorage(storageKeys.currentStep, "signed");
      }),

      getApprovalStatus: flow(function* getApprovalStatus() {
        self.creditRequestOffers = [];
        self.approvalStatus = undefined;

        const { data } = yield request({
          method: HTTPmethods.GET,
          url: `/ml/web/credit-requests/${getStorage(
            storageKeys.creditRequestID
          )}/approval`,
        });

        if (data) {
          const creditOffers = data?.creditOffers;
          if (creditOffers.length) {
            setStorage(storageKeys.creditRequestOffers, creditOffers, true);
          }
          self.approvalStatus = data?.status;
        }
      }),

      setAvalCode: flow(function* setAvalCode(fields) {
        const { data } = yield request({
          method: HTTPmethods.POST,
          url: "/ml/web/private-avals",
          data: { ...fields },
        });

        if (data) {
          self.avalCodeVerified = true;
          setStorage(storageKeys.avalCreditRequestID, data?.creditRequestId);
          setStorage(storageKeys.avalDocuments, data?.documents, true);
          setStorage(storageKeys.avalSignatureType, data?.signatureType);
        }
      }),

      returnToPrevState: function returnToPrevState() {
        if (self.prevStep) {
          setStorage(storageKeys.currentStep, self.prevStep);
        } else {
          localStorage.removeItem(storageKeys.currentStep);
        }
      },

      setSignPrivateAval: flow(function* setSignPrivateAval(fields) {
        yield request({
          method: HTTPmethods.POST,
          url: `/ml/web/private-avals/${getStorage(
            storageKeys.avalCreditRequestID
          )}/sign`,
          data: { ...fields },
        });

        self.avalCodeVerified = false;
        self.avalSigned = true;
      }),

      setRemoveRequestModalIsOpen: (isOpen) => {
        self.removeRequestModalIsOpen = isOpen;
      },

      removeCreditRequest: (message) => {
        localStorage.removeItem(storageKeys.creditRequestID);
        localStorage.removeItem(storageKeys.currentStep);
        localStorage.removeItem(storageKeys.avalType);
        localStorage.removeItem(storageKeys.creditRequestOffers);
        localStorage.removeItem(storageKeys.creditRequestSelectedOffer);
        localStorage.removeItem(storageKeys.signatureType);
        localStorage.removeItem(storageKeys.documents);

        if (message) {
          toast.success(message);
        }
      },
    };
  });
