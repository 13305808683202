import { useHistory } from "react-router-dom";
import { useLocation } from "react-use";

export default () => {
  const history = useHistory();
  const { search } = useLocation();

  const redirect = (baseUrl, historyState = {}) => {
    let redirectUrl;
    const searchURI = search ? `/${search}` : '';

    if (!baseUrl) {
      redirectUrl = '/'
    } else {
      redirectUrl = `${baseUrl}${searchURI}`;
    }

    history.push(redirectUrl, historyState);
  };

  return redirect;
};