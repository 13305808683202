import * as Yup from 'yup'

export default () => Yup.object().shape({
  email: Yup.string().email(window.translate.t('validations.invalidEmail'))
    .required(window.translate.t('validations.enterEmail')),

  firstName: Yup.string()
    .required(window.translate.t('validations.enterFirstName')),

  familyName: Yup.string()
    .required(window.translate.t('validations.enterLastName')),

  egn: Yup.string()
    .length(10,window.translate.t('validations.egnLength'))
    .matches(/^[0-9]*$/,window.translate.t('validations.engOnlyNumbers'))
    .typeError(window.translate.t('validations.enterEgn'))
    .required(window.translate.t('validations.enterEgn')),

  idCardNumber: Yup.string()
    .length(9,window.translate.t('validations.idCardNumberLength'))
    .matches(/^[0-9]*$/,window.translate.t('validations.idCardNumberOnlyNumbers'))
    .typeError(window.translate.t('validations.enterIdCardNumber'))
    .required(window.translate.t('validations.enterIdCardNumber')),

  mobilePhone: Yup.string()
    .required(window.translate.t('validations.enterMobilePhone')),

  passwordFirst: Yup.string()
    .min(8, window.translate.t('validations.min8'))
    .max(50, window.translate.t('validations.max50'))
    .matches(/[a-zA-Z]/, window.translate.t('validations.atLeastOneLetter') )
    .matches(/[0-9]/, window.translate.t('validations.atLeastOneNumber'))
    .required(window.translate.t('validations.enterPassword')),

  password: Yup.string()
    .oneOf([Yup.ref('passwordFirst'), null], window.translate.t('validations.passwordsNotMatch'))
    .required(window.translate.t('validations.enterPassword')),

  base64IdCardPictureFront: Yup.string()
    .required(window.translate.t('validations.enterIdCardFront')),

  base64IdCardPictureBack: Yup.string()
    .required(window.translate.t('validations.enterIdCardBack')),

  personalDataProcessingConsent: Yup.bool()
    .oneOf([true], window.translate.t('validations.acceptForContinue')),

  termsAndConditionsConsent: Yup.bool()
    .oneOf([true], window.translate.t('validations.acceptForContinue'))
})
