import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 512.001 512.001'
    version='1.1'
    viewBox='0 0 512.001 512.001'
    xmlSpace='preserve'
  >
    <path fill='currentColor' d='M505.922 476.567L285.355 256 505.92 35.435c8.106-8.105 8.106-21.248 0-29.354-8.105-8.106-21.248-8.106-29.354 0L256.001 226.646 35.434 6.081c-8.105-8.106-21.248-8.106-29.354 0-8.106 8.105-8.106 21.248 0 29.354L226.646 256 6.08 476.567c-8.106 8.106-8.106 21.248 0 29.354 8.105 8.105 21.248 8.106 29.354 0l220.567-220.567 220.567 220.567c8.105 8.105 21.248 8.106 29.354 0s8.106-21.248 0-29.354z'></path>
  </svg>
)
