import React, { useEffect } from "react";
import { useSelector } from 'react-redux';

import { DefaultPage } from "@/layouts";
import { SuccessMessage } from "@/components";
import { Headline } from "@/components/common";
import { RegisterIcon, SuccessIcon } from "@/assets/svg";
import { useRedirect } from "@/hooks";
import { creditRequestSelector } from "@/redux/slices/creditRequestSlice";

const PrivateAvalsSigned = () => {
  const { avalRequestID, avalSigned } = useSelector(creditRequestSelector)
  
  const redirect = useRedirect();

  useEffect(() => {
    if (!avalRequestID || !avalSigned) {
      redirect("/my-credits");
    }
  }, [avalRequestID, avalSigned]);

  return (
    <DefaultPage
      head={{
        icon: RegisterIcon,
        title: "Личен поръчител",
      }}
    >
      <SuccessMessage
        content={
          <Headline disableMargin>Документите са подписани успешно!</Headline>
        }
        icon={SuccessIcon}
      />
    </DefaultPage>
  );
};

export default PrivateAvalsSigned