import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 455.838 455.838'
    version='1.1'
    viewBox='0 0 455.838 455.838'
    xmlSpace='preserve'
  >
    <path d='M454.968 160.667a4.983 4.983 0 00-.595-.719C393.884 99.46 313.461 66.147 227.919 66.147c-85.537 0-165.953 33.306-226.439 93.788A5.007 5.007 0 000 163.487c0 1.326.526 2.598 1.465 3.536l33.113 33.114a4.998 4.998 0 007.07 0c49.836-49.839 115.988-77.285 186.271-77.285 70.282 0 136.434 27.447 186.271 77.284a4.997 4.997 0 007.069 0l33.113-33.114a5 5 0 00.596-6.355z'></path>
    <path d='M227.919 153.719c-62.056 0-120.461 24.231-164.458 68.229a5 5 0 00.001 7.071l33.01 33.009a4.999 4.999 0 007.07 0c33.277-33.277 77.448-51.605 124.377-51.605 46.931 0 91.102 18.328 124.376 51.605a5.001 5.001 0 007.071 0l33.011-33.009a5 5 0 000-7.072c-43.997-43.996-102.402-68.228-164.458-68.228z'></path>
    <path d='M227.919 241.292c-38.701 0-75.126 15.11-102.564 42.549a4.996 4.996 0 00-1.465 3.537c0 1.326.525 2.598 1.465 3.535l33.01 33.01a4.999 4.999 0 007.07 0c16.719-16.719 38.909-25.926 62.484-25.926s45.767 9.209 62.485 25.926a4.998 4.998 0 007.069 0l33.01-33.01a5 5 0 000-7.07c-27.437-27.441-63.862-42.551-102.564-42.551zM227.919 334.083c-13.521 0-26.229 5.264-35.784 14.822a4.999 4.999 0 00.001 7.07l32.248 32.25a4.999 4.999 0 007.07 0l32.248-32.25a5.001 5.001 0 000-7.071c-9.556-9.557-22.264-14.821-35.783-14.821z'></path>
  </svg>
)
