import * as Yup from 'yup'

export default () => Yup.object().shape({
  username: Yup.string().email(window.translate.t('validations.invalidEmail'))
    .required(window.translate.t('validations.enterEmail')),
  password: Yup.string()
    .min(4, window.translate.t('validations.min4'))
    .max(50, window.translate.t('validations.max50'))
    .required(window.translate.t('validations.enterPassword')),
})
