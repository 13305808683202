import React from "react";
import PropTypes from "prop-types";
import { Button, Headline } from "@/components/common";
import { Loader } from "@/components";

import * as S from "./Form.styles";

const FormComponent = ({
  buttonText,
  backText,
  buttonProps,
  children,
  onSubmit,
  onBack,
  hasBackground,
  additionalContent,
  isLoading,
  loaderFixedCircle,
  loaderSize,
  loaderBackground,
  size,
  ...restProps
}) => (
  <S.FormWrapper>
    <S.Form {...{ hasBackground }} {...restProps}>
      <S.FieldsWrapper>{children}</S.FieldsWrapper>
      <S.ButtonWrapper {...{ size }}>
        {backText && (
          <Button {...{ size }} onClick={onBack} {...buttonProps}>
            {backText}
          </Button>
        )}
        <Button {...{ size }} onClick={onSubmit} {...buttonProps}>
          {buttonText}
        </Button>
      </S.ButtonWrapper>
      {additionalContent && (
        <S.AdditionalContent>{additionalContent}</S.AdditionalContent>
      )}
      <Loader
        {...{
          isLoading,
          fixedCircle: loaderFixedCircle,
          size: loaderSize,
          background: loaderBackground,
        }}
      />
    </S.Form>
  </S.FormWrapper>
);

FormComponent.defaultProps = {
  buttonText: "",
  backText: "",
  buttonProps: {},
  onSubmit: () => {},
  onBack: undefined,
  hasBackground: false,
  additionalContent: "",
  children: <></>,
  isLoading: false,
  loaderFixedCircle: true,
  loaderSize: "large",
  loaderBackground: "light",
  size: "medium",
};

FormComponent.propTypes = {
  buttonText: PropTypes.string,
  backText: PropTypes.string,
  buttonProps: PropTypes.object,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  hasBackground: PropTypes.bool,
  additionalContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
    PropTypes.node,
  ]),
  isLoading: PropTypes.bool,
  loaderFixedCircle: PropTypes.bool,
  loaderSize: PropTypes.oneOf(["small", "large"]),
  loaderBackground: PropTypes.oneOf(["light", "semiLight", "concrete"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

FormComponent.ConditionalFields = ({ isShown, children }) => (
  <div
    style={{
      width: "100%",
      display: isShown ? "block" : "none",
    }}
  >
    {children}
  </div>
);

FormComponent.ConditionalFields.defaultProps = {
  isShown: true,
  children: <></>,
};

FormComponent.ConditionalFields.propTypes = {
  isShown: PropTypes.bool,
  children: PropTypes.any,
};

FormComponent.Separator = ({
  title,
  titleProps,
  children,
  disableMarginTop,
  disableMarginBottom,
}) => (
  <S.Form.Separator
    {...{
      disableMarginTop,
      disableMarginBottom,
    }}
  >
    {title && <Headline {...titleProps}>{title}</Headline>}
    {children}
  </S.Form.Separator>
);

FormComponent.Separator.defaultProps = {
  title: "",
  titleProps: {},
  children: <></>,
  disableMarginTop: false,
  disableMarginBottom: false,
};

FormComponent.Separator.propTypes = {
  title: PropTypes.string,
  titleProps: PropTypes.object,
  children: PropTypes.any,
  disableMarginTop: PropTypes.bool,
  disableMarginBottom: PropTypes.bool,
};

export default FormComponent;
