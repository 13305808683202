import { types, flow, getParent } from 'mobx-state-tree'
import { request, HTTPmethods } from '@/utils'
import { toast } from 'react-toastify'

export default types.model('RegistrationStore', {
  mobilePhone: types.maybe(types.string),
})
  .actions(self => {
    const { authenticationState } = getParent(self)

    return {
      createUser: flow(function* createUser (fields) {
        const { data } = yield request({
          method: HTTPmethods.POST,
          url: '/ml/web/users',
          data: {
            device: { deviceFingerprint: navigator.userAgent },
            ...fields
          }
        })

        const { sessionId, userProfile } = data
        if (sessionId) {
          self.mobilePhone = userProfile?.mobilePhone
          toast.success(window.translate.t('messages.successRegister'))
          authenticationState.setToken(sessionId)
        }
      }),
    }
  })
