import styled from "styled-components";

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  align-content: center;
  background: ${({ theme }) => theme.colors.footerBg};

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      text-align: center;
      padding: 20px 0;
    }

    @media ${theme.mediaQueries.smUp} {
      height: 50px;
    }
  `}
`;

export const Content = styled.div`
  width: 100%;
`;

export const Copyright = styled.p`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.light};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
`;