import React from "react";
import PropTypes from "prop-types";
import { icon } from "leaflet";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { debounce } from "lodash";
import markerPNG from "@/assets/images/marker.png";

import * as S from "./Map.styles";

const MapComponent = ({ position, draggable, onDragMarker, zoom }) => (
  <S.MapWrapper>
    <MapContainer center={[position.lat, position.lng]} zoom={zoom}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker
        icon={icon({
          iconUrl: markerPNG,
          iconSize: [40, 53],
          iconAnchor: [40, 53],
          popupAnchor: [-20, -60],
        })}
        ondrag={debounce((ev) => onDragMarker(ev.latlng), 400)}
        position={[position.lat, position.lng]}
        {...{ draggable }}
      ></Marker>
    </MapContainer>
  </S.MapWrapper>
);

MapComponent.defaultProps = { zoom: 14 };

MapComponent.propTypes = {
  zoom: PropTypes.number,
  onDragMarker: PropTypes.func,
  draggable: PropTypes.bool,
  position: PropTypes.object.isRequired,
};

export default MapComponent;
