import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Container } from "react-grid-system";

export const Header = styled.div`
  position: fixed;
  background: ${({ theme }) => theme.colors.mainBackground};
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.xsOnly} {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  }
`;

export const Logo = styled(Link)`
  display: inline-block;

  & svg {
    width: 100%;
    display: block;
  }

  ${({ theme }) => `
  @media ${theme.mediaQueries.xsOnly} {
    width: 160px;
    margin: 0 auto;
    display: block;
  }

  @media ${theme.mediaQueries.smOnly} {
    width: 180px;
  }

  @media ${theme.mediaQueries.mdUp} {
    width: 200px;
  }
`}
`;

export const Content = styled.div`
  width: 100%;
`;

export const MiddleContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TopBar = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  height: 34px;

  & ul {
    li {
      a {
        &::after {
          content: ">";
          margin-left: 8px;
        }
      }
    }
  }
`;

export const MiddleBar = styled.div`
  display: flex;
  align-items: center;
  align-content: "center";
  justify-content: "space-between";

  ${({ theme }) => css`
    a {
      color: ${theme.colors.mainMenuLinks}

      @media ${theme.mediaQueries.xsOnly} {
        color: ${theme.colors.white};
      }
    }

    @media ${theme.mediaQueries.xsOnly} {
      height: 60px;
    }

    @media ${theme.mediaQueries.smUp} {
      height: 80px;
    }
  `}
`;

export const MiddleBarWrapper = styled(Container)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.mainTabBg};

  ${({ theme }) => css`
    @media ${theme.mediaQueries.xsOnly} {
      background: ${theme.colors.primary};
    }
  `}
`;
