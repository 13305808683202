import React from "react";
import PropTypes from "prop-types";

import * as S from "./FieldGroup.styles";

const FieldGroup = ({
  label,
  labelFontWeight,
  children,
  orientation,
  ...restProps
}) => (
  <S.Group
    {...{
      orientation,
      ...restProps,
    }}
  >
    {label && <S.Label weight={labelFontWeight}>{label}</S.Label>}
    {children}
  </S.Group>
);

FieldGroup.defaultProps = {
  label: "",
  labelFontWeight: 400,
  orientation: "vertical",
  children: <></>,
};

FieldGroup.propTypes = {
  label: PropTypes.string,
  labelFontWeight: PropTypes.oneOf([300, 400, 600]),
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  children: PropTypes.any,
};

export default FieldGroup;
