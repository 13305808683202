import * as Yup from 'yup'
import valid from 'card-validator'
import IBAN from 'iban'

export default ()=> Yup.object().shape({
  avalType: Yup.string().typeError(window.translate.t('validations.chooseAvalType')).required(window.translate.t('validations.chooseAvalType')),
  paymentType: Yup.string().typeError(window.translate.t('validations.choosePaymentType')).required(window.translate.t('validations.choosePaymentType')),

  cardNumber: Yup.mixed().when('paymentType', (paymentType, schema) => (paymentType === 'CREDIT_OR_DEBIT_CARD' ?
    Yup.string().test('test-card-number', window.translate.t('validations.invalidCardNumber'), value=>valid.number(value).isValid )

      .typeError(window.translate.t('validations.enterCardNumber'))
      .required(window.translate.t('validations.enterCardNumber')) : schema)),

  iban: Yup.mixed().when('paymentType', (paymentType, schema) => ((paymentType === 'REVOLUTE' || paymentType === 'BANK_TRANSFER') ?
    Yup.string().test('test-IBAN-isValid', window.translate.t('validations.invalidIban'), value=> IBAN.isValid(value))
      .test('test-IBAN-isBG', window.translate.t('validations.invalidIbanNotBG'), value=>value.substring(0,2)==='BG')
      .typeError(window.translate.t('validations.enterIban'))
      .required(window.translate.t('validations.enterIban')) : schema)),
})
