import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@/components/common'

import * as S from './List.styles';

const ListComponent = ({ items })=> (
  <S.List>
    {items.map((item, index)=> (
      <S.Item key={`${item}-${index}`}>
        <Text>
          {item}
        </Text>
      </S.Item>
    ))}
  </S.List>
)

ListComponent.propTypes = { items: PropTypes.array.isRequired, }

export default ListComponent
