import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Container } from "react-grid-system";
import { Footer } from "@/components";
import { Headline } from "@/components/common";
import { useQueryParam } from "@/hooks";

const contentSpacing = {
  xs: "15px",
  sm: "53px",
  md: "53px",
  lg: "53px",
  xl: "53px",
};

const gutterStyled = css`
  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      padding-left: 35px;
      padding-right: 35px;
    }

    @media ${theme.mediaQueries.smUp} {
      padding-left: 38px;
      padding-right: 38px;
    }
  `}
`;

const DefaultLayout = styled.div`
  ${({ theme }) => `

 

    @media ${theme.mediaQueries.xsOnly} {
      margin-top: ${contentSpacing.xs};
    }

    @media ${theme.mediaQueries.smUp} {
      margin-top: ${contentSpacing.sm};
    }

    @media ${theme.mediaQueries.mdUp} {
      margin-top: ${contentSpacing.md};
    }

    @media ${theme.mediaQueries.lgUp} {
      margin-top: ${contentSpacing.lg};
    }

    @media ${theme.mediaQueries.xlUp} {
      margin-top: ${contentSpacing.xl};
    }
  `}
`;

const Content = styled.div`
  ${({ theme, disableContentGutter }) => `
    @media ${theme.mediaQueries.xsOnly} {
      padding-bottom: 30px;
      min-height: ${`calc(100vh - ${theme.header.height.xs} - ${contentSpacing.xs})`};
    }

    @media ${theme.mediaQueries.smUp} {
      padding-bottom: 40px;
      min-height: ${`calc(100vh - ${theme.header.height.sm} - ${contentSpacing.sm})`};

      ${!disableContentGutter ? gutterStyled : ""}
    }

    @media ${theme.mediaQueries.mdUp} {
      padding-bottom: 50px;
      min-height: ${`calc(100vh - ${theme.header.height.md} - ${contentSpacing.md})`};
    }

    @media ${theme.mediaQueries.lgUp} {
      min-height: ${`calc(100vh - ${theme.header.height.lg} - ${contentSpacing.lg})`};
    }

    @media ${theme.mediaQueries.xlUp} {
      min-height: ${`calc(100vh - ${theme.header.height.xl} - ${contentSpacing.xl})`};
    }
  `}
`;

const Head = styled.div`
  ${({ theme }) => `


    @media ${theme.mediaQueries.xsOnly} {
      margin-bottom: 15px;
    }

    @media ${theme.mediaQueries.smUp} {
      margin-bottom: 15px;
      width: ${({ fullWidth }) => (fullWidth ? "100%" : "85%")};
    }
  `}
`;

const HeadTopContent = styled.div`
  width: 100%;

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      display: block;
    }

    @media ${theme.mediaQueries.smUp} {
      display: flex;
      justify-content: space-between;
    }
  `}
`;

const SubHeadline = styled.div`
  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      margin-top: 5px;
    }

    @media ${theme.mediaQueries.smUp} {
      margin-top: 15px;
    }
  `}
`;

const RightContent = styled.div`
  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      margin-top: 30px;
    }
  `}
`;

const StyledHeadline = styled.div`
  position: relative;

  ${gutterStyled}

  & svg {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    fill: ${({ theme }) => theme.colors.mainHeadline};
  }

  ${({ theme }) => `

  
    @media ${theme.mediaQueries.xsOnly} {
      & svg {
        width: 22px;
        height: 22px;
      }
    }

    @media ${theme.mediaQueries.smUp} {
      & svg {
        width: 24px;
        height: 24px;
      }
    }
  `}
`;

const DefaultPage = ({ children, head, disableContentGutter }) => {
  const isMobile = useQueryParam("isMobile");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { title, subTitle, rightContent, fullWidth, icon: Icon } = head;

  return (
    <DefaultLayout>
      <Container>
        {(title || rightContent) && (
          <Head {...{ fullWidth }}>
            <HeadTopContent>
              <StyledHeadline>
                {Icon && <Icon />}
                <Headline disableMargin as="h2">
                  {title}
                </Headline>
                {subTitle && (
                  <SubHeadline>
                    <Headline
                      disableMargin
                      as="h6"
                      size="small"
                      color="secondary"
                    >
                      {subTitle}
                    </Headline>
                  </SubHeadline>
                )}
              </StyledHeadline>
              {rightContent && <RightContent>{rightContent}</RightContent>}
            </HeadTopContent>
          </Head>
        )}
        <Content {...{ disableContentGutter }}>{children}</Content>
      </Container>
      {!isMobile && <Footer />}
    </DefaultLayout>
  );
};

DefaultPage.defaultProps = {
  children: <></>,
  head: {
    title: "",
    subTitle: "",
    icon: false,
    rightContent: false,
    fullWidth: false,
  },
  disableContentGutter: false,
};

DefaultPage.propTypes = {
  children: PropTypes.node,
  head: PropTypes.object,
  disableContentGutter: PropTypes.bool,
};

export default DefaultPage;
