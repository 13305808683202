import React from "react";

export default () => (
  <svg
    width="444"
    height="444"
    viewBox="0 0 444 444"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <circle cx="225.5" cy="32.5" r="32.5" fill="currentColor" />
      <circle cx="225.5" cy="222.5" r="32.5" fill="currentColor" />
      <circle cx="225.5" cy="411.5" r="32.5" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="443.52" height="443.52" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
