/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { backEndTranslation, getStorage } from "@/utils";
import { Loader } from "@/components";
import {
  RangeSlider,
  Select,
  Check,
} from "@/components/common";

import * as S from './Calculator.styles'
import { useDispatch, useSelector } from "react-redux";
import { creditSelector, getCreditProducts, getCreditTerms } from "@/redux/slices/creditSlice";
import { storageKeys } from "@/constants";


const valueRender = (value = "", additionalSymbol = "") =>
  value ? (
    `${value} ${additionalSymbol}`
  ) : (
    <S.ValueLoader>
      <span />
      <span />
      <span />
    </S.ValueLoader>
  );

const CalculatorComponent = ({ onGetCreditClick, hideByInstallment }) => {
  const config = {
    currency: "лв.",
    minNumberOfPayment: 1,
    minInstallment: 50,
    maxNumberOfGracePeriods: 0,
    terms: [
      {
        slug: "by-periods",
        label: "По вноска",
      },
      {
        slug: "by-installment",
        label: "По сума за връщане",
      },
    ]
  };

  const localStorageTerms = getStorage(storageKeys.creditTerms) ? JSON.parse(getStorage(storageKeys.creditTerms)) : null;

  const [selectedTerm, setSelectedTerm] = useState(config.terms[0].slug);
  const [selectedProductGroup, setSelectedProductGroup] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [creditAmount, setCreditAmount] = useState(0);
  const [creditNumberOfPayments, setCreditNumberOfPayments] = useState(localStorageTerms ? localStorageTerms.numberOfPayments : config.minNumberOfPayment);
  const [creditNumberOfGracePeriodPayments, setCreditNumberOfGracePeriodPayments] = useState(localStorageTerms ? localStorageTerms.numberOfGracePeriods : config.maxNumberOfGracePeriods);
  const [creditInstallment, setCreditInstallment] = useState(localStorageTerms ? localStorageTerms.installmentAmount : config.minInstallment);

  const isByPeriods = selectedTerm === "by-periods";
  const isByInstallment = selectedTerm === "by-installment";

  const dispatch = useDispatch();
  const { creditProducts, creditTerms } = useSelector(creditSelector);

  const onChangeSelectedProduct = (selectedProduct) => {
    if (selectedProduct) {
      if (selectedProduct.maxGracePeriod > 0) setSelectedTerm(config.terms[0].slug)
      if (!creditAmount) {
        const amount = localStorageTerms ? localStorageTerms.requestedAmount : selectedProduct.minCreditAmount
        const numberOfPayments = localStorageTerms ? localStorageTerms.numberOfPayments : selectedProduct.minNumberOfPayments
        const numberOfGracePeriodPayments = localStorageTerms ? localStorageTerms.numberOfGracePeriods : 0
        const installment = localStorageTerms ? localStorageTerms.installmentAmount : selectedProduct.minInstallmentAmounts[amount.toFixed(2).toString()]
        setCreditNumberOfPayments(numberOfPayments)
        setCreditNumberOfGracePeriodPayments(numberOfGracePeriodPayments)
        setCreditInstallment(installment)
        setCreditAmount(amount)
      } else {
        if (selectedTerm === "by-installment") {
          if (creditAmount > selectedProduct.maxCreditAmount || creditAmount < selectedProduct.minCreditAmount) {
            setCreditAmount(selectedProduct.minCreditAmount)
            setCreditInstallment(selectedProduct.minInstallmentAmounts[selectedProduct.minCreditAmount.toFixed(2).toString()])
          } else {
            setCreditInstallment(selectedProduct.minInstallmentAmounts[creditAmount.toFixed(2).toString()])
          }
        }
        setCreditNumberOfGracePeriodPayments(0)
      }
    }
    setSelectedProduct(selectedProduct)
  }

  useEffect(() => {
    dispatch(getCreditProducts())
  }, []);

  useEffect(() => {
    if (creditProducts.length>0) {
      setSelectedProductGroup(creditProducts[0])
    }
  }, [creditProducts])

  useEffect(() => {
    if (selectedProductGroup) {
      onChangeSelectedProduct(selectedProductGroup?.creditProducts[0]);
    }
  }, [selectedProductGroup]);

  useEffect(() => {
    if (creditAmount && creditNumberOfPayments && creditInstallment) {
      dispatch(getCreditTerms(
        {
          principal: creditAmount,
          interestRate: selectedProduct?.interestRate,
          paymentPeriod: selectedProduct?.paymentPeriod,
          periods: creditNumberOfPayments,
          gracePeriods: creditNumberOfGracePeriodPayments,
          maxInstallment: creditInstallment,
          guarantorFeePercent: selectedProduct.guarantorFeePercent,
          guarantorDepreciation: selectedProduct.guarantorDepreciation,
          gracePeriodHasGuarantorFee: selectedProduct.gracePeriodHasGuarantorFee ?? false,
        },
        selectedTerm
      ));
    }
  }, [
    creditAmount,
    creditNumberOfPayments,
    creditNumberOfGracePeriodPayments,
    creditInstallment,
    selectedTerm,
    selectedProduct,
  ]);

  const creditProductsMemo = useMemo(() => {
    return creditProducts.map((product) => {
      return {
        value: product.id,
        label: product.name,
      };
    })
  }, [creditProducts])

  return (
    <S.Calculator>
      <S.Switch>
          <S.Switch.Item
            key={`${config.terms[0].slug}-${config.terms[0].label}`}
            active={selectedTerm === config.terms[0].slug}
            onClick={() => setSelectedTerm(config.terms[0].slug)}
          >
            {config.terms[0].label}
          </S.Switch.Item>
        {!(hideByInstallment || (selectedProduct && selectedProduct.maxGracePeriod > 0)) && (
            <S.Switch.Item
              key={`${config.terms[1].slug}-${config.terms[1].label}`}
              active={selectedTerm === config.terms[1].slug}
              onClick={() => setSelectedTerm(config.terms[1].slug)}
            >
              {config.terms[1].label}
            </S.Switch.Item>
        )}
      </S.Switch>

      <S.Content>
        {selectedProductGroup ? (
          <>
            <S.CalculatorForm>
              <S.CreditPackage>
                <Select
                  label="Избери продукт"
                  customStyle="grayLight"
                  fullWidth
                  color="formLabelColor"
                  size="extraSmall"
                  isAsync={false}
                  cacheOptions={false}
                  defaultOptions
                  value={{
                    value: selectedProductGroup.id,
                    label: selectedProductGroup.name,
                  }}
                  options={creditProductsMemo}
                  onChange={(product) =>
                    setSelectedProductGroup(
                      creditProducts.find(
                        (creditProduct) => creditProduct.id === product
                      ) || null
                    )
                  }
                />
              </S.CreditPackage>

              {(selectedProduct && creditAmount) && (
                <>
                  <RangeSlider
                    min={selectedProduct.minCreditAmount}
                    max={selectedProduct.maxCreditAmount}
                    incrementStep={selectedProduct.amountIncrementStep}
                    defaultValue={creditAmount}
                    label="сума на кредита"
                    valueLabel={config.currency}
                    onChange={debounce(
                      (amount) => setCreditAmount(amount),
                      400
                    )}
                  />

                  {isByPeriods && (
                    <RangeSlider
                      min={selectedProduct.minNumberOfPayments}
                      max={selectedProduct.maxNumberOfPayments}
                      incrementStep={1}
                      defaultValue={creditNumberOfPayments}
                      label="брой вноски"
                      valueLabel={
                        creditNumberOfPayments > 1 ? "вноски" : "вноска"
                      }
                      onChange={debounce(
                        (paymentNumber) => {
                          setCreditNumberOfPayments(paymentNumber)
                          if (paymentNumber < creditNumberOfGracePeriodPayments) setCreditNumberOfGracePeriodPayments(paymentNumber)
                        },
                        400
                      )}
                    />
                  )}

                  {isByPeriods && selectedProduct.maxGracePeriod > 0 && (
                      <RangeSlider
                          min={0}
                          max={creditNumberOfPayments > selectedProduct.maxGracePeriod ? selectedProduct.maxGracePeriod : creditNumberOfPayments - 1}
                          incrementStep={1}
                          defaultValue={creditNumberOfGracePeriodPayments}
                          label="брой гратисни периоди"
                          valueLabel={
                            creditNumberOfGracePeriodPayments > 1 ? "периоди" : "периода"
                          }
                          onChange={debounce(
                              (paymentNumber) => setCreditNumberOfGracePeriodPayments(paymentNumber),
                              400
                          )}
                      />
                  )}

                  {isByInstallment && (
                    <RangeSlider
                      min={selectedProduct.minInstallmentAmounts[creditAmount.toFixed(2).toString()]}
                      max={selectedProduct.maxInstallmentAmounts[creditAmount.toFixed(2).toString()]}
                      incrementStep={10}
                      defaultValue={creditInstallment}
                      label="Желана вноска"
                      valueLabel={config.currency}
                      onChange={debounce(
                        (installment) => setCreditInstallment(installment),
                        400
                      )}
                    />
                  )}
                </>
              )}

              <S.CreditPeriods orientation="horizontal">
                {selectedProductGroup.creditProducts.map(
                  ({ paymentPeriod }) => (
                    <Check
                      key={paymentPeriod}
                      checked={paymentPeriod === selectedProduct?.paymentPeriod}
                      type="radio"
                      name="paymentPeriod"
                      value={paymentPeriod}
                      onChange={(ev) => onChangeSelectedProduct(
                          selectedProductGroup.creditProducts.find((creditProduct) => creditProduct.paymentPeriod === ev.currentTarget.value) || null
                      )}
                    >
                      {backEndTranslation(paymentPeriod)}
                    </Check>
                  )
                )}
              </S.CreditPeriods>
            </S.CalculatorForm>

            <S.CalcCredit>
              <S.CalculatorTitle color="light">
                Това е твоят кредит
              </S.CalculatorTitle>


              {creditNumberOfGracePeriodPayments > 0 && (
                  <>
                  <S.AmountItem isInstallment>
                    <S.AmountDesc weight={600} color="light">
                      Вноска гратисен период
                    </S.AmountDesc>
                    <S.Amount isInstallment weight={600} color="light">
                      {valueRender(creditTerms?.gracePeriodInstallment, config.currency)}
                    </S.Amount>
                  </S.AmountItem>

                  <S.AmountItem isSmall>
                  <S.AmountDesc isSmall weight={600} color="light">
                  С ГАРАНТ
                  </S.AmountDesc>
                  <S.Amount isSmall weight={600} color="light">
                    {valueRender(creditTerms?.gracePeriodInstallmentWithAvalCompany, config.currency)}
                  </S.Amount>
                  </S.AmountItem>
                  </>
              )}

              <S.AmountItem isInstallment>
                <S.AmountDesc weight={600} color="light">
                  {isByPeriods ? "Вноска" : "Точна вноска"}
                </S.AmountDesc>
                <S.Amount isInstallment weight={600} color="light">
                  {valueRender(creditTerms?.installment, config.currency)}
                </S.Amount>
              </S.AmountItem>

              <S.AmountItem isSmall>
                <S.AmountDesc isSmall weight={600} color="light">
                  С ГАРАНТ
                </S.AmountDesc>
                <S.Amount isSmall weight={600} color="light">
                  {valueRender(
                    creditTerms?.installmentWithAvalCompany,
                    config.currency
                  )}
                </S.Amount>
              </S.AmountItem>

              <S.AmountItem>
                <S.AmountDesc weight={600} color="light">
                  Сума за връщане
                </S.AmountDesc>
                <S.Amount isInstallment weight={600} color="light">
                  {valueRender(creditTerms?.creditAmount, config.currency)}
                </S.Amount>
              </S.AmountItem>

              <S.AmountItem isSmall>
                <S.AmountDesc isSmall weight={600} color="light">
                  С ГАРАНТ
                </S.AmountDesc>
                <S.Amount isSmall weight={600} color="light">
                  {valueRender(
                    creditTerms?.creditAmountWithAvalCompany,
                    config.currency
                  )}
                </S.Amount>
              </S.AmountItem>

              <S.AmountItem isSmall>
                <S.AmountDesc isSmall weight={600} color="light">
                  Лихвен процент
                </S.AmountDesc>
                <S.Amount isSmall weight={600} color="light">
                  {valueRender(creditTerms?.interestRate * 100, "%")}
                </S.Amount>
              </S.AmountItem>

              <S.AmountItem>
                <S.AmountDesc isSmall weight={600} color="light">
                  Брой вноски
                </S.AmountDesc>
                <S.Amount isSmall weight={600} color="light">
                  {valueRender(creditTerms?.periods)}
                </S.Amount>
              </S.AmountItem>

              {creditTerms?.gracePeriods > 0 && (
                <S.AmountItem>
                <S.AmountDesc isSmall weight={600} color="light">
                  Брой гратисни периоди
                </S.AmountDesc>
                <S.Amount isSmall weight={600} color="light">
                  {valueRender(creditTerms?.gracePeriods)}
                </S.Amount>
                </S.AmountItem>
              )}
            </S.CalcCredit>

            <S.BottomContent>
              <S.GetCreditButton
                weight={600}
                onClick={() =>
                  onGetCreditClick({
                    creditProductGroupId: selectedProductGroup.id,
                    creditProductId: selectedProduct.id,
                    creditProductName: selectedProductGroup.name,
                    installmentAmount: creditTerms.installment,
                    gracePeriodInstallmentAmount: creditTerms.gracePeriodInstallment,
                    numberOfPayments: creditTerms.periods,
                    numberOfGracePeriodPayments: creditTerms.gracePeriods,
                    requestedAmount: creditTerms?.principal,
                    creditAmount: creditTerms?.creditAmount,
                    paymentPeriod: selectedProduct?.paymentPeriod,
                    creditAmountWithAvalCompany: creditTerms?.creditAmountWithAvalCompany,
                    installmentWithAvalCompany: creditTerms?.installmentWithAvalCompany,
                    gracePeriodInstallmentWithAvalCompany: creditTerms?.gracePeriodInstallmentWithAvalCompany,
                    interestRate: creditTerms?.interestRate,
                  })
                }
                size="large"
              >
                Вземи своя кредит
              </S.GetCreditButton>
            </S.BottomContent>
          </>
        ) : (
          <Loader size="small" background="semiLight" />
        )}
      </S.Content>
    </S.Calculator>
  );
};

CalculatorComponent.propTypes = {
  onGetCreditClick: PropTypes.func,
  hideByInstallment: PropTypes.bool
};

export default CalculatorComponent
