import styled from "styled-components";
import { Text } from "@/components/common";

export const CustomTooltip = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 350px;
  text-align: left;

  & svg {
    display: block;
    width: 40px;
    height: 40px;
    transform: rotate(180deg);
    fill: ${({ theme, color }) => theme.colors[color]};
  }
`;

export const ToolTipText = styled(Text)`
  width: calc(100% - 40px);
  padding-left: 10px;
`;
