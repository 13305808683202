import React from "react";
import { FacebookIcon, TwitterIcon, InstagramIcon } from "@/assets/svg";

import * as S from "./Social.styles";

const Networks = [
  {
    icon: FacebookIcon,
    href: "http://facebook.com",
  },
  {
    icon: InstagramIcon,
    href: "http://instagram.com",
  },
  {
    icon: TwitterIcon,
    href: "http://twitter.com",
  },
];

const SocialComponent = () => (
  <S.Social>
    {Networks.map(({ icon: Icon, href }, index) => (
      <li key={`${href}-${index}`}>
        <a href={href} target="_blank" rel="noopener noreferrer">
          <Icon />
        </a>
      </li>
    ))}
  </S.Social>
);

export default SocialComponent;
