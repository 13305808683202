import styled from "styled-components";
import { Text } from "@/components/common/Text/Text.styles";

export const Box = styled.div`
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      margin-bottom: 20px;
    }

    @media ${theme.mediaQueries.smUp} {
      margin-bottom: 25px;
    }
  `}
`;

export const BoxContent = styled.div`
  background: ${({ theme, background }) =>
    background ? theme.colors[background] : theme.colors.calculatorBg};

  ${({ theme }) => `
  @media ${theme.mediaQueries.xsOnly} {
    padding: 20px;
  }

  @media ${theme.mediaQueries.smUp} {
    padding: 25px;
  }
`}
`;

export const TextComponent = styled(Text)`
  margin-bottom: ${({ size, disableMargin }) =>
    !disableMargin ? (size === "large" ? "30px" : "15px") : 0};

  color: ${({ theme, color }) =>
    color ? theme.colors[color] : theme.colors.formLabelColor};
`;

export const Columns = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const SumList = styled.ul`
  list-style: none;

  li {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    > * {
      position: relative;
      z-index: 2;

      background: ${({ theme, background }) =>
        background ? theme.colors[background] : theme.colors.calculatorBg};

      &:first-child {
        padding-right: 10px;
      }

      &:last-child {
        padding-left: 10px;
      }
    }

    &::before {
      content: "";
      width: 100%;
      height: 2px;
      background-image: ${({ theme }) =>
        `linear-gradient(to right, ${theme.colors.formLabelColor} 33%, rgba(255,255,255,0) 0%)`};

      background-position: bottom;
      background-size: 5px 1px;
      background-repeat: repeat-x;
      position: absolute;
      left: 0;
      bottom: 6px;
    }
  }
`;

export const SumText = styled(Text)`
  color: ${({ theme }) => theme.colors.formLabelColor};
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 40px 0;

  > * {
    min-width: 70%;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 30px auto -20px auto;
  text-align: center;
  justify-content: center;

  a {
    display: block;
    font-weight: 600;
  }

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      a {
        width: 100%;
        font-size: 16px;
        margin-bottom: 25px;
      }
    }

    @media ${theme.mediaQueries.smUp} {
      a {
        width: 50%;
        padding: 0 20px;
        font-size: 17px;
        margin-bottom: 20px;
      }
    }
  `}
`;
