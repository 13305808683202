import { types } from 'mobx-state-tree'

import authenticationState from './Authentication'
import registrationState from './Registration'
import forgotPasswordState from './ForgotPassword'
import profileState from './Profile'
import creditState from './Credit'
import creditRequestState from './CreditRequest'
import referenceDataState from './ReferenceData'
import addressState from './Address'
import messagesState from './Messages'

export default types.model('RootStore', {
  language: types.maybe(types.enumeration('Language', ['bg', 'en'])),
  isLoading: false,
  authenticationState,
  registrationState,
  forgotPasswordState,
  profileState,
  creditState,
  creditRequestState,
  referenceDataState,
  addressState,
  messagesState,
})
  .actions(self => ({
    setLanguage(langCode) {
      self.language = langCode
    },
    setIsLoading(value) {
      self.isLoading = value
    }
  }))
