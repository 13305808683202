import React, { Component } from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import PropTypes from "prop-types";

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapCenter: {
        lat: props.value && props.value.latitude ? props.value.latitude : 42.6977,
        lng: props.value && props.value.longitude ? props.value.longitude : 23.3219
      }
    };    
  }

  onMarkerDragEnd = (coord) => {
    const { latLng } = coord;   
    const lat = latLng.lat();
    const lng = latLng.lng(); 
    //this.setState({ mapCenter: {lat,lng} });
    this.props.onCustomLatLng(lat, lng);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      //this.loadLocation(this.props.address);
      const latLag = {lat: this.props.value?.latitude ? this.props.value?.latitude : 42.6977, lng: this.props.value?.longitude ? this.props.value?.longitude : 23.3219}
      this.setState({ mapCenter: latLag });
    }
  }

  render() {    
    return (
      <div id='googleMaps'>       
        <Map 
          google={this.props.google}
          zoom={17}
          initialCenter={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
          center={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
        >
          <Marker 
            draggable={true}
            onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
            position={{
              lat: this.state.mapCenter.lat,
              lng: this.state.mapCenter.lng
            }} />
        </Map>
      </div>
    )
  }
}
MapContainer.propTypes = {
  google: PropTypes.object,
  value: PropTypes.object,
  onCustomLatLng: PropTypes.func
};
export default GoogleApiWrapper({
  apiKey: ('AIzaSyBCj4DJfadnsk_IPtljzoCnr4eFPmUWzU0')
})(MapContainer)