import { init as initApm } from '@elastic/apm-rum'

const _apm = initApm({
  serviceName: 'cashbox-web',
  serverUrl: 'https://apm.cashbox.bg',
  serviceVersion: `${process.env.REACT_APP_VERSION}`,
  environment: 'production',
  active: true
})

import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { ApmRoute } from '@elastic/apm-rum-react'

import { theme } from "@/GlobalStyle";
import BrowserHistory from "@/BrowserHistory";
import { constants, steps, storageKeys } from "@/constants";
import {
  Login,
  Register,
  ForgotPassword,
  ConfirmPhone,
  Profile,
  MyCredits,
  NotFound,
  Home,
  Messages,
} from "@/containers";
import {
  PrivateAvalsSign,
  PrivateAvalsSigned,
} from "@/containers/CreditRequest";
import {
  EarlyRepayment
} from "@/containers/CreditRenegotiation";
import {
  PrivateRoute,
  CreditRequestModal,
  HeaderComponent,
} from "@/components";

import { useQueryParam } from "./hooks";
import RenderGlobal from "./RenderGlobal";
import { globalSelector, setGlobalTheme } from "./redux/slices/globalSlice";
import { getStorage } from "./utils";

const AppElement = styled.div`
  position: relative;

  .animated-router {
    & > * {
      width: 100%;
      position: absolute;
      background: ${({ theme }) => theme.colors.mainBackground};
    }
  }
`;

const App = () => {
  const { globalTheme } = useSelector(globalSelector);
  const isMobile = useQueryParam(constants.isMobile);
  const dispatch = useDispatch();

  const localStorageTheme = getStorage(storageKeys.theme);

  useEffect(() => {
    if (!isMobile && localStorageTheme) {
      dispatch(setGlobalTheme(localStorageTheme))
    }
  }, [])

  return (
    <ThemeProvider {...{ theme: theme(globalTheme) }}>
      <AppElement>
        <Router history={BrowserHistory}>
          <HeaderComponent />
          <Switch>
            <PrivateRoute
              exact
              path="/confirm-phone"
              component={ConfirmPhone}
              redirect="/login"
            />
            <PrivateRoute
              exact
              path="/profile"
              component={Profile}
              redirect="/login"
            />
            <PrivateRoute exact path="/messages" component={Messages} />
            <PrivateRoute
              exact
              path="/my-credits"
              component={MyCredits}
              redirect="/login"
            />
            <PrivateRoute
              exact
              path="/credit-request/:requestID/private-avals-sign"
              component={PrivateAvalsSign}
              redirect="/login"
            />
            <PrivateRoute
              exact
              path="/credit-request/:requestID/private-avals-signed"
              component={PrivateAvalsSigned}
              redirect="/login"
            />
            <PrivateRoute
                exact
                path="/credit-request/:requestID/early-repayment"
                component={EarlyRepayment}
                redirect="/login"
            />

            {Object.keys(steps).map((key, index) => {
              const { slug, stepWithRequestID, component, onLoad } = steps[key];
              return (
                <PrivateRoute
                  key={key}
                  activeStep={index + 1}
                  redirect="/login"
                  exact
                  path={`/credit-request${stepWithRequestID ? "/:requestID" : ""}${slug ? `/${slug}` : ""}`}
                  {...{
                    component,
                    onLoad,
                  }}
                />
              );
            })}

            <ApmRoute exact path="/login" component={Login} />
            <ApmRoute exact path="/register" component={Register} />
            <ApmRoute exact path="/forgot-password" component={ForgotPassword} />
            <ApmRoute exact path="/" component={Home} />
            <ApmRoute exact path="/home" component={Home} />
            <ApmRoute component={NotFound} />
          </Switch>
          <CreditRequestModal />
        </Router>
        <RenderGlobal />
      </AppElement>
    </ThemeProvider>
  );
};

export default App;
