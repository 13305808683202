import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from '@/assets/svg'
import { AnimatedRender } from '@/components'

import * as S from './Loader.styles';

const LoaderComponent = ({
  isLoading,
  fixedCircle,
  size,
  background,
  position,
}) => (
  <AnimatedRender in={isLoading}>
    <S.FormLoadingOverlay
      {...{
        fixedCircle,
        size,
        background,
        position,
      }}
    >
      <Loader />
    </S.FormLoadingOverlay>
  </AnimatedRender>
)

LoaderComponent.defaultProps = {
  isLoading: true,
  fixedCircle: false,
  size: 'large',
  background: 'light',
  position: 'absolute',
}

LoaderComponent.propTypes = {
  isLoading: PropTypes.bool,
  fixedCircle: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  background: PropTypes.oneOf([
    'light',
    'semiLight',
    'concrete',
    'primary',
    'danger',
  ]),
  position: PropTypes.oneOf(['relative', 'absolute']),
}

export default LoaderComponent
