import styled from "styled-components";

export const LeftSide = styled.div`
  position: absolute;
  width: 70px;
  top: 10px;
  left: 30px;
  color: ${({ theme }) => theme.colors.light};
  font-size: 12px;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => `
    @media ${theme.mediaQueries.mdUp} {
      width: 98px;
      top: 13px;
    }
  `}
`;

export const RightSide = styled.div`
  position: absolute;
  width: 70px;
  top: 8px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  & svg {
    width: 16px;
    height: 16px;
    margin: 0 6px;
    fill: ${({ theme }) => theme.colors.light};
  }

  ${({ theme }) => `
    @media ${theme.mediaQueries.mdUp} {
      width: 98px;
      top: 13px;
    }
  `}
`;

export const PhoneWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.primary};
  padding: 60px 20px 30px 20px;
  margin-bottom: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
    }

    @media ${theme.mediaQueries.smUp} {
      width: 360px;
      border-top-left-radius: 36px;
      border-top-right-radius: 36px;
      padding: 60px 20px 50px 20px;
    }
  `}
`;

export const PhoneNumber = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.light};
  letter-spacing: 0.5px;

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      font-size: 26px;
      margin-bottom: 30px;
    }

    @media ${theme.mediaQueries.smUp} {
      font-size: 36px;
      margin-bottom: 50px;
    }
  `}

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 55px;
    height: 22px;
    background: ${({ theme }) => theme.colors.semiLight};
    border-bottom-left-radius: 36px;
    border-bottom-right-radius: 36px;
    margin: 0 auto;
  }

  ${({ theme }) => `
    @media ${theme.mediaQueries.mdUp} {
      &::before {
        width: 75px;
        height: 28px;
        border-bottom-left-radius: 36px;
        border-bottom-right-radius: 36px;
      }
    }
  `}
`;

export const TextWrapper = styled.div`
  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      text-align: center;
    }
  `}
`;