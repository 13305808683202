import styled from "styled-components";

export const Social = styled.ul`
  text-align: center;
  margin-top: 15px;

  @media ${({ theme }) => theme.mediaQueries.smUp} {
    text-align: right;
    margin-top: 0;
  }

  & li {
    margin: 0 10px;
    display: inline-block;

    & svg {
      width: 24px;
      height: 24px;
      fill: ${({ theme }) => theme.colors.light};

      @media ${({ theme }) => theme.mediaQueries.smUp} {
        width: 26px;
        height: 26px;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
