import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-grid-system";
import { useForm } from "react-hook-form";

import { Documents, FieldGroup } from "@/components";
import { Form, Check, Button, Text } from "@/components/common";
import { SignDocumentsValidator } from "@/formValidators";
import { useQueryParam, useFocusOnError } from "@/hooks";
import GooglePlayBadge from "@/assets/images/Google_Play_badge.png";
import AppStoreBadge from "@/assets/images/App_Store_badge.png";

import * as S from "./SignScreen.styles";
import { constants } from "@/constants";

const SignScreen = ({
  signatureType,
  documents,
  submitAction,
  formAdditionalContent,
  buttonText,
  isLoading,
}) => {
  const isMobile = useQueryParam(constants.isMobile);
  const { handleSubmit, register, errors } = useForm({
    validationSchema: SignDocumentsValidator(),
  });

  useFocusOnError(errors);

  return (
    <>
      {signatureType === constants.signatureType.advanced && (
        <Form
          {...{ isLoading }}
          buttonText={buttonText}
          onSubmit={handleSubmit(() => submitAction())}
          buttonProps={{
            weight: 600,
            size: "large",
          }}
          additionalContent={formAdditionalContent}
        >
          {documents.length > 0 && (
            <Documents
              items={documents.map(({ documentId, name }) => {
                return {
                  name: name,
                  id: documentId,
                };
              })}
            />
          )}

          <FieldGroup>
            <Check
              ref={register}
              {...{ errors }}
              weight={600}
              name="acceptDocuments"
            >
              Запознах се с горните документи и съм съгласен с условията по тях.
            </Check>
          </FieldGroup>
        </Form>
      )}

      {signatureType === constants.signatureType.signaturePad && (
          <Form
              {...{ isLoading }}
              buttonText={buttonText}
              onSubmit={handleSubmit(() => submitAction())}
              buttonProps={{
                weight: 600,
                size: "large",
              }}
              additionalContent={formAdditionalContent}
          >
            {documents.length > 0 && (
                <Documents
                    items={documents.map(({ documentId, name }) => {
                      return {
                        name: name,
                        id: documentId,
                      };
                    })}
                />
            )}

            <FieldGroup>
              <Check
                  ref={register}
                  {...{ errors }}
                  weight={600}
                  name="acceptDocuments"
              >
                Запознах се с горните документи и съм съгласен с условията по тях.
              </Check>
            </FieldGroup>

            <Text size="medium" weight={600}>
              1. Свалих файлa с документите по договор (обикновено е първия най-горе в списъка).
            </Text>
            <Text size="medium" weight={600}>
              2. Клиентът подписа файла на таблет за подпис (Wacom Signature Pad).
            </Text>
            <Text size="medium" weight={600}>
              3. Клиентът попълни и подписа собственоръчно предварително разпечатаната бланка - съгласие за обработка на лични данни.
            </Text>
            <Text size="medium" weight={600}>
              4. Клиентът завери с &quot;Вярно с оригинала&quot;, днешна дата и подпис сканирано и разпечатано копие на личната си карта.
            </Text>
            <Text size="medium" weight={600}>
              5. (НОВО) Клиентът провери и подписа разпечатаното заявление за кредит с данните, които е попълнил при заявката.
            </Text>
            <Text size="medium" weight={600}>
              6. Изпратих горните файлове на CASHBOX в канала за комуникация с тях !!!
            </Text>

          </Form>
      )}

      {signatureType === constants.signatureType.euroTrust && (
        <Row>
          <Col sm={6}>
            <Text weight={600}>
              Инсталирай приложението на Eвротръст и подпиши документите.
            </Text>
            <Text>
              Регистрацията в Евротръст и всички разноски по подписване на
              документите, свързани с CashBox, са{" "}
              <strong>безплатни за клиенти на CashBox.</strong>
            </Text>

            {isMobile ? (
              <S.ButtonWrapper>
                <Button
                  onClick={() => {
                    window.open("cashbox://evrotrust", "_blank");
                  }}
                >
                  Към приложението
                </Button>
              </S.ButtonWrapper>
            ) : (
              <div>
                <img
                  src={AppStoreBadge}
                  width={240}
                  height="auto"
                  onClick={() => {
                    window.open(
                      "https://itunes.apple.com/bg/app/evrotrust/id1195807317?mt=8",
                      "_blank"
                    );
                  }}
                ></img>
                <br />
                <img
                  src={GooglePlayBadge}
                  width={240}
                  height="auto"
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.evrotrust.app",
                      "_blank"
                    );
                  }}
                ></img>
              </div>
            )}

            <Text>
              Ако не продължите, ще загубите условията, които получихте. Имате{" "}
              <strong>24 часов срок</strong>, в който да подпишете документите.{" "}
            </Text>
          </Col>
        </Row>
      )}
    </>
  );
};

SignScreen.defaultProps = {
  signatureType: "",
  documents: [],
  submitAction: () => {},
  formAdditionalContent: <></>,
  buttonText: "Подпиши",
  isLoading: false,
};

SignScreen.propTypes = {
  signatureType: PropTypes.string,
  documents: PropTypes.array,
  submitAction: PropTypes.func,
  formAdditionalContent: PropTypes.elementType,
  buttonText: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default SignScreen;
