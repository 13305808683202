import { types, flow } from 'mobx-state-tree'
import { request, HTTPmethods, setStorage, getStorage, removeStorage } from '@/utils'
import { storageKeys, urls } from '@/constants'
import { toast } from 'react-toastify'

export default types.model('AuthenticationStore', {
  token: types.maybe(types.string),
  session: types.maybe(types.frozen())
})
  .actions(self => ({
    login: flow(function* login (fields) {
      const { data } = yield request({
        method: HTTPmethods.POST,
        url: urls.sessions,
        data: {
          device: { deviceFingerprint: navigator.userAgent },
          ...fields
        }
      })

      const { sessionId } = data

      if (sessionId) {
        self.token = sessionId
        setStorage(storageKeys.token, sessionId)
        toast.success(window.translate.t('messages.successLogin'))
      }
    }),
    getSession: flow(function* getSession () {
      self.token = getStorage(storageKeys.token);

      if (self.token) {
        try {
          const response = yield request({
            method: HTTPmethods.GET,
            url: urls.sessions,
            suppressErrors: true,
          })
          self.session = response.data
        } catch (err) {
          self.logout()
        }
      }
    }),
    setToken(token) {
      self.token = token
      setStorage(storageKeys.token, token)
    },
    logout() {
      request({
        method: HTTPmethods.DELETE,
        url: urls.sessions,
        suppressErrors: true
      }).then(() => {
        toast.success(window.translate.t('messages.successLogout'))
        removeStorage(storageKeys.token)
      })
      .catch(() => removeStorage(storageKeys.token))

      self.token = undefined
    }
  }))
  .views(self => ({
    get isAuthenticated() {
      return Boolean(self.token)
    }
  }))