import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 512 512'
    version='1.1'
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <path d='M469.334 170.682h-5.926L390.125 42.709c-5.667-9.895-14.813-16.978-25.792-19.916-4.086-1.086-8.235-1.575-12.341-1.437-6.886.231-13.65 2.183-19.784 5.739L21.146 208.181C7.548 216.103-.067 230.54 0 245.345a42.623 42.623 0 005.667 21.02L64 368.241v79.762c0 23.53 19.146 42.665 42.667 42.665h362.667c23.521 0 42.667-19.134 42.667-42.665V213.347c-.001-23.53-19.146-42.665-42.667-42.665zM64 213.334V325.32l-39.833-69.57c-5.833-10.198-2.375-23.271 7.729-29.146l32.686-19.029c-.26 1.902-.582 3.787-.582 5.759zM342.959 45.532c4.833-2.833 10.458-3.594 15.854-2.135 5.438 1.458 9.979 4.979 12.813 9.916l67.21 117.369H127.98l214.979-125.15zM490.667 448c0 11.76-9.563 21.333-21.333 21.333H106.667c-11.771 0-21.333-9.573-21.333-21.333V213.334c0-11.76 9.563-21.333 21.333-21.333h362.667c11.771 0 21.333 9.573 21.333 21.333V448z'></path>
    <path d='M458.667 362.667h-128A10.66 10.66 0 00320 373.334a10.66 10.66 0 0010.667 10.667h128a10.66 10.66 0 0010.667-10.667 10.66 10.66 0 00-10.667-10.667zM458.667 298.667H288a10.66 10.66 0 00-10.667 10.667A10.66 10.66 0 00288 320.001h170.667a10.66 10.66 0 0010.667-10.667 10.66 10.66 0 00-10.667-10.667zM192 341.334c23.521 0 42.667-19.135 42.667-42.667S215.521 256 192 256s-42.667 19.135-42.667 42.667S168.48 341.334 192 341.334zm0-64c11.771 0 21.333 9.573 21.333 21.333 0 11.76-9.563 21.333-21.333 21.333-11.771 0-21.333-9.573-21.333-21.333 0-11.761 9.563-21.333 21.333-21.333zM255.73 367.719C240.813 360.552 217.667 352 192 352c-25.667 0-48.813 8.552-63.729 15.719-13.333 6.417-21.604 19.219-21.604 33.406V406c0 11.396 9.563 20.667 21.333 20.667h128c11.771 0 21.333-9.271 21.333-20.667v-4.875c.001-14.187-8.27-26.989-21.603-33.406zm.27 37.615L128 406v-4.875c0-5.917 3.646-11.354 9.521-14.177 12.917-6.208 32.833-13.615 54.479-13.615s41.563 7.406 54.479 13.615c5.875 2.823 9.521 8.26 9.521 14.177v4.209z'></path>
  </svg>
)
