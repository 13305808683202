import styled from 'styled-components'

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(25, 46, 79, 0.6);
  z-index: 12;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`

export const ModalInner = styled.div`
  position: relative;
  margin: 0;
  background: ${props => props.theme.colors.light};
  max-width: calc(100% - 15px);
`

export const Close = styled.div`
  position: absolute;
  width: 22px;
  height: 22px;
  cursor: pointer;
  top: 20px;
  right: 20px;
  z-index: 3;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: ${props => props.theme.colors.dark};
  }
`

export const ModalHeadline = styled.div`
  text-align: center;
  margin-bottom: 50px;
  padding: 0 40px;
`

export const ModalContent = styled.div`
  text-align: center;
  padding: 22px;

  ${({ theme }) => `
    @media ${theme.mediaQueries.xsOnly} {
      width: 100%;
    }

    @media ${theme.mediaQueries.smUp} {
      width: 550px;
    }
  `}
`