import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";

import { Input } from "@/components/common";
import bg from "date-fns/locale/bg";
import { DateIcon } from "@/assets/svg";

import * as S from "./DatePicker.styles";

const DatePickerComponent = ({
  label,
  fullWidth,
  onChange,
  maxDate,
  minDate,
  defaultValue,
  ...restProps
}) => {
  const [date, setDate] = useState(defaultValue);
  registerLocale("bg", bg);

  return (
    <S.DatePickerWrapper>
      <DatePicker
        selected={date}
        locale="bg"
        showYearDropdown
        dateFormat="d MMMM, yyyy"
        dropdownMode="select"
        icon={<DateIcon />}
        autoComplete="off"
        name={restProps?.name}
        customInput={
          <Input
            icon={<DateIcon />}
            {...{
              label,
              errors: restProps?.errors,
              name: restProps?.name,
              fullWidth,
            }}
          />
        }
        onChange={(value) => {
          onChange(moment(new Date(value)).format("YYYY-MM-DD"));
          setDate(value);
        }}
        {...{ maxDate, minDate }}
      />
    </S.DatePickerWrapper>
  );
};

DatePickerComponent.defaultProps = {
  label: "",
  fullWidth: false,
  maxDate: null,
  minDate: null,
  defaultValue: null,
};

DatePickerComponent.propTypes = {
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  defaultValue: PropTypes.object,
};

export default DatePickerComponent;
