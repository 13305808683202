import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import { getYupError } from "@/utils";
import { Field } from "@/components/common";
import { ArrowIcon } from "@/assets/svg";

import * as S from './Select.styles';

const SelectClass = "Select";

const SelectComponent = ({
  label,
  color,
  customStyle,
  size,
  fullWidth,
  instructions,
  onChange,
  isAsync,
  defaultValue,
  isSearchable,
  ...restProps
}) => {
  const [selected, setSelected] = useState(null);
  
  return (
    <S.SelectWrapper {...{ fullWidth }}>
      <Field
        {...{
          label,
          size,
          color,
          instructions,
          errors: restProps?.errors,
          name: restProps?.name,
          customStyle
        }}
      >
        <S.Select
          {...{
            size,
            color,
            customStyle,
            fullWidth,
            hasError: Boolean(getYupError(restProps?.errors, restProps?.name)),
          }}
          onChange={(selected) => {
            onChange && onChange(selected.value, selected);
            setSelected(selected);
          }}
          as={isAsync ? AsyncSelect : ReactSelect}
          autoFocus={false}
          selectedValue={selected?.value}
          // value={selected ? selected : defaultValue}
          defaultValue={defaultValue}
          components={{
            DropdownIndicator: () => (
              <S.DropdownIndicator>
                <ArrowIcon />
              </S.DropdownIndicator>
            ),
          }}
          classNamePrefix={SelectClass}
          placeholder="Избери..."
          isSearchable={isSearchable}
          {...restProps}
        />
      </Field>
    </S.SelectWrapper>
  );
};

SelectComponent.defaultProps = {
  label: "",
  size: "small",
  color: "formLabelColor",
  customStyle: "",
  fullWidth: false,
  instructions: [],
  isAsync: false,
  defaultValue: undefined,
  isSearchable: false
};

SelectComponent.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(["extraSmall", "small", "large"]),
  fullWidth: PropTypes.bool,
  color: PropTypes.oneOf(["light", "primary", "formLabelColor"]),
  customStyle: PropTypes.string,
  instructions: PropTypes.array,
  onChange: PropTypes.func,
  isAsync: PropTypes.bool,
  defaultValue: PropTypes.object,
  isSearchable: PropTypes.bool
};

export default SelectComponent;
